import { message } from "antd";

class ValidationService {
  constructor(object) {
    this.object = object;
  }

  // Helper function to check if a value is empty
  static isObjectEmpty(value) {
    return value === null || value === undefined || 
           (typeof value === 'string' && value.trim() === '') || 
           (typeof value === 'string' && value.trim() === "") || 
           (typeof value === 'object' && Object.keys(value).length === 0);
  }

  // Method to check if the object or any of its children keys are empty
  hasEmptyKeys(obj = this.object) {
    if (typeof obj !== 'object' || obj === null) {
      // If obj is not an object, or if it's null, return false because we can't check keys.
      return false;
    }

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];

        // Check if the current value is empty
        if (ValidationService.isObjectEmpty(value)) {
          return true;
        }

        // If the current value is an object or an array, recurse
        if (typeof value === 'object') {
          if (Array.isArray(value)) {
            // If it's an array, check each element
            for (const item of value) {
              if (typeof item === 'object' && this.hasEmptyKeys(item)) {
                return true;
              } else if (ValidationService.isObjectEmpty(item)) {
                return true;
              }
            }
          } else {
            // If it's an object, recurse
            if (this.hasEmptyKeys(value)) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  hasEmptyKeysForms (obj) {
    let isSectionDescriptionEmpty = false;
  
    // Helper function to check if a value is empty
    const isEmpty = (value) => {
      return (
        value === null ||
        value === undefined ||
        value === "" ||
        (Array.isArray(value) && value.length === 0) ||
        (typeof value === "object" && !Array.isArray(value) && Object.keys(value).length === 0)
      );
    };
  
    // Recursive function to check keys and values in the object
    const checkObject = (obj) => {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const value = obj[key];
  
          // Check if sectionDescription is empty
          if (key === "sectionDescription") {
            if (isEmpty(value)) {
              isSectionDescriptionEmpty = true;
            } else {
              return false; // sectionDescription is filled, so return false
            }
          } else {
            // For other keys, return false if any are empty
            if (isEmpty(value)) {
              return false;
            }
  
            // If it's an object or array, recurse into it
            if (typeof value === "object" && value !== null) {
              if (Array.isArray(value)) {
                // Check each element in the array
                for (const item of value) {
                  if (!checkObject(item)) {
                    return false;
                  }
                }
              } else {
                // If it's an object, recurse into it
                if (!checkObject(value)) {
                  return false;
                }
              }
            }
          }
        }
      }
      return true; // Return true if all fields are valid
    };
  
    // Start checking the object
    const allFieldsFilled = checkObject(obj);
  
    // Return true if sectionDescription is empty and all other fields are filled
    return isSectionDescriptionEmpty && allFieldsFilled;
  };
  
  

  hasEmptyKeysWithByPassKeys(obj = this.object, bypassKeys = []) {
    if (typeof obj !== 'object' || obj === null) {
      // If obj is not an object, or if it's null, return false because we can't check keys.
      return false;
    }
  
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        // If the key is in the bypassKeys list, continue to the next iteration   
        if (bypassKeys.includes(key)) {
          continue;
        }
  
        const value = obj[key];
        // Skip the key if it's sectionDescription
        if (key === 'sectionDescription') {
          continue; // Skip checking this key
        }
    
        // Check if the current value is empty
        if (ValidationService.isObjectEmpty(value)) {
          return true;
        }
  
        // If the current value is an object or an array, recurse
        if (typeof value === 'object') {
          if (Array.isArray(value)) {
            // If it's an array, check each element
            for (const item of value) {
              if (typeof item === 'object' && this.hasEmptyKeysWithByPassKeys(item, bypassKeys)) {
                return true;
              } else if (ValidationService.isObjectEmpty(item)) {
                return true;
              }
            }
          } else {
            // If it's an object, recurse
            if (this.hasEmptyKeys(value, bypassKeys)) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  fieldWithError(field) {
    const errors = [];
  
    // Helper function to check if a value is empty
    const isEmpty = (value) => {
      if (value === null || value === undefined || value === '') return true;
      if (Array.isArray(value) && value.length === 0) return true;
      if (typeof value === 'object' && !Array.isArray(value) && Object.keys(value).length === 0) return true;
      return false;
    };
  
    // Iterate over the field's keys and check for errors
    for (let key in field) {
      if (field.hasOwnProperty(key)) {
        const value = field[key];
  
        // If the value is empty or invalid, add the key to errors
        if (isEmpty(value)) {
          errors.push(key);
        }
      }
    }
  
    return errors; // Returns the array of keys with errors
  }

  sectionsWithError (data) {
    const errors = [];

    // Helper function to check if a value is empty
    const isEmpty = (value) => {
      return (
        value === null ||
        value === undefined ||
        value === "" ||
        (Array.isArray(value) && value.length === 0) ||
        (typeof value === "object" && !Array.isArray(value) && Object.keys(value).length === 0)
      );
    };
  
    // Recursive function to check keys and values
    const checkObject = (obj, parentKey) => {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const value = obj[key];
  
          // If the value is empty, add the key to the error array
          if (isEmpty(value)) {
            errors.push(`${parentKey}.${key}`); // Include the parent key and the key itself
          }
  
          // If the value is an object or an array, recurse into it
          if (typeof value === "object" && value !== null && !Array.isArray(value)) {
            checkObject(value, `${parentKey}.${key}`); // Keep track of the parent key
          }
        }
      }
    };
  
    // Check the top-level object
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key];
        if(key === 'sectionDescription'){
          continue
        }
        console.log('value for validation: , value');
  
        // If it's an array (like the "fields" array), check each object in the array
        if (Array.isArray(value)) {
          value.forEach((item, index) => {
            checkObject(item, key); // Parent key is "fields" in this case
          });
        } else if (typeof value === "object" && value !== null) {
          checkObject(value, key); // For any other nested object
        } else {
          // If it's a primitive value, check if it's empty
          if (isEmpty(value)) {
            errors.push(key);
          }
        }
      }
    }
  
    return errors;
  };

  //--------------------New Validation Functions For Individual Components----------------------------
  validateMessageStrips(messageStrips) {
    // Check if the input is a valid array
    if (!Array.isArray(messageStrips)) {
      return { isValid: false, message: "Input is not an array" };
    }
    // Iterate over each object in the array
    for (let i = 0; i < messageStrips.length; i++) {
      const strip = messageStrips[i];
  
      // Iterate over each key-value pair in the object
      for (let key in strip) {
        // Check if the key is not empty and the value is a non-empty string
        if (!strip[key] || typeof strip[key] !== 'string' || strip[key].trim() === '') {
          return { isValid: false, message: `Invalid or empty value for key "${key}" in object at index ${i}` };
        }
      }
    }
    return { isValid: true, message: "All objects are valid" };
  };

  validateDependant(obj) {
    // Define the required keys
    const requiredKeys = ['title', 'field'];
  
    // Iterate over each required key to check if it exists and has a non-empty value
    for (const key of requiredKeys) {
      if (!obj.hasOwnProperty(key) || typeof obj[key] !== 'string' || obj[key].trim() === '') {
        return {
          isValid: false,
          message: `Missing or empty value for required key: "${key}"`,
        };
      }
    }
  
    // If all required keys are present and non-empty, return valid
    return {
      isValid: true,
      message: "All required keys are present and valid",
    };
  };

  validateStaticContent(obj) {
    // Define the required keys
    const requiredKeys = ['contentType', 'text', 'level'];
  
    // Iterate over each required key to check if it exists and has a non-empty value
    for (const key of requiredKeys) {
      if (!obj.hasOwnProperty(key) || typeof obj[key] !== 'string' || obj[key].trim() === '') {
        return {
          isValid: false,
          message: `Missing or empty value for required key: "${key}"`,
        };
      }
    }
  
    // If all required keys are present and non-empty, return valid
    return {
      isValid: true,
      message: "All required keys are present and valid",
    };
  };

  validateValueHelps(valueHelpsArray) {
    // Define the required keys for each object
    const requiredKeys = [
      'referenceSectionName',
      'referenceField',
      'valueHelpFilter',
      'referenceFieldValueHelpKey',
    ];
  
    // Check if the input is a valid array
    if (!Array.isArray(valueHelpsArray)) {
      return { isValid: false, message: "Input is not an array" };
    }
  
    // Iterate over each object in the array
    for (let i = 0; i < valueHelpsArray.length; i++) {
      const valueHelp = valueHelpsArray[i];
  
      // Check if each required key exists and has a non-empty string value
      for (const key of requiredKeys) {
        if (!valueHelp.hasOwnProperty(key) || typeof valueHelp[key] !== 'string' || valueHelp[key].trim() === '') {
          return {
            isValid: false,
            // message: `Missing or empty value for key "${key}" in object at index ${i}`,
            message: 'Fill all required inputs for value help filters'
          };
        }
      }
    }
  
    // If all objects are valid, return a positive result
    return {
      isValid: true,
      message: "All objects in the array are valid",
    };
  };

  validateOptionsApi(obj) {
    // Define the required keys
    const requiredKeys = ['url', 'label', 'value', 'displayPattern']; //default is not mandatory
    // Iterate over each required key to check if it exists and has a non-empty value
    for (const key of requiredKeys) {
      if (!obj.hasOwnProperty(key) || typeof obj[key] !== 'string' || obj[key].trim() === '') {
        return {
          isValid: false,
          // message: `Missing or empty value for required key: "${key}"`,
          message: 'Fill all required values for option'
        };
      }
    };

    const isValidValueHelpFilters = this.validateValueHelps(obj?.valueHelpFilters || []);
    if (!isValidValueHelpFilters.isValid){
      return isValidValueHelpFilters;
    };
  
    return {
      isValid: true,
      message: "All required keys are present and valid",
    };
  };

  validateField(field) {
    // Define required keys with their expected types
    const expectedKeys = {
        name: 'string',
        label: 'string',
        type: 'string',
        dataType: 'string',
        length: 'number',
        required: 'boolean',
        wrapping: 'boolean',
        visible: 'boolean',
    };

    const errorKeys = [];

    // Iterate over the expected keys and validate each one
    for (const [key, type] of Object.entries(expectedKeys)) {
      const value = field[key];
      if (value === null || value === undefined || value === '') {
        errorKeys.push(key);
      };
        // // Check if the key exists in the field object
        // if (!(key in field)) {
        //     return {
        //         isValid: false,
        //         message: `Validation failed: Missing key '${key}'.`,
        //     };
        // }

        //   // Check if the value is empty, null, or undefined
        //   const value = field[key];
        //   if (value === null || value === undefined || (type === 'string' && value.trim() === '')) {
        //       return {
        //           isValid: false,
        //           message: `Validation failed: Key '${key}' cannot be empty, null, or undefined.`,
        //       };
        //   }
        
        // // Check if the key's value matches the expected type
        // if (typeof field[key] !== type) {
        //     return {
        //         isValid: false,
        //         message: `Validation failed: Key '${key}' should be of type '${type}', but got '${typeof field[key]}'.`,
        //     };
        // }

    }

    if (errorKeys.length > 0){
      return {
          isValid: false,
          message: 'Validation failed.',
          errorKeys
      };
    }
    return {
      isValid: true,
      message: 'Validation successful.',
      errorKeys
    };

}

validateSection(section) {
  const { title, description, fields } = section;
  const errorKeys = [];
  const fieldErrors = [];

  // Validate title (cannot be empty)
  if (!title || typeof title !== 'string' || title.trim() === '') {
    errorKeys.push('title');
  }

  // Description is optional, so no need to validate its presence or type
  if (description && typeof description !== 'string') {
    errorKeys.push('description');
  }

  if (fields.length <= 0){
    errorKeys.push('fields');
  }

  // Validate each field using the validateField function
  if (Array.isArray(fields)) {
      fields.forEach((field, index) => {
          const result = this.validateField(field);
          if (!result.isValid) {
              fieldErrors.push({
                  fieldIndex: index,
                  errorKeys: result.errorKeys,
                  message: result.message
              });
          }
      });
  } else {
    errorKeys.push('fields');
  }

  // Determine the overall validity of the section
  const isValid = errorKeys.length === 0 && fieldErrors.length === 0;

  return {
      isValid,
      message: isValid ? 'Validation successful.' : 'Validation failed.',
      errorKeys, // Errors related to section structure
      fieldErrors // Errors related to individual fields
  };
};

validateFormHeaders(headers){
  let headerCheck = true;
  Object.values(headers).forEach((value) => {
    if (value === "") {
      headerCheck = false;
    }
  });
  return headerCheck;
};


validateForm(form) {
    const { sections } = form;
    const sectionErrors = [];

    // Validate each section using the validateSection function
    if (Array.isArray(sections)) {
      sections.forEach((section, index) => {
        const result = this.validateSection(section);
        if (!result.isValid) {
          sectionErrors.push({
            sectionIndex: index,
            errors: {
              errorKeys: result.errorKeys,
              fieldErrors: result.fieldErrors,
              message: result.message,
            },
          });
        }
      });
    } else {
      // If sections is not an array, add a validation error
      return {
        isValid: false,
        message: 'Validation failed: Sections must be an array.',
        sectionErrors: [{
          sectionIndex: -1,
          errors: {
            message: 'Sections must be an array.',
          },
        }],
      };
    }

    // Determine the overall validity of the form
    const isValid = sectionErrors.length === 0;

    return {
      isValid,
      message: isValid ? 'Form validation successful.' : 'Form validation failed.',
      sectionErrors,
    };
  };


};


const validationService = new ValidationService();
export default validationService;