import React from 'react';
import { Modal, Box, Button, Typography , IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const PublishFormsModal = ({ open, handleClose, submitPublishForms }) => {
  const handlePublishPublic = () => {
    submitPublishForms('public');
    handleClose();
  };

  const handlePublishPrivate = () => {
    submitPublishForms('publish');
    handleClose();
  };

  const handlePublishRemove = () => {
    submitPublishForms('private');
    handleClose();
  };

  const handleCancel = () => {
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleCancel}
      aria-labelledby="publish-forms-modal-title"
      aria-describedby="publish-forms-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 300,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <IconButton
        onClick={handleCancel}
        sx={{
          position: 'absolute',
          top: 8,
          right: 8,
          color: 'grey.600',
          ':hover': {
            color: 'black',
          },
        }}
      >
        <CloseIcon />
    </IconButton>
        <Typography id="publish-forms-modal-title" variant="h6" component="h2">
          Publish Forms
        </Typography>
        <Button
        onClick={handlePublishPublic}
        sx={{
          mt: 2,
          width: '100%',
          backgroundColor: 'var(--button-color)',
          color: 'white', // Set text color to white
          fontWeight: 'normal',
          textTransform: 'none', // Prevent uppercase text
          ":hover": {
            backgroundColor: 'var(--button-hover-color)',
          },
        }}
      >
        Make Available To Public
      </Button>
      <Button
        onClick={handlePublishPrivate}
        sx={{
          mt: 2,
          width: '100%',
          backgroundColor: 'var(--button-color)',
          color: 'white', // Set text color to white
          fontWeight: 'normal',
          textTransform: 'none', // Prevent uppercase text
          ":hover": {
            backgroundColor: 'var(--button-hover-color)',
          },
        }}
      >
        Publish For My Company
      </Button>
      <Button
        onClick={handlePublishRemove}
        sx={{
          mt: 2,
          width: '100%',
          backgroundColor: 'var(--button-color)',
          color: 'white', // Set text color to white
          fontWeight: 'normal',
          textTransform: 'none', // Prevent uppercase text
          ":hover": {
            backgroundColor: 'var(--button-hover-color)',
          },
        }}
      >
        Do not publish this form
      </Button>
      <Button
        variant="outlined"
        onClick={handleCancel}
        sx={{
          mt: 2,
          width: '100%',
          color: 'var(--button-color)', // Set text color to white
          backgroundColor: 'transparent',
          borderColor: 'var(--button-color)',
          textTransform: 'none', // Prevent uppercase text
          ":hover": {
            borderColor: 'var(--button-color)',
          },
        }}
      >
        Cancel
      </Button>
      </Box>
    </Modal>
  //   <Modal
  //   open={open}
  //   onClose={handleCancel}
  //   aria-labelledby="publish-forms-modal-title"
  //   aria-describedby="publish-forms-modal-description"
  // >
  //   <Box
  //     sx={{
  //       position: 'absolute',
  //       top: '50%',
  //       left: '50%',
  //       transform: 'translate(-50%, -50%)',
  //       width: 300,
  //       bgcolor: 'background.paper',
  //       boxShadow: 24,
  //       p: 4,
  //       display: 'flex',
  //       flexDirection: 'column',
  //       alignItems: 'center',
  //       justifyContent: 'center',
  //     }}
  //   >
  //     <Typography id="publish-forms-modal-title" variant="h6" component="h2">
  //       Publish Forms
  //     </Typography>
  //     <Button
  //     onClick={handlePublishPublic}
  //     sx={{
  //       mt: 2,
  //       width: '100%',
  //       backgroundColor: 'var(--button-color)',
  //       color: 'white', // Set text color to white
  //       fontWeight: 'normal',
  //       textTransform: 'none', // Prevent uppercase text
  //       ":hover": {
  //         backgroundColor: 'var(--button-hover-color)',
  //       },
  //     }}
  //   >
  //     Publish To Document Store
  //   </Button>
  //   {/* <Button
  //     onClick={handlePublishRemove}
  //     sx={{
  //       mt: 2,
  //       width: '100%',
  //       backgroundColor: 'var(--button-color)',
  //       color: 'white', // Set text color to white
  //       fontWeight: 'normal',
  //       textTransform: 'none', // Prevent uppercase text
  //       ":hover": {
  //         backgroundColor: 'var(--button-hover-color)',
  //       },
  //     }}
  //   >
  //     Remove From Document Store
  //   </Button> */}
  //   <Button
  //     variant="outlined"
  //     onClick={handleCancel}
  //     sx={{
  //       mt: 2,
  //       width: '100%',
  //       color: 'var(--button-color)', // Set text color to white
  //       backgroundColor: 'transparent',
  //       borderColor: 'var(--button-color)',
  //       textTransform: 'none', // Prevent uppercase text
  //       ":hover": {
  //         borderColor: 'var(--button-color)',
  //       },
  //     }}
  //   >
  //     Cancel
  //   </Button>
  //   </Box>
  // </Modal>
  );
};

export default PublishFormsModal;
