import React from 'react';
import {
  Modal,
  Box,
  Typography,
  IconButton,
  Button,
  Tooltip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const FormDetails = ({ isOpen, handleClose, formId, companyAlias }) => {
  // States for URLs
  const publicURL = `https://app.freeform.citras.io/displayForm/${companyAlias}/${formId}`;
  const privateURL = `https://app.freeform.citras.io/displayPublicForms/${companyAlias}/${formId}`;

  // Copy to clipboard function
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    alert('Copied to clipboard!');
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="form-details-title"
      aria-describedby="form-details-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '8px',
          boxShadow: 24,
          width: '400px',
          outline: 'none',
          paddingBottom: '30px',
        }}
      >
        {/* Close Icon */}
        <IconButton
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>

        {/* Title */}
        <h3>Form Details</h3>
        <hr />

        {/* Public URL Section */}
        <Typography variant="body1" sx={{ mt: 2, mb: 1 }}>
          Public URL
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#f5f5f5',
            padding: '10px',
            borderRadius: '4px',
            overflowWrap: 'break-word', // Ensures long words wrap
            wordBreak: 'break-word', // Breaks long URLs if necessary
          }}
        >
          <Typography variant="body2" sx={{ flex: 1, whiteSpace: 'normal' }}>
            {publicURL}
          </Typography>
          <Tooltip title="Copy">
            <IconButton
              onClick={() => copyToClipboard(publicURL)}
              sx={{ ml: 1 }}
            >
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
        </Box>

        {/* Private URL Section */}
        <Typography variant="body1" sx={{ mt: 2, mb: 1 }}>
          Private URL
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#f5f5f5',
            padding: '10px',
            borderRadius: '4px',
            overflowWrap: 'break-word', // Ensures long words wrap
            wordBreak: 'break-word', // Breaks long URLs if necessary
          }}
        >
          <Typography variant="body2" sx={{ flex: 1, whiteSpace: 'normal' }}>
            {privateURL}
          </Typography>
          <Tooltip title="Copy">
            <IconButton
              onClick={() => copyToClipboard(privateURL)}
              sx={{ ml: 1 }}
            >
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
        </Box>

        {/* Close Button */}
        <Button
          variant="outlined"
          onClick={handleClose}
          sx={{
            mt: 2,
            width: '100%',
            color: 'var(--button-color)',
            borderColor: 'var(--button-color)',
            textTransform: 'none',
            ':hover': {
              borderColor: 'var(--button-color)',
            },
          }}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default FormDetails;