import React, { useEffect, useState } from 'react';
import {
  Box,
  IconButton,
  Button,
  TextField,
  List,
  ListItem,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close'; // Import the Close Icon
import classes from './MessageStrip.module.css';
import validationService from '../../../common/validationService.service';
import CustomAlert from '../../CustomAlert/CustomAlert';

const values = [  'VALID_VALUE_ID',  'VALID_VALUE_SHORT_DESC',  'VALID_VALUE_DESCRIPTION',  'PARAMETER_ID',  'PARAMETER',  'FILTER1',  'FILTER2',  'FILTER3',  'FILTER4',  'COUNTER',  'ENTITY',  'VERSION',  'ATTRIBUTE1',  'ATTRIBUTE2',  'VALID_VALUE_ADDITIONAL_TEXT'];
const types = ['Error','Information','None','Success','Warning'];

const MessageStrip = (props) => {
  const [messageStrips, setMessageStrips] = useState([]);
  const [isEdit, setIsEdit] = useState(false);

  // Message Strip Values and Types
  const [messageStripValues] = useState(values);
  const [messageStripTypes] = useState(types);

  const [displayAlert, setDisplayAlert] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  useEffect(() => {
    if (props.messageStrip) {
      setMessageStrips(props.messageStrip|| []);
    }
    if (props.isEdit) {
      setIsEdit(true);
    }
  }, [props.strip]);

  const handleAdd = () => {
    setMessageStrips([...messageStrips, { conditionValue: '', messageStripValue: '', messageStripType: '' }]);
  };

  const handleUpdateMessageStrip = (index, key, value) => {
    const updatedStrips = [...messageStrips];
    updatedStrips[index][key] = value;
    setMessageStrips(updatedStrips);
  };

  const handleRemove = (index) => {
    const updatedStrips = messageStrips.filter((_, i) => i !== index);
    setMessageStrips(updatedStrips);
  };

  const handleSubmitStrips = () => {
    messageStrips.forEach((m)=>{
      if (m.conditionValue === '') {
          delete m['conditionValue']
      }
    });
    const isValidMessageStrip = validationService.validateMessageStrips(messageStrips);
    console.log("isValidMessageStrip: ", isValidMessageStrip);
    if (!isValidMessageStrip.isValid) {
        // alert('Message Strip Values cannot be empty');
        setAlertMessage('Message Strip Values cannot be empty');
        setAlertType("error");
        setDisplayAlert(true);
        setTimeout(() => setDisplayAlert(false), 3000);
      } else {
        props.submitMessageStrips(messageStrips);
        props.handleClose();
      }
  };

  return (
    
    <Dialog
      open={props.isOpen}
      onClose={props.handleClose}
      maxWidth="sm"
      fullWidth
    >
        {
        displayAlert &&
        <CustomAlert isOpen={displayAlert} message={alertMessage} type={alertType}></CustomAlert>
        }

      {/* Add Cancel Icon at the Top Right */}
      <DialogTitle>
        Message Strips
        {!displayAlert && <IconButton
          aria-label="close"
          onClick={props.handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>}
      </DialogTitle>

      <DialogContent>
        <Box sx={{ width: '100%', p: 0, m: 0, borderRadius: 2 }}>
          <List sx={{ mt: 0, p: 0 }}>
            {messageStrips.map((strip, index) => (
              <ListItem key={index} sx={{ p: 0, mt: 1 }}>
                <Grid container spacing={2} alignItems="center">
                  {/* Condition Input */}
                  <Grid item xs={4}>
                    <TextField
                      value={strip.conditionValue}
                      onChange={(e) => handleUpdateMessageStrip(index, 'conditionValue', e.target.value)}
                      fullWidth
                    //   placeholder={`Condition ${index + 1}`}
                      placeholder = 'Condition'
                      sx={{
                        "& .MuiInputBase-root": {
                          height: 40,
                          backgroundColor: 'white',
                        },
                        "& .MuiOutlinedInput-input": {
                          height: 40,
                          width: '100%',
                        },
                      }}
                    />
                  </Grid>

                  {/* Message Strip Value (Dropdown Select) */}
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <Select
                        value={strip.messageStripValue}
                        onChange={(e) => handleUpdateMessageStrip(index, 'messageStripValue', e.target.value)}
                        sx={{ height: '40px', backgroundColor: 'white' }}
                        displayEmpty
                      >
                        <MenuItem value="" disabled>
                         Value
                        </MenuItem>
                        {messageStripValues.map((value) => (
                          <MenuItem key={value} value={value}>
                            {value.charAt(0).toUpperCase() + value.slice(1)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* Message Strip Type (Dropdown Select) */}
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <Select
                        value={strip.messageStripType}
                        onChange={(e) => handleUpdateMessageStrip(index, 'messageStripType', e.target.value)}
                        sx={{ height: '40px', backgroundColor: 'white' }}
                        displayEmpty
                      >
                        <MenuItem value="" disabled>
                          Type
                        </MenuItem>
                        {messageStripTypes.map((type) => (
                          <MenuItem key={type} value={type}>
                            {type.charAt(0).toUpperCase() + type.slice(1)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                {/* Delete Button Outside the Form Controls */}
                <Grid container sx={{ mt: 0 }}  xs={1}>
                  <IconButton edge="end" onClick={() => handleRemove(index)}>
                    <DeleteIcon sx={{ color: 'var(--button-color-red)', ":hover": { color: 'var(--button-hover-color-red)' } }} />
                  </IconButton>
                </Grid>
              </ListItem>
            ))}
          </List>
          <Button
            variant="contained"
            onClick={handleAdd}
            sx={{
              mt: 2,
              width: '100%',
              backgroundColor: 'var(--button-color)',
              color: 'white',
              fontWeight: 'normal',
              textTransform: 'none',
              ":hover": {
                backgroundColor: 'var(--button-hover-color)',
              },
            }}
          >
            Add Strip
          </Button>
        </Box>
      </DialogContent>

      <DialogActions sx = {{justifyContent: 'space-between', pl: '24px', pr: '24px', pb: '24px'}}>
        <Button
          variant="contained"
          onClick={handleSubmitStrips}
          sx={{
            backgroundColor: 'var(--button-color)',
            color: 'white',
            fontWeight: 'normal',
            textTransform: 'none',
            ":hover": {
              backgroundColor: 'var(--button-hover-color)',
            },
          }}
        >
          Submit Strips
        </Button>
        <Button
          variant="contained"
          onClick={props.removeMessageStrip}
          sx={{
            backgroundColor: 'var(--button-color-red)',
            color: 'white',
            fontWeight: 'normal',
            textTransform: 'none',
            ":hover": {
              backgroundColor: 'var(--button-hover-color-red)',
            },
          }}
        >
          Remove Message Strip
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MessageStrip;