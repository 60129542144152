import React, { useState, useEffect, useRef } from 'react';
import { 
  Box, Button, IconButton, List, ListItem, ListItemText, Typography, 
  Container, Toolbar, Paper, Table, TableBody, TableCell, TableContainer, 
  TableHead, TableRow, TextField, InputAdornment, Divider, Tooltip
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import SearchIcon from '@mui/icons-material/Search';
import apiGatewayService from '../../common/services/apiGatewayService';
import localStorageService from '../../common/services/localStorage.service';
import utilityFunctions from '../../common/services/utilityFunctions';
import CircularLoader from '../Loader/Loader';
import FieldBuilder from '../FormBuilder/FieldBuilder/FieldBuilder';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CustomAlert from '../CustomAlert/CustomAlert';
import DeleteConfirmation from '../DeleteConfirmation/DeleteConfirmation';
import { useNavigate } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import "ag-grid-community/styles/ag-theme-material.css"; // Optional Theme applied to the Data Grid
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional Theme applied to the Data Grid
import TableActions from '../TableActions/TableActions';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import RefreshIcon from '@mui/icons-material/Refresh';

const tableColumnToKeyMapping = {
  'Name': "fieldName",
  "Description": "fieldDescription"
};
  

const Fields = (props) => {
  const [fields, setFields] = useState([]);
  const [isAddNew, setIsAddNew] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [viewIndex, setIsViewIndex] = useState(null);
  const [currentField, setCurrentField] = useState(null);
  const [cognitoUser, setCognitoUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isOnlyReadAccess, setIsOnlyReadAccess] = useState(false);
  const [open, setOpen] = useState(false);
  const [filterFieldName, setFilterFieldName] = useState('');
  const [isView, setIsView] = useState(false);
  const [displayAlert, setDisplayAlert] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [fieldToDelete, setFieldToDelete] = useState(null);
  const [filterFieldDescription, setFilterFieldDescription] = useState('');
  const [filteredFields, setFilteredFields] = useState(fields);
  const [sortKey, setSortKey] = useState(null);
  const gridRef = useRef(null); // Ref for accessing the grid API
  const [allSelectChecked, setAllSelectChecked] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedSingleForm, setSelectedSingleForm] = useState(null);
  const [currentFieldId, setCurrentFieldId ] =  useState(null);

  const pagination = true;
  const paginationPageSize = 10;
  const paginationPageSizeSelector = [10, 100, 1000];

  const handleSelectedAll = (e) => {
    console.log(e.target.checked);
    const isChecked = !allSelectChecked;
    // setSelectAll(isChecked); // Update state
    if (gridRef.current) {
        const api = gridRef.current.api;

        if (isChecked) {
            setAllSelectChecked(true);

            // Select rows on the current pagination page
            api.forEachNodeAfterFilterAndSort((node) => {
                if (node.rowIndex >= api.paginationGetCurrentPage() * api.paginationGetPageSize() &&
                    node.rowIndex < (api.paginationGetCurrentPage() + 1) * api.paginationGetPageSize()) {
                    node.setSelected(true);
                }
            });
        } else {
            setAllSelectChecked(false);

            // Deselect rows on the current pagination page
            api.forEachNodeAfterFilterAndSort((node) => {
                if (node.rowIndex >= api.paginationGetCurrentPage() * api.paginationGetPageSize() &&
                    node.rowIndex < (api.paginationGetCurrentPage() + 1) * api.paginationGetPageSize()) {
                    node.setSelected(false);
                }
            });
        }

        // Access selected rows if needed
        const selectedRows = api.getSelectedRows();
        const currSelectedIds =  selectedRows.map((r)=>r.formId);
        setSelectedIds(currSelectedIds);
        console.log("Currently selected rows:", selectedRows);
    }
};

const onRowSelected = () => {
    if (gridRef.current) {
      const api = gridRef.current.api;
      const currentlySelectedRows = api.getSelectedRows(); // Get all selected rows
    //   setSelectedRows(currentlySelectedRows); // Update state
      const selectedIds = currentlySelectedRows.map((m)=>m.fieldId);
      console.log("Selected Rows:", selectedIds);
      setSelectedIds(selectedIds);
    }
  };


  const [colDefs, setColDefs] = useState([
    {
      // cellRenderer: put your components here
      // valueGetter: p => p.data.formName+ ' '+p.data.status, // if you want concatenated values
      field: "fieldName",
      headerName: "Name",
      flex: 1,
      filter: true,
      floatingFilter: true,
      checkboxSelection: true,
      sortable: true,
    //   pinned: 'left'
    },
    {
      field: "fieldDescription",
      headerName: "Description",
      flex: 1,
      filter: true,
      floatingFilter: true,
    },
    {
        field: 'Options',
        flex: 1,
        cellRenderer: (params) => (
            <TableActions
              handleView={() => handleView(params.data)}
              handleEdit={() => handleEdit(params.data)}
              openDeleteModal={() => openDeleteModal(params.data)}
              index={params.data}
              isOnlyReadAccess={isOnlyReadAccess}
            />
          ),
    }
  ]);


  
  const navigate = useNavigate();

  const handleOpen = () => setOpen(true);
  const handleClose = async (alertObject) => {
    setOpen(false);
    setAllFalse();
    await getAllFields();
    if(alertObject?.type && alertObject?.message) {
      setAlertMessageAndPopup(alertObject);
    };
  }

  const setAlertMessageAndPopup  = (alertObject) => {
    setDisplayAlert(true);
    setAlertMessage(alertObject.message);
    setAlertType(alertObject.type);
    setTimeout(() => setDisplayAlert(false), 10000);
  }

  useEffect(() => {
    const getOptions = async (user) => {
      try {
        const response = await apiGatewayService.getFieldsData();
        const dataWithUpdatedKeyAndContent = response.data;
        dataWithUpdatedKeyAndContent.sort((a, b) => {
          if (a.fieldName < b.fieldName) return -1;
          if (a.fieldName > b.fieldName) return 1;
          return 0;
        });
        setFields(Array.from(dataWithUpdatedKeyAndContent));
      } catch (error) {
        if (error.response && error.response.status === 403) {
          console.log("Forbidden error detected, redirecting to login...");
          navigate('/');
        } else {
          console.error('Error fetching fields:', error);
        }
      }
      setIsLoading(false);
    };

    const user = localStorageService.getObject('user');
    setCognitoUser(user);
    setIsOnlyReadAccess(utilityFunctions.checkIfOnlyReadAccess(user));
    getOptions(user);
  }, []);

  const getAllFields = async () => {
    try {
      const response = await apiGatewayService.getFieldsData();
      if (response.status === 403) {
        console.log("User is not authorized, redirecting to login...");
        navigate('/login');  // Redirect user to login page
      }
      const dataWithUpdatedKeyAndContent = response.data;
      dataWithUpdatedKeyAndContent.sort((a, b) => {
        if (a.fieldName < b.fieldName) return -1;
        if (a.fieldName > b.fieldName) return 1;
        return 0;
      });
      setFields(Array.from(dataWithUpdatedKeyAndContent));
    } catch (error) {
      console.error('Error fetching fields:', error);
    }
  };

  const setAllFalse = () => {
    setIsAddNew(false);
    setIsEdit(false);
    setIsView(false);
    setIsViewIndex(null);
    setIsDeleteModalOpen(false);
    setFieldToDelete(null);
  };

  const handleAddNew = () => {
    setAllFalse();
    setIsAddNew(true);
    handleOpen();
  };

  const handleImport = () => {
    setAllFalse();
    alert("This feature is in progress.");
  };

  const handleEdit = async (row) => {
    console.log("data for edit: ", row);
    setAllFalse();
    setCurrentFieldId(row.fieldId);
    // setIsViewIndex(index);
    await getFieldById(row.fieldId);
    setTimeout(() => {
      setIsEdit(true);
      handleOpen();
    }, 300);
  };

  const handleView = async (row) => {
    setAllFalse();
    setCurrentFieldId(row.fieldId);
    // setIsViewIndex(index);
    await getFieldById(row.fieldId);
    setTimeout(() => {
      setIsView(true);
      handleOpen();
    }, 300);
  };

// Open delete modal and set the field to delete
const openDeleteModal = (index) => {
  setFieldToDelete(filteredFields[index]);
  setIsDeleteModalOpen(true);
};

  // const handleDelete = async (index) => {
  //   try {
  //     const postData = {
  //       fieldNames: [filteredFields[index].fieldId],
  //     };
  //     await apiGatewayService.deleteFieldsData(postData);
  //     setAllFalse();
  //     await getAllFields();
  //     setDisplayAlert(true);
  //     setAlertMessage('Field deleted successfully!');
  //     setAlertType('success');
  //     setTimeout(() => setDisplayAlert(false), 10000);
  //     await getAllFields();
  //     // const fieldsNew = fields.filter((option, index2) => index2 !== index);
  //     // setFields(Array.from(fieldsNew));
  //   } catch (error) {
  //     console.error("Error: ", error);
  //     // alert('Field cannot be deleted');
  //     setDisplayAlert(true);
  //     setAlertMessage('Error in deleting field!');
  //     setAlertType('error');
  //     setTimeout(() => setDisplayAlert(false), 10000);
  //   }
  // };

  const handleConfirmDelete = async () => {
    console.log("section ids to delete: ", selectedIds);
    if (selectedIds.length>0) {
      try {
        const postData = {
          fieldNames: selectedIds,
        };
        await apiGatewayService.deleteFieldsData(postData);
        setSelectedIds([]);
        setDisplayAlert(true);
        setAlertMessage('Field deleted successfully!');
        setAlertType('success');
        await getAllFields();
        //const fieldsNew = fields.filter((option, index2) => index2 !== index);
        //setFields(Array.from(fieldsNew));
      } catch (error) {
        setDisplayAlert(true);
        setAlertMessage('Error deleting field!');
        setAlertType('error');
      } finally {
        setIsDeleteModalOpen(false);
        setTimeout(() => setDisplayAlert(false), 10000);
      }
    } else {
        setAllFalse();
        setDisplayAlert(true);
        setAlertMessage('Select a field to delete!');
        setAlertType('success');
        setTimeout(() => setDisplayAlert(false), 6000);
    }
    handleClose();
  };

  const getFieldById = async (fieldId) => {
    console.log("fieldId: ", fieldId);
    try {
      setIsLoading(true);
      const response = await apiGatewayService.getFieldsDataById(fieldId);
      setCurrentField(response.data);
    } catch (error) {
      console.error('Error in getting field:', error);
    }
    setIsLoading(false);
  };
  
  const renderBreadcrumbs = () => {
    if (isAddNew) return "Fields / New";
    if (isEdit) return "Fields  / Edit";
    if (isView) return "Fields / View";
    return "Fields";
  };

  useEffect(() => {
    const applyFilters = () => {
      // The section description is not mandatory here. If filter is applied if filter is empty we return all sections
      // If filter is applied on description and description is not there then we should be returning false.
      let filteredFields = fields.filter((field) =>
        field.fieldName.toLowerCase().includes(filterFieldName.toLowerCase()) &&
        (!filterFieldDescription || (field.fieldDescription && field.fieldDescription.toLowerCase().includes(filterFieldDescription.toLowerCase())))
      );
      if (sortKey){
        filteredFields = utilityFunctions.sortByKey(filteredFields, sortKey);
      }

      setFilteredFields(filteredFields);
    }

    applyFilters();

  }, [fields, sortKey, filterFieldName, filterFieldDescription ]);

  const sortByColumn = (column) => {
    if (sortKey === tableColumnToKeyMapping[column]){
      setSortKey(null);
    } else{
      setSortKey(tableColumnToKeyMapping[column]);
    }
  };
  const handleRefresh = async () => {
    setIsLoading(true);
    await handleClose();
    setIsLoading(false);
  }
  

  return (
    <Container disableGutters id = 'HELLO CHECK' sx={{
      pl:0, pr: 0, margin: 0, minWidth: '100%', height: '90%', display: 'flex', flexDirection: 'column'
      }}>      
    <Divider sx={{ borderBottomWidth: '2px', borderColor: 'var(--app-color)' }} />
    <Toolbar
        sx={{
            backgroundColor: 'white',
            borderRadius: "10px",
            borderEndEndRadius: '0px',
            borderEndStartRadius: '0px',
            padding: '0px',
            color: 'white',
            position: 'sticky',
            top: 0,
            zIndex: 1000,
            justifyContent: 'space-between',
        }}
        >
        {(isEdit || isAddNew || isView) ? (
            <>
            <Box sx={{ display: 'flex', flexGrow: 1}}></Box>
            <Box>
            <Button
                sx={{
                marginRight: '-25px',
                backgroundColor: 'var(--app-color)',
                width: '130px',
                ":hover": {
                    backgroundColor: 'var(--button-hover-color)',
                },
                '&.Mui-disabled': {
                    backgroundColor: '#e0e0e0',
                    color: 'grey',
                },
                }}
                color="inherit"
                startIcon={<KeyboardBackspaceIcon />}
                onClick={handleClose}
            >
                Back
            </Button>
            </Box>
            </>
        ) : (
            <>
            <Box sx={{ display: 'flex', alignItems: 'center', padding: 0, ml: '-24px' }}>
                {/* Select All Button */}
                <Button
                sx={{
                    color: '#2b4252',
                    p: '5px',
                    pt: 0,
                    pb: 0,
                    backgroundColor: 'transparent',
                    textTransform: 'none',
                    ':hover': {
                    backgroundColor: 'rgba(43, 66, 82, 0.1)',
                    },
                    '&.Mui-disabled': {
                    color: 'grey',
                    },
                    '.MuiButton-startIcon': {
                    marginRight: '2px',
                    },
                }}
                startIcon={
                    allSelectChecked ? (
                    <CheckBoxIcon />
                    ) : (
                    <CheckBoxOutlineBlankIcon />
                    )
                }
                onClick={handleSelectedAll}
                disabled={isOnlyReadAccess}
                >
                <Box sx={{ marginTop: '2px' }}>Select All</Box>
                </Button>

                {/* Delete Button */}
                <Button
                sx={{
                    ml: '5px',
                    color: '#CC3824',
                    p: '5px',
                    pt: 0,
                    pb: 0,
                    backgroundColor: 'transparent',
                    textTransform: 'none',
                    ':hover': {
                    backgroundColor: 'rgba(204, 56, 36, 0.1)',
                    },
                    '&.Mui-disabled': {
                    color: 'grey',
                    },
                    '.MuiButton-startIcon': {
                    marginRight: '2px',
                    },
                }}
                startIcon={<DeleteIcon />}
                onClick={() => openDeleteModal(-1)}
                disabled={isOnlyReadAccess}
                >
                <Box sx={{ marginTop: '2px' }}>Delete</Box>
                </Button>
            </Box>

            {/* Refresh and Add New Buttons */}
            <Box>
                <Tooltip title="Refresh">
                <IconButton
                    sx={{
                    color: 'var(--app-color)',
                    p: '5px',
                    mr: '10px',
                    }}
                    aria-label="refresh"
                    onClick={handleRefresh}
                    disabled={isOnlyReadAccess}
                >
                    <RefreshIcon sx={{ fontSize: '34px', fontWeight: 'bold' }} />
                </IconButton>
                </Tooltip>

                <Button
                sx={{
                    marginRight: '-25px',
                    backgroundColor: 'var(--app-color)',
                    width: '130px',
                    ":hover": {
                    backgroundColor: 'var(--button-hover-color)',
                    },
                    '&.Mui-disabled': {
                    backgroundColor: '#e0e0e0',
                    color: 'grey',
                    },
                }}
                color="inherit"
                startIcon={<AddIcon />}
                onClick={handleAddNew}
                disabled={isOnlyReadAccess}
                >
                Add New
                </Button>
            </Box>
            </>
        )}
    </Toolbar>
      {

        displayAlert &&
        <CustomAlert isOpen={displayAlert} message={alertMessage} type={alertType}></CustomAlert>
      }
      {
        isDeleteModalOpen &&
        <DeleteConfirmation
        isOpen={isDeleteModalOpen}
        handleClose={handleClose}
        submitDelete={handleConfirmDelete}
      />
      }

      {isLoading && <CircularLoader />}
      {(!isLoading && !isAddNew && !isEdit && !isView) &&
              <div
                  className="ag-theme-quartz" // Or replace with your custom theme name
                  style={{
                      overflow: 'auto',
                      height: '75vh',
                      minWidth: '1000px',
                      // '--ag-foreground-color': 'rgb(14, 68, 145)', // Grid text color
                      // '--ag-background-color': 'rgb(241, 247, 255)', // Grid background color
                      '--ag-header-background-color': 'rgb(228, 237, 250)', // Header row background color
                      '--ag-row-hover-color': 'rgb(216, 226, 255)', // Row hover color
                  }}
              >
                  <AgGridReact
                      ref={gridRef} // Attach the ref
                      onRowSelected={onRowSelected}
                      rowData={fields}
                      columnDefs={colDefs}
                      rowSelection="multiple"
                      domLayout="normal" // Dynamically adjusts the height of the grid based on its content
                      // suppressHorizontalScroll={false} // Ensures horizontal scrolling is enabled
                      suppressColumnVirtualisation={false} // Ensure columns render correctly for scrolling
                      // suppressRowTransform={true} // Fixes issues with row scrolling
                      pagination={pagination}
                      paginationPageSize={paginationPageSize}
                  // paginationPageSizeSelector={paginationPageSizeSelector}
                  />
              </div>
      }

      {isAddNew && <FieldBuilder field={{}} handleClose={handleClose}/>}
      {isView && <FieldBuilder field={currentField} handleClose={handleClose} isView = {true} fieldId = {currentFieldId || null } />}      
      {isEdit && <FieldBuilder field={currentField} handleClose={handleClose} isEdit = {true} fieldId = {currentFieldId || null } />}
    </Container>
  );
};

export default Fields;