import React, { useState, useEffect, useRef } from 'react';
import {
  Divider,
  Box, Button, IconButton, Typography, Container, Toolbar, TextField, InputAdornment,
  Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Tooltip
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ViewListIcon from '@mui/icons-material/ViewList';
import SearchIcon from '@mui/icons-material/Search';
import SectionBuilder from '../FormBuilder/SectionBuilder/SectionBuilder';
import CircularLoader from '../Loader/Loader';
import apiGatewayService from '../../common/services/apiGatewayService';
import utilityFunctions from '../../common/services/utilityFunctions';
import localStorageService from '../../common/services/localStorage.service';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import CustomAlert from '../CustomAlert/CustomAlert';
import DeleteConfirmation from '../DeleteConfirmation/DeleteConfirmation';
import { useNavigate } from 'react-router-dom';
import LockIcon from '@mui/icons-material/Lock';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import "ag-grid-community/styles/ag-theme-material.css"; // Optional Theme applied to the Data Grid
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional Theme applied to the Data Grid
import TableActions from '../TableActions/TableActions';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import RefreshIcon from '@mui/icons-material/Refresh';

const tableColumnToKeyMapping = {
  'Name': "sectionName",
  "Description": "sectionDescription"
};
  

const Sections = (props) => {
  const [sections, setSections] = useState([]);
  const [isAddNew, setIsAddNew] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [viewIndex, setIsViewIndex] = useState(null);
  const [currentSection, setCurrentSection] = useState(null);
  const [filterSectionName, setFilterSectionName] = useState('');
  const [filterSectionDescription, setFilterSectionDescription] = useState(''); // New state for section description filter
  const [isLoading, setIsLoading] = useState(true);
  const [isOnlyReadAccess, setIsOnlyReadAccess] = useState(false);
  const [displayAlert, setDisplayAlert] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [sectionToDelete, setSectionToDelete] = useState(null);
  const [filteredSections, setFilteredSections] = useState(sections);
  const [sortKey, setSortKey] = useState(null);
  const [selectAll, setSelectAll] = useState(false); // State for the external checkbox
  const gridRef = useRef(null); // Ref for accessing the grid API
  const [allSelectChecked, setAllSelectChecked] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedSingleForm, setSelectedSingleForm] = useState(null);
  const [currentSectionId, setCurrentSectionId] = useState(null);

  const pagination = true;
  const paginationPageSize = 10;
  const paginationPageSizeSelector = [10, 100, 1000];

  const handleSelectedAll = (e) => {
    console.log(e.target.checked);
    const isChecked = !allSelectChecked;
    // setSelectAll(isChecked); // Update state
    if (gridRef.current) {
        const api = gridRef.current.api;

        if (isChecked) {
            setAllSelectChecked(true);

            // Select rows on the current pagination page
            api.forEachNodeAfterFilterAndSort((node) => {
                if (node.rowIndex >= api.paginationGetCurrentPage() * api.paginationGetPageSize() &&
                    node.rowIndex < (api.paginationGetCurrentPage() + 1) * api.paginationGetPageSize()) {
                    node.setSelected(true);
                }
            });
        } else {
            setAllSelectChecked(false);

            // Deselect rows on the current pagination page
            api.forEachNodeAfterFilterAndSort((node) => {
                if (node.rowIndex >= api.paginationGetCurrentPage() * api.paginationGetPageSize() &&
                    node.rowIndex < (api.paginationGetCurrentPage() + 1) * api.paginationGetPageSize()) {
                    node.setSelected(false);
                }
            });
        }

        // Access selected rows if needed
        const selectedRows = api.getSelectedRows();
        const currSelectedIds =  selectedRows.map((r)=>r.formId);
        setSelectedIds(currSelectedIds);
        console.log("Currently selected rows:", selectedRows);
    }
};

const onRowSelected = () => {
    if (gridRef.current) {
      const api = gridRef.current.api;
      const currentlySelectedRows = api.getSelectedRows(); // Get all selected rows
    //   setSelectedRows(currentlySelectedRows); // Update state
      const selectedIds = currentlySelectedRows.map((m)=>m.sectionId);
      console.log("Selected Rows:", selectedIds);
      setSelectedIds(selectedIds);
    }
  };


  const [colDefs, setColDefs] = useState([
    {
      // cellRenderer: put your components here
      // valueGetter: p => p.data.formName+ ' '+p.data.status, // if you want concatenated values
      field: "sectionName",
      headerName: "Name",
      flex: 1,
      filter: true,
      floatingFilter: true,
      checkboxSelection: true,
      sortable: true,
    //   pinned: 'left'
    },
    {
      field: "sectionDescription",
      headerName: "Description",
      flex: 1,
      filter: true,
      floatingFilter: true,
    },
    {
        field: 'Options',
        flex: 1,
        cellRenderer: (params) => (
            <TableActions
              handleView={() => handleView(params.data)}
              handleEdit={() => handleEdit(params.data)}
              openDeleteModal={() => openDeleteModal(params.data)}
              index={params.data}
              isOnlyReadAccess={isOnlyReadAccess}
            />
          ),
    }
  ]);


  const navigate = useNavigate();

  const handleClose = async (alertObject) => {
    setAllFalse();
    await getAllSections();
    if(alertObject?.type && alertObject?.message) {
      setAlertMessageAndPopup(alertObject);
    };
  };

  const setAlertMessageAndPopup  = (alertObject) => {
    setDisplayAlert(true);
    setAlertMessage(alertObject.message);
    setAlertType(alertObject.type);
    setTimeout(() => setDisplayAlert(false), 10000);
  }

  const setAllFalse = () => {
    setIsAddNew(false);
    setIsEdit(false);
    setIsView(false);
    setIsViewIndex(null);
    setIsDeleteModalOpen(false);
    setSectionToDelete(null);
  };

  useEffect(() => {
    const getOptions = async (user) => {
      try {
        const response = await apiGatewayService.getSectionsData();
        const dataWithUpdatedKeyAndContent = response.data;
        dataWithUpdatedKeyAndContent.sort((a, b) => a.sectionName.localeCompare(b.sectionName));
        setSections(dataWithUpdatedKeyAndContent);
      } catch (error) {
        if (error.response && error.response.status === 403) {
          console.log("Forbidden error detected, redirecting to login...");
          navigate('/');
        } else {
          console.error('Error fetching sections:', error);
        }
      }
      setIsLoading(false);
    };
    const user = localStorageService.getObject('user');
    setIsOnlyReadAccess(utilityFunctions.checkIfOnlyReadAccess(user));
    getOptions(user);
  }, []);

  const getAllSections = async () => {
    try {
      const response = await apiGatewayService.getSectionsData();
      const dataWithUpdatedKeyAndContent = response.data;
      dataWithUpdatedKeyAndContent.sort((a, b) => a.sectionName.localeCompare(b.sectionName));
      setSections(dataWithUpdatedKeyAndContent);
    } catch (error) {
      console.error('Error fetching sections:', error);
    }
    setIsLoading(false);
  };

  const handleAddNew = () => {
    setAllFalse();
    setIsAddNew(true);
  };

  const handleEdit = async (row) => {
    setAllFalse();
    // setIsViewIndex(index);
    setCurrentSectionId(row.sectionId);
    await getSectionById(row.sectionId);
    setIsEdit(true);
  };

  const handleView = async (row) => {
    setAllFalse();
    // setIsViewIndex(row);
    setCurrentSectionId(row.sectionId);
    await getSectionById(row.sectionId);
    setIsView(true);
  };

  const openDeleteModal = () => {
    // setSectionToDelete(filteredSections[index]);
    setIsDeleteModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    console.log("section ids to delete: ", selectedIds);
    if (selectedIds.length>0) {
      try {
        const postData = { sectionNames: selectedIds };
        await apiGatewayService.deleteSectionsData(postData);
        setSelectedIds([]);
        setDisplayAlert(true);
        setAlertMessage('Section deleted successfully!');
        setAlertType('success');
        setTimeout(() => setDisplayAlert(false), 10000);
        await getAllSections();
      } catch (error) {
        console.error('Error deleting section:', error);
        setDisplayAlert(true);
        setAlertMessage('Error in deleting section!');
        setAlertType('error');
        setTimeout(() => setDisplayAlert(false), 10000);
      }
    } else {
        setAllFalse();
        setDisplayAlert(true);
        setAlertMessage('Select a section to delete!');
        setAlertType('success');
        setTimeout(() => setDisplayAlert(false), 6000);

    }
    handleClose();
  };

  const getSectionById = async (sectionId) => {
    try {
      setIsLoading(true);
      const response = await apiGatewayService.getSectionsDataById(sectionId);
      setCurrentSection(response.data);
    } catch (error) {
      console.error('Error getting section:', error);
    }
    setIsLoading(false);
  };
  
  const renderBreadcrumbs = () => {
    if (isAddNew) return "Sections / New";
    if (isEdit) return "Sections  / Edit";
    if (isView) return "Sections / View";
    return "Sections";
  };

  useEffect(() => {
    const applyFilters = () => {
      // The section description is not mandatory here. If filter is applied if filter is empty we return all sections
      // If filter is applied on description and description is not there then we should be returning false.
      let filteredSections = sections.filter(section =>
        section.sectionName.toLowerCase().includes(filterSectionName.toLowerCase()) &&
        (section.sectionDescription ? section.sectionDescription.toLowerCase().includes(filterSectionDescription.toLowerCase()) : (filterSectionDescription === '' ? true : false))
      );
      
      if (sortKey){
        filteredSections = utilityFunctions.sortByKey(filteredSections, sortKey);
      }

      setFilteredSections(filteredSections);
    }

    applyFilters();

  }, [sections, sortKey,filterSectionName, filterSectionDescription ]);

  const sortByColumn = (column) => {
    if (sortKey === tableColumnToKeyMapping[column]){
      setSortKey(null);
    } else{
      setSortKey(tableColumnToKeyMapping[column]);
    }
  };

  const handleRefresh = async () => {
    setIsLoading(true);
    await handleClose();
    setIsLoading(false);
  }
  
  return (
    <Container disableGutters id = 'HELLO CHECK' sx={{pl:0, pr: 0, margin: 0, minWidth: '100%', height: '90%', display: 'flex', flexDirection: 'column'}}>      
      <Divider sx={{ borderBottomWidth: '2px', borderColor: 'var(--app-color)' }} />
      <Toolbar
        sx={{
            backgroundColor: 'white',
            borderRadius: "10px",
            borderEndEndRadius: '0px',
            borderEndStartRadius: '0px',
            padding: '0px',
            color: 'white',
            position: 'sticky',
            top: 0,
            zIndex: 1000,
            justifyContent: 'space-between',
        }}
        >
        {(isEdit || isAddNew || isView) ? (
            <>
            <Box sx={{ display: 'flex', flexGrow: 1}}></Box>
            <Box>
            <Button
                sx={{
                marginRight: '-25px',
                backgroundColor: 'var(--app-color)',
                width: '130px',
                ":hover": {
                    backgroundColor: 'var(--button-hover-color)',
                },
                '&.Mui-disabled': {
                    backgroundColor: '#e0e0e0',
                    color: 'grey',
                },
                }}
                color="inherit"
                startIcon={<KeyboardBackspaceIcon />}
                onClick={handleClose}
            >
                Back
            </Button>
            </Box>
            </>
        ) : (
            <>
            <Box sx={{ display: 'flex', alignItems: 'center', padding: 0, ml: '-24px' }}>
                {/* Select All Button */}
                <Button
                sx={{
                    color: '#2b4252',
                    p: '5px',
                    pt: 0,
                    pb: 0,
                    backgroundColor: 'transparent',
                    textTransform: 'none',
                    ':hover': {
                    backgroundColor: 'rgba(43, 66, 82, 0.1)',
                    },
                    '&.Mui-disabled': {
                    color: 'grey',
                    },
                    '.MuiButton-startIcon': {
                    marginRight: '2px',
                    },
                }}
                startIcon={
                    allSelectChecked ? (
                    <CheckBoxIcon />
                    ) : (
                    <CheckBoxOutlineBlankIcon />
                    )
                }
                onClick={handleSelectedAll}
                disabled={isOnlyReadAccess}
                >
                <Box sx={{ marginTop: '2px' }}>Select All</Box>
                </Button>

                {/* Delete Button */}
                <Button
                sx={{
                    ml: '5px',
                    color: '#CC3824',
                    p: '5px',
                    pt: 0,
                    pb: 0,
                    backgroundColor: 'transparent',
                    textTransform: 'none',
                    ':hover': {
                    backgroundColor: 'rgba(204, 56, 36, 0.1)',
                    },
                    '&.Mui-disabled': {
                    color: 'grey',
                    },
                    '.MuiButton-startIcon': {
                    marginRight: '2px',
                    },
                }}
                startIcon={<DeleteIcon />}
                onClick={() => openDeleteModal(-1)}
                disabled={isOnlyReadAccess}
                >
                <Box sx={{ marginTop: '2px' }}>Delete</Box>
                </Button>
            </Box>

            {/* Refresh and Add New Buttons */}
            <Box>
                <Tooltip title="Refresh">
                <IconButton
                    sx={{
                    color: 'var(--app-color)',
                    p: '5px',
                    mr: '10px',
                    }}
                    aria-label="refresh"
                    onClick={handleRefresh}
                    disabled={isOnlyReadAccess}
                >
                    <RefreshIcon sx={{ fontSize: '34px', fontWeight: 'bold' }} />
                </IconButton>
                </Tooltip>

                <Button
                sx={{
                    marginRight: '-25px',
                    backgroundColor: 'var(--app-color)',
                    width: '130px',
                    ":hover": {
                    backgroundColor: 'var(--button-hover-color)',
                    },
                    '&.Mui-disabled': {
                    backgroundColor: '#e0e0e0',
                    color: 'grey',
                    },
                }}
                color="inherit"
                startIcon={<AddIcon />}
                onClick={handleAddNew}
                disabled={isOnlyReadAccess}
                >
                Add New
                </Button>
            </Box>
            </>
        )}
      </Toolbar>


      {
        displayAlert &&
        <CustomAlert isOpen={displayAlert} message={alertMessage} type={alertType}></CustomAlert>
      }
      {
        isDeleteModalOpen &&
        <DeleteConfirmation
          isOpen={isDeleteModalOpen}
          handleClose={handleClose}
          submitDelete={handleConfirmDelete}
        />
      }
      {isLoading && <CircularLoader />}

      {!isLoading && !isEdit && !isAddNew && !isView &&
            <div
            className="ag-theme-quartz" // Or replace with your custom theme name
            style={{
                overflow: 'auto',
                height: '75vh',
                minWidth: '1000px',
                // '--ag-foreground-color': 'rgb(14, 68, 145)', // Grid text color
                // '--ag-background-color': 'rgb(241, 247, 255)', // Grid background color
                '--ag-header-background-color': 'rgb(228, 237, 250)', // Header row background color
                '--ag-row-hover-color': 'rgb(216, 226, 255)', // Row hover color
            }}
        >
            <AgGridReact
                ref={gridRef} // Attach the ref
                onRowSelected={onRowSelected}
                rowData={sections}
                columnDefs={colDefs}
                rowSelection="multiple"
                domLayout="normal" // Dynamically adjusts the height of the grid based on its content
                // suppressHorizontalScroll={false} // Ensures horizontal scrolling is enabled
                suppressColumnVirtualisation={false} // Ensure columns render correctly for scrolling
                // suppressRowTransform={true} // Fixes issues with row scrolling
                pagination={pagination}
                paginationPageSize={paginationPageSize}
                // paginationPageSizeSelector={paginationPageSizeSelector}
            />
        </div>
      }

      {isAddNew && <SectionBuilder section={{}} handleClose={handleClose}/>}
      {isEdit && <SectionBuilder section={currentSection} handleClose={handleClose} isEdit={true} sectionId={currentSectionId || null} />}
      {isView && <SectionBuilder section={currentSection} handleClose={handleClose} isView={true} sectionId={currentSectionId || null}/>}
    </Container>
  );
};

export default Sections;