import React, { useEffect, useState } from 'react';
import { Rating as MuiRating } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import CircleIcon from '@mui/icons-material/Circle';

const CustomRating = ({ config, fieldValue, updateValue, field, sectionIndex, disabled}) => {
  const [value, setValue] = useState(0); // Current user rating
  const [hover, setHover] = useState(-1); // Hovered value


    useEffect(() => {
        if(fieldValue){
            setValue(fieldValue)
        };
    }, [fieldValue]);

  // Map string to icon component
  const iconMap = {
    StarIcon: StarIcon,
    FavoriteIcon: FavoriteIcon,
    ThumbUpIcon: ThumbUpIcon,
    CircleIcon: CircleIcon,
  };

  const IconComponent = iconMap[config.icon] || StarIcon;

  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log(`User selected rating: ${newValue}`);
    const newEvent =  { target: { name: field.name, value: newValue } };
    // updateValue({ target: { name: field.name, value: newValue } }, sectionIndex, field.required);
    updateValue(newEvent, sectionIndex);
  };

  return (
    <MuiRating
      name= {field?.name || ''}
      value={value}
      onChange={handleChange}
      onChangeActive={(event, newHover) => setHover(newHover)}
      max={config.range}
      icon={<IconComponent fontSize="inherit" />}
      emptyIcon={<IconComponent fontSize="inherit" style={{ opacity: 0.3 }} />}
      size="large"
      disabled = {disabled}
    />
  );
};

export default CustomRating;
