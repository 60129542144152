import React, { useState, useEffect } from 'react';
import { 
  Box, Button, Grid, TextField, Typography, Dialog, DialogActions, 
  DialogContent, DialogTitle, Container, InputAdornment, MenuItem, CircularProgress
} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ApiIcon from '@mui/icons-material/Api';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import SearchIcon from '@mui/icons-material/Search';
import { Divider, Table, TableBody, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
// import logo from './orange2.jpg';
import logo from './api-new.webp';
import apiGatewayService from '../../common/services/apiGatewayService';
import CircularLoader from '../Loader/Loader';
import CancelIcon from '@mui/icons-material/Cancel';
import { useNavigate } from 'react-router-dom';
import LockIcon from '@mui/icons-material/Lock';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import AddIcon from '@mui/icons-material/Add';

const Integrations = (props) => {
  const [apiIntegrations, setApiIntegrations] = useState([]);
  const [filteredIntegrations, setFilteredIntegrations] = useState([]);
  const [selectedIntegration, setSelectedIntegration] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [filters, setFilters] = useState({
    name: '',
    authenticationType: '',
    createdBy: '',
    editedBy: '',
  });
  const [isAddNew, setIsAddNew] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isOnlyReadAccess, setIsOnlyReadAccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailPassword, setIsEmailPassword] = useState(false);
  const [apiError, setApiError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [isAuthenticationNone, setIsAuthenticationNone] = useState(false);

  const navigate = useNavigate();

  const authenticationTypeOptions = ['Basic', 'API Key', 'HMAC', 'Bearer','None'];

  useEffect(() => {
    const fetchIntegrations = async () => {
      setIsLoading(true);
      try {
        const response = await apiGatewayService.getApi();
        console.log("response: ", response);
        const integrationsMapped = response.data.map((I) => I.value);
        setApiIntegrations(integrationsMapped);
        setFilteredIntegrations(integrationsMapped);
      } catch (error) {
        if (error.response && error.response.status === 403) {
          console.log("Forbidden error detected, redirecting to login...");
          navigate('/');
        } else {
          console.log("error in fetching apis: ", error);
        }
      }
      setIsLoading(false);
    };

    fetchIntegrations();
  }, []);

  const getAllApis = async () => {
    setIsLoading(true);
    try {
      const response = await apiGatewayService.getApi();
      console.log("response: ", response);
      const integrationsMapped = response.data.map((I) => I.value);
      setApiIntegrations(integrationsMapped);
      setFilteredIntegrations(integrationsMapped);
    } catch (error) {
      console.log("error in fetching apis: ", error);
    }
    setIsLoading(false);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
    const filtered = apiIntegrations.filter(integration => 
      integration.name.includes(value) ||
      integration.authenticationType.includes(value) ||
      integration.createdBy.includes(value) ||
      integration.editedBy.includes(value)
    );
    setFilteredIntegrations(filtered);
  };

  const handleEditClick = (integration) => {
    setSelectedIntegration(integration);
    setIsEdit(true);
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setSelectedIntegration(null);
    setApiError('');
    setEmailError('');
  };

  const validateApi = (api) => {
    const apiRegex = /^(https?:\/\/)[^\s$.?#].[^\s]*$/;
    return apiRegex.test(api);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSave = async () => {
    if (selectedIntegration.api && !validateApi(selectedIntegration.api)) {
      setApiError('Please enter a valid API endpoint.');
      return;
    }
    if (isEmailPassword && selectedIntegration.email && !validateEmail(selectedIntegration.email)) {
      setEmailError('Please enter a valid email address.');
      return;
    }

    setIsLoading(true);
    try {
      if (isEdit) {
        const response = await apiGatewayService.putApi(selectedIntegration, selectedIntegration.name);
        console.log("Updated response: ", response);
      } else {
        const response = await apiGatewayService.postApi(selectedIntegration);
        console.log("Created response: ", response);
      }
    } catch (error) {
      console.log("Error in saving api: ", error);
    }
    setIsLoading(false);
    handleDialogClose();
    getAllApis();
  };

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      const response = await apiGatewayService.deleteApi(selectedIntegration.name);
      console.log("Deleted response: ", response);
      handleDialogClose();
    } catch (error) {
      console.log("Error in deleting api: ", error);
    }
    setIsLoading(false);
    getAllApis();
  };

  const handleCreateNew = () => {
    setSelectedIntegration({ name: '', api: '', type: '', authenticationType: '', authenticationValue: '', createdBy: '', editedBy: '' });
    setIsAddNew(true);
    setIsDialogOpen(true);
  };

  const handleBackClick = () => {
    setIsEdit(false);
    setIsAddNew(false);
  };


  const handleAuthenticationType = (e) => {
    setSelectedIntegration({ ...selectedIntegration, authenticationType: e.target.value });
    setIsEmailPassword(e.target.value === 'Basic');
    setIsAuthenticationNone(e.target.value === 'None');
  };

  return (
    <Container disableGutters id = 'HELLO CHECK' sx={{pl:0, pr: 0, margin: 0, minWidth: '100%', height: '85%', display: 'flex', flexDirection: 'column'}}>      
        <Divider sx={{ borderBottomWidth: '2px', borderColor: 'var(--app-color)' }} />
      {isLoading ? (
        <CircularLoader />
      ) : (
        <>
        <Toolbar 
            sx={{ 
              // backgroundColor: 'var(--app-color-headings)', 
              backgroundColor: 'white',
              borderRadius: "10px", 
              borderEndEndRadius: '0px', 
              borderEndStartRadius: '0px', 
              padding: '0px', 
              color: 'white', 
              position: 'sticky', // Keeps the toolbar fixed inside the scrollable container
              top: 0,  // Sticks the toolbar to the top of the container
              zIndex: 1000, // Ensures it stays on top of the table
              justifyContent: 'space-between'
            }}>         
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
            </Box>
            <Box>
            <Button sx={{
              backgroundColor: 'var(--app-color)',
              width: '150px',
              marginRight: '-23px',
              ":hover": {
                // backgroundColor: '#F0F4F4',
                backgroundColor: 'var(--button-hover-color)',
                // color: '#2b4252',
              },
              '&.Mui-disabled': {
                backgroundColor: '#e0e0e0',
                color: 'grey',
              },
            }} color="inherit" startIcon={<AddIcon />} onClick={handleCreateNew} disabled={isOnlyReadAccess}>
              Create New
            </Button>
          </Box>
          </Toolbar>
          <Box mb={2} sx={{ mt: '0px' }}>
            <TableContainer component={Paper} sx = {{"& .MuiTableCell-root": { p: '8px'}}}>
              <Table>
                <TableBody>
                <TableRow
                sx={{
                  height: '40px',
                  top: 0,
                  zIndex: 1200,
                  backgroundColor: 'var(--app-color)',
                }}
              >
                {['Name', 'Authentication Type', 'Created By', 'Edited By'].map((head, index) => (
                  <TableCell key={index} style={head === 'Name' ? { paddingLeft: '16px' } : {}}>
                    <span style={{ fontWeight: 'bold' , color: 'white'}}>{head}</span>
                  </TableCell>
                ))}
              </TableRow>
                  <TableRow>
                    {/* Filters */}
                    <TableCell style={{ paddingLeft: '16px' }}>
                      <TextField
                        variant="outlined"
                        // placeholder="Name"
                        name="name"
                        value={filters.name}
                        onChange={handleFilterChange}
                        size="small"
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        sx={{
                          width: '100%',
                          "& .MuiInputBase-root": {
                            height: 35,
                          },
                          "& .MuiOutlinedInput-input": {
                            height: 35,
                            width: '100%',
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        variant="outlined"
                        // placeholder="Authentication Type"
                        name="authenticationType"
                        value={filters.authenticationType}
                        onChange={handleFilterChange}
                        size="small"
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        sx={{
                          width: '100%',
                          "& .MuiInputBase-root": {
                            height: 35,
                          },
                          "& .MuiOutlinedInput-input": {
                            height: 35,
                            width: '100%',
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        variant="outlined"
                        // placeholder="Created By"
                        name="createdBy"
                        value={filters.createdBy}
                        onChange={handleFilterChange}
                        size="small"
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        sx={{
                          width: '100%',
                          "& .MuiInputBase-root": {
                            height: 35,
                          },
                          "& .MuiOutlinedInput-input": {
                            height: 35,
                            width: '100%',
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        variant="outlined"
                        // placeholder="Edited By"
                        name="editedBy"
                        value={filters.editedBy}
                        onChange={handleFilterChange}
                        size="small"
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        sx={{
                          width: '100%',
                          "& .MuiInputBase-root": {
                            height: 35,
                          },
                          "& .MuiOutlinedInput-input": {
                            height: 35,
                            width: '100%',
                          },
                        }}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>

          <Grid container spacing={2}>
            {filteredIntegrations.map((integration, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <Box p={2} borderRadius={0} textAlign="center" sx = {{backgroundColor: 'var(--field-background-color-1)'}}>
                  <img src={logo} alt={integration.name} style={{ maxWidth: '50%' }} />
                  <Typography variant="h6">{integration.name}</Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleEditClick(integration)}
                    sx={{
                      mt: 2,
                      width: '100%',
                      backgroundColor: 'var(--button-color)',
                      color: 'white',
                      fontWeight: 'normal',
                      textTransform: 'none',
                      ":hover": {
                        backgroundColor: 'var(--button-hover-color)',
                      },
                    }}
                  >
                    Edit
                  </Button>
                </Box>
              </Grid>
            ))}

            {/* Create New Integration Box */}
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Box p={2} border={0} borderRadius={0} textAlign="center" sx = {{backgroundColor: 'var(--field-background-color-1)'}}>
                <Typography variant="h6">Create New Integration</Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleCreateNew}
                  sx={{
                    mt: 2,
                    width: '100%',
                    backgroundColor: 'var(--button-color)',
                    color: 'white',
                    fontWeight: 'normal',
                    textTransform: 'none',
                    ":hover": {
                      backgroundColor: 'var(--button-hover-color)',
                    },
                  }}
                >
                  Create
                </Button>
              </Box>
            </Grid>
          </Grid>
          </Box>
        </>
      )}

      <Dialog open={isDialogOpen} onClose={handleDialogClose}>
      <IconButton
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
          }}
          onClick={handleDialogClose}
        >
          <CancelIcon />
        </IconButton>
        <DialogTitle>{selectedIntegration?.name ? 'Edit Integration' : 'Create New Integration'}</DialogTitle>
        <DialogContent sx = {{paddingBottom: 0}}>
          <TextField
            placeholder="Name"
            type="text"
            value={selectedIntegration?.name || ''}
            onChange={(e) => setSelectedIntegration({ ...selectedIntegration, name: e.target.value })}
            fullWidth
            sx={{
              // mt: '5px',
              width: '100%',
              "& .MuiInputBase-root": {
                height: 40,
                backgroundColor: 'white',
              },
              "& .MuiOutlinedInput-input": {
                height: 40,
                width: '100%',
              },
            }}
          />
          <TextField
            placeholder="API Endpoint"
            type="text"
            value={selectedIntegration?.api || ''}
            onChange={(e) => {
              setSelectedIntegration({ ...selectedIntegration, api: e.target.value });
              setApiError(validateApi(e.target.value) ? '' : 'Please enter a valid API endpoint.');
            }}
            fullWidth
            error={!!apiError}
            helperText={apiError}
            sx={{
              mt: '10px',
              width: '100%',
              "& .MuiInputBase-root": {
                height: 40,
                backgroundColor: 'white',
              },
              "& .MuiOutlinedInput-input": {
                height: 40,
                width: '100%',
              },
            }}
          />
          <TextField
            select
            label="Type"
            value={selectedIntegration?.type || ''}
            onChange={(e) =>
              setSelectedIntegration({
                ...selectedIntegration,
                type: e.target.value,
              })
            }
            fullWidth
            sx={{
              mt: '10px',
              width: '100%',
              "& .MuiInputBase-root": {
                height: 40,
                backgroundColor: 'white',
              },
              "& .MuiOutlinedInput-input": {
                height: 40,
                width: '100%',
              },
            }}
          >
            {/* Placeholder MenuItem */}
            <MenuItem value="" disabled>
              Type
            </MenuItem>
            <MenuItem value="webhook">Webhook</MenuItem>
            <MenuItem value="api">API</MenuItem>
          </TextField>

          <TextField
            select
            label="Authentication Type"
            type="text"
            value={selectedIntegration?.authenticationType || ''}
            onChange={handleAuthenticationType}
            fullWidth
            sx={{
              mt: '10px',
              width: '100%',
              "& .MuiInputBase-root": {
                height: 40,
                backgroundColor: 'white',
              },
              "& .MuiOutlinedInput-input": {
                height: 40,
                width: '100%',
              },
            }}
          >
            {authenticationTypeOptions.map((type, index) => (
              <MenuItem key={index} value={type}>
                {type}
              </MenuItem>
            ))}
          </TextField>
          {!isEmailPassword && (
            <TextField
              placeholder="Authentication Value"
              type = "text"
              value={selectedIntegration?.authenticationValue || ''}
              onChange={(e) => setSelectedIntegration({ ...selectedIntegration, authenticationValue: e.target.value })}
              fullWidth
              sx={{
                mt: '10px',
                width: '100%',
                "& .MuiInputBase-root": {
                  height: 40,
                  backgroundColor: 'white',
                },
                "& .MuiOutlinedInput-input": {
                  height: 40,
                  width: '100%',
                },
              }}
            />
          )}
          {isEmailPassword && (
            <>
              <TextField
                placeholder="Username"
                type = "text"
                value={selectedIntegration?.userName || ''}
                onChange={(e) => {
                  setSelectedIntegration({ ...selectedIntegration, userName: e.target.value });
                  // setEmailError(validateEmail(e.target.value) ? '' : 'Please enter a valid email address.');
                }}
                fullWidth
                margin="normal"
                error={!!emailError}
                helperText={emailError}
                sx={{
                  width: '100%',
                  "& .MuiInputBase-root": {
                    height: 40,
                    backgroundColor: 'white',
                  },
                  "& .MuiOutlinedInput-input": {
                    height: 40,
                    width: '100%',
                  },
                }}
              />
              <TextField
                placeholder="Password"
                type = "text"
                value={selectedIntegration?.password || ''}
                onChange={(e) => setSelectedIntegration({ ...selectedIntegration, password: e.target.value })}
                fullWidth
                margin="normal"
                sx={{
                  width: '100%',
                  "& .MuiInputBase-root": {
                    height: 40,
                    backgroundColor: 'white',
                  },
                  "& .MuiOutlinedInput-input": {
                    height: 40,
                    width: '100%',
                  },
                }}
              />
            </>
          )}
          {/* <TextField
            disabled
            label="Created By"
            value={selectedIntegration?.createdBy || ''}
            onChange={(e) => setSelectedIntegration({ ...selectedIntegration, createdBy: e.target.value })}
            fullWidth
            margin="normal"
            sx={{
              width: '100%',
              "& .MuiInputBase-root": {
                height: 40,
                backgroundColor: 'white',
              },
              "& .MuiOutlinedInput-input": {
                height: 40,
                width: '100%',
              },
            }}
          />
          <TextField
            disabled
            label="Edited By"
            value={selectedIntegration?.editedBy || ''}
            onChange={(e) => setSelectedIntegration({ ...selectedIntegration, editedBy: e.target.value })}
            fullWidth
            margin="normal"
            sx={{
              width: '100%',
              "& .MuiInputBase-root": {
                height: 40,
                backgroundColor: 'white',
              },
              "& .MuiOutlinedInput-input": {
                height: 40,
                width: '100%',
              },
            }}
          /> */}
        </DialogContent>
        <DialogActions sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingLeft: 3 , paddingRight: 3}}>
          <Button
            variant="outlined"
            onClick={handleSave}
            sx={{
              mt: '10px',
              ml: '7px',
              width: '100%',
              height: '40px',
              backgroundColor: 'var(--button-color)',
              color: 'white',
              fontWeight: 'normal',
              textTransform: 'none',
              ":hover": {
                backgroundColor: 'var(--button-hover-color)',
              },
            }}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            onClick={handleDialogClose}
              sx={{
                mt: 1,
                width: '100%',
                height: '40px',
                color: 'var(--button-color)', // Set text color to white
                backgroundColor: 'transparent',
                borderColor: 'var(--button-color)',
                textTransform: 'none', // Prevent uppercase text
                ":hover": {
                  borderColor: 'var(--button-color)',
                },
              }
            }
          >
            Cancel
          </Button>
          {isEdit && (
            <Button
              onClick={handleDelete}
              sx={{
                mt: 2,
                width: '100%',
                backgroundColor: 'var(--button-color-red)',
                color: 'white',
                fontWeight: 'normal',
                textTransform: 'none',
                ":hover": {
                  backgroundColor: 'var(--button-hover-color-red)',
                },
              }}
            >
              Delete
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Integrations;