import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation  } from 'react-router-dom';
import { Button, Drawer, List, ListItem, ListItemText, IconButton, AppBar, Toolbar, Typography, Box, CssBaseline, ListItemIcon, Divider, Avatar, useMediaQuery  } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import classes from './SideMenu.module.css';
import { styled } from '@mui/material/styles';

import ArticleIcon from '@mui/icons-material/Article';
import ViewListIcon from '@mui/icons-material/ViewList';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ChecklistIcon from '@mui/icons-material/Checklist';
import SettingsIcon from '@mui/icons-material/Settings';
import PersonIcon from '@mui/icons-material/Person';
import LockOpenSharpIcon from '@mui/icons-material/LockOpenSharp';
import CommentIcon from '@mui/icons-material/Comment';
import AddToQueueIcon from '@mui/icons-material/AddToQueue';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import ApiIcon from '@mui/icons-material/Api';
import CircleIcon from '@mui/icons-material/Circle';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import RemoveIcon from '@mui/icons-material/Remove';

import Options from '../Options/Options';
import Options2 from '../Options/Options2';
import Fields from '../Fields/Fields';
import Fields2 from '../Fields/Fields2';
import Sections from '../Sections/Sections';
import Sections2 from '../Sections/Sections2';
import Forms from '../Forms/Forms';
import Forms2 from '../Forms/Forms2';
import EditUsers from '../EditUsers/EditUsers';
import SubscriptionsComponent from '../SubscriptionsComponent/SubscriptionsComponent';
import Settings from '../Settings/Settings';
import Integrations from '../Integrations/Integrations';
import logoNew from '../../common/images/FreeForm-Logo_White.png';
import localStorageService from '../../common/services/localStorage.service';
import utilityFunctions from '../../common/services/utilityFunctions';
import apiGatewayService from '../../common/services/apiGatewayService';
import FormLimits from '../FormLimits/FormLimits';
import authService from '../../common/services/auth.service';
import LogoutConfirmation from '../LogoutConfirmation/LogoutConfirmation';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import LockIcon from '@mui/icons-material/Lock';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Feedback from '../Feedback/Feedback';
import FeatureRequest from '../FeatureRequest/FeatureRequest';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import UserMenu from '../UserMenu/UserMenu';

const MyDrawer = () => {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [drawerOpenOldState, setDrawerOpenOldState] = useState(true);
  const [isOptions, setIsOptions] = useState(false);
  const [isFields, setIsFields] = useState(false);
  const [isSections, setIsSections] = useState(false);
  const [isForms, setIsForms] = useState(false);
  const [isEditUsers, setIsEditUsers] = useState(false);
  const [isSubscription, setIsSubscription] = useState(false);
  const [cognitoUser, setCognitoUser] = useState(null);
  const [userGroupName, setUserGroupName] = useState(null);
  const [isSettings, setIsSettings] = useState(false);
  const [isIntegration, setIsIntegration] = useState(false);
  const [isFormDropDown, setIsFormDropDown] = useState(false);
  const [isGroups, setIsGroups] = useState(false); // New state for Groups option
  const [isSubmitted, setIsSubmitted] = useState(false); // New state for Submitted option
  const [groups, setGroups] = useState([]);
  const [selectedGroupId, setselectedGroupId] = useState('');
  const currentDrawerSelection = useRef(null);
  const [mappingsForDrawer, setMappingsForDrawer] = useState({
    'forms': 'Forms',
    'fields':'Fields',
    'sections':'Sections',
    'options':'Options',
    'edit-users':'Edit Users',
    'settings':'Settings',
    'integrations':'Integrations',
    'feedback': 'Feedback',
    'request': 'Request'
  });

  const [isLogoutModalOpen, setIsLogOutModal] = useState(false);
  const [currentHash, setCurrentHash] = useState('');
  const [isFeedback, setIsFeedback] = useState(false);
  const [isRequest, setIsRequest] = useState(false);

  const navigate = useNavigate();
  const location = useLocation(); // New hook to access the current location

  const isSmallScreen = useMediaQuery('(max-width:1100px)'); // Detect small screens

  // Call a function or update state based on screen size
  React.useEffect(() => {
    if (isSmallScreen) {
      if (drawerOpen) {
        setDrawerOpen(false);
        setDrawerOpenOldState(true);
      } else {
        setDrawerOpenOldState(false);
      }
    } else {
      if (drawerOpenOldState){
        setDrawerOpen(true)
      }
    }
  }, [isSmallScreen]);


  useEffect(() => {
    const hash = location.hash.replace('#', '');
    console.log("hash: ", hash);
    setCurrentHash(hash);

    currentDrawerSelection.current = mappingsForDrawer[hash];
    console.log("currentDrawerSelection: ", currentDrawerSelection.current);

    setAllFalse(); // Reset all states
    console.log("IS FEEDBACK STATE: ", isFeedback);

    switch (hash) {
      case 'forms':
        setIsForms(true);
        break;
      case 'fields':
        setIsFields(true);
        break;
      case 'sections':
        setIsSections(true);
        break;
      case 'options':
        setIsOptions(true);
        break;
      case 'edit-users':
        setIsEditUsers(true);
        break;
      // case 'subscription':
      //   setIsSubscription(true);
      //   break;
      case 'settings':
        setIsSettings(true);
        break;
      case 'integrations':
        setIsIntegration(true);
        break;
      case 'feedback':
        setIsFeedback(true);
        break;
      case 'request':
        setIsFeedback(true);
        break;
      // case 'submitted':
      //   setIsSubmitted(true);
      //   break;
      default:
        break;
    }
  }, [location.hash]);

  useEffect(() => {
    const checkToken = async () => {
      const isRefreshTokenValid = await authService.isRefreshTokenValid();
      console.log("isRefreshTokenValid: ", isRefreshTokenValid);
      if (!isRefreshTokenValid) {
        localStorageService.removeObject('user');
        navigate('/');
      } else {
        const token = await authService.refreshIdToken();
        user.signInUserSession.idToken.jwtToken = token;
        localStorageService.setObject('user', user);
        console.log("user: ", user);
      }
    };

    const user = localStorageService.getObject('user');
    setCognitoUser(user);
    if (user === null) {
      navigate('/');
    } else {
      checkToken();
      try {
        setUserGroupName(utilityFunctions.getPrimaryUserGroup(user));
      } catch(error) {
        console.log("User has not been assigned group: ", error);
        localStorageService.removeObject('user');
        navigate('/');
      }
    }


    const getFormGroups = async () => {
      try {
        const response = await apiGatewayService.getFormgroups();
        const dataWithUpdatedKeyAndContent = response.data.sort((a, b) => a.groupName.localeCompare(b.groupName));
        const tempGroups = dataWithUpdatedKeyAndContent.map((data) => ({
          text: data.groupName || '',
          icon: <CircleIcon sx={{ fontSize: '15px', padding: '0px' }} />,
          onClick: () => handleFormGroupSelected(data.groupId),
          groupId: data.groupId
        }));
        setGroups(tempGroups);
      } catch (error) {
        console.error('Error fetching forms:', error);
      }
    };
    getFormGroups();
  }, []);

  const setAllFalse = () => {
    setIsOptions(false);
    setIsFields(false);
    setIsSections(false);
    setIsForms(false);
    setIsEditUsers(false);
    setIsSubscription(false);
    setIsSettings(false);
    setIsIntegration(false);
    setIsGroups(false);
    setIsSubmitted(false);
    setIsFeedback(false);
    setIsRequest(false);
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'click') {
      setDrawerOpen(!drawerOpen);
    }
  };

  const handleOptionsSelection = () => {
    // setAllFalse();
    // setIsOptions(true);
    navigate('/sideMenu/#options');
  };

  const handleFieldsSelection = () => {
    // setAllFalse();
    // setIsFields(true);
    navigate('/sideMenu/#fields');
  };

  const handleSectiosSelection = () => {
    // setAllFalse();
    // setIsSections(true);
    navigate('/sideMenu/#sections');
  };

  const handleFormsSelection = () => {
    // setAllFalse();
    // setIsForms(true);
    setselectedGroupId('');
    // toggleIsFormDropDown();
    navigate('/sideMenu/#forms');
  };

  const handleEditUsersSelection = () => {
    // setAllFalse();
    // setIsEditUsers(true);
    navigate('/sideMenu/#edit-users')
  };

  const handleLogOut = () => {
    localStorageService.removeObject('user');
    navigate('/');
  };

  const handleSubscription = () => {
    setAllFalse();
    setIsSubscription(true);
    navigate('/sideMenu/#subscription');
  };

  const handleSettings = () => {
    setAllFalse();
    setIsSettings(true);
    navigate('/sideMenu/#settings');
  };

  const handleIntegrations = () => {
    setAllFalse();
    setIsIntegration(true);
    navigate('/sideMenu/#integrations');
  };

  const handleGroupsSelection = () => {
    setIsGroups(!isGroups);
  };

  const handleSubmittedSelection = () => {
    setAllFalse();
    setIsSubmitted(true);
  };

  const toggleIsFormDropDown = () => {
    setIsFormDropDown(!isFormDropDown);
  };

  const handleFormGroupSelected = (groupId) => {
    console.log("groupId: ", groupId);
    setselectedGroupId(groupId);
    navigate('/sideMenu/#forms');
  };

  const updateFormGroups = async () => {
    try {
      const response = await apiGatewayService.getFormgroups();
      console.log("get form groups response: ", response);
      const dataWithUpdatedKeyAndContent = response.data.sort((a, b) => a.groupName.localeCompare(b.groupName));
      const tempGroups = dataWithUpdatedKeyAndContent.map((data) => ({
        text: data.groupName || '',
        icon: <CircleIcon sx={{ fontSize: '15px', padding: '0px' }} />,
        onClick: () => handleFormGroupSelected(data.groupId)
      }));
      setGroups(Array.from(tempGroups));
    } catch (error) {
      console.error('Error fetching forms:', error);
    }
  };

  const handleFeedback  = () => {
    setAllFalse();
    setIsFeedback(true);
    navigate('/sideMenu/#feedback');
  };

  const handleRequest  = () => {
    setAllFalse();
    setIsRequest(true);
    navigate('/sideMenu/#request');
  };

  const handleReader = () => {
    const newTab = window.open(`https://app.freeform.citras.io/sideMenu`, '_blank', 'noopener,noreferrer');
    if (newTab) newTab.opener = null;
  };


  const sideMenuOptions = [
    { id: 'forms', text: 'Forms', icon: <ArticleIcon sx={{ fontSize: '30px', padding: '0px' }} />, onClick: handleFormsSelection },
    { id: 'sections' , text: 'Sections', icon: <ViewListIcon sx={{ fontSize: '30px', padding: '0px' }} />, onClick: handleSectiosSelection },
    { id: 'fields', text: 'Fields', icon: <FormatListBulletedIcon sx={{ fontSize: '30px', padding: '0px' }} />, onClick: handleFieldsSelection },
    { id: 'options', text: 'Options', icon: <ChecklistIcon sx={{ fontSize: '30px', padding: '0px' }} />, onClick: handleOptionsSelection },
    { id: 'integrations', text: 'Integrations', icon: <ApiIcon sx={{ fontSize: '30px', padding: '0px' }} />, onClick: handleIntegrations },
    { id: 'edit-users', text: 'Edit Users', icon: <GroupAddIcon sx={{ fontSize: '30px', padding: '0px' }} />, onClick: handleEditUsersSelection },
    { id: 'subscription', text: 'Subscription', icon: <CardMembershipIcon sx={{ fontSize: '30px', padding: '0px' }} />, onClick: handleSubscription },
    { id: 'settings', text: 'Settings', icon: <SettingsIcon sx={{ fontSize: '30px', padding: '0px' }} />, onClick: handleSettings },
    { id: 'feedback', text: 'Feedback', icon: <CommentIcon sx={{ fontSize: '30px', padding: '0px' }} />, onClick: handleFeedback },
    { id: 'request', text: 'Request', icon: <AddToQueueIcon sx={{ fontSize: '30px', padding: '0px' }} />, onClick: handleRequest },
    { id: 'reader', text: 'Reader', icon: <CollectionsBookmarkIcon sx={{ fontSize: '30px', padding: '0px' }} />, onClick: handleReader },
  ];

  return (
    <Box sx={{ display: 'flex', minWidth: '800px' }}>
      {isLogoutModalOpen && (
        <LogoutConfirmation
          isOpen={isLogoutModalOpen}
          handleClose={() => setIsLogOutModal(false)}
          handleLogOut={handleLogOut}
        />
      )}
      <Box sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap', // Ensures items wrap if they overflow
        gap: 2, // Adds space between items
      }}>
        <Drawer
          variant="persistent"
          anchor="left"
          open={true}
          ModalProps={{
            keepMounted: true,
            disableBackdropClick: true,
            BackdropProps: {
              sx: {
                backgroundColor: 'transparent',
              },
            },
          }}
          sx={{
            zIndex: 2000,
            '& .MuiDrawer-paper': {
              top: '0px',
              minWidth: (drawerOpen ? '250px' : '0px'),
              backgroundColor: '#2b4252',
              borderTop: '0px solid white',
              transition: 'min-width 0.3s ease-in-out, width 0.3s ease-in-out', // Smooth transition
              overflowX: 'hidden', // Prevents content from overflowing
            },
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
           { drawerOpen && <Avatar src={logoNew} alt="Logo" sx={{
              marginLeft: '10px', marginRight: '0px', marginTop: '-5px', width: '190px', height: '75px',
              '& img': {
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                mt: '7px',
                ml: '-5px'
              }
            }} />}
            {drawerOpen ? (
              <IconButton sx={{ marginLeft: "-2px", marginTop: '5px' }} edge="start" color="white" aria-label="menu" onClick={toggleDrawer(true)}>
                <MenuOpenIcon sx={{ fontSize: 38, color: "white" }} />
              </IconButton>
            ) : (
              <IconButton sx={{ marginLeft: "-1px", marginTop: '5px' }} edge="start" color="white" aria-label="menu" onClick={toggleDrawer(true)}>
                <MenuIcon sx={{ fontSize: 36, color: "white" }} />
              </IconButton>
            )}
          </Box>
          <Divider sx={{ borderBottomWidth: '3px', borderColor: 'white', mt:  (drawerOpen ? '-1px' : '12px'  ) }} />
          <div role="presentation">
            <List>
              {sideMenuOptions.map((option, index) => (
                <div key={index}>
                  <ListItem
                    button
                    sx={{
                      padding: '0px',
                      margin: '0px',
                      marginTop: '0px',
                      color: 'white',
                      backgroundColor: option.text === currentDrawerSelection.current ? 'var(--button-hover-color)': 'transparent',
                      '&:hover': {
                        backgroundColor: 'var(--button-hover-color)',
                        color: 'white',
                      },
                    }}

                    onClick={option.onClick}
                  >
                    <ListItemIcon sx={{ margin: '0px', marginTop: '0px', padding: '10px', color: 'white' }}>
                      {option.icon}
                    </ListItemIcon>
                    {drawerOpen && (
                      <>
                        <ListItemText
                          sx={{ marginLeft: '10px' }}
                          primary={option.text}
                          primaryTypographyProps={{ fontSize: '20px' }}
                        />
                        {option.text === 'Forms' && (
                          <IconButton
                            sx={{ color: 'white', marginLeft: 'auto' }}
                            onClick={toggleIsFormDropDown}
                          >
                            {isFormDropDown ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                          </IconButton>
                        )}
                      </>
                    )}
                  </ListItem>
                  {option.text === 'Forms' && (isFormDropDown && drawerOpen) && (
                    <List sx={{ paddingLeft: '20px', marginTop: '-20px' }}>
                      <ListItem
                        button
                        sx={{
                          padding: '0px',
                          margin: '0px',
                          marginTop: '10px',
                          color: 'white',
                          // '&:hover': {
                          //   backgroundColor: 'var(--button-hover-color)',
                          //   color: 'white',
                          // },
                        }}
                        onClick={handleGroupsSelection}
                      >
                        {/* <ListItemIcon sx={{ margin: 0, padding: 0, color: 'white' }}>
                          <RemoveIcon></RemoveIcon>
                        </ListItemIcon> */}
                        <ListItemText sx={{ marginLeft: '50px' }} primary="Groups" primaryTypographyProps={{ fontSize: '15px' }} />
                        <IconButton sx={{ marginLeft: 'auto', marginRight: '0px' , color: 'white'}}>
                          {isGroups ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                        </IconButton>
                      </ListItem>
                      {isGroups && (
                        <List sx={{ paddingLeft: '20px', marginTop: '-10px' , paddingBottom: 0, marginBottom: 0}}>
                          {groups.map((option2, index2) => (
                            <ListItem
                              button
                              key={index2}
                              sx={{
                                padding: '0px',
                                margin: '0px',
                                marginTop: '0px',
                                color: 'white',
                                // '&:hover': {
                                //   backgroundColor: 'var(--button-hover-color)',
                                //   color: 'white',
                                // },
                              }}
                              onClick={option2.onClick}
                            >
                              <ListItemIcon 
                                sx={{ 
                                  margin: '0px', 
                                  marginTop: '0px', 
                                  marginLeft: '45px', 
                                  padding: '0px', 
                                  color: 'white' 
                                }}
                              >
                                {(selectedGroupId === option2.groupId) ? <RadioButtonCheckedIcon sx={{ fontSize: '16px', padding: '0px' }}/> : option2.icon }
                              </ListItemIcon>
                              <ListItemText sx={{ marginLeft: '-30px' , paddingBottom: 0}} primary={option2.text} primaryTypographyProps={{ fontSize: '15px' }} />
                            </ListItem>
                          ))}
                        </List>
                      )}
                      {/* <ListItem
                        button
                        sx={{
                          padding: '0px',
                          margin: '0px',
                          marginTop: '10px',
                          color: 'white',
                          '&:hover': {
                            backgroundColor: 'var(--button-hover-color)',
                            color: 'white',
                          },
                        }}
                        onClick={handleSubmittedSelection}
                      >
                        <ListItemText sx={{ marginLeft: '10px' }} primary="Submitted" primaryTypographyProps={{ fontSize: '15px' }} />
                      </ListItem> */}
                    </List>
                  )}
                </div>
              ))}
            </List>
          </div>
        </Drawer>
        { (isOptions || isFields || isSections  || isForms || isEditUsers || isSubscription || isSettings || isIntegration || isSubmitted || isFeedback || isRequest) && 
          <Box
              component="main"
              sx={{
                flexGrow: 1,           
                p: 0,
                m: 0,
                marginLeft: drawerOpen ? '275px' : '80px',  // Dynamic margin based on drawer state
                marginRight: '20px',
                display: 'flex',          // Flexbox layout to control the alignment of children
                flexDirection: 'column',  // Stack children vertically
                alignItems: 'center',     // Center the children horizontally
                justifyContent: 'flex-start', // Align children to the top
                height: '100vh',          // Ensure the Box takes the full viewport height
                overflow: 'hidden',        // Prevent overflow, we will make inner content scrollable
                width: '100%',
              }}>       
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', m: 0, ml: '-5px', mb: '8px', mr: 0, width: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton sx={{ m: 0, p: 0 }} edge="start" aria-label="menu">
                {React.cloneElement(
                  sideMenuOptions.find((o) => o.id === currentHash)?.icon || <ArticleIcon />,
                  { sx: { fontSize: 32, color: 'var(--app-color)' } }
                )}
              </IconButton>
              <Typography variant="h4" sx={{ ml:'5px', mr: 2, color: 'var(--app-color)' }}>
                {sideMenuOptions.find((o) => o.id === currentHash)?.text || 'Default Text'}
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', mr: '-15px' }}>
              <UserMenu userGroupName={ userGroupName} setIsLogOutModal = {setIsLogOutModal} navigate={navigate}>
              </UserMenu>
              {/* <Typography
                  variant="body2"
                  sx={{
                    marginRight: '0px',
                    fontWeight: 'bold',
                    fontFamily: '"Montserrat", sans-serif',
                  }}
                >
                  {userGroupName}
                </Typography>
                <IconButton
                  sx={{
                    marginLeft: "auto",
                    marginRight: '-5px',
                    '&:hover': { backgroundColor: 'transparent', color: 'var(--button-hover-color)' }
                  }}
                  edge="end"
                  color="inherit"
                  aria-label="menu"
                  onClick={() => { }}
                >
                  <AccountCircleIcon sx={{ fontSize: 30 }} />
                </IconButton>
                <Typography
                  variant="body2"
                  sx={{
                    marginRight: '0px',
                    marginLeft: '5px',
                    fontWeight: 'bold',
                    fontFamily: '"Montserrat", sans-serif',
                  }}
                >
                  {'LogOut'}
                </Typography>
                <IconButton
                  sx={{
                    marginLeft: "auto",
                    marginRight: '0px',
                    '&:hover': { backgroundColor: 'transparent', color: 'var(--button-hover-color)' }
                  }}
                  edge="end"
                  color="inherit"
                  aria-label="menu"
                  onClick={() => setIsLogOutModal(true)}
                >
                  <LockIcon sx={{ fontSize: 30 }} />
                </IconButton> */}
            </Box>
          </Box>

          {isOptions && <Options2 userGroupName = {userGroupName} handleLogOut = {setIsLogOutModal} />}
          {isFields && <Fields2  userGroupName = {userGroupName} handleLogOut = {setIsLogOutModal}/>}
          {isSections && <Sections2 userGroupName = {userGroupName} handleLogOut = {setIsLogOutModal} />}
          {isForms && <Forms2 selectedGroupId={selectedGroupId} updateFormGroups = {setIsLogOutModal}  userGroupName = {userGroupName} handleLogOut = {setIsLogOutModal}/>}
          {isEditUsers && <EditUsers isEditUsers={isEditUsers} updateFormGroups = {setIsLogOutModal} userGroupName = {userGroupName} handleLogOut = {setIsLogOutModal} />}
          {isSubscription && <SubscriptionsComponent />}
          {isSettings && <Settings  updateFormGroups = {setIsLogOutModal} userGroupName = {userGroupName} handleLogOut = {setIsLogOutModal}/>}
          {isIntegration && <Integrations userGroupName = {userGroupName} updateFormGroups = {setIsLogOutModal} />}
          {isSubmitted && <div>Submitted Forms View</div>}
          {isFeedback && <Feedback />}
          {isRequest && <FeatureRequest/>}
        </Box>}
        {(!isOptions && !isFields && !isSections  && !isForms && !isEditUsers && !isSubscription && !isSettings && !isIntegration && !isSubmitted) &&
         <Box component="main" sx={{ flexGrow: 1, p: 0, marginTop: "0px", marginLeft: (drawerOpen ? '210px' : '30px')}}>
          <FormLimits/>
         </Box>
        }
      </Box>
    </Box>
  );
};

export default MyDrawer;