import React, { useState } from 'react';
import { Divider, Box, IconButton, Menu, MenuItem, Typography, ListItemIcon } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SettingsIcon from '@mui/icons-material/Settings';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

const UserMenu = ({ navigate, userGroupName, setIsLogOutModal }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangePassword = () => {
    // Handle Change Password
    console.log("Change Password clicked");
    // navigate('/changePassword');
    navigate('/changePassword', { state: { inAppChange: true } });
    handleClose();
  };

  const handleLogOut = () => {
    console.log("Log Out clicked");
    setIsLogOutModal(true);
    handleClose();
  };

  const handleSettings = () => {
    console.log("Settings clicked");
    navigate('/sideMenu/#settings');
    handleClose();
  };

  return (
    <div>
      <Box sx={{ display: 'flex', alignItems: 'center', ml: 0 }}>
        <Typography
          variant="body3"
          sx={{
            // marginRight: '10px',  // Adjust margin to properly space the icon and text
            fontWeight: 'bold',
            fontFamily: '"Montserrat", sans-serif',
            mr: '8px'
          }}
        >
          {userGroupName}
        </Typography>
        <IconButton
          onClick={handleClick}
          sx={{
            marginLeft: "auto", 
            color: 'var(--app-color)',
            p: 0,
            mr: '14px'
            // backgroundColor: 'var(--app-color)',
            // '&:hover': { backgroundColor: 'transparent', color: 'var(--button-hover-color)' },
          }}
        >
          <AccountCircleIcon sx={{ fontSize: 35 }} />
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        >
        {/* User Group in the Menu */}
        <MenuItem
            sx={{
            '&:hover': { backgroundColor: 'transparent', color: 'var(--button-hover-color)' },
            fontWeight: 'bold',
            fontFamily: '"Montserrat", sans-serif',
            }}
        >
            <ListItemIcon sx={{ fontSize: 25, color: 'var(--app-color)', }}>
            <ManageAccountsIcon />
            </ListItemIcon>
            {userGroupName}
        </MenuItem>
        <Divider sx={{ height: '1px', backgroundColor: 'black', m: 0, p: 0 }} />

        {/* Change Password */}
        <MenuItem
            onClick={handleChangePassword}
            sx={{
            '&:hover': { backgroundColor: 'transparent', color: 'var(--button-hover-color)' },
            fontWeight: 'bold',
            fontFamily: '"Montserrat", sans-serif',
            }}
        >
            <ListItemIcon sx={{ fontSize: 25 ,  color: 'var(--app-color)',}}>
            <LockIcon />
            </ListItemIcon>
            Change Password
        </MenuItem>

        {/* Log Out */}
        <MenuItem
            onClick={handleLogOut}
            sx={{
            '&:hover': { backgroundColor: 'transparent', color: 'var(--button-hover-color)' },
            fontWeight: 'bold',
            fontFamily: '"Montserrat", sans-serif',
            }}
        >
            <ListItemIcon sx={{ fontSize: 25,  color: 'var(--app-color)', }}>
            <ExitToAppIcon />
            </ListItemIcon>
            Log Out
        </MenuItem>

        {/* Settings */}
        <MenuItem
            onClick={handleSettings}
            sx={{
            '&:hover': { backgroundColor: 'transparent', color: 'var(--button-hover-color)' },
            fontWeight: 'bold',
            fontFamily: '"Montserrat", sans-serif',
            }}
        >
            <ListItemIcon sx={{ fontSize: 25 ,  color: 'var(--app-color)',}}>
            <SettingsIcon />
            </ListItemIcon>
            Settings
        </MenuItem>
        </Menu>
    </div>
  );
};

export default UserMenu;
