import React, { useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const AddUser = ({ isOpen, handleClose, submitUser }) => {
  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [groupType, setGroupType] = useState('reader');  // Default group type

  // Email validation function
  const validateEmail = (email) => {
    // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // return emailRegex.test(email);
    return true;
  };

  // Handle email input change
  const handleEmailChange = (e) => {
    const inputEmail = e.target.value;
    setEmail(inputEmail);
    setIsValidEmail(validateEmail(inputEmail));
  };

  // Handle group type change
  const handleGroupTypeChange = (e) => {
    setGroupType(e.target.value);
  };

  // Handle form submission
  const handleSubmit = () => {
    if (isValidEmail) {
      submitUser({ userEmail: email, groupType });  // Submit both email and group type
      setEmail('');  // Clear input after submission
      setGroupType('builder');  // Reset to default
      handleClose();  // Close modal
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="add-user-title"
      aria-describedby="add-user-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '8px',
          boxShadow: 24,
          width: '400px',
          outline: 'none',
          paddingBottom: '30px',
        }}
      >
        {/* Close Icon */}
        <IconButton
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>

        {/* Title */}
        {/* <Typography variant="h6" component="h2" sx={{ textAlign: 'center', mb: 2 }}>
          Add User
        </Typography> */}

        <h3>Add New User</h3>

        <hr></hr>

        {/* Email Input */}
        <Typography variant="body1" sx={{ mt: 2, mb: 1 }}>
          Email
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          value={email}
          onChange={handleEmailChange}
          placeholder="Enter a valid email address"
          error={!isValidEmail && email.length > 0}
          helperText={!isValidEmail && email.length > 0 ? 'Please enter a valid email address' : ''}
          sx={{
            "& .MuiInputBase-root": {
              height: 40,
              backgroundColor: 'white',
            },
            "& .MuiOutlinedInput-input": {
              height: 40,
              width: '100%',
            },
          }}
        />

        {/* Group Type Section */}
        <Typography variant="body1" sx={{ mt: 2, mb: 1 }}>
          Group Type
        </Typography>
        <FormControl component="fieldset" fullWidth>
          <RadioGroup
            row
            aria-label="group-type"
            name="group-type"
            value={groupType}
            onChange={handleGroupTypeChange}
          >
            <FormControlLabel
              value="admin"
              control={
                <Radio
                  sx={{
                    color: 'var(--app-color)',
                    '&.Mui-checked': {
                      color: 'var(--app-color)',
                    },
                  }}
                />
              }
              label="Admin"
            />
            <FormControlLabel
              value="builder"
              control={
                <Radio
                  sx={{
                    color: 'var(--app-color)',
                    '&.Mui-checked': {
                      color: 'var(--app-color)',
                    },
                  }}
                />
              }
              label="Builder"
            />
            <FormControlLabel
              value="reader"
              control={
                <Radio
                  sx={{
                    color: 'var(--app-color)',
                    '&.Mui-checked': {
                      color: 'var(--app-color)',
                    },
                  }}
                />
              }
              label="Reader"
            />
          </RadioGroup>
        </FormControl>

        {/* Submit Button */}
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={!isValidEmail}  // Disable button if email is not valid
          fullWidth
          sx={{
            mt: 4,
            backgroundColor: isValidEmail ? 'var(--button-color)' : '#ddd',
            color: isValidEmail ? 'white' : '#aaa',
            fontWeight: 'normal',
            textTransform: 'none',
            ":hover": {
              backgroundColor: isValidEmail ? 'var(--button-hover-color)' : '#ddd',
            },
          }}
        >
          Submit
        </Button>

                {/* Cancel Button */}
                <Button
          variant="outlined"
          onClick={handleClose}
          sx={{
            mt: 2,
            width: '100%',
            color: 'var(--button-color)',
            borderColor: 'var(--button-color)',
            textTransform: 'none',
            ":hover": {
              borderColor: 'var(--button-color)',
            },
          }}
        >
          Cancel
        </Button>
      </Box>
    </Modal>
  );
};

export default AddUser;
