import React, { useEffect, useState } from 'react';
import classes from './AuthComponent.module.css';
import apiGatewayService from '../../common/services/apiGatewayService';
import { useNavigate } from 'react-router-dom';
import localStorageService from '../../common/services/localStorage.service';
import {Checkbox, FormControl, FormControlLabel, Button, Drawer, List, ListItem, ListItemText, IconButton, AppBar, Toolbar, Typography, Box, CssBaseline, ListItemIcon, Divider, Avatar } from '@mui/material';
import logo from '../../common/images/FreeForm-Logo-Without-Text.png';
import logoNew from '../../common/images/FreeForm-Logo_White.png';
import Error from '../Error/Error';
import { CircularProgress } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const AuthComponentChangePassword = ({auth, changePassword}) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [code, setCode] = useState(null);
  const [signUpSuccessfull, setSignUpSuccessfull] = useState(false);
  const [isIndividual, setIsIndividual] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [passwordReset, setPasswordReset] = useState(false);
  const [showPasswordButton, setShowPasswordButton] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [passwordChange, setPasswordChange] = useState(false);
  const [newPassword, setNewPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordChangeCode, setPasswordChangeCode] = useState(false);

  
  useEffect(()=> {
    const signUpType = localStorageService.getObject('signUpType');
    console.log("Check the signup type: ", signUpType);
    if (signUpType === 'individual') {
      setIsIndividual(true);
    };
    if (changePassword){
      setPasswordChange(true);
    };
  }, []);

  const handleSignUp = async () => {
    setErrorMessage('');
    setIsLoading(true);
    try {
      await auth.signUp(email, password);
      // alert('Sign up successful!');
      localStorageService.setObject('user', auth.user);
      setSignUpSuccessfull(true);
      // navigate('/sideMenu');
    } catch (error) {
      // alert('Sign up error: ' + error.message);
      setErrorMessage(`Error: ${error.message}`);
    }
    setIsLoading(false);
  };

  const handleSignIn = async (password) => {
    setErrorMessage('');
    setShowPasswordButton(false);
    setIsLoading(true);
    try {
      await auth.signIn(email, password);
      console.log("CHECK FOR THE USER HERE: ");
      console.log(auth.user);
      localStorageService.setObject('user', auth.user);
      updateSignUpType(auth.user);
      // alert('Sign in successful!');
      // navigate('/landingPage');
      navigate('/sideMenu');
    } catch (error) {
      // alert('Sign in error: ' + error.message);
      setErrorMessage(`Error: ${error.message}`);
      if(error.message === 'Incorrect username or password.'){
        setShowPasswordButton(true);
      }
    }
    setIsLoading(false);
  };

  const handleGetUserGroups = async () => {
    try {
      const groups = await auth.getUserGroups();
      alert('User groups: ' + groups.join(', '));
    } catch (error) {
      alert('Error getting user groups: ' + error.message);
    }
  };

  const handleVerifyCode = async () => {
    setErrorMessage('');
    setIsLoading(true);
    try {
      await auth.confirmSignUp(email, code);
      // alert('User confirmed successfully');
      const signUpType = localStorageService.getObject('signUpType');
      const response  = await apiGatewayService.assignGroupToUser(email, signUpType);
      // console.log(response);
      setSignUpSuccessfull(false);
      // await auth.createUserGroup(email.split['@'], 'Users New grou')
    } catch (error) {
    //  alert('User Not confirmed! '+error.message);
        setErrorMessage(`Error: ${error.message}`);
    }
    setIsLoading(false);
  };

  const handlePrivacyButton = () => {

  };

  const updateSignUpType = (user) => {
    const userGroupName = user?.signInUserSession?.accessToken?.payload["cognito:groups"][0];
    if(userGroupName.includes('admin') || userGroupName.includes('builder') || userGroupName.includes('reader')) {
      localStorageService.setObject('signUpType', 'organization');
    } else {
      localStorageService.setObject('signUpType', 'individual');
    }
  };

  const handlePasswordReset = async () => {
    setErrorMessage(null);
    setIsLoading(true);
    setPassword(null);
    setCode("");
    await auth.forgotPassword(email);
    setPasswordReset(true);
    setIsLoading(false);
  };

  const handlePasswordResetRemove = () => {
    setPassword(null);
    setCode("");
    setPasswordReset(false);
    setShowPasswordButton(false);
    setErrorMessage(null);
    setNewPassword(null);
    setPasswordChange(false);
  };
  

  const submitPasswordReset = async () => {
    setIsLoading(true);
    console.log("email: ", email);
    console.log("code: ", code);
    console.log("password: ", password);
    await auth.forgotPasswordSubmit(email, code, password);
    setIsLoading(false);
    await handleSignIn(password);
  };

  const handleSignUpNavigation = () => {
    navigate('/signUpType');
  };

  const submitPasswordChange = async () => {
    setIsLoading(true);
    await auth.changePassword(email, password, newPassword);
    setIsLoading(false);
    await handleSignIn(newPassword);
  };

  return (
  <div>
    <AppBar position="fixed">
      <Toolbar sx={{ backgroundColor: '#2b4252' }}>
      <Avatar src={logoNew} alt="Logo" sx={{
                marginLeft: '-20px', marginRight: '0px', marginTop: '-5px', width: '165px', height: '65px',
                '& img': {
                  width: '100%',
                  height: '80%',
                  objectFit: 'contain',
                  mt: '7px',
                  ml: '0px'
                }
        }} />
      </Toolbar>
    </AppBar>
    { passwordChange ?
          <div className={classes.Container}>
          <div className={classes.Border}>
            <div className={classes.LogoDiv}>
              <img className={classes.Logo} src={logo} alt="ao-logo" />
            </div>
            <hr />
            <h5 className={classes.Heading}>Change your password</h5>
            <input
              type="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              placeholder="Email"
              className={classes.InputClass}
            />
            <input
               type={showPassword ? 'email' : 'password'}
              value={password}
              onChange={e => setPassword(e.target.value)}
              placeholder="Current Password"
              className={classes.InputClass}
              maxLength={8}
            />
            <input
               type={showPassword ? 'email' : 'password'}
              value = {code}
              onChange={e => setNewPassword(e.target.value)}
              placeholder="New Password"
              className={classes.InputClass}
              maxLength={8}
            />
            <button className={classes.Button} onClick={submitPasswordChange}>Submit New Password</button>
            <button className={classes.Button} onClick={handlePasswordResetRemove}>Back to signIn</button>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <IconButton
                  onClick={() => setShowPassword((prev) => !prev)}
                  sx={{
                    color: 'var(--app-color)',
                  }}
                >
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              <Typography sx={{ mt: '4px', fontSize: '16px' }}>
                {showPassword ? 'Hide Password' : 'Show Password'}
              </Typography>
            </Box>
            <Error error={errorMessage} />

            {isLoading && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: '30px' }}>
              <CircularProgress sx={{ color: 'var(--app-color)' }} />
            </div>}
          </div>
  
        </div> : (passwordReset ? (
      <div className={classes.Container}>
        <div className={classes.Border}>
          <div className={classes.LogoDiv}>
            <img className={classes.Logo} src={logo} alt="ao-logo" />
          </div>
          <hr />
          <h5 className={classes.Heading}>Reset your password</h5>
          <input
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder="Email"
            className={classes.InputClass}
          />
          <input
            type="code"
            value = {code}
            onChange={e => setCode(e.target.value)}
            placeholder="Enter Code"
            className={classes.InputClass}
            maxLength={8}
          />
          <input
             type={showPassword ? 'email' : 'password'}
            value={password}
            onChange={e => setPassword(e.target.value)}
            placeholder="New Password"
            className={classes.InputClass}
            maxLength={8}
          />
          <button className={classes.Button} onClick={submitPasswordReset}>Submit New Password</button>
          <button className={classes.Button} onClick={handlePasswordResetRemove}>Back to signIn</button>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Checkbox
                checked={showPassword}
                onChange={() => setShowPassword((prev) => !prev)}
                color="primary"
                sx={{
                  color: 'var(--app-color)', 
                  '&.Mui-checked': {
                    color: 'var(--app-color)', 
                  },
                }}
              />
              <Typography sx={{ ml: '-15px', mt: '4px', fontSize: '16px' }}>
                Show Passwords
              </Typography>
            </Box>
          <Error error={errorMessage} />
          {/* <button className={classes.Button} onClick={handleSignUp}>Sign Up</button> */}
          {/* <button className={classes.Button} onClick={handleGetUserGroups}>Get User Groups</button> */}
          {isLoading && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: '30px' }}>
            <CircularProgress sx={{ color: 'var(--app-color)' }} />
          </div>}
        </div>

      </div>
    ) : (
      !signUpSuccessfull ? (
        <div className={classes.Container}>
          <div className={classes.Border}>
            <div className={classes.LogoDiv}>
              <img className={classes.Logo} src={logo} alt="ao-logo" />
            </div>
            <hr />
            {/* <h1>Free Form Registration</h1> */}
            <h5 className={classes.Heading}>Enter your email address to continue</h5>
            <input
              type="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              placeholder="Email"
              className={classes.InputClass}
            />
            <input
              type={showPassword ? 'email' : 'password'}
              value={password}
              onChange={e => setPassword(e.target.value)}
              placeholder="Password"
              className={classes.InputClass}
              maxLength={8}
            />
            <button className={classes.Button} onClick={()=> handleSignIn(password)}>Sign In</button>
            {showPasswordButton && 
            <button className={classes.Button} onClick={handlePasswordReset}>Reset Password</button>
            }

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <IconButton
                  onClick={() => setShowPassword((prev) => !prev)}
                  sx={{
                    color: 'var(--app-color)',
                  }}
                >
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              <Typography sx={{ mt: '4px', fontSize: '16px' }}>
                {showPassword ? 'Hide Password' : 'Show Password'}
              </Typography>
            </Box>

            <Error error={errorMessage} />
            {isLoading && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress sx={{ color: 'var(--app-color)' }} />
          </div>}
            {/* <button className={classes.Button} onClick={handleSignUp}>Sign Up</button> */}
            {/* <button className={classes.Button} onClick={handleGetUserGroups}>Get User Groups</button> */}
          </div>
        </div>
      ) : (
        <div className={classes.Container}>
          <div className={classes.Border}>
            <div className={classes.LogoDiv}>
              <img className={classes.Logo} src={logo} alt="ao-logo" />
            </div>
            <hr />
            {/* <h3 className={classes.Heading}>Enter your email address to continue</h3> */}
            <input
              type="code"
              onChange={e => setCode(e.target.value)}
              placeholder="Enter Code"
              className={classes.InputClass}
              maxLength={8}
            />
            <button className={classes.Button} onClick={handleVerifyCode}>Verify Code</button>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <IconButton
                  onClick={() => setShowPassword((prev) => !prev)}
                  sx={{
                    color: 'var(--app-color)',
                  }}
                >
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              <Typography sx={{ mt: '4px', fontSize: '16px' }}>
                {showPassword ? 'Hide Password' : 'Show Password'}
              </Typography>
            </Box>
            <Error error={errorMessage} />
            {isLoading && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress sx={{ color: 'var(--app-color)' }} />
          </div>}
          </div>
    
        </div>
      )
    ))}
          <Box
            sx={{
              marginTop: '-35px',
              textAlign: 'center',
            }}
          >
            <Typography sx={{ fontSize: '14px', display: 'inline' }}>
              Don’t have an account?
            </Typography>
            <Button
              onClick={handleSignUpNavigation}
              sx={{
                textTransform: 'none',
                fontWeight: 'bold',
                fontSize: '14px',
                color: 'var(--app-color)',
                marginLeft: '0px',
                "&:hover": {
                  textDecoration: 'underline',
                },
              }}
            >
              Sign Up
            </Button>
          </Box>
  </div>

  );
};

export default AuthComponentChangePassword;
