import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

const CustomAlert = ({ message, type, isOpen, hideCancelIcon }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(isOpen);
  }, [message, type, isOpen]);

  return (
    <Box sx={{ width: '100%', position: 'sticky', top: 0, zIndex: 1000, mb: '5px' }}> {/* Added sticky positioning */}
      <Collapse in={open}>
        <Alert
          severity={type}
          action={
            !hideCancelIcon && (
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            )
          }
          sx={{ mb: '0px', mt: '5px' }}
        >
          {message}
        </Alert>
      </Collapse>
    </Box>
  );
};

export default CustomAlert;