import { Auth } from 'aws-amplify';
import { region, userPoolId } from '../../globalVariables';
import { CognitoIdentityProviderClient, CreateGroupCommand } from "@aws-sdk/client-cognito-identity-provider";
import localStorageService from './localStorage.service';

class AuthService {
  constructor() {
    this.client = new CognitoIdentityProviderClient({ region });
    this.user = null;
    this.password = null;
  }

  async createUserGroup(groupName, description) {
    const params = {
      UserPoolId: userPoolId,
      GroupName: groupName,
      Description: description,
    };

    try {
      const command = new CreateGroupCommand(params);
      const response = await this.client.send(command);
      console.log(`Group ${groupName} created successfully`);
      return response;
    } catch (error) {
      console.error("Error creating group:", error);
      throw error;
    }
  }

  async signUp(email, password) {
    try {
      const { user } = await Auth.signUp({
        username: email,
        password,
        attributes: { email },
      });
      console.log(user);
      this.password = password;
      return user;
    } catch (error) {
      console.log('Error signing up:', error);
      throw error;
    }
  }

  async confirmSignUp(email, confirmationCode) {
    try {
      await Auth.confirmSignUp(email, confirmationCode);
      // const user = await this.signIn(email, this.password);
      // this.user = user;
      // console.log(user);
      console.log('User confirmed successfully!');
    } catch (error) {
      console.log('Error confirming sign up:', error);
      throw error;
    }
  }

  async signIn(email, password) {
    try {
      const user = await Auth.signIn(email, password);
      this.user = user;
      console.log("SignIn has been successful!");
      console.log(user);
      return user;
    } catch (error) {
      console.log('Error signing in:', error);
      throw error;
    }
  }

  async signInDefault(email) {
    try {
      const user = await Auth.signIn(email, this.password);
      this.user = user;
      console.log(user);
      return user;
    } catch (error) {
      console.log('Error signing in:', error);
      throw error;
    }
  }

  async getUserGroups() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const groups = user.signInUserSession.accessToken.payload['cognito:groups'];
      console.log(groups);
      return groups;
    } catch (error) {
      console.log('Error getting user groups:', error);
      throw error;
    }
  }

  getCurrentUser() {
    return this.user;
  }

  async getCurrentAuthenticatedUser () {
   const user = await Auth.currentAuthenticatedUser();
   return user;

  }


  async forgotPassword(email) {
    try {
      await Auth.forgotPassword(email);
      console.log('Password reset initiated successfully!');
    } catch (error) {
      console.log('Error initiating password reset:', error);
      throw error;
    }
  }

  async forgotPasswordSubmit(email, code, newPassword) {
    try {
      await Auth.forgotPasswordSubmit(email, code, newPassword);
      // const user = await Auth.signIn(email, newPassword);
      // this.user = user;
      console.log('Password reset successfully!');
    } catch (error) {
      console.log('Error resetting password:', error);
      throw error;
    }
  }

  async refreshIdToken() {
    try {
      const session = await Auth.currentSession();
      const idToken = session.getIdToken().getJwtToken();
      // console.log("ID Token refreshed:", idToken);
      return idToken;
    } catch (error) {
      console.log("Error refreshing ID token:", error);
      throw error;
    }
  }

    // New method to check refresh token validity
    async isRefreshTokenValid() {
      try {
        // Attempt to get the current session
        const session = await Auth.currentSession();
        const refreshToken = session.getRefreshToken().getToken();
        
        // Simulate using the refresh token by refreshing the session (Amplify manages this)
        const newSession = await Auth.currentSession(); // This will internally use the refresh token
  
        console.log("Refresh Token is valid.");
        return true;
      } catch (error) {
        // If there's an error, the refresh token is likely expired or invalid
        if (error.code === 'NotAuthorizedException' && error.message.includes('Refresh Token has expired')) {
          console.log("Refresh Token has expired.");
        } else {
          console.log("Error checking refresh token validity:", error);
        }
        return false;
      }
    }

    // async isRefreshTokenValid() {
    //   try {
    //     // Fetch user object from localStorage
    //     const user = localStorageService.getObject('user');
    //     const refreshToken = user?.signInUserSession?.refreshToken?.token ? user.signInUserSession.refreshToken.token : null;
    //     console.log(refreshToken);
    //     if (!refreshToken) {
    //       console.log("No refresh token found in localStorage.");
    //       return false;
    //     }
    
    //     // Decode the refresh token to check its expiration
    //     const payload = JSON.parse(atob(refreshToken.split('.')[1])); // Decode the JWT payload
    //     const currentTimestamp = Math.floor(Date.now() / 1000); // Current time in seconds
    //     console.log("currentTimestamp: ", currentTimestamp);
    //     console.log("payload.exp: ", payload.exp);
    
    //     // Check if the refresh token has expired
    //     if (payload.exp > currentTimestamp) {
    //       console.log("Refresh Token is valid.");
    //       return true;
    //     } else {
    //       console.log("Refresh Token has expired.");
    //       return false;
    //     }
    //   } catch (error) {
    //     console.error("Error checking refresh token validity:", error);
    //     return false;
    //   }
    // }
    
    async isIdTokenValid() {
      // return false;
      try {
        // Get the current session
        // const session = await Auth.currentSession();
        // const idToken = session.getIdToken();
        // const idTokenJwt = idToken.getJwtToken();

        const user = localStorageService.getObject('user');
        const idTokenJwt = user?.signInUserSession?.idToken?.jwtToken ? user.signInUserSession.idToken.jwtToken : null;
    
        // Decode the ID token to extract the payload (base64 decoding the middle part of JWT)
        const payload = JSON.parse(atob(idTokenJwt.split('.')[1]));
        const currentTimestamp = Math.floor(Date.now() / 1000); // Current time in seconds
        console.log("currentTimestamp: ", currentTimestamp);
        console.log("payload.exp: ", payload.exp );
    
        // Check if the token has expired
        if (payload.exp > currentTimestamp) {
          // console.log("ID Token is valid.");
          return true;
        } else {
          // console.log("ID Token has expired.");
          return false;
        }
      } catch (error) {
        // console.error("Error checking ID token validity:", error);
        return false;
      }
    }

    async changePassword(email, currentPassword, newPassword) {
      console.log("email: ", email);
      console.log("currentPassword: ", currentPassword);
      console.log("newPassword: ", newPassword);
      try {
        // First, sign in the user to get the authenticated user object
        const user = await this.signIn(email, currentPassword);
    
        // Once signed in, change the password
        await Auth.changePassword(user, currentPassword, newPassword);
        console.log('Password changed successfully!');
        // await this.signIn(email, newPassword);
        return { success: true, message: 'Password changed successfully' };
      } catch (error) {
        console.error('Error changing password:', error);
        throw new Error(`Error changing password: ${error.message}`);
      }
    }

    async signInWithTemporaryPassword(email, tempPassword, newPassword) {
      try {
        const user = await Auth.signIn(email, tempPassword);
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          const updatedUser = await Auth.completeNewPassword(user, newPassword);  // Change to new password
          console.log('Password changed successfully:', updatedUser);
          return updatedUser;
        }
        console.log('Signed in without password change:', user);
        return user;
      } catch (error) {
        console.error('Error during sign-in:', error);
        throw error;
      }
    }
    
}

const authService = new AuthService();
export default authService;
