import React, { useEffect, useState } from 'react';
import classes from './AuthComponentSignUp.module.css';
import apiGatewayService from '../../common/services/apiGatewayService';
import { useNavigate } from 'react-router-dom';
import localStorageService from '../../common/services/localStorage.service';
import { Checkbox, Button, Drawer, List, ListItem, ListItemText, IconButton, AppBar, Toolbar, Typography, Box, CssBaseline, ListItemIcon, Divider, Avatar } from '@mui/material';
import logo from '../../common/images/FreeForm-Logo-Without-Text.png';
import logoNew from '../../common/images/FreeForm-Logo_White.png';
import Error from '../Error/Error';
import { CircularProgress } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const AuthComponentSignUp = ({auth}) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [code, setCode] = useState(null);
  const [signUpSuccessfull, setSignUpSuccessfull] = useState(false);
  const [isIndividual, setIsIndividual] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(()=> {
    const signUpType = localStorageService.getObject('signUpType');
    console.log("Check the signup type: ", signUpType);
    if (signUpType === 'individual') {
      setIsIndividual(true);
    };
  }, []);

  const handleSignUp = async () => {
    setErrorMessage('');
    setIsLoading(true);
    try {
      await auth.signUp(email, password);
      // alert('Sign up successful!');
      localStorageService.setObject('user', auth.user);
      setSignUpSuccessfull(true);
      // navigate('/sideMenu');
    } catch (error) {
      setErrorMessage(`Error: ${error.message}`);
      // alert('Sign up error: ' + error.message);
    }
    setIsLoading(false);
  };

  const handleSignIn = async () => {
    setErrorMessage('');
    setIsLoading(true);
    try {
      await auth.signIn(email, password);
      console.log("CHECK FOR THE USER HERE: ");
      console.log(auth.user);
      localStorageService.setObject('user', auth.user);
      updateSignUpType(auth.user);
      // alert('Sign in successful!');
      // navigate('/landingPage');
      navigate('/sideMenu');
    } catch (error) {
      // alert('Sign in error: ' + error.message);
    }
    setIsLoading(false);
  };

  const handleGetUserGroups = async () => {
    try {
      const groups = await auth.getUserGroups();
      alert('User groups: ' + groups.join(', '));
    } catch (error) {
      alert('Error getting user groups: ' + error.message);
    }
  };

  const handleVerifyCode = async () => {
    setErrorMessage('');
    setIsLoading(true);
    try {
      await auth.confirmSignUp(email, code);
      // alert('User confirmed successfully');
      const signUpType = localStorageService.getObject('signUpType');
      const response  = await apiGatewayService.assignGroupToUser(email, signUpType);
      await auth.signInDefault(email);
      localStorageService.setObject('user', auth.user);
      navigate('/sideMenu');
      console.log(response);
      setSignUpSuccessfull(false);
      // await auth.createUserGroup(email.split['@'], 'Users New grou')
    } catch (error) {
      setErrorMessage(`Error: ${error.message}`);
    //  alert('User Not confirmed! '+error.message);
    }
    setIsLoading(false);
  };

  const handlePrivacyButton = () => {

  };

  const updateSignUpType = (user) => {
    const userGroupName = user?.signInUserSession?.accessToken?.payload["cognito:groups"][0];
    if(userGroupName.includes('admin') || userGroupName.includes('builder') || userGroupName.includes('reader')) {
      localStorageService.setObject('signUpType', 'organization');
    } else {
      localStorageService.setObject('signUpType', 'individual');
    }
  };

  const handleSignInNavigation = () => {
    navigate('/signIn');
  };

  return (
    <div>
      <AppBar position="fixed">
        <Toolbar   sx={{ backgroundColor: '#2b4252'}}>
          {/* <Avatar src={logo} alt="Logo" sx={{ marginRight: '10px',width: '40px', height: '40px' }} />
          <Typography variant="h5" noWrap>
            Freeform
          </Typography> */}
        {/* <Avatar src={logoNew} alt="Logo" sx={{ marginLeft: '-30px',  marginTop: '5px', marginRight: '0px', width: '180px', height: '60px' }} />   */}
        <Avatar src={logoNew} alt="Logo" sx={{
              marginLeft: '-20px', marginRight: '0px', marginTop: '-5px', width: '165px', height: '65px',
              '& img': {
                width: '100%',
                height: '80%',
                objectFit: 'contain',
                mt: '7px',
                ml: '0px'
              }
        }} />
        </Toolbar>
      </AppBar>
      {!signUpSuccessfull ? (<div className={classes.Container}>
        <div className={classes.Border}>
        <div className={classes.LogoDiv}>
                <img className={classes.Logo} src={logo} alt="ao-logo"/>
         </div>
         <hr></hr>
        {/* <h1>Free Form Registration</h1> */}
        <h5 className={classes.Heading}>Enter your email address to continue</h5>
        <input
          type="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
          placeholder="Email"
          className={classes.InputClass}
        />
        <input
         type={showPassword ? 'email' : 'password'}
          value={password}
          onChange={e => setPassword(e.target.value)}
          placeholder="Password"
          className={classes.InputClass}
          maxLength={8}
        />
        {/* <button className={classes.Button} onClick={handleSignIn}>Sign In</button> */}
        <button className={classes.Button} onClick={handleSignUp}>Sign Up</button>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <IconButton
                  onClick={() => setShowPassword((prev) => !prev)}
                  sx={{
                    color: 'var(--app-color)',
                  }}
                >
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              <Typography sx={{ mt: '4px', fontSize: '16px' }}>
                {showPassword ? 'Hide Password' : 'Show Password'}
              </Typography>
            </Box>
        <Error error={errorMessage}></Error>
        {isLoading && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress sx={{ color: 'var(--app-color)' }} />
        </div>}
        {/* <button className={classes.Button} onClick={handleGetUserGroups}>Get User Groups</button> */}
       </div>
      </div>
      ) : (
        <div className={classes.Container}>        
        <div className={classes.Border}>
        <div className={classes.LogoDiv}>
                <img className={classes.Logo} src={logo} alt="ao-logo"/>
         </div>
         <hr></hr>
        {/* <h3 className={classes.Heading}>Enter your email address to continue</h3> */}
          <input
            type="code"
            onChange={e => setCode(e.target.value)}
            placeholder="Enter Code"
            className={classes.InputClass}
            maxLength={8}
          />
          <button className={classes.Button} onClick={handleVerifyCode}>Verify Code</button>
          <Error error={errorMessage}></Error>
          {isLoading && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',  mt: '30px'  }}>
          <CircularProgress sx={{ color: 'var(--app-color)' }} />
        </div>}
        </div>
        </div>
      ) 
     }
        <Box
            sx={{
              marginTop: '-30px',
              textAlign: 'center',
            }}
          >
            <Typography sx={{ fontSize: '14px', display: 'inline' }}>
              Already have an account?
            </Typography>
            <Button
              onClick={handleSignInNavigation}
              sx={{
                textTransform: 'none',
                fontWeight: 'bold',
                fontSize: '14px',
                color: 'var(--app-color)',
                marginLeft: '0px',
                "&:hover": {
                  textDecoration: 'underline',
                },
              }}
            >
              Sign In
            </Button>
          </Box>

   </div>
  );
};

export default AuthComponentSignUp;
