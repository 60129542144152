import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, Button, IconButton, Paper, Container } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FormSectionsBuilder from '../FormBuilder/FormSectionsBuilder/FormSectionsBuilder';
import ReactJson from 'react-json-view';

const FormsModal = ({ open, handleClose, form, isViewJSON }) => {

  useEffect(() => {
    console.log('form received: ', form);
    console.log('isViewJSON: ', isViewJSON);
    if (isViewJSON) {
      setIsViewEnabledJSON(true);
    }
  }, [form, isViewJSON]);

  const [isViewEnabledJSON, setIsViewEnabledJSON] = useState(false);

  // Function to download JSON file
  const downloadJSON = () => {
    console.log("form in download json: ", form);
    const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(form, null, 2));
    const downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", `${form?.formHeaders?.FormName || 'form'}.json`);
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      {isViewEnabledJSON ? (
        <Container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', width: '50%' }}>
          <Box sx={{ p: 0, maxWidth: '100%', maxHeight: '85%', width: '100%', marginTop: '-50px', overflow: 'auto', border: '10px solid', borderRadius: '5px', borderColor: '#2b4252' }}>
            {/* Sticky Header with Download Button */}
            <Box sx={{ 
              position: 'sticky', 
              top: 0, 
              backgroundColor: 'white', 
              zIndex: 1, 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center', 
              padding: '10px', 
              borderBottom: '1px solid #ccc'
            }}>
              <Typography variant="h6" gutterBottom>
                {form?.formHeaers?.FormName}
              </Typography>
              <Button 
                variant="contained" 
                onClick={downloadJSON} 
                sx={{ backgroundColor: '#2b4252', ':hover': { backgroundColor: '#3b5462' } }}
              >
                Download JSON
              </Button>
            </Box>
            <Paper
              elevation={3}
              sx={{
                p: 2,
                maxHeight: '100%',
                maxWidth: '100%',
                overflow: 'auto',
                border: '0px solid #ccc',
                padding: 0,
                margin: 0,
                marginTop: '-5px',
                paddingBottom: '5px'
              }}
            >
              <ReactJson src={form} theme="githubLightTheme" />
            </Paper>
          </Box>
        </Container>
      ) : (
        <Box sx={{
          width: '90%',
          height: '90%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          overflowY: 'auto',
          overflowX: 'auto',
          justifyContent: 'center'
        }}>
          <Typography variant="body1" id="modal-description" sx={{ mt: 2 }}>
            {(Object.keys(form).length === undefined) ? <FormSectionsBuilder form={{}} handleClose={handleClose} /> : <FormSectionsBuilder form={form} handleClose={handleClose} />}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              color: 'text.secondary',
              backgroundColor: '#2b4252',
              color: 'white',
              ":hover": {
                backgroundColor: '#2b4252',
                color: 'white',
              }
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      )}
    </Modal>
  );
}

export default FormsModal;
