import React, { useState, useEffect } from 'react';
import apiGatewayService from '../../../common/services/apiGatewayService';
import {
  Modal,
  Box,
  TextField,
  Select,
  MenuItem,
  Button,
  CircularProgress,
  InputLabel,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import utilityFunctions from '../../../common/services/utilityFunctions';
import ReactSelect from 'react-select';

const FieldsSelector = ({ isOpen, handleClose, submitField }) => {
  const [availableFields, setAvailableFields] = useState([]);
  const [selectedField, setSelectedField] = useState(null);
  const [fieldSelected, setFieldsSelected] = useState(false);
  const [selectedFieldDisplayName, setSelectedFieldDisplayName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState('');

  useEffect(() => {
    const getOptions = async () => {
      setIsLoading(true);
      try {
        const response = await apiGatewayService.getFieldsData();
        const data = utilityFunctions.updateFieldNamesWithDescription(response.data);
        const dataSorted = utilityFunctions.sortByKey(data, 'fieldDisplayName');
        const formattedFields = dataSorted.map((field) => ({
          ...field,
          value: field.fieldDisplayName,
          label: field.fieldDisplayName,
        }));
        setAvailableFields(formattedFields);
      } catch (error) {
        console.error('Error fetching fields:', error);
      }
      setIsLoading(false);
    };
    getOptions();
  }, []);

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredFields = availableFields.filter((option) =>
    option.fieldDisplayName.toLowerCase().includes(filter.toLowerCase())
  );

  const handleChange = (event) => {
    console.log("selectedField: ", event);
    // const fieldName =  event.target.value?.fieldName;
    // const fieldDescription = event.target.value?.fieldDescription
    // const selectOption = availableFields.find((option) => option.fieldName === fieldName && option.fieldDescription === fieldDescription);
    if (event!==null){
      const selectField = availableFields.find((option) => option.fieldDisplayName === event.fieldDisplayName);
      setSelectedField(selectField);
      setSelectedFieldDisplayName(selectField.fieldDisplayName);
      setFieldsSelected(true);
    } else {
      setSelectedField(null);
      setSelectedFieldDisplayName('');
      setFieldsSelected(false);
    }

  };

  const handleSubmit = async () => {
    console.log("selectedField: ",selectedField);
    const field =  selectedField?.fieldId ? await getFieldById(selectedField.fieldId): undefined;
    submitField(field);
    handleClose();
  };

  const getFieldById = async (fieldId) => {
    try {
      setIsLoading(true);
      const response = await apiGatewayService.getFieldsDataById(fieldId);
      setIsLoading(false);
      return response.data
    } catch (error) {
      setIsLoading(false);
      console.error('Error in getting field:', error);
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="fields-selector-title"
      aria-describedby="fields-selector-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '8px',
          boxShadow: 24,
          width: '400px',
          outline: 'none',
        }}
      >
        <IconButton
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
          }}
          onClick={handleClose}
        >
          <CloseIcon />
      </IconButton>
        <h3>Field Selector</h3>
        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' ,  mt: '30px' }}>
            <CircularProgress sx={{ color: 'var(--app-color)' }} />
          </Box>
        ) : (
          <>
            {/* <TextField
              fullWidth
              variant="outlined"
              placeholder="Filter Fields"
              value={filter}
              onChange={handleFilterChange}
              sx={{
                marginBottom: '16px',
                "& .MuiInputBase-root": {
                  height: 40,
                  backgroundColor: 'white',
                },
                "& .MuiOutlinedInput-input": {
                  height: 40,
                  width: '100%',
                },
              }}
            /> */}

            {/* <InputLabel sx={{ marginBottom: '8px' }}>Select an option</InputLabel>
            <Select
              fullWidth
              value={selectedFieldDisplayName}
              onChange={handleChange}
              displayEmpty
              sx={{ height: '40px', backgroundColor: 'white', marginBottom: '16px' }}
            >
              <MenuItem value="" disabled>
                <em>Select an option</em>
              </MenuItem>
              {filteredFields.map((option, index) => (
                <MenuItem key={index} value={option.fieldDisplayName}>
                  {option.fieldDisplayName}
                </MenuItem>
              ))}
            </Select> */}
            <ReactSelect
                value={selectedField}
                onChange={handleChange}
                options={availableFields}
                placeholder="Select Field"
                isClearable
                isSearchable
                styles={{
                  control: (base) => ({
                    ...base,
                    height: 40,
                    backgroundColor: 'white',
                    marginTop: '20px',
                  }),
                  menu: (base) => ({
                    ...base,
                    zIndex: 100,
                  }),
                }}
           />

            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              sx={{
                mt: '20px',
                backgroundColor: 'var(--button-color)',
                color: 'white',
                fontWeight: 'normal',
                textTransform: 'none',
                ":hover": {
                  backgroundColor: 'var(--button-hover-color)',
                },
              }}
            >
              Submit
            </Button>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default FieldsSelector;