import React, { useEffect, useState } from 'react';
import classes from './FieldOptionsBuilder.module.css';
import Modal from '../Modal/Modal';
import apiGatewayService from '../../../common/services/apiGatewayService';
import validationService from '../../../common/validationService.service';
import localStorageService from '../../../common/services/localStorage.service';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Button, IconButton, Typography, InputLabel, TextField , Box, Toolbar} from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import EditAttributesIcon from '@mui/icons-material/EditAttributes';
import FiltersModal from '../FiltersModal/FiltersModal';
import utilityFunctions from '../../../common/services/utilityFunctions';
import CustomAlert from '../../CustomAlert/CustomAlert';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const valueTemplate = {
  VALID_VALUE_ID: 'string',
  VALID_VALUE_SHORT_DESC: 'string',
  VALID_VALUE_DESCRIPTION: 'string',
  PARAMETER_ID: 'string',
  PARAMETER: 'string',
  COUNTER: 'number',
  ENTITY: 'string',
  VERSION: 'number',
  VALID_VALUE_ADDITIONAL_TEXT: 'string',
  FILTERS: 'string',
  ATTRIBUTES: 'string',
};

const valueTemplateKeysMapped = {
  VALID_VALUE_ID: 'Id',
  VALID_VALUE_SHORT_DESC: 'Short Description',
  VALID_VALUE_DESCRIPTION: 'Description',
  PARAMETER_ID: 'Parameter Id',
  PARAMETER: 'Parameter',
  ENTITY: 'Entity',
  COUNTER: 'Counter',
  VERSION: 'Version',
  VALID_VALUE_ADDITIONAL_TEXT: 'Additional Text',
  FILTERS: 'Filters',
  ATTRIBUTES: 'Attribute',
};

const initialValue = {
  VALID_VALUE_ID: '',
  VALID_VALUE_SHORT_DESC: '',
  VALID_VALUE_DESCRIPTION: '',
  PARAMETER_ID: '',
  PARAMETER: '',
  ENTITY: '',
  COUNTER: 0,
  VERSION: 0,
  VALID_VALUE_ADDITIONAL_TEXT: null,
  FILTERS: ['', '', '', ''],
  ATTRIBUTES: [],
};

const FieldOptionsBuilder = (props) => {
  useEffect(() => {
    const getOptions = async () => {
      if (props.option) {
        let updatedOptionValues = processFiltersAndAttribute(props.option.values);
        if (updatedOptionValues[0]['VALID_VALUE_ID'] === '') {
          updatedOptionValues = [];
        }
        setValuesArray(updatedOptionValues);
        setDisplayName(props.option.title);
      }
      if (props.isForm) {
        setIsForm(true);
      }
      if (props.isView){
        setIsView(true);
      }
    };
    getOptions();
    if (props.isEdit) {
      setIsEdit(true);
    }
    if(props.option?.optionDescription){
      setDescription(props.option?.optionDescription);
    }
    const user = localStorageService.getObject('user');
    setCogntioUser(user);

  }, [props.option, props.isForm, props.isEdit]);

  const [valuesArray, setValuesArray] = useState([]);
  const [displayName, setDisplayName] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [modalIndex, setModalIndex] = useState(null);
  const [modalType, setModalType] = useState();
  const [inputValue, setInputValue] = useState('');
  const [isForm, setIsForm] = useState(false);
  const [cogntioUser, setCogntioUser] = useState(null);
  const [isArrowDownArray, setIsArrowDownArray] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);

  const [displayAlert, setDisplayAlert] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  const [description, setDescription] = useState('');


  function processFiltersAndAttribute(objInput) {
    return objInput.map((obj) => {
      let filters = ['', '', '', ''];
      let attributes = [];

      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (key.startsWith('FILTER')) {
            const index = parseInt(key.slice(-1)) - 1;
            filters[index] = obj[key];
          } else if (key.startsWith('ATTRIBUTE')) {
            attributes.push(obj[key]);
          }
        }
      }

      obj.FILTERS = filters;
      obj.ATTRIBUTES = attributes;

      for (let i = 1; i <= 4; i++) {
        delete obj[`FILTER${i}`];
      }
      for (let i = 1; i <= 2; i++) {
        delete obj[`ATTRIBUTE${i}`];
      }
      return obj;
    });
  }

  const handleAddValue = () => {
    setValuesArray([...valuesArray, { ...initialValue }]);
    isArrowDownArray.push(false);
    setIsArrowDownArray(Array.from(isArrowDownArray));
  };

  const updateValues = (values) =>  {
    if(values.length === 0){
      values = [{
        VALID_VALUE_ID: '',
        VALID_VALUE_SHORT_DESC: '',
        VALID_VALUE_DESCRIPTION: '',
        PARAMETER_ID: '',
        PARAMETER: '',
        ENTITY: '',
        COUNTER: 0,
        VERSION: 0,
        VALID_VALUE_ADDITIONAL_TEXT: null,
        FILTER1: null,
        FILTER2: null,
        FILTER3: null,
        FILTER4: null,
        ATTRIBUTE1: '',
        ATTRIBUTE2: '',
      }]
    };
    return values;
  };

  const handleSubmitValues = async () => {
    if (displayName === '') {
      // alert('Parameter ID can not be empty');
      setAlertMessage('Parameter ID can not be empty');
      setAlertType("error");
      setDisplayAlert(true);
      setTimeout(() => setDisplayAlert(false), 3000);
    }
    else {
      const transformedValueArray = updateFiltersAndAttributes(valuesArray);
      const optionsObject = {
        displayName: displayName,
        values: transformedValueArray,
      };
      
      if (valuesArray.length === 0) {
        optionsObject.values = updateValues(valuesArray);
        try {
          optionsObject['description'] = description;
          let response;
          if (isEdit) {
            response = await apiGatewayService.putOptionsData(props.option.optionId, optionsObject);
          } else {
            response = await apiGatewayService.postOptionsData(optionsObject);
          }
          console.log("Post options response: ", response);
          props.handleClose();
        } catch (error) {
          console.log('Error in submitting form options: ', error);
        }
      }
  
      else if (validationService.hasEmptyKeysWithByPassKeys(optionsObject, ['VALID_VALUE_ADDITIONAL_TEXT', 'FILTER1','FILTER2','FILTER3','FILTER4','ATTRIBUTE1','ATTRIBUTE2'])) {
        alert('Parameter valid value keys cannot be empty');
      } else {
        try {
          optionsObject['description'] = description;
          let response;
          if (isEdit) {
            response = await apiGatewayService.putOptionsData(props.option.optionId, optionsObject);
          } else {
            response = await apiGatewayService.postOptionsData(optionsObject);
          }
          console.log("Post options response: ", response);
          props.handleClose();
        } catch (error) {
          console.log('Error in submitting form options: ', error);
        }
      }
    }
  };

  const handleInputChange = (event, index, key) => {
    const newValuesArray = [...valuesArray];
    const value = valueTemplate[key] === 'number' ? Number(event.target.value) : event.target.value;
    newValuesArray[index][key] = value;
    setValuesArray(newValuesArray);
  };

  const handleTitleInputChange = (e) => {
    setDisplayName(e.target.value);
  };

  const handleDescriptionInputChange = (e) => {
    setDescription(e.target.value);
  };

  const handleAddFilter = (index) => {
    setModalType('FILTERS');
    setModalIndex(index);
    openModal();
  };

  const handleAddAttribute = (index) => {
    setModalType('ATTRIBUTES');
    setModalIndex(index);
    openModal();
  };

  const removeItem = (key, index1, index2) => {
    if (key === 'FILTERS') {
      const newFilters = valuesArray[index1].FILTERS.filter((_, i) => i !== index2);
      valuesArray[index1].FILTERS = newFilters;
    } else {
      const newAttributes = valuesArray[index1].ATTRIBUTES.filter((_, i) => i !== index2);
      valuesArray[index1].ATTRIBUTES = newAttributes;
    }
    setValuesArray([...valuesArray]);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleRemoveValue = (index) => {
    const newValuesArray = valuesArray.filter((_, ind) => ind !== index);
    setValuesArray(newValuesArray);
  };

  const saveValue = () => {
    if (inputValue !== '') {
      const newFiltersOrAttributes = [...valuesArray[modalIndex][modalType]];
      newFiltersOrAttributes.push(inputValue);
      valuesArray[modalIndex][modalType] = [...newFiltersOrAttributes];
    }
    setModalIndex(null);
    setModalType();
    setInputValue('');
    closeModal();
  };

  const saveFilterValues = (filterValues) => {
    const currentFilters = valuesArray[modalIndex].FILTERS;
    Object.keys(filterValues).forEach((f, index) => {
      if (filterValues[f] !== '' || filterValues[f] !== null) {
        currentFilters[index] = filterValues[f];
      }
    });

    valuesArray[modalIndex].FILTERS = currentFilters;
    setValuesArray(Array.from(valuesArray));
    setModalIndex(null);
    setModalType();
    setInputValue('');
    closeModal();
  };

  const updateFiltersAndAttributes = (valuesArray) => {
    
    console.log("valuesArray: ", valuesArray);
    return valuesArray.map((item) => {
      const transformedValue = { ...item };

      let filterCounter = 0;
      let attributeCounter = 0;
  
      // Add filters to transformedValue
      item.FILTERS.forEach((filter, index) => {
        if (filter !== '' && filter !== undefined && filter !== null) {
          transformedValue[`FILTER${index + 1}`] = filter;
        } else {
          transformedValue[`FILTER${index + 1}`] = null;
        }
        filterCounter+=1;
      });
  
      // Ensure there are at least 4 filters
      for (let i = filterCounter; i < 4; i++) {
        transformedValue[`FILTER${i + 1}`] = ''; // Add empty filter if count is less than 4
      }
  
      // Add attributes to transformedValue
      item.ATTRIBUTES.forEach((attribute, index) => {
        if (attribute !== '' && attribute !== undefined && attribute !== null) {
          transformedValue[`ATTRIBUTE${index + 1}`] = attribute;
        }
        attributeCounter+=1
      });
  
      // Ensure there are at least 2 attributes
      for (let i = attributeCounter; i < 2; i++) {
        transformedValue[`ATTRIBUTE${i + 1}`] = ''; // Add empty attribute if count is less than 2
      }

      console.log("transformedValue: ", transformedValue);
  
      // Remove original FILTERS and ATTRIBUTES arrays
      delete transformedValue.FILTERS;
      delete transformedValue.ATTRIBUTES;
  
      return transformedValue;
    });
  };
  

  const toggleEditArrow = (index) => {
    isArrowDownArray[index] = !isArrowDownArray[index];
    setIsArrowDownArray(Array.from(isArrowDownArray));
  };

  return (
    <div className={classes.FieldOptionContainer}>
      <Toolbar 
        sx={{ 
          // backgroundColor: 'var(--app-color-headings)', 
          backgroundColor: 'white',
          borderRadius: "10px", 
          borderEndEndRadius: '0px', 
          borderEndStartRadius: '0px', 
          padding: '0px', 
          color: 'white', 
          position: 'sticky', // Keeps the toolbar fixed inside the scrollable container
          top: 0,  // Sticks the toolbar to the top of the container
          zIndex: 1000, // Ensures it stays on top of the table
          justifyContent: 'space-between'
        }}>         
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
          </Box>
          <Box>
          <Button sx={{
            backgroundColor: 'var(--app-color)',
            width: '130px',
            marginRight: '10px',
            ":hover": {
              // backgroundColor: '#F0F4F4',
              backgroundColor: 'var(--button-hover-color)',
              // color: '#2b4252',
            },
            '&.Mui-disabled': {
              backgroundColor: '#e0e0e0',
              color: 'grey',
            },
          }} color="inherit" startIcon={<SaveAltIcon />} onClick={handleSubmitValues} disabled={false}>
            Save
          </Button>
          <Button sx={{
            backgroundColor: 'var(--app-color)',
            width: '130px',
            marginRight: '-23px',
            ":hover": {
              // backgroundColor: '#F0F4F4',
              backgroundColor: 'var(--button-hover-color)',
              // color: '#2b4252',
            },
            '&.Mui-disabled': {
              backgroundColor: '#e0e0e0',
              color: 'grey',
            },
          }} color="inherit" startIcon={<KeyboardBackspaceIcon />} onClick={props.handleClose} disabled={false}>
            Back
          </Button>
          </Box>
      </Toolbar>
        {
          displayAlert &&
          <CustomAlert isOpen={displayAlert} message={alertMessage} type={alertType}></CustomAlert>
        }
      {isOpen && modalType === 'ATTRIBUTES' && (
        <Modal isOpen={isOpen} closeModal={closeModal} saveValue={saveValue} inputValue={inputValue} setInputValue={setInputValue} />
      )}
      {isOpen && modalType === 'FILTERS' && (
        <FiltersModal open={isOpen} handleClose={closeModal} handleSubmit={saveFilterValues} inputFilters={modalIndex !== null ? valuesArray[modalIndex].FILTERS : []} />
      )}
      <InputLabel sx={{mt: 2}}>Parameter ID</InputLabel>
      <TextField
        fullWidth
        variant="outlined"
        name="Group Name"
        onChange={handleTitleInputChange}
        value={displayName}
        sx={{
          marginBottom: '16px',
          width: '100%',
          "& .MuiInputBase-root": {
            height: 40,
            backgroundColor: 'white',
          },
          "& .MuiOutlinedInput-input": {
            height: 40,
            width: '100%',
          },
        }}
      />

      <InputLabel sx={{mt: 2}}>Description</InputLabel>
      <TextField
        fullWidth
        variant="outlined"
        name="Group Name"
        onChange={handleDescriptionInputChange}
        value={description}
        sx={{
          marginBottom: '16px',
          width: '100%',
          "& .MuiInputBase-root": {
            height: 40,
            backgroundColor: 'white',
          },
          "& .MuiOutlinedInput-input": {
            height: 40,
            width: '100%',
          },
        }}
      />

      {valuesArray.map((value, index) => (
        <div className={ utilityFunctions.isEven(index) ?  classes.ValueMainContainer1:  classes.ValueMainContainer2 } key={index}>
        <IconButton
          sx={{
            padding: 0,
            margin: 0,
            position: 'relative',
            top: 0,
            left: 0,
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
            },
            marginLeft: 'auto', // This will push the button to the rightmost side
          }}
          edge="end"
          color="inherit"
          aria-label="menu"
          onClick={() => toggleEditArrow(index)}
        >
          <MoreHorizIcon sx={{ fontSize: 35 }} />
        </IconButton>
          <div className={classes.ValueContainer}>
            <div className={isArrowDownArray[index] ? classes.ValueVertical : classes.Value}>
              {Object.keys(value).map((key) => (
                <div key={key} className={classes.ValueField}>
                  {(key === 'VALID_VALUE_ID' ||
                    key === 'VALID_VALUE_SHORT_DESC' ||
                    key === 'VALID_VALUE_DESCRIPTION' ||
                    key === 'PARAMETER_ID' ||
                    key === 'PARAMETER' ||
                    key === 'ENTITY' ||
                    isArrowDownArray[index]) && (
                    <>
                      <InputLabel htmlFor={key}>{valueTemplateKeysMapped[key]}</InputLabel>
                      <TextField
                        fullWidth
                        variant="outlined"
                        type={valueTemplate[key] === 'number' ? 'number' : 'text'}
                        name={key}
                        value={value[key]}
                        onChange={(e) => handleInputChange(e, index, key)}
                        sx={{
                          marginBottom: '8px',
                          width: '100%',
                          "& .MuiInputBase-root": {
                            height: 40,
                            backgroundColor: 'white',
                          },
                          "& .MuiOutlinedInput-input": {
                            height: 40,
                            width: '100%',
                          },
                        }}
                      />
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
          {
            !isView && ( <div className={classes.ButtonDirection}>
              <Button
                onClick={() => handleRemoveValue(index)}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: 0,
                  background: 'transparent',
                  color: 'var(--app-color)',
                  cursor: 'pointer',
                  borderRadius: '10px',
                  ':hover': {
                    backgroundColor: '#F0F4F4',
                    color: '#2b4252',
                  },
                }}
              >
                <RemoveCircleOutlineIcon sx={{ fontSize: 30, marginTop: 1, marginBottom: '5px', color: 'red', padding: 0 }} />
                <Typography sx={{ fontSize: 10, textTransform: 'none' }} variant="body2">
                  Remove
                </Typography>
              </Button>
              <Button
                onClick={() => handleAddFilter(index)}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: 0,
                  background: 'transparent',
                  color: 'var(--app-color)',
                  cursor: 'pointer',
                  borderRadius: '10px',
                  ':hover': {
                    backgroundColor: '#F0F4F4',
                    color: '#2b4252',
                  },
                }}
              >
                <FilterAltIcon sx={{ fontSize: 30, marginTop: 1, marginBottom: '5px', color: 'var(--app-color)', padding: 0 }} />
                <Typography sx={{ fontSize: 10, textTransform: 'none' }} variant="body2">
                  Add Filter
                </Typography>
              </Button>
              <Button
                onClick={() => handleAddAttribute(index)}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: 0,
                  background: 'transparent',
                  color: 'var(--app-color)',
                  cursor: 'pointer',
                  borderRadius: '10px',
                  ':hover': {
                    backgroundColor: '#F0F4F4',
                    color: '#2b4252',
                  },
                }}
              >
                <EditAttributesIcon sx={{ fontSize: 30, marginTop: 1, marginBottom: '5px', color: 'var(--app-color)', padding: 0 }} />
                <Typography sx={{ fontSize: 10, textTransform: 'none' }} variant="body2">
                  Add Attribute
                </Typography>
              </Button>
            </div>)
          }

        </div>
      ))}
      {
        !isView && (
          <Box sx ={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', marginLeft: 'auto'}}>
            <Button
              className={classes.Button}
              onClick={handleAddValue}
              sx={{
                mt: 2,
                width: '100px',
                backgroundColor: 'var(--button-color)',
                color: 'white', // Set text color to white
                fontWeight: 'normal',
                textTransform: 'none', // Prevent uppercase text
                ':hover': {
                  backgroundColor: 'var(--button-hover-color)',
                },
              }}
            >
              Add Value
            </Button>
            <Button
              className={classes.Button}
              onClick={handleSubmitValues}
              sx={{
                mt: 2,
                width: '100px',
                backgroundColor: 'var(--button-color)',
                color: 'white', // Set text color to white
                fontWeight: 'normal',
                textTransform: 'none', // Prevent uppercase text
                ':hover': {
                  backgroundColor: 'var(--button-hover-color)',
                },
              }}
            >
              Submit
            </Button>
            </Box>
        )
      }

    </div>
  );
};

export default FieldOptionsBuilder;