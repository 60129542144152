import React, { useState, useEffect , useCallback, useRef} from 'react';
import {FormGroup,Checkbox, FormControlLabel, Divider, Box, Button, IconButton, Typography, Container, Toolbar, Paper, Tooltip, TextField, InputAdornment, Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DataObjectIcon from '@mui/icons-material/DataObject';
import ArticleIcon from '@mui/icons-material/Article';
import PublishIcon from '@mui/icons-material/Publish';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import SearchIcon from '@mui/icons-material/Search';
import WorkspacesIcon from '@mui/icons-material/Workspaces';

import FormsModal from '../FormsModal/FormsModal';
import apiGatewayService from '../../common/services/apiGatewayService';
import localStorageService from '../../common/services/localStorage.service';
import utilityFunctions from '../../common/services/utilityFunctions';
import CircularLoader from '../Loader/Loader';
import PublishFormsModal from '../PublishFormsModal/PublishFormsModal';
import FormSectionsBuilder from '../FormBuilder/FormSectionsBuilder/FormSectionsBuilder';
import AddGroupModal from '../AddGroupModal/AddGroupModal';
import FormGroupSelector from '../FormGroupSelector/FormGroupSelector';
import CustomAlert from '../CustomAlert/CustomAlert';
import DeleteConfirmation from '../DeleteConfirmation/DeleteConfirmation';
import { useNavigate } from 'react-router-dom';
import ImportFormsModal from '../ImportFormsModal/ImportFormsModal';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import LockIcon from '@mui/icons-material/Lock';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import SortIcon from '@mui/icons-material/Sort';
import RefreshIcon from '@mui/icons-material/Refresh';
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';


import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import "ag-grid-community/styles/ag-theme-material.css"; // Optional Theme applied to the Data Grid
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional Theme applied to the Data Grid

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import ActionToolbar from './ActionToolBar';


const tableColumnToKeyMapping = {
'Name': "formName",
'Created By': "createdBy",
'Edited By': "lastEditedBy",
'Created On': "createdOn",
'Edited On': "lastEditedOn",
'Status': "route",
'Group': "groupName"
};


const Forms2 = (props) => {
  const [forms, setForms] = useState([]);
  const [isAddNew, setIsAddNew] = useState(false);
  const [isViewJSON, setIsViewJSON] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [viewIndex, setIsViewIndex] = useState(null);
  const [open, setOpen] = useState(false);
  const [cogntioUser, setCogntioUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isOnlyReadAccess, setIsOnlyReadAccess] = useState(false);
  const [isPublish, setIsPublish] = useState(false);
  const [filterFormName, setFilterFormName] = useState('');
  const [filterLastEditedOn, setFilterLastEditedOn] = useState('');
  const [filterCreatedOn, setFlterCreatedOn] = useState('');
  const [filterLastEditedBy, setFilterLastEditedBy] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [filterCreatedBy, setFilterCreatedBy] = useState(''); // New filter for createdBy
  const [filterGroupName, setFilterGroupName] = useState(''); // New filter for groupName
  const [currentForm, setCurrentForm] = useState(null);
  const [companyAlias, setCompanyAlias] = useState('');
  const [isAddGroup, setIsAddGroup] = useState(false); // New state for add group modal
  const [isFormGroupSelectorOpen, setIsFormGroupSelectorOpen] = useState(false); // New state for FormGroupSelector modal
  const [displayAlert, setDisplayAlert] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [formToDelete, setFormToDelete] = useState(null);
  const [isImportForm, setIsImportForm] = useState(false);
  const [isOpenImportedForm, setIsOpenImportedForm] = useState(false);
  const [isSaveForm, setIsSaveForm] = useState(false);
  // State to store filtered forms
  const [filteredForms, setFilteredForms] = useState(forms);
  const [sortKey, setSortKey] = useState(null);
  const [selectAll, setSelectAll] = useState(false); // State for the external checkbox
  const gridRef = useRef(null); // Ref for accessing the grid API
  const [allSelectChecked, setAllSelectChecked] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedSingleForm, setSelectedSingleForm] = useState(null);

  const pagination = true;
  const paginationPageSize = 10;
  const paginationPageSizeSelector = [10, 100, 1000];

  const handleSelectedAll = (e) => {
    console.log(e.target.checked);
    const isChecked = !allSelectChecked;
    // setSelectAll(isChecked); // Update state
    if (gridRef.current) {
        const api = gridRef.current.api;

        if (isChecked) {
            setAllSelectChecked(true);

            // Select rows on the current pagination page
            api.forEachNodeAfterFilterAndSort((node) => {
                if (node.rowIndex >= api.paginationGetCurrentPage() * api.paginationGetPageSize() &&
                    node.rowIndex < (api.paginationGetCurrentPage() + 1) * api.paginationGetPageSize()) {
                    node.setSelected(true);
                }
            });
        } else {
            setAllSelectChecked(false);

            // Deselect rows on the current pagination page
            api.forEachNodeAfterFilterAndSort((node) => {
                if (node.rowIndex >= api.paginationGetCurrentPage() * api.paginationGetPageSize() &&
                    node.rowIndex < (api.paginationGetCurrentPage() + 1) * api.paginationGetPageSize()) {
                    node.setSelected(false);
                }
            });
        }

        // Access selected rows if needed
        const selectedRows = api.getSelectedRows();
        const currSelectedIds =  selectedRows.map((r)=>r.formId);
        setSelectedIds(currSelectedIds);
        console.log("Currently selected rows:", selectedRows);
    }
};

const onRowSelected = () => {
    if (gridRef.current) {
      const api = gridRef.current.api;
      const currentlySelectedRows = api.getSelectedRows(); // Get all selected rows
    //   setSelectedRows(currentlySelectedRows); // Update state
      const selectedIds = currentlySelectedRows.map((m)=>m.formId);
      console.log("Selected Rows:", selectedIds);
      setSelectedIds(selectedIds);
    }
  };


  const [colDefs, setColDefs] = useState([
    {
      // cellRenderer: put your components here
      // valueGetter: p => p.data.formName+ ' '+p.data.status, // if you want concatenated values
      field: "formName",
      headerName: "Name",
      flex: 1,
      filter: true,
      floatingFilter: true,
      checkboxSelection: true,
      sortable: true,
    //   pinned: 'left'
      // width: 180
    },
    {
      field: "createdBy",
      headerName: "Created By",
      flex: 1,
      filter: true,
      floatingFilter: true,
      // width: 180
    },
    {
      field: "createdOn",
      headerName: "Created On",
      flex: 1,
      filter: true,
      floatingFilter: true,
      // width: 180
    },
    {
      field: "lastEditedBy",
      headerName: "Edited By",
      flex: 1,
      filter: true,
      floatingFilter: true,
      // width: 180
    },
    {
      field: "lastEditedOn",
      headerName: "Edited On",
      flex: 1,
      filter: true,
      floatingFilter: true,
      // width: 180
    },
    {
      field: "route",
      headerName: "Status",
      flex: 1,
      filter: true,
      floatingFilter: true,
      // width: 180
    },
    {
      field: "groupName",
      headerName: "Group",
      flex: 1,
      filter: true,
      floatingFilter: true,
      // width: 180
    },
    {
        field: 'Options',
        flex: 1,
        cellRenderer: (params) => (
            <ActionToolbar
              handlePublishForm={() => handlePublishForm(params.data)}
              handleViewJSON={() => handleViewJSON(params.data)}
              handleView={() => handleView(params.data)}
              handleEdit={() => handleEdit(params.data)}
              handleAssignGroup={() => handleAssignGroup(params.data)}
              openDeleteModal={() => openDeleteModal(params.data)}
              index={params.data} // Pass the row index
              isOnlyReadAccess={isOnlyReadAccess} // Pass the access control flag
            />
          ),
          // width: 180
    }
  ]);


  // useEffect to apply filters whenever a filter value changes
  useEffect(() => {
    const applyFilters = () => {
      let updatedForms = forms.filter((form) => {
        const formNameMatches = !filterFormName || form.formName.toLowerCase().includes(filterFormName.toLowerCase());
        const lastEditedOnMatches = !filterLastEditedOn || form.lastEditedOn?.toLowerCase().includes(filterLastEditedOn.toLowerCase());
        const lastEditedByMatches = !filterLastEditedBy || form.lastEditedBy.toLowerCase().includes(filterLastEditedBy.toLowerCase());
        const statusMatches = !filterStatus || form.route.toLowerCase().includes(filterStatus.toLowerCase());
        const createdByMatches = !filterCreatedBy || form.createdBy?.toLowerCase().includes(filterCreatedBy.toLowerCase());
        const groupNameMatches = !filterGroupName || form.groupName?.toLowerCase().includes(filterGroupName.toLowerCase());
        const createdOnMatches = !filterCreatedOn || form.createdOn?.toLowerCase().includes(filterCreatedOn.toLowerCase());

        return formNameMatches && lastEditedOnMatches && lastEditedByMatches && statusMatches && createdByMatches && groupNameMatches && createdOnMatches;
      });

      if (sortKey){
        updatedForms = utilityFunctions.sortByKey(updatedForms, sortKey);
      }

      setFilteredForms(updatedForms);
    };

    applyFilters();
  }, [sortKey, forms, filterFormName, filterLastEditedOn, filterLastEditedBy, filterStatus, filterCreatedBy, filterGroupName, filterCreatedOn]);


  const navigate = useNavigate();

  const handleOpen = () => setOpen(true);
  const handleClose = async (alertObject) => {
    setAllFalse();
    setOpen(false);
    setIsPublish(false);
    await getAllForms();
    if(alertObject?.type && alertObject?.message) {
      setAlertMessageAndPopup(alertObject);
    };
  }

  const handleCloseWithoutGetForms = async (alertObject) => {
    setAllFalse();
    setOpen(false);
    setIsPublish(false);
    if(alertObject?.type && alertObject?.message) {
      setAlertMessageAndPopup(alertObject);
    };
  }

  const setAlertMessageAndPopup  = (alertObject) => {
    setDisplayAlert(true);
    setAlertMessage(alertObject.message);
    setAlertType(alertObject.type);
    setTimeout(() => setDisplayAlert(false), 6000);
  }

  const handleCloseWithGetAllForms = async () => {
    setAllFalse();
    setOpen(false);
    setIsPublish(false);
    await getAllForms();
  }

  useEffect(() => {
    console.log("Inside Forms2!!!!!!: ");
    const getCompanyAlias = async () => {
      try {
        const response = await apiGatewayService.getCompanyAlias();
        console.log("Company Alias Response: ", response.data[0].companyAlias);
        setCompanyAlias(response.data[0].companyAlias);
      } catch (error) {
        console.error('Error getting company alias:', error);
      }
    };
    getCompanyAlias();
    const getForms = async (user) => {
      try {
        const response = await apiGatewayService.getFormsData();
        const dataWithUpdatedKeyAndContent = response.data;
        // const dataWithUpdatedKeyAndContent = [
        //     {
        //       lastEditedOn: '2024-10-22 23:21:47.392000000',
        //       formId: '3',
        //       groupId: 'AGFMA',
        //       route: 'publish',
        //       groupName: 'AGFMA',
        //       userGroupName: 'gmail-admin',
        //       lastEditedBy: 'haroonkhansadaat88@gmail.com',
        //       createdBy: 'kendallmacmillan@gmail.com',
        //       formName: 'Create Breakdown Request'
        //     },
        //     {
        //       lastEditedOn: '2024-10-14 15:13:29.533000000',
        //       formId: '4',
        //       route: '',
        //       userGroupName: 'gmail-admin',
        //       lastEditedBy: 'kendallmacmillan@gmail.com',
        //       createdBy: 'kendallmacmillan@gmail.com',
        //       formName: 'CBR Test'
        //     },
        //     {
        //       lastEditedOn: '2024-10-04 11:05:36.666000000',
        //       formId: '5',
        //       route: '',
        //       userGroupName: 'gmail-admin',
        //       lastEditedBy: 'kendallmacmillan@gmail.com',
        //       createdBy: 'kendallmacmillan@gmail.com',
        //       formName: 'km_test_adhoc'
        //     },
        //     {
        //       lastEditedOn: '2024-10-31 16:20:17.353000000',
        //       createdOn: '',
        //       formId: '7',
        //       groupId: '',
        //       route: 'edited',
        //       groupName: '',
        //       userGroupName: 'gmail-admin',
        //       lastEditedBy: 'haroonkhansadaat88@gmail.com',
        //       createdBy: 'kendallmacmillan@gmail.com',
        //       formName: 'test_km2'
        //     },
        //     {
        //       lastEditedOn: '2024-11-04 07:21:49.711000000',
        //       createdOn: '2024-10-10 15:16:08.831000000',
        //       formId: '8',
        //       groupId: '',
        //       route: 'edited',
        //       groupName: '',
        //       userGroupName: 'gmail-admin',
        //       lastEditedBy: 'haroonkhansadaat88@gmail.com',
        //       createdBy: 'kendallmacmillan@gmail.com',
        //       formName: 'test'
        //     },
        //     {
        //       lastEditedOn: '2024-10-31 16:20:00.715000000',
        //       createdOn: '2024-10-21 10:42:39.957000000',
        //       formId: '9',
        //       groupId: '',
        //       route: 'edited',
        //       groupName: '',
        //       userGroupName: 'gmail-admin',
        //       lastEditedBy: 'haroonkhansadaat88@gmail.com',
        //       createdBy: 'randeelkv@gmail.com',
        //       formName: 'test_km'
        //     },
        //     {
        //       lastEditedOn: '2024-10-22 23:21:47.392000000',
        //       formId: '3',
        //       groupId: 'AGFMA',
        //       route: 'publish',
        //       groupName: 'AGFMA',
        //       userGroupName: 'gmail-admin',
        //       lastEditedBy: 'haroonkhansadaat88@gmail.com',
        //       createdBy: 'kendallmacmillan@gmail.com',
        //       formName: 'Create Breakdown Request'
        //     },
        //     {
        //       lastEditedOn: '2024-10-14 15:13:29.533000000',
        //       formId: '4',
        //       route: '',
        //       userGroupName: 'gmail-admin',
        //       lastEditedBy: 'kendallmacmillan@gmail.com',
        //       createdBy: 'kendallmacmillan@gmail.com',
        //       formName: 'CBR Test'
        //     },
        //     {
        //       lastEditedOn: '2024-10-04 11:05:36.666000000',
        //       formId: '5',
        //       route: '',
        //       userGroupName: 'gmail-admin',
        //       lastEditedBy: 'kendallmacmillan@gmail.com',
        //       createdBy: 'kendallmacmillan@gmail.com',
        //       formName: 'km_test_adhoc'
        //     },
        //     {
        //       lastEditedOn: '2024-10-31 16:20:17.353000000',
        //       createdOn: '',
        //       formId: '7',
        //       groupId: '',
        //       route: 'edited',
        //       groupName: '',
        //       userGroupName: 'gmail-admin',
        //       lastEditedBy: 'haroonkhansadaat88@gmail.com',
        //       createdBy: 'kendallmacmillan@gmail.com',
        //       formName: 'test_km2'
        //     },
        //     {
        //       lastEditedOn: '2024-11-04 07:21:49.711000000',
        //       createdOn: '2024-10-10 15:16:08.831000000',
        //       formId: '8',
        //       groupId: '',
        //       route: 'edited',
        //       groupName: '',
        //       userGroupName: 'gmail-admin',
        //       lastEditedBy: 'haroonkhansadaat88@gmail.com',
        //       createdBy: 'kendallmacmillan@gmail.com',
        //       formName: 'test'
        //     },
        //     {
        //       lastEditedOn: '2024-10-31 16:20:00.715000000',
        //       createdOn: '2024-10-21 10:42:39.957000000',
        //       formId: '9',
        //       groupId: '',
        //       route: 'edited',
        //       groupName: '',
        //       userGroupName: 'gmail-admin',
        //       lastEditedBy: 'haroonkhansadaat88@gmail.com',
        //       createdBy: 'randeelkv@gmail.com',
        //       formName: 'test_km'
        //     },
        //     {
        //       lastEditedOn: '2024-10-22 23:21:47.392000000',
        //       formId: '3',
        //       groupId: 'AGFMA',
        //       route: 'publish',
        //       groupName: 'AGFMA',
        //       userGroupName: 'gmail-admin',
        //       lastEditedBy: 'haroonkhansadaat88@gmail.com',
        //       createdBy: 'kendallmacmillan@gmail.com',
        //       formName: 'Create Breakdown Request'
        //     },
        //     {
        //       lastEditedOn: '2024-10-14 15:13:29.533000000',
        //       formId: '4',
        //       route: '',
        //       userGroupName: 'gmail-admin',
        //       lastEditedBy: 'kendallmacmillan@gmail.com',
        //       createdBy: 'kendallmacmillan@gmail.com',
        //       formName: 'CBR Test'
        //     },
        //     {
        //       lastEditedOn: '2024-10-04 11:05:36.666000000',
        //       formId: '5',
        //       route: '',
        //       userGroupName: 'gmail-admin',
        //       lastEditedBy: 'kendallmacmillan@gmail.com',
        //       createdBy: 'kendallmacmillan@gmail.com',
        //       formName: 'km_test_adhoc'
        //     },
        //     {
        //       lastEditedOn: '2024-10-31 16:20:17.353000000',
        //       createdOn: '',
        //       formId: '7',
        //       groupId: '',
        //       route: 'edited',
        //       groupName: '',
        //       userGroupName: 'gmail-admin',
        //       lastEditedBy: 'haroonkhansadaat88@gmail.com',
        //       createdBy: 'kendallmacmillan@gmail.com',
        //       formName: 'test_km2'
        //     },
        //     {
        //       lastEditedOn: '2024-11-04 07:21:49.711000000',
        //       createdOn: '2024-10-10 15:16:08.831000000',
        //       formId: '8',
        //       groupId: '',
        //       route: 'edited',
        //       groupName: '',
        //       userGroupName: 'gmail-admin',
        //       lastEditedBy: 'haroonkhansadaat88@gmail.com',
        //       createdBy: 'kendallmacmillan@gmail.com',
        //       formName: 'test'
        //     },
        //     {
        //       lastEditedOn: '2024-10-31 16:20:00.715000000',
        //       createdOn: '2024-10-21 10:42:39.957000000',
        //       formId: '9',
        //       groupId: '',
        //       route: 'edited',
        //       groupName: '',
        //       userGroupName: 'gmail-admin',
        //       lastEditedBy: 'haroonkhansadaat88@gmail.com',
        //       createdBy: 'randeelkv@gmail.com',
        //       formName: 'test_km'
        //     },
        //     {
        //       lastEditedOn: '2024-10-22 23:21:47.392000000',
        //       formId: '3',
        //       groupId: 'AGFMA',
        //       route: 'publish',
        //       groupName: 'AGFMA',
        //       userGroupName: 'gmail-admin',
        //       lastEditedBy: 'haroonkhansadaat88@gmail.com',
        //       createdBy: 'kendallmacmillan@gmail.com',
        //       formName: 'Create Breakdown Request'
        //     },
        //     {
        //       lastEditedOn: '2024-10-14 15:13:29.533000000',
        //       formId: '4',
        //       route: '',
        //       userGroupName: 'gmail-admin',
        //       lastEditedBy: 'kendallmacmillan@gmail.com',
        //       createdBy: 'kendallmacmillan@gmail.com',
        //       formName: 'CBR Test'
        //     },
        //     {
        //       lastEditedOn: '2024-10-04 11:05:36.666000000',
        //       formId: '5',
        //       route: '',
        //       userGroupName: 'gmail-admin',
        //       lastEditedBy: 'kendallmacmillan@gmail.com',
        //       createdBy: 'kendallmacmillan@gmail.com',
        //       formName: 'km_test_adhoc'
        //     },
        //     {
        //       lastEditedOn: '2024-10-31 16:20:17.353000000',
        //       createdOn: '',
        //       formId: '7',
        //       groupId: '',
        //       route: 'edited',
        //       groupName: '',
        //       userGroupName: 'gmail-admin',
        //       lastEditedBy: 'haroonkhansadaat88@gmail.com',
        //       createdBy: 'kendallmacmillan@gmail.com',
        //       formName: 'test_km2'
        //     },
        //     {
        //       lastEditedOn: '2024-11-04 07:21:49.711000000',
        //       createdOn: '2024-10-10 15:16:08.831000000',
        //       formId: '8',
        //       groupId: '',
        //       route: 'edited',
        //       groupName: '',
        //       userGroupName: 'gmail-admin',
        //       lastEditedBy: 'haroonkhansadaat88@gmail.com',
        //       createdBy: 'kendallmacmillan@gmail.com',
        //       formName: 'test'
        //     },
        //     {
        //       lastEditedOn: '2024-10-31 16:20:00.715000000',
        //       createdOn: '2024-10-21 10:42:39.957000000',
        //       formId: '9',
        //       groupId: '',
        //       route: 'edited',
        //       groupName: '',
        //       userGroupName: 'gmail-admin',
        //       lastEditedBy: 'haroonkhansadaat88@gmail.com',
        //       createdBy: 'randeelkv@gmail.com',
        //       formName: 'test_km'
        //     },
        //     {
        //       lastEditedOn: '2024-10-22 23:21:47.392000000',
        //       formId: '3',
        //       groupId: 'AGFMA',
        //       route: 'publish',
        //       groupName: 'AGFMA',
        //       userGroupName: 'gmail-admin',
        //       lastEditedBy: 'haroonkhansadaat88@gmail.com',
        //       createdBy: 'kendallmacmillan@gmail.com',
        //       formName: 'Create Breakdown Request'
        //     },
        //     {
        //       lastEditedOn: '2024-10-14 15:13:29.533000000',
        //       formId: '4',
        //       route: '',
        //       userGroupName: 'gmail-admin',
        //       lastEditedBy: 'kendallmacmillan@gmail.com',
        //       createdBy: 'kendallmacmillan@gmail.com',
        //       formName: 'CBR Test'
        //     },
        //     {
        //       lastEditedOn: '2024-10-04 11:05:36.666000000',
        //       formId: '5',
        //       route: '',
        //       userGroupName: 'gmail-admin',
        //       lastEditedBy: 'kendallmacmillan@gmail.com',
        //       createdBy: 'kendallmacmillan@gmail.com',
        //       formName: 'km_test_adhoc'
        //     },
        //     {
        //       lastEditedOn: '2024-10-31 16:20:17.353000000',
        //       createdOn: '',
        //       formId: '7',
        //       groupId: '',
        //       route: 'edited',
        //       groupName: '',
        //       userGroupName: 'gmail-admin',
        //       lastEditedBy: 'haroonkhansadaat88@gmail.com',
        //       createdBy: 'kendallmacmillan@gmail.com',
        //       formName: 'test_km2'
        //     },
        //     {
        //       lastEditedOn: '2024-11-04 07:21:49.711000000',
        //       createdOn: '2024-10-10 15:16:08.831000000',
        //       formId: '8',
        //       groupId: '',
        //       route: 'edited',
        //       groupName: '',
        //       userGroupName: 'gmail-admin',
        //       lastEditedBy: 'haroonkhansadaat88@gmail.com',
        //       createdBy: 'kendallmacmillan@gmail.com',
        //       formName: 'test'
        //     },
        //     {
        //       lastEditedOn: '2024-10-31 16:20:00.715000000',
        //       createdOn: '2024-10-21 10:42:39.957000000',
        //       formId: '9',
        //       groupId: '',
        //       route: 'edited',
        //       groupName: '',
        //       userGroupName: 'gmail-admin',
        //       lastEditedBy: 'haroonkhansadaat88@gmail.com',
        //       createdBy: 'randeelkv@gmail.com',
        //       formName: 'test_km'
        //     },
        //     {
        //       lastEditedOn: '2024-10-22 23:21:47.392000000',
        //       formId: '3',
        //       groupId: 'AGFMA',
        //       route: 'publish',
        //       groupName: 'AGFMA',
        //       userGroupName: 'gmail-admin',
        //       lastEditedBy: 'haroonkhansadaat88@gmail.com',
        //       createdBy: 'kendallmacmillan@gmail.com',
        //       formName: 'Create Breakdown Request'
        //     },
        //     {
        //       lastEditedOn: '2024-10-14 15:13:29.533000000',
        //       formId: '4',
        //       route: '',
        //       userGroupName: 'gmail-admin',
        //       lastEditedBy: 'kendallmacmillan@gmail.com',
        //       createdBy: 'kendallmacmillan@gmail.com',
        //       formName: 'CBR Test'
        //     },
        //     {
        //       lastEditedOn: '2024-10-04 11:05:36.666000000',
        //       formId: '5',
        //       route: '',
        //       userGroupName: 'gmail-admin',
        //       lastEditedBy: 'kendallmacmillan@gmail.com',
        //       createdBy: 'kendallmacmillan@gmail.com',
        //       formName: 'km_test_adhoc'
        //     },
        //     {
        //       lastEditedOn: '2024-10-31 16:20:17.353000000',
        //       createdOn: '',
        //       formId: '7',
        //       groupId: '',
        //       route: 'edited',
        //       groupName: '',
        //       userGroupName: 'gmail-admin',
        //       lastEditedBy: 'haroonkhansadaat88@gmail.com',
        //       createdBy: 'kendallmacmillan@gmail.com',
        //       formName: 'test_km2'
        //     },
        //     {
        //       lastEditedOn: '2024-11-04 07:21:49.711000000',
        //       createdOn: '2024-10-10 15:16:08.831000000',
        //       formId: '8',
        //       groupId: '',
        //       route: 'edited',
        //       groupName: '',
        //       userGroupName: 'gmail-admin',
        //       lastEditedBy: 'haroonkhansadaat88@gmail.com',
        //       createdBy: 'kendallmacmillan@gmail.com',
        //       formName: 'test'
        //     },
        //     {
        //       lastEditedOn: '2024-10-31 16:20:00.715000000',
        //       createdOn: '2024-10-21 10:42:39.957000000',
        //       formId: '9',
        //       groupId: '',
        //       route: 'edited',
        //       groupName: '',
        //       userGroupName: 'gmail-admin',
        //       lastEditedBy: 'haroonkhansadaat88@gmail.com',
        //       createdBy: 'randeelkv@gmail.com',
        //       formName: 'test_km'
        //     },
        //     {
        //       lastEditedOn: '2024-10-22 23:21:47.392000000',
        //       formId: '3',
        //       groupId: 'AGFMA',
        //       route: 'publish',
        //       groupName: 'AGFMA',
        //       userGroupName: 'gmail-admin',
        //       lastEditedBy: 'haroonkhansadaat88@gmail.com',
        //       createdBy: 'kendallmacmillan@gmail.com',
        //       formName: 'Create Breakdown Request'
        //     },
        //     {
        //       lastEditedOn: '2024-10-14 15:13:29.533000000',
        //       formId: '4',
        //       route: '',
        //       userGroupName: 'gmail-admin',
        //       lastEditedBy: 'kendallmacmillan@gmail.com',
        //       createdBy: 'kendallmacmillan@gmail.com',
        //       formName: 'CBR Test'
        //     },
        //     {
        //       lastEditedOn: '2024-10-04 11:05:36.666000000',
        //       formId: '5',
        //       route: '',
        //       userGroupName: 'gmail-admin',
        //       lastEditedBy: 'kendallmacmillan@gmail.com',
        //       createdBy: 'kendallmacmillan@gmail.com',
        //       formName: 'km_test_adhoc'
        //     },
        //     {
        //       lastEditedOn: '2024-10-31 16:20:17.353000000',
        //       createdOn: '',
        //       formId: '7',
        //       groupId: '',
        //       route: 'edited',
        //       groupName: '',
        //       userGroupName: 'gmail-admin',
        //       lastEditedBy: 'haroonkhansadaat88@gmail.com',
        //       createdBy: 'kendallmacmillan@gmail.com',
        //       formName: 'test_km2'
        //     },
        //     {
        //       lastEditedOn: '2024-11-04 07:21:49.711000000',
        //       createdOn: '2024-10-10 15:16:08.831000000',
        //       formId: '8',
        //       groupId: '',
        //       route: 'edited',
        //       groupName: '',
        //       userGroupName: 'gmail-admin',
        //       lastEditedBy: 'haroonkhansadaat88@gmail.com',
        //       createdBy: 'kendallmacmillan@gmail.com',
        //       formName: 'test'
        //     },
        //     {
        //       lastEditedOn: '2024-10-31 16:20:00.715000000',
        //       createdOn: '2024-10-21 10:42:39.957000000',
        //       formId: '9',
        //       groupId: '',
        //       route: 'edited',
        //       groupName: '',
        //       userGroupName: 'gmail-admin',
        //       lastEditedBy: 'haroonkhansadaat88@gmail.com',
        //       createdBy: 'randeelkv@gmail.com',
        //       formName: 'test_km'
        //     },
        //     {
        //       lastEditedOn: '2024-10-22 23:21:47.392000000',
        //       formId: '3',
        //       groupId: 'AGFMA',
        //       route: 'publish',
        //       groupName: 'AGFMA',
        //       userGroupName: 'gmail-admin',
        //       lastEditedBy: 'haroonkhansadaat88@gmail.com',
        //       createdBy: 'kendallmacmillan@gmail.com',
        //       formName: 'Create Breakdown Request'
        //     },
        //     {
        //       lastEditedOn: '2024-10-14 15:13:29.533000000',
        //       formId: '4',
        //       route: '',
        //       userGroupName: 'gmail-admin',
        //       lastEditedBy: 'kendallmacmillan@gmail.com',
        //       createdBy: 'kendallmacmillan@gmail.com',
        //       formName: 'CBR Test'
        //     },
        //     {
        //       lastEditedOn: '2024-10-04 11:05:36.666000000',
        //       formId: '5',
        //       route: '',
        //       userGroupName: 'gmail-admin',
        //       lastEditedBy: 'kendallmacmillan@gmail.com',
        //       createdBy: 'kendallmacmillan@gmail.com',
        //       formName: 'km_test_adhoc'
        //     },
        //     {
        //       lastEditedOn: '2024-10-31 16:20:17.353000000',
        //       createdOn: '',
        //       formId: '7',
        //       groupId: '',
        //       route: 'edited',
        //       groupName: '',
        //       userGroupName: 'gmail-admin',
        //       lastEditedBy: 'haroonkhansadaat88@gmail.com',
        //       createdBy: 'kendallmacmillan@gmail.com',
        //       formName: 'test_km2'
        //     },
        //     {
        //       lastEditedOn: '2024-11-04 07:21:49.711000000',
        //       createdOn: '2024-10-10 15:16:08.831000000',
        //       formId: '8',
        //       groupId: '',
        //       route: 'edited',
        //       groupName: '',
        //       userGroupName: 'gmail-admin',
        //       lastEditedBy: 'haroonkhansadaat88@gmail.com',
        //       createdBy: 'kendallmacmillan@gmail.com',
        //       formName: 'test'
        //     },
        //     {
        //       lastEditedOn: '2024-10-31 16:20:00.715000000',
        //       createdOn: '2024-10-21 10:42:39.957000000',
        //       formId: '9',
        //       groupId: '',
        //       route: 'edited',
        //       groupName: '',
        //       userGroupName: 'gmail-admin',
        //       lastEditedBy: 'haroonkhansadaat88@gmail.com',
        //       createdBy: 'randeelkv@gmail.com',
        //       formName: 'test_km'
        //     },
        //     {
        //       lastEditedOn: '2024-10-22 23:21:47.392000000',
        //       formId: '3',
        //       groupId: 'AGFMA',
        //       route: 'publish',
        //       groupName: 'AGFMA',
        //       userGroupName: 'gmail-admin',
        //       lastEditedBy: 'haroonkhansadaat88@gmail.com',
        //       createdBy: 'kendallmacmillan@gmail.com',
        //       formName: 'Create Breakdown Request'
        //     },
        //     {
        //       lastEditedOn: '2024-10-14 15:13:29.533000000',
        //       formId: '4',
        //       route: '',
        //       userGroupName: 'gmail-admin',
        //       lastEditedBy: 'kendallmacmillan@gmail.com',
        //       createdBy: 'kendallmacmillan@gmail.com',
        //       formName: 'CBR Test'
        //     },
        //     {
        //       lastEditedOn: '2024-10-04 11:05:36.666000000',
        //       formId: '5',
        //       route: '',
        //       userGroupName: 'gmail-admin',
        //       lastEditedBy: 'kendallmacmillan@gmail.com',
        //       createdBy: 'kendallmacmillan@gmail.com',
        //       formName: 'km_test_adhoc'
        //     },
        //     {
        //       lastEditedOn: '2024-10-31 16:20:17.353000000',
        //       createdOn: '',
        //       formId: '7',
        //       groupId: '',
        //       route: 'edited',
        //       groupName: '',
        //       userGroupName: 'gmail-admin',
        //       lastEditedBy: 'haroonkhansadaat88@gmail.com',
        //       createdBy: 'kendallmacmillan@gmail.com',
        //       formName: 'test_km2'
        //     },
        //     {
        //       lastEditedOn: '2024-11-04 07:21:49.711000000',
        //       createdOn: '2024-10-10 15:16:08.831000000',
        //       formId: '8',
        //       groupId: '',
        //       route: 'edited',
        //       groupName: '',
        //       userGroupName: 'gmail-admin',
        //       lastEditedBy: 'haroonkhansadaat88@gmail.com',
        //       createdBy: 'kendallmacmillan@gmail.com',
        //       formName: 'test'
        //     },
        //     {
        //       lastEditedOn: '2024-10-31 16:20:00.715000000',
        //       createdOn: '2024-10-21 10:42:39.957000000',
        //       formId: '9',
        //       groupId: '',
        //       route: 'edited',
        //       groupName: '',
        //       userGroupName: 'gmail-admin',
        //       lastEditedBy: 'haroonkhansadaat88@gmail.com',
        //       createdBy: 'randeelkv@gmail.com',
        //       formName: 'test_km'
        //     }
        //   ];
        // dataWithUpdatedKeyAndContent.sort((a, b) => {
        //   if (a.formName < b.formName) return -1;
        //   if (a.formName > b.formName) return 1;
        //   return 0;
        // });
        setForms(dataWithUpdatedKeyAndContent);
      } catch (error) {
        if (error.response && error.response.status === 403) {
          console.log("Forbidden error detected, redirecting to login...");
          navigate('/');
        } else {
          console.error('Error fetching forms:', error);
        }
      }
      setIsLoading(false);
    };

    const getFormsByGroup = async () => {
      try {
        const response = await apiGatewayService.getFormsByGroup(props.selectedGroupId);
        console.log("Get forms by group response: ", response);
        const dataWithUpdatedKeyAndContent = response.data;
        dataWithUpdatedKeyAndContent.sort((a, b) => {
          if (a.formName < b.formName) return -1;
          if (a.formName > b.formName) return 1;
          return 0;
        });
        setForms(dataWithUpdatedKeyAndContent);
      } catch (error) {
        console.error('Error fetching forms:', error);
      }
      setIsLoading(false);
    };


    const user = localStorageService.getObject('user');
    setCogntioUser(user);
    setIsOnlyReadAccess(utilityFunctions.checkIfOnlyReadAccess(user));
    if (props.selectedGroupId!==''){
      getFormsByGroup();
    } else {
      getForms();
    }
    // getCompanyAlias();
  }, [props.selectedGroupId]);

  useEffect(() => {
    console.log("Company Alias has been updated:", companyAlias);
  }, [companyAlias]);

  const getAllForms = async () => {
    try {
      setIsLoading(true);
      const response = await apiGatewayService.getFormsData();
      const dataWithUpdatedKeyAndContent = response.data;
      dataWithUpdatedKeyAndContent.sort((a, b) => {
        if (a.formName < b.formName) return -1;
        if (a.formName > b.formName) return 1;
        return 0;
      });
      setForms(dataWithUpdatedKeyAndContent); 
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching forms:', error);
    }
  };

  const setAllFalse = () => {
    setIsAddNew(false);
    setIsViewJSON(false);
    setIsView(false);
    setIsEdit(false);
    setIsViewIndex(null);
    setIsPublish(false);
    setIsAddGroup(false); // Reset isAddGroup when closing modals
    setIsDeleteModalOpen(false);
    setFormToDelete(null);
    setIsImportForm(false);
    setIsOpenImportedForm(false);
  };

  const handleAddNew = () => {
    setAllFalse();
    setIsAddNew(true);
    handleOpen();
  };

  const handleAddGroup = () => {
    setAllFalse();
    setIsAddGroup(true); // Open add group modal
  };

  const handleViewJSON = async (row) => {
    setAllFalse();
    // await getFormById(filteredForms[index].formId);
    await getFormById(row.formId);
    setTimeout(() => {
      setIsViewJSON(true);
      setIsLoading(false);
    }, 300);
    // setIsViewIndex(index);
    setSelectedSingleForm(row);
    handleOpen();
  };

  const handleView = async (row) => {
    const response = await apiGatewayService.getCompanyAlias();
    const companyAliasCurr = response.data[0]?.companyAlias || '';
    console.log("Company ALis: ", companyAliasCurr);
    setAllFalse();
    setIsView(true);
    localStorageService.setObject('isCurrForm', 'true');
    const newTab = window.open(`/displayForm/${companyAliasCurr}/${row.formId}`, '_blank', 'noopener,noreferrer');
    if (newTab) newTab.opener = null;
  };

  const handleEdit = async (row) => {
    console.log("row in edit: ", row);
    setAllFalse();
    await getFormById(row.formId);
    setTimeout(() => {
      setIsEdit(true);
      setIsLoading(false);
    }, 300);
    // setIsViewIndex(index);
    setSelectedSingleForm(row);
    handleOpen();
  };

  // Open delete modal and set the field to delete
  const openDeleteModal = (index) => {
    // setFormToDelete(filteredForms[index]);
    setIsDeleteModalOpen(true);
  };

  const handleConfirmDelete = async (index) => {
    console.log("form ids to delete: ", selectedIds);
    if (selectedIds.length>0) {
      try {
        const postData = {
          formNames: selectedIds,
        };
        await apiGatewayService.deleteFormsData(postData);
        setSelectedIds([]);
        setAllFalse();
        setDisplayAlert(true);
        setAlertMessage('Forms deleted successfully!');
        setAlertType('success');
        setTimeout(() => setDisplayAlert(false), 6000);
        await getAllForms();
        // const formsNew = forms.filter((_, index2) => index2 !== index);
        // setForms(formsNew);
      } catch (error) {
        console.error("Error: ", error);
        // alert('Form cannot be deleted');
        setDisplayAlert(true);
        setAlertMessage('Error in deleting forms!');
        setAlertType('error');
        setTimeout(() => setDisplayAlert(false), 6000);
      }
    } else{
        setAllFalse();
        setDisplayAlert(true);
        setAlertMessage('Select a form to delete!');
        setAlertType('success');
        setTimeout(() => setDisplayAlert(false), 6000);
    }
    handleClose();
  };

  const handlePublishForm = async (index) => {
    setAllFalse();
    // setIsViewIndex(index);
    setIsPublish(true);
  };

  const submitPublishForms = async (status) => {
    if (status !== 'cancel') {
      try {
        const formsToPublish = forms.filter((form)=>selectedIds.includes(form.formId));
        const postData = {forms: formsToPublish.map((form) => ({
            formId: decodeURIComponent(form.formId),
            formName: decodeURIComponent(form.formName),
            route: status,
            lastEditedOn: formatDate(new Date()),
            createdBy: form.createdBy,
            createdOn: form?.createdOn || '',
            groupId: decodeURIComponent(form?.groupId || ''),
            groupName: decodeURIComponent(form?.groupName || ''),
          }))};
        // console.log("form to publish: ", postData);
        await apiGatewayService.postPublishFormsData(postData);
        setAllFalse();
        setDisplayAlert(true);
        setAlertMessage('Form published successfully!');
        setAlertType('success');
        setTimeout(() => setDisplayAlert(false), 6000);
      } catch (error) {
        console.error("Error: ", error);
        // alert('Form cannot be published.');
        setAlertMessage('Error in publishing form!');
        setAlertType('error');
        setTimeout(() => setDisplayAlert(false), 6000);
      }
    }
    setAllFalse();
    handleCloseWithGetAllForms();
  };

  function formatDate(date) {
    const pad = (number, length = 2) => String(number).padStart(length, '0');
    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());
    const milliseconds = pad(date.getMilliseconds(), 3) + '000000';
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
  }

  const getFormById = async (formId) => {
    console.log("form id: ", formId);
    setIsLoading(true);
    try {
      const response = await apiGatewayService.getFormById(formId);
      setCurrentForm(response.data);
      return response;
    } catch (error) {
      console.error('Error getting form by id: ', error);
    }
    setIsLoading(false);
  }

  // let filteredForms = forms.filter(form => {
  //   const formNameMatches = !filterFormName || form.formName.toLowerCase().includes(filterFormName.toLowerCase());
  //   const lastEditedOnMatches = !filterLastEditedOn || form.lastEditedOn?.toLowerCase().includes(filterLastEditedOn.toLowerCase());
  //   const lastEditedByMatches = !filterLastEditedBy || form.lastEditedBy.toLowerCase().includes(filterLastEditedBy.toLowerCase());
  //   const statusMatches = !filterStatus || form.route.toLowerCase().includes(filterStatus.toLowerCase());
  //   const createdByMatches = !filterCreatedBy || form.createdBy?.toLowerCase().includes(filterCreatedBy.toLowerCase());
  //   const groupNameMatches = !filterGroupName || form.groupName?.toLowerCase().includes(filterGroupName.toLowerCase());
  //   const createdOnMatches = !filterCreatedOn || form.createdOn?.toLowerCase().includes(filterCreatedOn.toLowerCase()); // New filter logic for createdOn
  
  //   return formNameMatches && lastEditedOnMatches && lastEditedByMatches && statusMatches && createdByMatches && groupNameMatches && createdOnMatches;
  // });

  // console.log('filteredForms: ', filteredForms);
  

  const handleGroupSubmit = async (groupName) => {
    console.log('Group Name:', groupName);
    try {
      const response = await apiGatewayService.postFormGroups({groupName});
      console.log("Post form group response: ", response);
      setAlertMessageAndPopup({
        message: 'Group created successfully!',
        type: 'success'
      });
    } catch(error){
      console.log('Error in posting form group: ', error);
      setAlertMessageAndPopup({
        message: 'Error in creating group!',
        type: 'error'
      });
    }
    setIsAddGroup(false); 
  };

  
  const handleAssignGroup = (index) => {
    setAllFalse();
    setIsViewIndex(index);
    setIsFormGroupSelectorOpen(true); // Open FormGroupSelector modal
  };

  const closeGroupSelector = (alertObject) => {
    setIsFormGroupSelectorOpen(false);
    if(alertObject?.type && alertObject?.message) {
      setAlertMessageAndPopup(alertObject);
    };
  };

  const handleAssignGroupToForms  = async (groupId) => {
    console.log("Group id selected ", groupId);
    console.log("Selecetd Ids  for group: ", selectedIds);
    if (selectedIds.length > 0) {
      const formIds = selectedIds;
      if (formIds) {
        try {
          await apiGatewayService.assignFormGroup(formIds, groupId);
          setSelectedIds([]);
          setIsFormGroupSelectorOpen(false);
          await props.updateFormGroups();
          handleCloseWithGetAllForms();
          setAlertMessageAndPopup({
            message: 'Group successfully assignied to forms!',
            type: 'success'
          });
        } catch (error) {
            setAlertMessageAndPopup({
            message: 'Error in assigning group to forms!',
            type: 'error'
            });
          console.error('Error submitting group for forms:', error);
        }
      }
    } else {
        setAlertMessageAndPopup({
            message: 'Select a form to assign group!',
            type: 'error'
        });
    }
  };

  const renderBreadcrumbs = () => {
    if (isAddNew) return "Forms / New";
    if (isEdit) return "Forms  / Edit";
    if (isOpenImportedForm) return "Forms  / New";
    return "Forms";
  };

  const handleImportForm = () => {
    setIsImportForm(true);
  };

  const handleSubmitImportedJsonFile = (form) => {
    console.log("This is the current form: ", form);
    setCurrentForm(form);
    setIsImportForm(false);
    console.log("isOpenImportedForm: ", isOpenImportedForm);
    setIsOpenImportedForm(true);
  };

  function formatDateForDisplay(dateString) {
    // Split the string to remove the nanoseconds part
    if(dateString === ''){
      return ''
    }
    return dateString.split('.')[0];
  };

  const handleSaveForm = (value) => {
    setIsSaveForm(value);
  };

  const sortByColumn = (column) => {
    if (sortKey === tableColumnToKeyMapping[column]){
      setSortKey(null);
    } else{
      setSortKey(tableColumnToKeyMapping[column]);
    }
  };

  const handleRefresh = async () => {
    console.log("Refresh button for the form has been pressed!");
    await getAllForms();
  }
  

  return (
    <Container disableGutters id = 'HELLO CHECK' sx={{pl:0, pr: 0, margin: 0, minWidth: '100%', height: '90%', display: 'flex', flexDirection: 'column'}}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', ml: '10px', width: '100%' }}>
        {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton sx={{ marginLeft: '-20px' }} edge="start" color="var(--app-color)" aria-label="menu">
            <ArticleIcon sx={{ fontSize: 40, color: "var(--app-color)" }} />
          </IconButton>
          <Typography variant="h3" sx={{ mr: 2, color: "var(--app-color)" }}>
            {renderBreadcrumbs()}
          </Typography>
        </Box> */}

        {/* Right aligned content */}
        {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography
            variant="body1"
            sx={{
              marginRight: '0px',
              fontWeight: 'bold',
              fontFamily: '"Montserrat", sans-serif',
            }}
          >
            {props.userGroupName}
          </Typography>
          <IconButton
            sx={{
              marginLeft: "auto",
              marginRight: '-5px',
              '&:hover': { backgroundColor: 'transparent', color: 'var(--button-hover-color)' }
            }}
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={() => { }}
          >
            <AccountCircleIcon sx={{ fontSize: 35 }} />
          </IconButton>
          <Typography
            variant="body1"
            sx={{
              marginRight: '0px',
              marginLeft: '5px',
              fontWeight: 'bold',
              fontFamily: '"Montserrat", sans-serif',
            }}
          >
            {'LogOut'}
          </Typography>
          <IconButton
            sx={{
              marginLeft: "auto",
              marginRight: '0px',
              '&:hover': { backgroundColor: 'transparent', color: 'var(--button-hover-color)' }
            }}
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={() => props.handleLogOut(true)}
          >
            <LockIcon sx={{ fontSize: 35 }} />
          </IconButton>
        </Box> */}
      </Box>
      <Divider sx={{ borderBottomWidth: '2px', borderColor: 'var(--app-color)' }} />
      { (!isEdit && !isAddNew && !isOpenImportedForm) && <Toolbar 
        sx={{ 
          // backgroundColor: 'var(--app-color-headings)', 
          backgroundColor: 'white',
          borderRadius: "10px", 
          borderEndEndRadius: '0px', 
          borderEndStartRadius: '0px', 
          padding: '0px', 
          color: 'white', 
          position: 'sticky', // Keeps the toolbar fixed inside the scrollable container
          top: 0,  // Sticks the toolbar to the top of the container
          zIndex: 1000, // Ensures it stays on top of the table
          justifyContent: 'space-between'
        }}>         
        <Box sx={{ display: 'flex', alignItems: 'center', padding: 0, ml: '-24px' }}>
            <Button
                sx={{
                    color: '#2b4252',
                    p: '5px',
                    pt: 0,
                    pb: 0,
                    backgroundColor: 'transparent',
                    textTransform: 'none', // Keep the text as-is without capitalization
                    ':hover': {
                        backgroundColor: 'rgba(43, 66, 82, 0.1)', // Add subtle hover effect
                    },
                    '&.Mui-disabled': {
                        color: 'grey', // Style for disabled button
                    },
                    '.MuiButton-startIcon': {
                        marginRight: '2px', // Adjust spacing between icon and text
                      },
                }}
                startIcon={
                    allSelectChecked ? (
                      <CheckBoxIcon /> // Explicitly set dimensions
                    ) : (
                      <CheckBoxOutlineBlankIcon />
                    )
                  }
                onClick={handleSelectedAll}
                disabled={isOnlyReadAccess}
            >
               <Box sx={{ marginTop: '2px' }}>Select All</Box>
            </Button>
            <Button
                sx={{
                    ml: '5px',
                    color: '#2b4252',
                    p: '5px',
                    pt: 0,
                    pb: 0,
                    backgroundColor: 'transparent',
                    textTransform: 'none', // Keep the text as-is without capitalization
                    ':hover': {
                        backgroundColor: 'rgba(43, 66, 82, 0.1)', // Add subtle hover effect
                    },
                    '&.Mui-disabled': {
                        color: 'grey', // Style for disabled button
                    },
                    '.MuiButton-startIcon': {
                        marginRight: '2px', // Adjust spacing between icon and text
                      },
                }}
                startIcon={<PublishIcon />}
                onClick={handlePublishForm}
                disabled={isOnlyReadAccess}
            >
               <Box sx={{ marginTop: '2px' }}>Publish Forms</Box>
            </Button>

            {/* Assign Group */}
            <Button
                sx={{
                    ml: '5px',
                    color: '#2b4252',
                    p: '5px',
                    pt: 0,
                    pb: 0,
                    backgroundColor: 'transparent',
                    textTransform: 'none',
                    ':hover': {
                        backgroundColor: 'rgba(43, 66, 82, 0.1)',
                    },
                    '&.Mui-disabled': {
                        color: 'grey',
                    },
                    '.MuiButton-startIcon': {
                        marginRight: '4px', // Adjust spacing between icon and text
                      },
                }}
                startIcon={<WorkspacesIcon />}
                onClick={() => handleAssignGroup(-1)}
                disabled={isOnlyReadAccess}
            >
                <Box sx={{ marginTop: '2px' }}>Assign Group</Box>
            </Button>

            {/* Delete */}
            <Button
                sx={{
                    ml: '5px',
                    color: '#CC3824',
                    p: '5px',
                    pt: 0,
                    pb: 0,
                    backgroundColor: 'transparent',
                    textTransform: 'none',
                    ':hover': {
                        backgroundColor: 'rgba(204, 56, 36, 0.1)',
                    },
                    '&.Mui-disabled': {
                        color: 'grey',
                    },
                    '.MuiButton-startIcon': {
                        marginRight: '2px', // Adjust spacing between icon and text
                      },
                }}
                startIcon={<DeleteIcon />}
                onClick={() => openDeleteModal(-1)}
                disabled={isOnlyReadAccess}
            >
                <Box sx={{ marginTop: '2px' }}>Delete</Box>
            </Button>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {(!isEdit && !isAddNew && !isOpenImportedForm) && (
            <>

            <Tooltip title="Refresh">
                <IconButton
                sx={{ color: 'var(--app-color)', p: '5px' ,mr: '10px'}}
                aria-label="edit"
                onClick={handleRefresh}
                disabled={isOnlyReadAccess}
                >
                <RefreshIcon sx={{ fontSize: '34px', fontWeight: 'bold'  }}/>
                </IconButton>
            </Tooltip>
              <Button sx={{
                backgroundColor: 'var(--app-color)',
                width: '130px',
                marginRight: '10px',
                ":hover": {
                  // backgroundColor: '#F0F4F4',
                  backgroundColor: 'var(--button-hover-color)',
                  // color: '#2b4252',
                },
                '&.Mui-disabled': {
                  backgroundColor: '#e0e0e0',
                  color: 'grey',
                },
              }} color="inherit" startIcon={<AddIcon />} onClick={handleAddNew} disabled={isOnlyReadAccess}>
                Add New
              </Button>
              <Button sx={{
                backgroundColor: 'var(--app-color)',
                width: '130px',
                marginRight: '10px',
                ":hover": {
                  // backgroundColor: '#F0F4F4',
                  backgroundColor: 'var(--button-hover-color)',
                  // color: '#2b4252',
                },
                '&.Mui-disabled': {
                  backgroundColor: '#e0e0e0',
                  color: 'grey',
                },
              }} color="inherit" startIcon={<AddIcon />} onClick={handleAddGroup} disabled={isOnlyReadAccess}>
                Add Group
              </Button>
              <Button sx={{
                backgroundColor: 'var(--app-color)',
                width: '130px',
                marginRight: '-23px',
                ":hover": {
                  // backgroundColor: '#F0F4F4',
                  backgroundColor: 'var(--button-hover-color)',
                  // color: '#2b4252',
                },
                '&.Mui-disabled': {
                  backgroundColor: '#e0e0e0',
                  color: 'grey',
                },
              }} color="inherit" startIcon={<ImportExportIcon />} onClick={handleImportForm} disabled={isOnlyReadAccess}>
                Import
              </Button>
            </>
          )}
         {/* {(isEdit || isAddNew || isOpenImportedForm) && 
            <Button sx={{
              backgroundColor: 'var(--app-color)',
              width: '130px',
              marginRight: '10px',
              ":hover": {
                // backgroundColor: '#F0F4F4',
                backgroundColor: 'var(--button-hover-color)',
                // color: '#2b4252',
              },
              '&.Mui-disabled': {
                backgroundColor: '#e0e0e0',
                color: 'grey',
              },
            }} color="inherit" startIcon={<SaveAltIcon />} onClick={handleSaveForm} disabled={isOnlyReadAccess}>
              Save
            </Button>
          }
          {(isEdit || isAddNew || isOpenImportedForm) && 
            <Button sx={{
              backgroundColor: 'var(--app-color)',
              width: '130px',
              marginRight: '10px',
              ":hover": {
                // backgroundColor: '#F0F4F4',
                backgroundColor: 'var(--button-hover-color)',
                // color: '#2b4252',
              },
              '&.Mui-disabled': {
                backgroundColor: '#e0e0e0',
                color: 'grey',
              },
            }} color="inherit" startIcon={<KeyboardBackspaceIcon />} onClick={handleClose} disabled={isOnlyReadAccess}>
              Back
            </Button>
          } */}
        </Box>
      </Toolbar>}
      {
      displayAlert &&
      <CustomAlert isOpen={displayAlert} message={alertMessage} type={alertType}></CustomAlert>
      }
      {
        isDeleteModalOpen &&
        <DeleteConfirmation
        isOpen={isDeleteModalOpen}
        handleClose={handleCloseWithoutGetForms}
        submitDelete={handleConfirmDelete}
      />
      }
      {isLoading && <CircularLoader />}
      {(!isLoading && !isEdit && !isAddNew && !isOpenImportedForm) &&
            <div
                className="ag-theme-quartz" // Or replace with your custom theme name
                style={{
                     overflow: 'auto',
                     height: '75vh',
                    //  widht: '75vh',
                     minWidth: '1000px',
                    // '--ag-foreground-color': 'rgb(14, 68, 145)', // Grid text color
                    // '--ag-background-color': 'rgb(241, 247, 255)', // Grid background color
                    '--ag-header-background-color': 'rgb(228, 237, 250)', // Header row background color
                    '--ag-row-hover-color': 'rgb(216, 226, 255)', // Row hover color
                }}
            >
                <AgGridReact
                    ref={gridRef} // Attach the ref
                    onRowSelected={onRowSelected}
                    rowData={forms}
                    columnDefs={colDefs}
                    rowSelection="multiple"
                    domLayout="normal" // Dynamically adjusts the height of the grid based on its content
                    suppressHorizontalScroll={false} // Ensures horizontal scrolling is enabled
                    suppressColumnVirtualisation={false} // Ensure columns render correctly for scrolling
                    suppressRowTransform={true} // Fixes issues with row scrolling
                    pagination={pagination}
                    paginationPageSize={paginationPageSize}
                    // paginationPageSizeSelector={paginationPageSizeSelector}
           
                />
            </div>

      }

      {isAddNew && <FormSectionsBuilder form={{}} handleClose={handleClose} isNew={true} companyAlias = {companyAlias}/>}
      {isOpenImportedForm && <FormSectionsBuilder form={currentForm} handleClose={handleClose} isNew={true} companyAlias = {companyAlias}/>}
      {isEdit && <FormSectionsBuilder form={currentForm} handleClose={handleClose} isEditInput = {true} formIdInput = {selectedSingleForm?.formId || null} companyAlias = {companyAlias} />}
      {isViewJSON && <FormsModal open={open} handleClose={handleClose} form={currentForm} isViewJSON={true} />}
      {isPublish && 
        <PublishFormsModal
          handleClose={handleCloseWithoutGetForms}
          open={isPublish}
          submitPublishForms={submitPublishForms}
        />
      }
      {
        isAddGroup && <AddGroupModal
        open = {isAddGroup}
        handleClose = {handleClose}
        handleSubmit= {handleGroupSubmit}

        />
      }
      {
        isFormGroupSelectorOpen && <FormGroupSelector
          isOpen={isFormGroupSelectorOpen}
          handleClose={closeGroupSelector}
          submitGroup={handleAssignGroupToForms }
          groupName = {filteredForms[viewIndex]?.groupName || null}
          selectedIds={selectedIds}
        />
      }
      {
        isImportForm && <ImportFormsModal
        open={isImportForm}
        handleClose={handleClose}
        submitJsonFile={handleSubmitImportedJsonFile}
      />
      }
    </Container>
  );
};

export default Forms2;