import React, {useState, useEffect} from 'react';
import Button from '@mui/material/Button';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import UpdateIcon from '@mui/icons-material/Update';
import CancelIcon from '@mui/icons-material/Cancel';
import { CircularProgress, Box, IconButton, List, ListItem, ListItemText, Typography, Container, Toolbar, AppBar, Paper, Divider} from '@mui/material';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import { useNavigate } from 'react-router-dom';
import SubscriptionManagement from '../Stripe/SubscriptionManagement/SubscriptionManagement';
import apiGatewayService from '../../common/services/apiGatewayService';
import Subscription from '../Stripe/Subscription/Subscription';

const SubscriptionsComponent = () => {
  const navigate = useNavigate();

  const [subscriptions, setSubscriptions] = useState([]);
  const [isPurchasedSubscription, setIsPurchasedSubscription] = useState(false);
  const [isBuySubscription, setIsBuySubscription] = useState(false);

  useEffect(() => {
    // initGA(); // Initialize Google Analytics
    // logPageView(window.location.pathname); // Log the page view
    // const fetchTokenAndSubscriptions = async () => {
    //     try {
    //         const fetchedToken = await getValidToken(); // Get the valid token
    //         setToken(fetchedToken); // Set the token state
    //         await fetchSubscriptionDetails(fetchedToken); // Fetch subscription details using the token
    //         await fetchPrices(); // Fetch available plans
    //     } catch (error) {
    //         console.error("Error fetching token and subscription details:", error);
    //     }
    // };

    // fetchTokenAndSubscriptions(); // Call the function to fetch token and subscription
    const fetchSubscriptionDetails = async (token) => {
        try {
            // const response = await fetch(`${config.subscriptionUrl}`, {
            //     headers: {
            //         authorization: `${token}`, // Set the Authorization header with the token
            //     },
            // });
            const customerIdObj = await apiGatewayService.getStripeCustomerId();
            const customerId = customerIdObj.data.Item.customerId
            const response = await apiGatewayService.getStripeSubscriptionDetails(customerId); //Confirm this from Jay
            // if (!response.ok) {
            //     throw new Error('Network response was not ok');
            // }
            // const data = await response.json();
            const data = response.data;
            console.log("Fetched subscription data: ", data);
            setSubscriptions(data.data); // Set the subscriptions state
            if(data.data.length > 0){
                setIsPurchasedSubscription(true);
            };

            // Store last payment dates
            const paymentDates = {};
            data.data.forEach(subscription => {
                paymentDates[subscription.id] = subscription.latest_invoice_data ? subscription.latest_invoice_data.created : null;
            });
        } catch (error) {
            console.error("Error fetching subscription details", error);
        }
    };
    fetchSubscriptionDetails();
}, []);
  
  const handleBuySubscription = () => {
    // Add logic for buying a subscription
    console.log('Buy Subscription clicked');
    // navigate('/StripePayment');
    setAllFalse();
    setIsBuySubscription(true);
  };

  const handleUpdateSubscription = () => {
    // Add logic for updating a subscription
    console.log('Update Subscription clicked');
  };

  const handleCancelSubscription = () => {
    // Add logic for canceling a subscription
    console.log('Cancel Subscription clicked');
  };

  const setAllFalse = () => {
    setIsBuySubscription(false);
    setIsPurchasedSubscription(false);

  };

  return (
    <Container disableGutters id = 'HELLO CHECK' sx={{pl:0, pr: 0, margin: 0, minWidth: '100%', height: '87%', display: 'flex', flexDirection: 'column'}}>      
       <Divider sx={{ borderBottomWidth: '2px', borderColor: 'var(--app-color)' }} />
     { isPurchasedSubscription && <SubscriptionManagement />}
          
     { (!isPurchasedSubscription && !isBuySubscription) && <div style={{ display: 'flex', flexDirection: 'column', gap: '0px', alignItems: 'center', marginTop: '20px' }}>
        <Button
            variant="contained"
            color="primary"
            startIcon={<ShoppingCartIcon />}
            onClick={handleBuySubscription}
            sx={{
                borderRadius: '10px',
                marginTop: '50px',
                height: '50px',
                width: '230px',
                color: 'white',
                cursor: 'pointer',
                backgroundColor: 'var(--button-color)',
                ':hover' : {
                backgroundColor: 'var(--button-hover-color)',
                //   color: 'var(--button-color)',
                },
            }}
        >
            Buy Subscription
        </Button>
        </div>}
        {isBuySubscription && <Subscription/>}
    </Container>
    
  );
};

export default SubscriptionsComponent;
