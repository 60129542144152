import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  InputLabel,
  Toolbar
} from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DeleteIcon from '@mui/icons-material/Delete';
import classes from './SimpleFieldOptionsBuilder.module.css';
import validationService from '../../../common/validationService.service';
import apiGatewayService from '../../../common/services/apiGatewayService';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const SimpleFieldOptionsBuilder = (props) => {
  const [simpleValueHelps, setSimpleValueHelps] = useState([]);
  const [displayName, setDisplayName] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);


  useEffect(()=>{
    console.log('props in simple value helps: ', props);
    if(props.option){
        setDisplayName(props.option.title);
        setSimpleValueHelps(props.option.values);
    };
    if(props.isEdit){
      setIsEdit(true);
    }
    if (props.isView){
      setIsView(true);
    }
  }, [props.option]);

  const handleAdd = () => {
    setSimpleValueHelps([...simpleValueHelps, { value: '' }]);
  };

  const handleUpdateName = (index, value) => {
    const updatedOptions = [...simpleValueHelps];
    updatedOptions[index].value = value;
    setSimpleValueHelps(updatedOptions);
  };

  const handleRemove = (index) => {
    const updatedOptions = simpleValueHelps.filter((_, i) => i !== index);
    setSimpleValueHelps(updatedOptions);
  };

  const moveUp = (index) => {
    if (index === 0) return;
    const updatedOptions = [...simpleValueHelps];
    [updatedOptions[index - 1], updatedOptions[index]] = [updatedOptions[index], updatedOptions[index - 1]];
    setSimpleValueHelps(updatedOptions);
  };

  const moveDown = (index) => {
    if (index === simpleValueHelps.length - 1) return;
    const updatedOptions = [...simpleValueHelps];
    [updatedOptions[index + 1], updatedOptions[index]] = [updatedOptions[index], updatedOptions[index + 1]];
    setSimpleValueHelps(updatedOptions);
  };

  const handleTitleInputChange = (e) => {
    setDisplayName(e.target.value);
  };

  const handleSubmitValues = async () => {
    const transformedValueArray = simpleValueHelps;
    const optionsObject = {
      displayName: displayName,
      values: transformedValueArray,
    };
    if (validationService.hasEmptyKeys(optionsObject)) {
      alert('Values cannot be empty');
    } else {
      try {
        if(isEdit){
          await apiGatewayService.putOptionsData(props.option.optionId, optionsObject, 'simple');
        } else {
          await apiGatewayService.postOptionsData(optionsObject, 'simple');
        }

        props.handleClose();
      } catch (error) {
        console.log('Error in submitting form options: ', error);
      }
    }
  };

  return (
    <div className={classes.FieldOptionContainer}>
      <Toolbar 
        sx={{ 
          // backgroundColor: 'var(--app-color-headings)', 
          backgroundColor: 'white',
          borderRadius: "10px", 
          borderEndEndRadius: '0px', 
          borderEndStartRadius: '0px', 
          padding: '0px', 
          color: 'white', 
          position: 'sticky', // Keeps the toolbar fixed inside the scrollable container
          top: 0,  // Sticks the toolbar to the top of the container
          zIndex: 1000, // Ensures it stays on top of the table
          justifyContent: 'space-between'
        }}>         
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
          </Box>
          <Box>
          <Button sx={{
            backgroundColor: 'var(--app-color)',
            width: '130px',
            marginRight: '10px',
            ":hover": {
              // backgroundColor: '#F0F4F4',
              backgroundColor: 'var(--button-hover-color)',
              // color: '#2b4252',
            },
            '&.Mui-disabled': {
              backgroundColor: '#e0e0e0',
              color: 'grey',
            },
          }} color="inherit" startIcon={<SaveAltIcon />} onClick={handleSubmitValues} disabled={false}>
            Save
          </Button>
          <Button sx={{
            backgroundColor: 'var(--app-color)',
            width: '130px',
            marginRight: '-23px',
            ":hover": {
              // backgroundColor: '#F0F4F4',
              backgroundColor: 'var(--button-hover-color)',
              // color: '#2b4252',
            },
            '&.Mui-disabled': {
              backgroundColor: '#e0e0e0',
              color: 'grey',
            },
          }} color="inherit" startIcon={<KeyboardBackspaceIcon />} onClick={props.handleClose} disabled={false}>
            Back
          </Button>
          </Box>
      </Toolbar>
    <InputLabel sx={{mt: 2}}>Display Name</InputLabel>
      <TextField
        fullWidth
        variant="outlined"
        name="Group Name"
        onChange={handleTitleInputChange}
        value={displayName}
        sx={{
          marginBottom: '16px',
          width: '100%',
          "& .MuiInputBase-root": {
            height: 40,
            backgroundColor: 'white',
          },
          "& .MuiOutlinedInput-input": {
            height: 40,
            width: '100%',
          },
        }}
      />
    <InputLabel sx={{mt: 2}}>Values</InputLabel>
    <Box
      sx={{
        width: 500,
        bgcolor: 'background.paper',
        p: 0,
        m: 0,
        borderRadius: 2,
      }}
    >
      <List sx ={{mt: 0, p: 0}}>
        {simpleValueHelps.map((option, index) => (
          <ListItem key={index} sx={{ p: 0, mt: 1 }}>
            <TextField
              value={option.value}
              onChange={(e) => handleUpdateName(index, e.target.value)}
              fullWidth
              placeholder={`Option ${index + 1} Name`}
              sx={{
                mr: 1,
                "& .MuiInputBase-root": {
                  height: 40,
                  backgroundColor: 'white',
                },
                "& .MuiOutlinedInput-input": {
                  height: 40,
                  width: '100%',
                },
              }}
            />
            <ListItemSecondaryAction>
              <IconButton edge="end" onClick={() => moveUp(index)} disabled={(index === 0) || (isView)}>
                <ArrowUpwardIcon sx={{ color: 'var(--button-color)', ':hover': { color: 'var(--button-hover-color)', backgroundColor: 'transparent' } }} />
              </IconButton>
              <IconButton edge="end" onClick={() => moveDown(index)} disabled={(index === simpleValueHelps.length - 1) ||  (isView)}>
                <ArrowDownwardIcon sx={{ color: 'var(--button-color)', ':hover': { color: 'var(--button-hover-color)', backgroundColor: 'transparent' } }} />
              </IconButton>
              <IconButton edge="end" onClick={() => handleRemove(index)} disabled = {(isView)}>
                <DeleteIcon sx={{ color: 'var(--button-color-red)', ":hover": { color: 'var(--button-hover-color-red)' } }} />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
        {(!isView) && <div>
          <Button
            variant="contained"
            onClick={handleAdd}
            sx={{
              mt: 2,
              width: '100%',
              backgroundColor: 'var(--button-color)',
              color: 'white',
              fontWeight: 'normal',
              textTransform: 'none',
              ":hover": {
                backgroundColor: 'var(--button-hover-color)',
              },
            }}
          >
            Add Option
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmitValues}
            sx={{
              mt: 2,
              width: '100%',
              backgroundColor: 'var(--button-color)',
              color: 'white',
              fontWeight: 'normal',
              textTransform: 'none',
              ":hover": {
                backgroundColor: 'var(--button-hover-color)',
              },
            }}
          >
            Submit Options
          </Button>
        </div>}
      </Box>
    </div>
  );
};

export default SimpleFieldOptionsBuilder;