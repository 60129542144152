import React from 'react';
import { Box, Tooltip, IconButton } from '@mui/material';
import PublishIcon from '@mui/icons-material/Publish';
import DataObjectIcon from '@mui/icons-material/DataObject';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import DeleteIcon from '@mui/icons-material/Delete';

const TableActions = ({
  handlePublishForm,
  handleViewJSON,
  handleView,
  handleEdit,
  handleAssignGroup,
  openDeleteModal,
  index,
  isOnlyReadAccess,
}) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
      {/* <Tooltip title="Publish Forms">
        <IconButton
          sx={{ color: '#2b4252', p: '5px' }}
          aria-label="view"
          onClick={() => handlePublishForm(index)}
          disabled={isOnlyReadAccess}
        >
          <PublishIcon />
        </IconButton>
      </Tooltip> */}
      {/* <Tooltip title="View JSON">
        <IconButton
          sx={{ color: '#2b4252', p: '5px' }}
          aria-label="view"
          onClick={() => handleViewJSON(index)}
        >
          <DataObjectIcon />
        </IconButton>
      </Tooltip> */}
      <Tooltip title="View">
        <IconButton
          sx={{ p: '5px' }}
          aria-label="json"
          onClick={() => handleView(index)}
        >
          <VisibilityIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Edit">
        <IconButton
          sx={{ color: '#2b4252', p: '5px' }}
          aria-label="edit"
          onClick={() => handleEdit(index)}
          disabled={isOnlyReadAccess}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
      {/* <Tooltip title="Assign Group">
        <IconButton
          sx={{ color: '#2b4252', p: '5px' }}
          aria-label="assign-group"
          onClick={() => handleAssignGroup(index)}
          disabled={isOnlyReadAccess}
        >
          <WorkspacesIcon />
        </IconButton>
      </Tooltip> */}
      {/* <Tooltip title="Delete">
        <IconButton
          sx={{ color: '#CC3824', p: '5px' }}
          aria-label="delete"
          onClick={() => openDeleteModal(index)}
          disabled={isOnlyReadAccess}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip> */}
    </Box>
  );
};

export default TableActions;
