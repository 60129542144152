import React, { useState, useEffect, useRef, memo } from 'react';
import classes from './FieldBuilder.module.css';
import ModalComponent from '../OptionsModal/OptionsModal';
import FieldOptionsBuilder from '../FieldOptionsBuilder/FieldOptionsBuilder';
import FieldOptionsSelector from '../FieldOptionsSelector/FieldOptionsSelector';
import SelectionModalComponent from '../SelectionModal/SelectionModal';
import apiGatewayService from '../../../common/services/apiGatewayService';
import FieldsDependentSelector from '../FieldsDependentSelector/FieldsDependentSelector';
import validationService from '../../../common/validationService.service';
import localStorageService from '../../../common/services/localStorage.service';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button, IconButton, InputLabel, TextField, Checkbox, 
 FormControlLabel, FormControl, Select, MenuItem, Typography , Box} from '@mui/material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import BackupIcon from '@mui/icons-material/Backup';
import LowPriorityIcon from '@mui/icons-material/LowPriority'; 
import utilityFunctions from '../../../common/services/utilityFunctions';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ListIcon from '@mui/icons-material/List';
import StyleIcon from '@mui/icons-material/Style';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import TableColumns from '../../TableColumns/TableColumns';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import MultiCheckBoxInputs from '../../MultiCheckBoxInputs/MultiCheckBoxInputs';
import DataObjectIcon from '@mui/icons-material/DataObject';
import FieldConfigBuilder from '../FieldConfigBuilder/FieldConfigBuilder';
import MessageIcon from '@mui/icons-material/Message';
import MessageStrip from '../MessageStrip/MessageStrip';
import CustomAlert from '../../CustomAlert/CustomAlert';
import DeleteConfirmation from '../../DeleteConfirmation/DeleteConfirmation';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import Rating from '../Rating/Rating';


const mandatoryFieldKeys = ['name', 'label', 'type', 'dataType', 'length', 'required', 'wrapping','visible'];

const initialFieldState = {
  name: '',
  label: '',
  type: 'text',
  dataType: 'string',
  length: 50,
  description: '',
  required: false,
  wrapping: false,
  visible: true,
  placeholdertext: '',
  controlType: '',
  barcode: false,
  options: '',
  dependent: '',
  config: '',
  sessionID: '',
  validate: {},
  // descriptionName: '',
};


const validationValues = ['none','phone', 'email'];

const controlTypeValues = {
  "":"",
  "Advance Location Selection": "AdvancedLocationSearch",
  "AGFMA Site Selector": "AGFMASiteSearch",
  "AGFMA Facility Selector": "AGFMAFacilitySearch",
  "Configurable Table Selector": "TableSearch",
  "Advance Equipment Selection": "AdvancedEquipmentSearch"
}


const FieldBuilder = (props) => {
  useEffect(() => {
    console.log("Props field builder: ",props);
    if(Object.keys(props.field).length !== 0){
      if(props.field.api){
        props.field.options = getOptionName(props.field.api);
        setOptionsEnabled(true);
      }
      setField(props.field);
      handleExtrasOnInput(props.field);
    } else {
      setField(initialFieldState);
    }
    if(props.isForm) {
      setIsForm(true);
    };

    if(props.isEdit){
      setIsEdit(true);
      setFieldId(props.fieldId);
    }

    if(props.isView){
      console.log("We are inside isView: ", props.isView);
      setIsView(true);
      setFieldId(props.fieldId);
    }

    if (props.isForm && props.checkForErroInField) {
      checkForErrorsInField(props.field);
    }

    const user = localStorageService.getObject('user');
    setCogntioUser(user);
    setIsOnlyReadAccess(utilityFunctions.checkIfOnlyReadAccess(user));
    // console.log("Check if only user access: ",utilityFunctions.checkIfOnlyReadAccess(user) )
  }, [props]); 

  const [typeOptions] = useState({
    text: ["string"],
    textarea: ["string"],
    selection: ["object", "array", "string"],
    dropdown: ["object", "array", "string"],
    checkbox: ["boolean"],
    number: ["integer", "float", "double"],
    upload: ["base64", "string", "file"],
    date: ["string", "date", "datetime"],
    table: ["object", "array", "string"],
    signature: ["base64"],
    time: ["string", "date", "datetime"],
    multiCheckbox: ["object"],
    staticContent: ["string"],
    rating: ["object"]
  });

  const [fieldTemplate] = useState({
    name: 'string',
    label: 'string',
    type: 'select',
    dataType: 'select',
    length: 'number',
    description: 'text',
    required: 'boolean',
    wrapping: 'boolean',
    visible: 'boolean',
    placeholdertext: 'string',
    controlType: 'select',
    barcode: 'boolean',
    options: 'text',
    dependent: 'text',
    config: 'text',
    sessionID: 'text',
    validate: 'select',
    // descriptionName: 'text',
  });

  const [field, setField] = useState(props.field);
  const [changedValue, setChangedValue] = useState(false);
  const [optionsEnabled, setOptionsEnabled] = useState(false);
  const [dependentEnabled, setdependentEnabled] = useState(false);
  const [allFields, setAllFields] = useState([{name: ''}]);
  const [isForm, setIsForm] = useState(false);
  const [cogntioUser, setCogntioUser] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isArrowDown, setIsArrowDown] = useState(false);
  const [isOnlyReadAccess, setIsOnlyReadAccess] = useState(false);
  const [optionsModalOpen, setOptionsModalOpen] = useState(false);
  const [dependentModalOpen, setdependentModalOpen] = useState(false);
  const [tableEnabled, setTableEnabled] = useState(false);
  const [tableModalOpen, setTableModalOpen] = useState(false);
  const [multiCheckBoxInputModalOpen, setMultiCheckBoxInputModalOpen] = useState(false);
  const [multiCheckBoxEnabled, setMultiCheckBoxEnabled] = useState(false);
  const [fieldId, setFieldId] = useState(null);
  const [isView, setIsView] = useState(false);
  const [configEnabled, setConfigEnabled] = useState(false);
  const [configModalOpen, setConfigModalOpen] = useState(false);
  const [isUpload, setIsUpload] = useState(false);
  const [messageStripModalOpen, setMessageStripModalOpen] = useState(false);
  const [fieldDescription, setFieldDescription] = useState('');
  const [disableControlType, setDisableControlType] = useState(false);
  const [fieldKeysWithError, setFieldKeysWithError] = useState([]);
  const [displayAlert, setDisplayAlert] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isBarcodeSelectionEnabled, setIsBarcodeSelectionEnabled] =  useState(false);
  const [isRatingEnabled, setIsRatingEnabled] = useState(false);
  const [openRatingModal, setOpenRatingModal] = useState();
  // const fieldKeysWithError = useRef([]); 


  const convertToCapital = (value) => {
    return `${value[0].toUpperCase()}${value.slice(1)}`;
  };

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    setField(prevState => ({
      ...prevState,
      [name]: type === 'number' ? parseInt(value) : value
    }));
    setChangedValue(true);

    console.log("name: ",name);
    console.log("value: ",value);
    console.log("type: ",type);

    if (name === 'validate') {
      setField(prevState => ({
        ...prevState,
        [name]: {type: value}
      }));
    }


    if(name === 'type'){
      const selectedType = value;
      setField(prevState => ({
        ...prevState,
        dataType: typeOptions[selectedType][0] // Set default dataType based on selected type
      }));

      if(['dropdown', 'selection'].includes(value)) {
        setOptionsEnabled(true);
      } else {
        setOptionsEnabled(false);
      };

      if(['table'].includes(value)){
        setTableEnabled(true);
      } else {
        setTableEnabled(false);
      };

      if(['multiCheckbox'].includes(value)){
        setMultiCheckBoxEnabled(true);
      } else {
        setMultiCheckBoxEnabled(false);
      };

      if(['staticContent'].includes(value)){
        setConfigEnabled(true);
      } else {
        setConfigEnabled(false);
      };

      if (['upload'].includes(value)) {
        console.log("Inside upload check");
        setIsUpload(true);
        if (fieldTemplate.url === undefined) {
            // fieldTemplate['url'] = '';
            // fieldTemplate['area'] = '';
        };
      } else {
        console.log("Inside upload check else");
        // delete fieldTemplate['url'];
        // delete fieldTemplate['area'];
        // delete field['url'];
        // delete field['area'];
        setIsUpload(false);
      };

      if (value === 'text') {
        setDisableControlType(false);
      } else {
        setDisableControlType(true);
      }; 
      
      if (value === 'rating') {
        setIsRatingEnabled(true)
      } else {
        setIsRatingEnabled(false);
      }
    }

    else if (name === 'controlType') {
      if (value==='AdvancedLocationSearch' || value === 'AdvancedEquipmentSearch'){
        setIsBarcodeSelectionEnabled(true);
      } else {
        setIsBarcodeSelectionEnabled(false);
      }
    }

  };

  const handleMouseOut = () => {
    if(changedValue){
      if(isForm){
        const fieldToUpdateInSection = field;
        removeOptionalKeys(fieldToUpdateInSection)
        props.updateFieldsArray(fieldToUpdateInSection, props.indexFieldArray);
      }
      setChangedValue(false);
    }
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setField(prevState => ({
      ...prevState,
      [name]: checked
    }));
    setChangedValue(true);
  };  

  const handleRemoveField = () => {
    setField(initialFieldState);
    if(isForm){
      props.updateFieldsArray(undefined, props.indexFieldArray);
    };
    setIsDeleteModalOpen(false);
  };

  const removeOptionalKeys = (field) => {
    delete field.options;
    if (field.placeholdertext === '') {
      delete field.placeholdertext; 
    }

    if (field.description === ''){
      delete field.description;
    }

    if (field?.descriptionName === ''){
      delete field.descriptionName;
    }

    // console.log("controlType: ", field?.controlType);
    //Check for barcode based on values
    if (field.controlType === '' || disableControlType) {
      delete field.controlType;
    }

    // console.log("check control type: ", field.controlType);
    // console.log("isBarcodeSelectionEnabled: ", isBarcodeSelectionEnabled);
    
    if (field.controlType ==='AdvancedLocationSearch'){
    
    } else if (field.controlType === 'AdvancedEquipmentSearch') {

    } else if (isBarcodeSelectionEnabled) {
   
    } else{ 
      
      delete field.barcode;
    }

    if (field.config === '') {
      delete field.config;
    }

    if ( field.api && Object.keys(field.api).length === 0) {
      delete field.api;
    } 
    if (!optionsEnabled && field?.api && Object.keys(field?.api).length !== 0){
      delete field.api;
    }
    if(field.dependent === ''){
      delete field.dependent;
    }
    if (field.type!=='table') {
      if(field?.tableColumns) {
        if(field.tableColumns.length > 0){
          delete field.tableColumns;
        }
      }
    };

    if (field.type!=='multiCheckbox') {
      if(field?.multiCheckBoxInputs) {
        if(field.multiCheckBoxInputs.length > 0){
          delete field.multiCheckBoxInputs;
        }
      }
    };

    if (!configEnabled && field?.config && Object.keys(field?.config).length !== 0){
      delete field.config;
    };

    if (!field['validate']?.type || field['validate'].type === 'none' ) {
      delete field['validate'];
    };

    if (field.sessionID === ''){
      delete field['sessionID'];
    };

    if (field.type==='upload'){
    field["url"] = "rest/case-interaction/getPresignedURL";
    field["area"] = "SRA_ATTACHMENTS"; 
    } else {
      delete field["url"];
      delete field["area"]; 
    }

    if (field.type!=='rating') {
      if(field?.rating) {
          delete field.multiCheckBoxInputs;
      }
    };

    console.log("field: ", field);

  };  

  const handleSubmitField = async () => {
    const displayName = field.name;
    const currentField = field;
    console.log("Field to submit before removal: ", currentField);
    removeOptionalKeys(field);
    const fieldObject = {
        displayName: displayName,
        field: field
    };
    console.log("Field to submit: ", fieldObject);
    const isFieldValid = checkForErrorsInField(field);
    if (!isFieldValid) {
      // if (validationService.hasEmptyKeys(fieldObject)) {
      setField(currentField); 
      // alert('Field keys can not be empty!');
      // props.setAlertMessageAndPopup(
      setAlertMessageAndPopupFields(
          {
            message: 'Error in submitting field: mandatory values can not be empty!',
            type: 'error'
          }
      );
    } else {
        fieldObject['fieldDescription'] = field?.description || '';
        try {
          let response;
          console.log("isEdit: ", isEdit);
          console.log("fieldId: ", fieldId);
          console.log("field object: ", fieldObject);
          if (isEdit) {
            response = await apiGatewayService.putFieldsData(fieldId, fieldObject);
            if (isForm) {
              setAlertMessageAndPopupFields(
                {
                  message: 'Field edited successfully!',
                  type: 'success'
                });
            } else {
              props.handleClose({
                message: 'Field edited successfully!',
                type: 'success'
              });
            }

          } else {
            response = await apiGatewayService.postFieldsData(fieldObject);
            if (isForm){
              setAlertMessageAndPopupFields(
                {
                  message: 'Field created successfully!',
                  type: 'success'
                });
            } else {
              props.handleClose({
                message: 'Field created successfully!',
                type: 'success'
              });
            }

          }
          if(response.data.errorName && response.data.errorName === 'KeyExistsAlready' ){
            alert("Field with same name already exists!!!");
          } else {
  
          }

        } catch (error) {
          console.log("Error in submitting field ", error);
          // props.setAlertMessageAndPopup({
          //   message: 'Error in creating/updating field!',
          //   type: 'error'
          // });
          setField(currentField); 
        }
    };
  };

  const getOptionName = (api) => {
    if(api.url) {
      return api.url.split('/')[1].split('.')[0];
    }
    return '';
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const closeModal = () => {
    setModalIsOpen(false);
    setOptionsModalOpen(false);
    setdependentModalOpen(false);
    setTableModalOpen(false);
    setMultiCheckBoxInputModalOpen(false);
    setConfigModalOpen(false);
    setMessageStripModalOpen(false);
    setOpenRatingModal(false);
  };

  const fieldKeys = Object.keys(fieldTemplate);

  const excludeFirstElement = (inputString) => {
    const parts = inputString.split('/');
    parts.shift();
    return parts.join('/');
  };
  
  const generateApiObjectForField = (option) => {
    const url = excludeFirstElement(option.path);
    return {
      url,
      label: "VALID_VALUE_DESCRIPTION",
      value: "VALID_VALUE_ID",
    };
  };

  const addOptionsIntoField = (api) => {
    console.log("api object received: ", api);
    field.api = api;
    field.options = getOptionName(api);
    closeModal();
  };

  const handleAdddependent = () => {
    // setdependentEnabled(true);
    setdependentModalOpen(true);
  };

  const handleAddConfig = () => {
    // setdependentEnabled(true);
    console.log("Add config!");
    setConfigModalOpen(true);
  };


  const handleAddOption = () => {
    setOptionsModalOpen(true);
  };

  const handleAddTable = () => {
    console.log("Add table clicked!");
    setTableModalOpen(true);
  };

  const handleAddMessageStrip = () => {
    setMessageStripModalOpen(true);
  };

  const submitdependent = (dependent) => {
    console.log('Submitted dependent is: ', dependent)
    field['dependent'] = [dependent];
    setdependentEnabled(false);
  };

  const removedependent = () => {
    setdependentEnabled(false);
    delete field['dependent'];
    closeModal();
  };

  const submitMessageStrip = (messageStrip) => {
    console.log('Submitted message strip: ', messageStrip);
    field['messageStrip'] = messageStrip;
    setMessageStripModalOpen(false);
  };

  const removeMessageStrip = () => {
    console.log('Removing message strip');
    delete field['messageStrip'];
    closeModal();
  };
  
  
  

  const handleMouseEnterMain = () => {
    // setIsEdit(true);
  };
  
  const handleMouseOutMain = () => {
    // setIsEdit(false);
  };

  const toggleEditArrow = () => {
    setIsArrowDown(!isArrowDown);
  };

  const handleExtrasOnInput = (field) => {
    if(['dropdown','selection'].includes(field.type)){
      setOptionsEnabled(true);
    };
    if(['table'].includes(field.type)){
      setTableEnabled(true);
    };
    if(['multiCheckbox'].includes(field.type)){
      setMultiCheckBoxEnabled(true);
    };
    if(['staticContent'].includes(field.type)){
      setConfigEnabled(true);
    };
    if (['upload'].includes(field.type)) {
      setIsUpload(true);
      if (fieldTemplate.url === undefined) {
        fieldTemplate['url'] = '';
        fieldTemplate['area'] = '';
      };
    } else {
      delete fieldTemplate['url'];
      delete fieldTemplate['area'];
      // delete field['url'];
      // delete field['area'];
      setIsUpload(false);
    };

    if(field?.controlType==='AdvancedLocationSearch' || field?.controlType === 'AdvancedEquipmentSearch' ){
      setIsBarcodeSelectionEnabled(true);
    };

    if(field.type === 'rating') {
      field['rating'] = {icon: 'StarIcon', range: 5};
      setIsRatingEnabled(true);
    };

  };

  const handleSubmitTableColumns = (tableColumns) => {
    console.log("tableColumns: ", tableColumns);
    if (tableColumns.length > 0) {
      field.tableColumns = tableColumns;
    } else {
      delete field['tableColumns']
    }
  };

  const handleOpenMultiCheckBoxModal = () => {
    setMultiCheckBoxInputModalOpen(true);
  };
  
  const handleCloseMultiCheckBoxModal = () => {
    setMultiCheckBoxInputModalOpen(false);
  };
  
  const handleMultiCheckBoxSubmit = (selectedOptions) => {
    console.log("Selected MultiCheckbox Options: ", selectedOptions);
    // Handle the submitted data from MultiCheckBoxInputs here
  };  

  const handleMultiCheckBoxInputSubmit = (multiCheckBoxInputs) => {
    console.log("multiCheckBoxInputs: ", multiCheckBoxInputs);
    if (multiCheckBoxInputs.length > 0) {
      field.multiCheckBoxInputs = multiCheckBoxInputs;
    } else {
      delete field['multiCheckBoxInputs']
    }
  };

  const submitFieldConfig = (config) => {
    console.log("field config: ", config);
    field['config'] = config;
  };

  const removeFieldConfig = (config) => {
    console.log("Deleting field cofig");
    delete field['config'];
  };

  const handleInputChangeUrl = (event) => {
    console.log(event.target.value);
    field.url = event.target.value;
  };

  const handleInputChangeFieldDescription = (event) => {
    setFieldDescription(event.target.value);
  };

  const checkForErrorsInField = (field) => {
    // const fieldErrorKeys = validationService.fieldWithError(field);
    const fieldValidationResponse = validationService.validateField(field);
    console.log('field keys with erros: ', fieldValidationResponse.errorKeys);
    if (fieldValidationResponse.errorKeys.length > 0) {
      setFieldKeysWithError(fieldValidationResponse.errorKeys);
      return false;
    }
    setFieldKeysWithError([]);
    return true;
  };

  const setAlertMessageAndPopupFields  = (alertObject) => {
    setDisplayAlert(true);
    setAlertMessage(alertObject.message);
    setAlertType(alertObject.type);
    setTimeout(() => setDisplayAlert(false), 10000);
  }

  const openDeleteModal = (index) => {
    setIsDeleteModalOpen(true);
  };

  const handleSubmitRating = (rating) => {
    console.log("This is the input for rating: ", rating);
    field['rating'] = rating;
  };

  const handleOpenRatingModal = () => {
    setOpenRatingModal(true);
  };
  

  return (
    <div className = {classes.FormFieldContainerWithError}>
      {
        displayAlert &&
        <CustomAlert isOpen={displayAlert} message={alertMessage} type={alertType}></CustomAlert>
      }
      {
        isDeleteModalOpen &&
        <DeleteConfirmation
          isOpen={isDeleteModalOpen}
          handleClose={()=> setIsDeleteModalOpen(false)}
          submitDelete={handleRemoveField}
          message = { 'Are you sure you want to remove this field?'}
          isRemove = {true}
        />
      }

    <div className={ utilityFunctions.isEven(props.indexFieldArray) ?  classes.FormFieldContainerTop2 : classes.FormFieldContainerTop1 }>
      {/* { !isForm && <div className={classes.HeaderContainer}><h2 style={{ padding: '0px', margin: '0px' }}>Field Builder</h2></div>} */}
      <div style={{ position: 'relative', marginBottom: '20px', marginLeft: '3px', marginTop: "-5px" }}>
      <IconButton
            sx={{
              padding: 0,
              margin: 0,
              position: 'absolute',
              top: 0,
              right: 0, // Move the button to the right-most side
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
              },
            }}
            edge="end" // Keeps alignment consistent with positioning
            color="inherit"
            aria-label="menu"
            onClick={toggleEditArrow}
          >
            <MoreHorizIcon sx={{ fontSize: 35 }} />
          </IconButton>
      </div>
      {/* onMouseEnter={handleMouseEnterMain} onMouseLeave={handleMouseOutMain} */}
      <div className={isArrowDown ? classes.FormFieldContainerVertical : classes.FormFieldContainer} >
        {/* {isArrowDown && 
        <div className={classes.Field}>
         <InputLabel sx={{ mt: 1, mb: 1 }}> Description </InputLabel>
                        <TextField
                        type="text"
                        name={'fieldDescription'}
                        value={fieldDescription}
                        onChange={handleInputChangeFieldDescription}
                        variant="outlined"
                        size="medium"
                        sx={{
                          width: '100%',
                          "& .MuiInputBase-root": {
                            height: 40,
                            backgroundColor: 'white'
                          },
                          "& .MuiOutlinedInput-input": {
                            height: 40,
                            width: '100%',
                          },
                        }}
                      />
        </div>
        } */}
      {fieldKeys.map((key, index) => (
        <div
          key={index}
          className={((['name', 'label', 'type', 'dataType', 'required', 'url', 'area','description'].includes(key) && !isArrowDown) || isArrowDown)
            ? classes.Field
            : classes.ClassesNone}
        >
          <InputLabel sx={{ mt: 1, mb: 1 }}>
            {convertToCapital(key)} {mandatoryFieldKeys.includes(key) && <span style={{ color: 'red' }}>*</span>}
          </InputLabel>
          <div>
            {
            key === 'barcode' ?
                <FormControlLabel
                  disabled={!isBarcodeSelectionEnabled}
                  control={
                    <Checkbox
                      name={key}
                      checked={field[key]}
                      onChange={handleCheckboxChange}
                      onMouseOut={handleMouseOut}
                      sx={{
                        p: 0,
                        m: 0,
                        ml: '2px',
                        height: '10px',
                        width: '10px',
                        '& .MuiSvgIcon-root': { fontSize: '30px' },
                        borderColor: fieldKeysWithError.includes(key) ? 'red' : 'inherit',
                      }}
                    />
                  }
                  sx={{ m: 0, ml: '5px', p: 0 }}
                />: 
            (fieldTemplate[key] === 'boolean' && key!=='barcode')? (
              <FormControlLabel
                control={
                  <Checkbox
                    name={key}
                    checked={field[key]}
                    onChange={handleCheckboxChange}
                    onMouseOut={handleMouseOut}
                    sx={{
                      p: 0,
                      m: 0,
                      ml: '2px',
                      height: '10px',
                      width: '10px',
                      '& .MuiSvgIcon-root': { fontSize: '30px' },
                      borderColor: fieldKeysWithError.includes(key) ? 'red' : 'inherit',
                    }}
                  />
                }
                sx={{ m: 0, ml: '5px', p: 0 }}
              />
            ) : fieldTemplate[key] === 'number' && key === 'length' ? (
              <TextField
                type="text"
                name={key}
                value={field[key]}
                onChange={handleInputChange}
                onBlur={handleMouseOut}
                variant="outlined"
                size="medium"
                disabled={optionsEnabled}
                sx={{
                  width: '100%',
                  '& .MuiInputBase-root': {
                    height: 40,
                    backgroundColor: 'white',
                    border: fieldKeysWithError.includes(key) ? '1px solid red' : 'inherit',
                  },
                  '& .MuiOutlinedInput-input': {
                    height: 40,
                    width: '100%',
                    border: fieldKeysWithError.includes(key) ? '1px solid red' : 'inherit'
                  },
                }}
              />
            ) : fieldTemplate[key] === 'number' ? (
              <TextField
                type="number"
                name={key}
                value={field[key]}
                onChange={handleInputChange}
                onBlur={handleMouseOut}
                variant="outlined"
                size="medium"
                sx={{
                  width: '100%',
                  '& .MuiInputBase-root': {
                    height: 40,
                    backgroundColor: 'white',
                    border: fieldKeysWithError.includes(key) ? '1px solid red' : 'inherit',
                  },
                  '& .MuiOutlinedInput-input': {
                    height: 40,
                    width: '100%',
                    border: fieldKeysWithError.includes(key) ? '1px solid red' : 'inherit'
                  },
                }}
              />
            ) : fieldTemplate[key] === 'select' && key === 'type' ? (
              <FormControl
                variant="outlined"
                className={classes.Select}
                sx={{
                  minWidth: '100%',
                  '& .MuiOutlinedInput-root': {
                    border: fieldKeysWithError.includes(key) ? '1px solid red' : 'inherit',
                  },
                }}
              >
                <Select
                  name={key}
                  value={field[key] || ''}
                  onChange={handleInputChange}
                  onBlur={handleMouseOut}
                  sx={{ height: '40px', backgroundColor: 'white' }}
                >
                  {Object.keys(typeOptions).map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : fieldTemplate[key] === 'select' && key === 'validate' ? (
              <FormControl
                variant="outlined"
                className={classes.Select}
                sx={{
                  minWidth: '100%',
                  '& .MuiOutlinedInput-root': {
                    border: fieldKeysWithError.includes(key) ? '1px solid red' : 'inherit',
                  },
                }}
              >
                <Select
                  name={key}
                  value={field[key]?.type || ''}
                  onChange={handleInputChange}
                  onBlur={handleMouseOut}
                  sx={{ height: '40px', backgroundColor: 'white' }}
                >
                  {validationValues.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : fieldTemplate[key] === 'select' && key === 'dataType' ? (
              <FormControl
                variant="outlined"
                className={classes.Select}
                sx={{
                  minWidth: '100%',
                  '& .MuiOutlinedInput-root': {
                    border: fieldKeysWithError.includes(key) ? '1px solid red' : 'inherit',
                  },
                }}
              >
                <Select
                  name={key}
                  value={field[key] || ''}
                  onChange={handleInputChange}
                  onBlur={handleMouseOut}
                  disabled={optionsEnabled || tableEnabled}
                  sx={{ height: '40px', backgroundColor: 'white' }}
                >
                  {typeOptions[field.type]?.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : fieldTemplate[key] === 'select' && key === 'controlType' ? (
              <FormControl
                variant="outlined"
                className={classes.Select}
                sx={{
                  minWidth: '100%',
                  '& .MuiOutlinedInput-root': {
                    border: fieldKeysWithError.includes(key) ? '1px solid red' : 'inherit',
                  },
                }}
              >
                <Select
                  name={key}
                  value={field[key] || ''}
                  onChange={handleInputChange}
                  onBlur={handleMouseOut}
                  disabled={disableControlType}
                  sx={{ height: '40px', backgroundColor: 'white' }}
                >
                  {Object.keys(controlTypeValues).map((controlTypeKey) => (
                    <MenuItem key={controlTypeKey} value={controlTypeValues[controlTypeKey]}>
                      {controlTypeKey}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : fieldTemplate[key] === 'url' ? (
              <TextField
                type="text"
                name={key}
                value={field[key] ? field[key] : ''}
                onChange={handleInputChangeUrl}
                onBlur={handleMouseOut}
                disabled={true}
                variant="outlined"
                size="medium"
                sx={{
                  width: '100%',
                  '& .MuiInputBase-root': {
                    height: 40,
                    backgroundColor: 'white',
                    border: fieldKeysWithError.includes(key) ? '1px solid red' : 'inherit',
                  },
                  '& .MuiOutlinedInput-input': {
                    height: 40,
                    width: '100%',
                    border: fieldKeysWithError.includes(key) ? '1px solid red' : 'inherit'
                  },
                }}
              />
            ) : 
            key === 'dependent' ? (
              <TextField
                type="text"
                name={key}
                value={field[key] ? field[key][0]?.field : ''}
                onChange={handleInputChangeUrl}
                onBlur={handleMouseOut}
                disabled={true}
                variant="outlined"
                size="medium"
                sx={{
                  width: '100%',
                  '& .MuiInputBase-root': {
                    height: 40,
                    backgroundColor: 'white',
                    border: fieldKeysWithError.includes(key) ? '1px solid red' : 'inherit',
                  },
                  '& .MuiOutlinedInput-input': {
                    height: 40,
                    width: '100%',
                    border: fieldKeysWithError.includes(key) ? '1px solid red' : 'inherit'
                  },
                }}
              />
            ) : (
              <TextField
                type="text"
                name={key}
                value={field[key]}
                onChange={handleInputChange}
                onBlur={handleMouseOut}
                variant="outlined"
                size="medium"
                sx={{
                  width: '100%',
                  '& .MuiInputBase-root': {
                    height: 40,
                    backgroundColor: 'white',
                    border: fieldKeysWithError.includes(key) ? '1px solid red' : 'inherit',
                  },
                  '& .MuiOutlinedInput-input': {
                    height: 40,
                    width: '100%',
                    border: fieldKeysWithError.includes(key) ? '1px solid red' : 'inherit',
                  },
                }}
              />
            )}
          </div>
        </div>
      ))}
          <div style={{ display: 'flex', justifyContent: 'flex-end', position: 'relative', flexGrow: '1' }}>
            {(isForm && !isArrowDown) &&
              <div className={classes.ButtonDirection}>
                <Button
                  onClick={handleSubmitField}
                  sx={{
                    top: 0,
                    right: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: 0,
                    background: 'transparent',
                    color: 'var(--app-color)',
                    cursor: 'pointer',
                    borderRadius: '10px',
                    ':hover': {
                      backgroundColor: '#F0F4F4',
                      color: '#2b4252',
                    },
                    height: '58px'
                  }}
                >
                  <BackupIcon sx={{ fontSize: 30, marginTop: 1, marginBottom: '5px', color: 'var(--app-color)', padding: 0 }} />
                  <Typography sx={{ fontSize: 10, textTransform: 'none' }} variant="body2">Save Field</Typography>
                </Button>
                <Button
                  onClick={openDeleteModal}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: 0,
                    background: 'transparent',
                    color: 'var(--app-color)',
                    cursor: 'pointer',
                    borderRadius: '10px',
                    ':hover': {
                      backgroundColor: '#F0F4F4',
                      color: '#2b4252',
                    },
                    height: '58px'
                  }}
                >
                  <RemoveCircleIcon sx={{ fontSize: 30, marginTop: 1, marginBottom: '5px', color: 'red', padding: 0 }} />
                  <Typography sx={{ fontSize: 10, textTransform: 'none' }} variant="body2">Remove</Typography>
                </Button>
              </div>
            }

          </div>
      </div>
      {(!isOnlyReadAccess && !isView ) && (
        <div className={ isForm  ? (isArrowDown ? classes.ButtonDirection : classes.ClassesNone) : classes.ButtonDirection}>
          <Button
            onClick={openDeleteModal}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: 0,
              background: 'transparent',
              color: 'var(--app-color)',
              cursor: 'pointer',
              borderRadius: '10px',
              ':hover': {
                backgroundColor: '#F0F4F4',
                color: '#2b4252',
              },
              height: '58px'
            }}
          >
            <RemoveCircleIcon sx={{ fontSize: 30, marginTop: 1, marginBottom: '5px', color: 'red', padding: 0 }} />
            <Typography sx={{ fontSize: 10, textTransform: 'none' }} variant="body2">Remove</Typography>
          </Button>
          <Button
            onClick={handleSubmitField}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: 0,
              background: 'transparent',
              color: 'var(--app-color)',
              cursor: 'pointer',
              borderRadius: '10px',
              ':hover': {
                backgroundColor: '#F0F4F4',
                color: '#2b4252',
              },
              height: '58px'
            }}
          >
            <BackupIcon sx={{ fontSize: 30, marginTop: 1, marginBottom: '5px', color: 'var(--app-color)', padding: 0 }} />
            <Typography sx={{ fontSize: 10, textTransform: 'none' }} variant="body2">Save Field</Typography>
          </Button>
          <Button
            onClick={handleAdddependent}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: 0,
              background: 'transparent',
              color: 'var(--app-color)',
              cursor: 'pointer',
              borderRadius: '10px',
              ':hover': {
                backgroundColor: '#F0F4F4',
                color: '#2b4252',
              },
              height: '58px'
            }}
          >
            <LowPriorityIcon sx={{ fontSize: 30, marginTop: 1, marginBottom: '5px', color: 'var(--app-color)', padding: 0 }} />
            <Typography sx={{ fontSize: 10, textTransform: 'none' }} variant="body2">dependent</Typography>
          </Button>

          <Button
          onClick={handleAddMessageStrip}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: 0,
            background: 'transparent',
            color: 'var(--app-color)',
            cursor: 'pointer',
            borderRadius: '10px',
            ':hover': {
              backgroundColor: '#F0F4F4',
              color: '#2b4252',
            },
            height: '58px'
          }}
         >
          <MessageIcon sx={{ fontSize: 30, marginTop: 1, marginBottom: '5px', color: 'var(--app-color)', padding: 0 }} />
          <Typography sx={{ fontSize: 10, textTransform: 'none' }} variant="body2">Message Strip</Typography>
        </Button>

          {configEnabled && <Button
            onClick={handleAddConfig}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: 0,
              background: 'transparent',
              color: 'var(--app-color)',
              cursor: 'pointer',
              borderRadius: '10px',
              ':hover': {
                backgroundColor: '#F0F4F4',
                color: '#2b4252',
              },
              height: '58px'
            }}
          >
            <DataObjectIcon sx={{ fontSize: 30, marginTop: 1, marginBottom: '5px', color: 'var(--app-color)', padding: 0 }} />
            <Typography sx={{ fontSize: 10, textTransform: 'none' }} variant="body2">Config</Typography>
          </Button> }
          {optionsEnabled &&
            <Button
              onClick={handleAddOption}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 0,
                background: 'transparent',
                color: 'var(--app-color)',
                cursor: 'pointer',
                borderRadius: '10px',
                ':hover': {
                  backgroundColor: '#F0F4F4',
                  color: '#2b4252',
                },
                height: '58px'
              }}
            >
              <StyleIcon sx={{ fontSize: 30, marginTop: 1, marginBottom: '5px', color: 'var(--app-color)', padding: 0 }} />
              <Typography sx={{ fontSize: 10, textTransform: 'none' }} variant="body2">Options</Typography>
            </Button>
          }
          {tableEnabled &&
            <Button
              onClick={handleAddTable}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 0,
                background: 'transparent',
                color: 'var(--app-color)',
                cursor: 'pointer',
                borderRadius: '10px',
                ':hover': {
                  backgroundColor: '#F0F4F4',
                  color: '#2b4252',
                },
                height: '58px'
              }}
            >
              <FormatListNumberedIcon sx={{ fontSize: 30, marginTop: 1, marginBottom: '5px', color: 'var(--app-color)', padding: 0 }} />
              <Typography sx={{ fontSize: 10, textTransform: 'none' }} variant="body2">Table</Typography>
            </Button>
          }
          {multiCheckBoxEnabled &&
            <Button
              onClick={handleOpenMultiCheckBoxModal}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 0,
                background: 'transparent',
                color: 'var(--app-color)',
                cursor: 'pointer',
                borderRadius: '10px',
                ':hover': {
                  backgroundColor: '#F0F4F4',
                  color: '#2b4252',
                },
                height: '58px'
              }}
            >
              <AutoAwesomeMotionIcon sx={{ fontSize: 30, marginTop: 1, marginBottom: '5px', color: 'var(--app-color)', padding: 0 }} />
              <Typography sx={{ fontSize: 10, textTransform: 'none' }} variant="body2">MultiCheck</Typography>
            </Button>
          }

        {isRatingEnabled &&
            <Button
              onClick={handleOpenRatingModal}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 0,
                background: 'transparent',
                color: 'var(--app-color)',
                cursor: 'pointer',
                borderRadius: '10px',
                ':hover': {
                  backgroundColor: '#F0F4F4',
                  color: '#2b4252',
                },
                height: '58px'
              }}
            >
              <StarOutlineIcon sx={{ fontSize: 30, marginTop: 1, marginBottom: '5px', color: 'var(--app-color)', padding: 0 }} />
              <Typography sx={{ fontSize: 10, textTransform: 'none' }} variant="body2">Rating</Typography>
            </Button>
          }
          {optionsModalOpen && <FieldOptionsSelector
            isOpen={optionsModalOpen}
            handleClose={closeModal}
            submitOption={addOptionsIntoField}
            field={field}
          />}
          {dependentModalOpen && <FieldsDependentSelector
            isOpen={dependentModalOpen}
            handleClose={closeModal}
            submitdependent={submitdependent}
            removedependent={removedependent}
            dependent={field?.dependent || undefined}
          />}

           {messageStripModalOpen && (
            <MessageStrip
              // strip={{ values: messageStrips }}  // Pass current message strips as props
              // isEdit={messageStrips.length > 0}  // Pass isEdit flag
              messageStrip = {field?.messageStrip || null}
              isOpen = {messageStripModalOpen}
              handleClose={closeModal}
              isEdit = {false}
              submitMessageStrips={submitMessageStrip}  // Pass submit function
              removeMessageStrip={removeMessageStrip}  // Pass remove function
            />
          )}
          {
            configModalOpen &&
            <FieldConfigBuilder
              isOpen={configModalOpen}
              handleClose={closeModal}
              onSubmit={submitFieldConfig}
              onRemove={removeFieldConfig}
              fieldConfig={field?.config || null}
            />
          }
          {
            isRatingEnabled && 
            <Rating
            open = {openRatingModal}
            onClose = {closeModal}
            onSubmit = {handleSubmitRating}
            rating = {field?.rating || null}
            />
          }

        <TableColumns
          open={tableModalOpen} // Use "open" instead of "isOpen"
          onClose={closeModal} // Use "onClose" instead of "handleClose"
          onSubmit={handleSubmitTableColumns} // "onSubmit" is correct
          tableColumnsInput = {field.tableColumns || []}
        />
        <MultiCheckBoxInputs
        open={multiCheckBoxInputModalOpen}
        onClose={closeModal}
        onSubmit={handleMultiCheckBoxInputSubmit}
        multiCheckBoxInputsInput = {field?.multiCheckBoxInputs || []} // Replace with your dynamic options
        />
      </div>
      )}
        {/* <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end', // Aligns the button to the right
            width: '100%', // Ensures the Box takes up the full width of its parent
            position: 'sticky'
          }}
        >
          <Button
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </Box> */}
    </div>

    </div>
  );
};

export default memo(FieldBuilder);
