import React, { useState, useEffect } from 'react';
import classes from './FieldOptionsSelector.module.css';
import apiGatewayService from '../../../common/services/apiGatewayService';
import localStorageService from '../../../common/services/localStorage.service';
import utilityFunctions from '../../../common/services/utilityFunctions';
import validationService from '../../../common/validationService.service';
import { CircularProgress, TextField, MenuItem, Select, InputLabel, Button, Dialog, DialogTitle, DialogContent, DialogActions, Box, IconButton } from '@mui/material';
import { List, ListItem, Grid, FormControl } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import OptionsDependentSelector from '../OptionsDependentSelector/OptionsDependentSelector';
import CustomAlert from '../../CustomAlert/CustomAlert';
import ReactSelect from 'react-select';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddIcon from '@mui/icons-material/Add';

const displayPatternValus = ['{label}','{value}','{label} - {value}', '{value} - {label}'];
const PVV_keys = [  'VALID_VALUE_ID',  'VALID_VALUE_SHORT_DESC',  'VALID_VALUE_DESCRIPTION',  'PARAMETER_ID',  'PARAMETER',  'FILTER1',  'FILTER2',  'FILTER3',  'FILTER4',  'COUNTER',  'ENTITY',  'VERSION',  'ATTRIBUTE1',  'ATTRIBUTE2',  'VALID_VALUE_ADDITIONAL_TEXT'];


const FieldOptionsSelector = ({ isOpen, handleClose, submitOption, field }) => {
  const [availableOptions, setAvailableOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [optionSelected, setOptionSelected] = useState(false);
  const [labels, setLabels] = useState([]);
  const [values, setValues] = useState([]);
  const [label, setLabel] = useState('');
  const [value, setValue] = useState('');
  const [displayPattern, setDisplayPattern] = useState('');
  const [defaultValues, setDefaultValues] = useState([]);
  const [defaultValue, setDefaultValue] = useState('');
  const [valueHelpFilters, setValueHelpFilters] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOptionId, setSelectedOptionId] = useState(null);
  const [type, setType] = useState(null);
  const [valueHelpsTypeMapping, setValueHelpsTypeMapping ] = useState({
    'options': 'advance',
    'optionsSimple': 'simple',
    'optionsApi': 'api'
  });

  const [displayAlert, setDisplayAlert] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [multiOutput, setMultiOutput] = useState([]);
  const [selectedOptionValues, setSelectedOptionValues] = useState(null);

  useEffect(() => {
    const getOptions = async (user) => {
      try {
        setIsLoading(true);
        const response = await apiGatewayService.getOptionsData();
        console.log("response: ", response);
        const dataWithUpdatedKeyAndContent = response.data;
        dataWithUpdatedKeyAndContent.push({
          optionName: '',
          optionId: '',
        });
        console.log("dataWithUpdatedKeyAndContent: ", dataWithUpdatedKeyAndContent);
        const dataWithUpdatedKeyAndContentWithDisplayNames = utilityFunctions.updateOptionNamesWithDescription(dataWithUpdatedKeyAndContent);
        const dataWithUpdatedKeyAndContentSorted = utilityFunctions.sortByKey(dataWithUpdatedKeyAndContentWithDisplayNames, 'optionDisplayName');
        console.log("dataWithUpdatedKeyAndContentSorted: ", dataWithUpdatedKeyAndContentSorted);
        const formattedOptions = dataWithUpdatedKeyAndContentSorted.map((option) => ({
          ...option,
          value: option.optionId,
          label: option.optionDisplayName,
        }));
        console.log("formattedOptions: ", formattedOptions);
        setAvailableOptions(formattedOptions);

        if (field.api) {
          setInitialValues(field.api, formattedOptions);
        }
      } catch (error) {
        console.error('Error fetching options:', error);
      }
      setIsLoading(false);
    };

    const user = localStorageService.getObject('user');
    getOptions(user);
  }, [field.api]);

  const setInitialValues = async (api, options) => {
    setLabel(api.label);
    setValue(api.value);
    setDisplayPattern(api.displayPattern);
    setDefaultValue(api.default || '');
    setType(valueHelpsTypeMapping[api.url.split('/')[0]]);
    const option = options.find((o)=>o.optionId === api.url.split('/')[1]);
    console.log("option: ", option);
    setSelectedOption(option);

    if (api.multiOutput){
      setMultiOutput(api.multiOutput);
    }

    if (api.valueHelpFilters) {
      setValueHelpFilters(api.valueHelpFilters);
    }

    // const currOption = options.find((o) => api.url.includes(o.optionId)) || [];
    const currOption = options.find((o)=>o.optionId === api.url.split('/')[1]);

    console.log("currOption: ", currOption);
    // const currOption = options.find((o) => api.url.includes(o.optionName)) || [];
    if(currOption) {
      const optionValues = await getOptionDataById(currOption.optionId, currOption.type);
      console.log("optionValues: ", optionValues);
      setCurrentOption(currOption, optionValues);
    }
    // if (currOption.values.length > 0) {
    //   setCurrentOption(currOption);
    // }
  };

  const setCurrentOption = (currOption, optionValues, type) => {
    setSelectedOption(currOption);
    setSelectedOptionValues(optionValues);
    setOthers(optionValues, type, currOption);
    setOptionSelected(true);
  };

  const removeFiltersAndAttributes = (keys) => {
    return keys.filter((k) => !k.includes('FILTER') && !k.includes('ATTRIBUTE'));
  };

  const setOthers = (optionValues, type, currOption) => {
    console.log("option value: ", optionValues);
    console.log("curr option: ", currOption);
    if (type === 'api'){
      setValues(removeFiltersAndAttributes(Object.keys(optionValues[0])));
      setLabels(removeFiltersAndAttributes(Object.keys(optionValues[0])));
      setDefaultValues(optionValues.map((o) => o[Object.keys(optionValues[0])[0]]));
    }
    else if (type === 'simple') {      
      setValues(['value']);
      setLabels(['value']);
      setDefaultValues(optionValues.map((o) => o.name));
    } else {
      // setValues(removeFiltersAndAttributes(Object.keys(optionValues[0])));
      // setLabels(removeFiltersAndAttributes(Object.keys(optionValues[0])));
      console.log("keys: ", Object.keys(optionValues[0]));
      setValues(Object.keys(optionValues[0]));
      setLabels(Object.keys(optionValues[0]));
      setDefaultValues(optionValues.map((o) => o[currOption?.value || 'VALID_VALUE_DESCRIPTION']));
      // setDefaultValues(optionValues.map((o) => o['VALID_VALUE_DESCRIPTION']));
    }

  };

  const resetOthers = () => {
    setValues([]);
    setLabels([]);
    setDefaultValues([]);
    setValue('');
    setLabel('');
    setDefaultValue('');
  };

  const handleChange = async (event) => {
    console.log("selected value: ", event);
    if (event !== '' && event !== null) {
      const value = event.optionId;
      resetOthers();
      console.log("availableOptions: ", availableOptions);
      const currOption = availableOptions.find((o) => o.optionId=== value);
      setType(currOption.type || '');
      console.log("currOption: ", currOption);
      const selectedOptionValues = await getOptionDataById(value, currOption.type);
      console.log("optionValues: ", selectedOptionValues);
      setSelectedOptionValues(selectedOptionValues);
      setSelectedOptionId(value);
      setSelectedOption(currOption);
      setOthers(selectedOptionValues, currOption.type, currOption);
      setOptionSelected(true);
    } else {
      setSelectedOption('');
      resetOthers();
      setOptionSelected(false);
    }
  };

  const getOptionDataById = async (optionId, type) => {
    try {
      let response;
      response = await apiGatewayService.getOptionsDataById(decodeURIComponent(optionId), type);
      console.log("getOptionDataById response: ", response);
      return response.data;
    } catch (error) {
      console.log('Error in getting option by Id: ', error);
    }
  };

  const handleLabelChange = (event) => {
    setLabel(event.target.value);
  };

  const handleValueChange = (event) => {
    console.log("Changed Value: ", event.target.value);
    setValue(event.target.value);
    const defaultValues = selectedOptionValues.map((o) => o[event.target.value]);
    console.log("defaultValues: ", defaultValues);
    setDefaultValues(defaultValues); // We take key from the value selected
  };

  const handleDefaultValueChange = (event) => {
    setDefaultValue(event.target.value);
  };

  const handleDisplayPatternChange = (event) => {
    setDisplayPattern(event.target.value);
  };

  const generateAPIObject = () => {
    const api = {
      url: selectedOption?.optionId 
        ? (type === 'api' ? `optionsApi/${selectedOption.optionId}` 
          : type === 'simple' ? `optionsSimple/${selectedOption.optionId}` 
          : `options/${selectedOption.optionId}`) 
        : '',
      // url: selectedOption.optionId 
      //   ? (type === 'api' ? `optionsApi/${selectedOption.optionName}` 
      //     : type === 'simple' ? `optionsSimple/${selectedOption.optionName}` 
      //     : `options/${selectedOption.optionName}`)ss
      //   : '',
      label: label,
      value: value,
      displayPattern: displayPattern,
      default: defaultValue,
      valueHelpFilters: valueHelpFilters,
      description: selectedOption?.description || ''
    };
    const nonEmptyMultiOutput = getNonEmptyMultiOutput();
    if (nonEmptyMultiOutput.length>0){
      api['multiOutput'] = multiOutput;
    }
    return api.url === '' ? {} : api;
  };
  

  const removeOptionalKeys = (api) => {
    if (api?.valueHelpFilters?.length === 0) {
      delete api.valueHelpFilters;
    }

    if (api.default === '') {
      delete api.default;
    }

    return api;
  };

  const handleSubmit = () => {
    let api = generateAPIObject();
    api = removeOptionalKeys(api);
    console.log("Current api object: ", api);
    const validateOptionsApi = validationService.validateOptionsApi(api);
    // if (validationService.hasEmptyKeys(api)) {
    //   alert('Please select required values for options');
    if (!validateOptionsApi.isValid){
      setAlertMessage(validateOptionsApi.message);
      setAlertType("error");
      setDisplayAlert(true);
      setTimeout(() => setDisplayAlert(false), 3000);
    } else {
      submitOption(api);
      handleClose();
    }
  };

  const handleRemove = () => {
    submitOption({});
    handleClose();
  };

  const submitValueHelpFilters = (valueHelpFilters) => {
    console.log("valueHelpFilters: ", valueHelpFilters);
    setValueHelpFilters(valueHelpFilters);
  };

    // Function to add a new entry to multiOutput
    const addMultiOutput = () => {
      setMultiOutput((prev) => [...prev, { fieldName: '', key: '' }]);
    };
  
    // Function to remove an entry from multiOutput at a given index
    const removeMultiOutput = (index) => {
      setMultiOutput((prev) => prev.filter((_, i) => i !== index));
    };
  
    // Handler to update specific fields in multiOutput
    const handleUpdateMultiOutput = (index, key, value) => {
      console.log('index: ', index )
      console.log(index,key, value)
      const temp = multiOutput;
      temp[index][key] = value;
      setMultiOutput(Array.from(temp))
      console.log(temp);
      // console.log()
    };

    const getNonEmptyMultiOutput = () => {
      console.log("multiOutput: ", multiOutput);
      return multiOutput.length > 0 ? multiOutput.filter((m)=> (m.fieldName!=='' && m.key!=='')): [];
    };

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="md">
        {
        displayAlert &&
        <CustomAlert isOpen={displayAlert} message={alertMessage} type={alertType}></CustomAlert>
      }
      {!displayAlert && <IconButton
        sx={{
          position: 'absolute',
          top: 8,
          right: 8,
        }}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>}
      <DialogTitle>Select Options</DialogTitle>
      <DialogContent dividers>
        {isLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress sx={{ color: 'var(--app-color)' }} />
          </div>
        ) : (
          <>
            <InputLabel sx={{ mt: '10px' }}>Option</InputLabel>
            {/* <Select
              fullWidth
              value={selectedOption.optionId}
              onChange={handleChange}
              displayEmpty
              sx={{ height: '40px', backgroundColor: 'white' }}
            >
              <MenuItem value="" disabled>
                <em>Select an option</em>
              </MenuItem>
              {availableOptions.map((option, index) => (
                <MenuItem key={index} value={option.optionId}>
                  {option.optionId}
                </MenuItem>
              ))}
            </Select> */}
              <ReactSelect
                value={selectedOption}
                onChange={handleChange}
                options={availableOptions}
                placeholder="Select an option"
                isClearable
                isSearchable
                styles={{
                  control: (base) => ({
                    ...base,
                    height: 40,
                    backgroundColor: 'white',
                  }),
                  menu: (base) => ({
                    ...base,
                    zIndex: 100,
                  }),
                }}
              />

            <InputLabel sx={{ mt: '10px' }}>Label</InputLabel>
            <Select
              fullWidth
              value={label}
              onChange={handleLabelChange}
              displayEmpty
              disabled={!optionSelected}
              sx={{ height: '40px', backgroundColor: 'white' }}
            >
              <MenuItem value="" disabled>
                <em>Select a label</em>
              </MenuItem>
              {labels.map((labelOption, index) => (
                <MenuItem key={index} value={labelOption}>
                  {labelOption}
                </MenuItem>
              ))}
            </Select>

            <InputLabel sx={{ mt: '10px' }}>Value</InputLabel>
            <Select
              fullWidth
              value={value}
              onChange={handleValueChange}
              displayEmpty
              disabled={!optionSelected}
              sx={{ height: '40px', backgroundColor: 'white' }}
            >
              <MenuItem value="" disabled>
                <em>Select a value</em>
              </MenuItem>
              {values.map((valueOption, index) => (
                <MenuItem key={index} value={valueOption}>
                  {valueOption}
                </MenuItem>
              ))}
            </Select>

            <InputLabel sx={{ mt: '10px' }}>Default Value</InputLabel>
            <Select
              fullWidth
              value={defaultValue}
              onChange={handleDefaultValueChange}
              displayEmpty
              disabled={!optionSelected}
              sx={{ height: '40px', backgroundColor: 'white' }}
            >
              <MenuItem value="" disabled>
                <em>Select a default value</em>
              </MenuItem>
              {defaultValues.map((defaultOption, index) => (
                <MenuItem key={index} value={defaultOption}>
                  {defaultOption}
                </MenuItem>
              ))}
            </Select>

            <InputLabel sx={{ mt: '10px' }}>Display Pattern</InputLabel>
            {/*
            <TextField
              fullWidth
              variant="outlined"
              name="Display Pattern"
              placeholder="Display Pattern"
              value={displayPattern}
              onChange={handleDisplayPatternChange}
              sx={{
                marginBottom: '16px',
                width: '100%',
                "& .MuiInputBase-root": {
                  height: 40,
                  backgroundColor: 'white',
                },
                "& .MuiOutlinedInput-input": {
                  height: 40,
                  width: '100%',
                },
              }}
            /> */}
            <Select
              fullWidth
              value={displayPattern}
              onChange={handleDisplayPatternChange}
              displayEmpty
              sx={{ height: '40px', backgroundColor: 'white' }}
            >
              <MenuItem value="" disabled>
                <em>Select a default value</em>
              </MenuItem>
              {displayPatternValus.map((d, index) => (
                <MenuItem key={index} value={d}>
                  {d}
                </MenuItem>
              ))}
            </Select>
          </>
        )}
        <InputLabel sx={{ mt: '10px' }}>Multi Output Configuration</InputLabel>
        <Box sx={{ width: '100%', p: 0, m: 0, borderRadius: 2 }}>
        <List sx={{ mt: 0, p: 0 }}>
          {multiOutput.map((item, index) => (
            <ListItem key={index} sx={{ p: 0, mt: 1 }}>
              <Grid container spacing={2} alignItems="center">
                
                {/* Key Input (TextField) */}
                <Grid item xs={6}>
                  <TextField
                    value={item.fieldName}
                    onChange={(e) => handleUpdateMultiOutput(index, 'fieldName', e.target.value)}
                    fullWidth
                    placeholder="Key"
                    sx={{
                      "& .MuiInputBase-root": {
                        height: 40,
                        backgroundColor: 'white',
                      },
                      "& .MuiOutlinedInput-input": {
                        height: 40,
                        width: '100%',
                      },
                    }}
                  />
                </Grid>

                {/* Value Selector (Dropdown Select) */}
                <Grid item xs={5}>
                  <FormControl fullWidth>
                    <Select
                      value={item.key}
                      onChange={(e) => handleUpdateMultiOutput(index, 'key', e.target.value)}
                      sx={{ height: '40px', backgroundColor: 'white' }}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                      
                      </MenuItem>
                      {PVV_keys.map((value) => (
                        <MenuItem key={value} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                {/* Delete Button */}
                <Grid item xs={1}>
                  <IconButton edge="end" onClick={() => removeMultiOutput(index)}>
                    <DeleteIcon sx={{ color: 'var(--button-color-red)', ":hover": { color: 'var(--button-hover-color-red)' } }} />
                  </IconButton>
                </Grid>
              </Grid>
            </ListItem>
          ))}
        </List>

        {/* Add Button */}
        {/* <Button
          variant="contained"
          onClick={addMultiOutput}
          sx={{
            mt: 2,
            width: '100%',
            backgroundColor: 'var(--button-color)',
            color: 'white',
            fontWeight: 'normal',
            textTransform: 'none',
            ":hover": {
              backgroundColor: 'var(--button-hover-color)',
            },
          }}
        >
          Add MultiOutput
        </Button> */}
          {/* <Button
            onClick={addMultiOutput}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: 0,
              background: 'transparent',
              color: 'var(--icon-button-color-green)',
              cursor: 'pointer',
              borderRadius: '10px',
              ':hover': {
                backgroundColor: '#F0F4F4',
                color: 'var(--icon-button-color-green)',
              },
              height: '58px'
            }}
          >
            <AddCircleIcon sx={{ fontSize: 32, marginTop: 1, marginBottom: '5px', color: 'var(--icon-button-color-green)', padding: 0 }} />
            <Typography sx={{ fontSize: 10, textTransform: 'none' }} variant="body2">Add Field</Typography>
          </Button> */}
            <Button sx={{
                width: '100%',
                backgroundColor: 'var(--app-color)',
                // marginRight: '-22px',
                marginTop: '10px',
                color: 'white',
              ":hover": {
                backgroundColor: 'var(--button-hover-color)',
                // color: '#2b4252',
              },
              '&.Mui-disabled': {
                backgroundColor: '#e0e0e0',
                color: 'grey',
              },
            }} color="inherit" startIcon={<AddIcon />} onClick={addMultiOutput} >
              Add Output
            </Button>
      </Box>

        <InputLabel sx={{ mt: '10px' }}>Value Help Filters</InputLabel>
         {/* This is the code for value help filters  ============================================*/}
        <Box sx={{mt:1}}>
          <OptionsDependentSelector
            field={field || null}
            submitValueHelpFilters={submitValueHelpFilters}
          ></OptionsDependentSelector>
        </Box>
        {/* This is the code for value help filters  =============================================*/}
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            sx={{
              backgroundColor: 'var(--button-color)',
              color: 'white',
              fontWeight: 'normal',
              textTransform: 'none',
              ":hover": {
                backgroundColor: 'var(--button-hover-color)',
              },
            }}
          >
            Update
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleRemove}
            sx={{
              backgroundColor: 'var(--button-color-red)',
              color: 'white',
              fontWeight: 'normal',
              textTransform: 'none',
              ":hover": {
                backgroundColor: 'var(--button-hover-color-red)',
              },
            }}
          >
            Remove
          </Button>
        </Box>
      </DialogContent>

    </Dialog>
  );
};

export default FieldOptionsSelector;