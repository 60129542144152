import React, { useState, useEffect } from 'react';
import apiGatewayService from '../../../common/services/apiGatewayService';
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  MenuItem,
  InputLabel,
  IconButton,
  InputAdornment,
  Select,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import utilityFunctions from '../../../common/services/utilityFunctions';
import ReactSelect from 'react-select';

const SectiondependentSelector = ({ isOpen, handleClose, submitdependent, removedependent, dependent }) => {
  const [availableSections, setAvailableSections] = useState([]);
  const [availableFields, setAvailableFields] = useState([]);
  const [currentSection, setCurrentSection] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null); 
  const [selectedField, setSelectedField] = useState(null); 
  const [searchQuery, setSearchQuery] = useState(''); // State for search input
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSectionDisplayName, setSelectedSectionDisplayName] = useState('');
  const [selectedSectionDescription, setSelectedSectionDescription] = useState('');

  useEffect(() => {
    // Fetch all sections for the dropdown
    const getAllSections = async () => {
      try {
        const response = await apiGatewayService.getSectionsData();
        const updatedSections = utilityFunctions.updateSectionNamesWithDescription(response.data);
        const updatedSectionsSorted = utilityFunctions.sortByKey(updatedSections, 'sectionDisplayName');
        const formattedSections = updatedSectionsSorted.map((section) => ({
          ...section,
          value: section.sectionDisplayName,
          label: section.sectionDisplayName,
        }));
        console.log("formattedSections: ",formattedSections);
        console.log("dependent: ", dependent);
        setAvailableSections(formattedSections); 
        // if (dependent && dependent.title && dependent.field) {
        //   const sectionDisplayName = `${dependent.title} - (${dependent.description})`;
        //   const fieldDisplayName = dependent.field;
        //   // const selectedSection = {sectionName: dependent.title, sectionDisplayName: sectionDisplayName, label: sectionDisplayName, value: sectionDisplayName};
        //   // const selectedField = {fieldName: dependent.field, fieldDisplayName: fieldDisplayName, label: fieldDisplayName, value: fieldDisplayName};
        //   let selectedSection = formattedSections.find((section)=>section.sectionDisplayName === sectionDisplayName);
        //   let selectedField;
        //   if (selectedField===null) {
        //     selectedSection = {sectionName: dependent.title, sectionDisplayName: sectionDisplayName, label: sectionDisplayName, value: sectionDisplayName};
        //     formattedSections.push(selectedSection);
        //     selectedField = selectedField.
        //   }
        //   console.log("selectSection: ", selectedSection);
        //   setSelectedSection(selectedSection);
        //   setSelectedField(selectedField);
        //   setAvailableFields([selectedField]);
        //   setAvailableSections(formattedSections);
        //   setSelectedSectionDescription(dependent.description);
        //   setSelectedSectionDisplayName(`${dependent.title} - (${dependent.description})`);
        // } else {
        //   setAvailableSections(formattedSections);
        // }
      } catch (error) {
        console.error('Error fetching sections:', error);
      }
    };
    getAllSections();
  }, [dependent]);

  const getSectionById = async (sectionId) => {
    try {
      setIsLoading(true);
      const response = await apiGatewayService.getSectionsDataById(sectionId);
      setCurrentSection({ ...response.data });
      console.log("setCurrentSection: ",response.data);
      // const fields = utilityFunctions.updateFieldNamesWithDescriptionOnSectionFields(response.data.fields);
      const fields = response.data.fields;
      console.log("fields: ", fields);
      const fieldsSorted = utilityFunctions.sortByKey(fields, 'fieldDisplayName');
      const formattedFields = fieldsSorted.map((field) => ({
        name: field.name,
        description: field.description,
        value: field.name,
        label: field.name,
      }));
      console.log("formattedFields: ",formattedFields);

      setAvailableFields(formattedFields); 
    } catch (error) {
      console.error('Error getting section:', error);
    }
    setIsLoading(false);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value); // Update search query
  };

  const handleSectionChange = (event) => {
    if (event!==null) {
      const selectedSection = availableSections.find((option) => option.sectionDisplayName === event.sectionDisplayName);
      setSelectedSection(selectedSection);
      setSelectedSectionDisplayName(selectedSection?.sectionDisplayName || '');
      setSelectedField(null);
      getSectionById(selectedSection.sectionId);
      setSelectedSectionDescription(selectedSection?.sectionDescription || '');
    } else {
      setSelectedSection(null);
      setSelectedSectionDisplayName('');
      setSelectedField(null);
      setSelectedSectionDescription( '');
    }
  };

  const handleFieldChange = (event) => {
    console.log("field selected: ", event);
    setSelectedField(event);
  };

  useEffect(() => {
    if (dependent && dependent.title && dependent.field) {
      const sectionDisplayName = `${dependent.title} - (${dependent.description})`;
      const fieldDisplayName = dependent.field;
      const selectedSection = {sectionName: dependent.title, sectionDisplayName: sectionDisplayName, label: sectionDisplayName, value: sectionDisplayName};
      const selectedField = {fieldName: dependent.field, fieldDisplayName: fieldDisplayName, label: fieldDisplayName, value: fieldDisplayName};
      setSelectedSection(selectedSection);
      setSelectedField(selectedField);
      setAvailableFields([{ name: dependent.field}]); 
      setSelectedSectionDescription(dependent.description);
      setSelectedSectionDisplayName(`${dependent.title} - (${dependent.description})`);
    }
  }, [dependent]);

  const handleSubmit = () => {
    if (currentSection && selectedField) {
      const field = selectedField.name ;
      const title = currentSection.title;
      const description = selectedSectionDescription;
      submitdependent(title, field, description);
    }
    handleClose();
  };

  // Filter sections based on the search query
  const filteredSections = availableSections.filter((section) =>
    section.sectionDisplayName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="section-dependent-selector-title"
      aria-describedby="section-dependent-selector-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '8px',
          boxShadow: 24,
          width: '400px', // Adjust the width as needed
          outline: 'none',
        }}
      >
        <IconButton
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>

        {/* <Typography id="section-dependent-selector-title" variant="h6" component="h2" gutterBottom>
          Select dependent
        </Typography> */}

       <h3>Section Dependent</h3>
       <hr></hr>

        {/* Search Field Above the Select */}
        <InputLabel sx={{ mt: 2 }}>Section</InputLabel>
        {/* <TextField
          fullWidth
          variant="outlined"
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Search sections"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{
            marginTop: '10px',
            marginBottom: '16px',
            "& .MuiInputBase-root": {
              height: 40,
              backgroundColor: 'white',
            },
            "& .MuiOutlinedInput-input": {
              height: 40,
              width: '100%',
            },
          }}
        />
        <Select
          fullWidth
          value={selectedSectionDisplayName}
          onChange={handleSectionChange}
          displayEmpty
          sx={{ height: '40px', backgroundColor: 'white', marginBottom: '16px' }}
        >
          <MenuItem disabled value="">
            <em>Select a section</em>
          </MenuItem>
          {filteredSections.map((section, index) => (
            <MenuItem key={index} value={section.sectionDisplayName}>
              {section.sectionDisplayName}
            </MenuItem>
          ))}
        </Select> */}
            <ReactSelect
                value={selectedSection}
                onChange={handleSectionChange}
                options={availableSections}
                placeholder="Select Section"
                isClearable
                isSearchable
                styles={{
                  control: (base) => ({
                    ...base,
                    height: 40,
                    backgroundColor: 'white',
                    marginTop: '5px',
                  }),
                  menu: (base) => ({
                    ...base,
                    zIndex: 100,
                  }),
                }}
           />

        <InputLabel sx={{ mt: 2 }}>Field</InputLabel>
        {/* <Select
          fullWidth
          value={selectedField}
          onChange={handleFieldChange}
          displayEmpty
          sx={{ height: '40px', backgroundColor: 'white', marginBottom: '16px' }}
        >
          <MenuItem value="" disabled>
            <em>Select a field</em>
          </MenuItem>
          {availableFields.map((field, index) => (
            <MenuItem key={index} value={field.name}>
              {field.name}
            </MenuItem>
          ))}
        </Select> */}
          <ReactSelect
                value={selectedField}
                onChange={handleFieldChange}
                options={availableFields}
                placeholder="Select Field"
                isClearable
                isSearchable
                styles={{
                  control: (base) => ({
                    ...base,
                    height: 40,
                    backgroundColor: 'white',
                    marginTop: '5px',
                  }),
                  menu: (base) => ({
                    ...base,
                    zIndex: 100,
                  }),
                }}
           />

        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: '20px'}}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={!selectedSection || !selectedField}
            sx={{
              backgroundColor: 'var(--button-color)',
              color: 'white',
              fontWeight: 'normal',
              textTransform: 'none',
              ":hover": {
                backgroundColor: 'var(--button-hover-color)',
              },
            }}
          >
            Update
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={removedependent}
            sx={{
              backgroundColor: 'var(--button-color-red)',
              color: 'white',
              fontWeight: 'normal',
              textTransform: 'none',
              ":hover": {
                backgroundColor: 'var(--button-hover-color-red)',
              },
            }}
          >
            Remove
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default SectiondependentSelector;