import React, { useState, useEffect, useRef } from 'react';
import { 
  Box, Button, IconButton, List, ListItem, ListItemText, Typography, 
  Container, Toolbar, Paper, Table, TableBody, TableCell, TableContainer, 
  TableHead, TableRow, TextField, InputAdornment , Divider, Tooltip
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ChecklistIcon from '@mui/icons-material/Checklist';
import SearchIcon from '@mui/icons-material/Search';
import apiGatewayService from '../../common/services/apiGatewayService';
import localStorageService from '../../common/services/localStorage.service';
import utilityFunctions from '../../common/services/utilityFunctions';
import CircularLoader from '../Loader/Loader';
import FieldOptionsBuilder from '../FormBuilder/FieldOptionsBuilder/FieldOptionsBuilder';
import SimpleFieldOptionsBuilder from '../FormBuilder/SimpleFieldOptionsBuilder/SimpleFieldOptionsBuilder';
import { OptionsJSONTemplate } from '../../common/configurations/json-configurations';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ImportOptionsModal from '../ImportOptionsModal/ImportOptionsModal';
import SimpleAdvancedPopup from '../SimpleAdvancedPopup/SimpleAdvancedPopup';
import ApiIntegrationSelectorOption from '../ApiIntegrationSelectorOption/ApiIntegrationSelectorOption';
import CustomAlert from '../CustomAlert/CustomAlert';
import DeleteConfirmation from '../DeleteConfirmation/DeleteConfirmation';
import { useNavigate } from 'react-router-dom';
import LockIcon from '@mui/icons-material/Lock';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import "ag-grid-community/styles/ag-theme-material.css"; // Optional Theme applied to the Data Grid
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional Theme applied to the Data Grid
import TableActions from '../TableActions/TableActions';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import RefreshIcon from '@mui/icons-material/Refresh';

const tableColumnToKeyMapping = {
  'Name': "optionName",
  "Description": "optionDescription"
};
  

const Options = (props) => {
  const [options, setOptions] = useState([]);
  const [isAddNew, setIsAddNew] = useState(false);
  const [isAddNewSimple, setIsAddNewSimple] = useState(false);
  const [isImport, setIsImport] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [viewIndex, setIsViewIndex] = useState(null);
  const [cognitoUser, setCognitoUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isOnlyReadAccess, setIsOnlyReadAccess] = useState(false);
  const [open, setOpen] = useState(false);
  const [filterOptionName, setFilterOptionName] = useState('');
  const [filterOptionDescription, setFilterOptionDescription] = useState('');
  const [importModalOpen, setImportModalOpen] = useState(false);
  const [importedOptions, setImportedOptions] = useState(null);
  const [isImportedOption, setIsImportedOption] = useState(false);
  const [currentOption, setCurrentOption] = useState(null);
  const [popupOpen, setPopupOpen] = useState(false); 
  const [isEditSimple, setIsEditSimple] = useState(false);
  const [isEditAdvanced, setIsEditAdvanced] = useState(false); 
  const [isAddApi, setIsAddApi] = useState(false); 
  const [isURLModalOpen, setIsURLModalOpen] = useState(false); 
  const [apiIntegration, setApiIntegration] = useState(null);
  const [isEditApi, setIsEditApi] = useState(false);
  const [isViewSimple, setIsViewSimple] = useState(false);  // New state for viewing simple options
  const [isViewAdvanced, setIsViewAdvanced] = useState(false);  // New state for viewing advanced options
  const [isViewApi, setIsViewApi] = useState(false);  // New 
  const [displayAlert, setDisplayAlert] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [optionToDelete, setOptionToDelete] = useState(null);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [sortKey, setSortKey] = useState(null);
  const gridRef = useRef(null); // Ref for accessing the grid API
  const [allSelectChecked, setAllSelectChecked] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedSingleForm, setSelectedSingleForm] = useState(null);
  const pagination = true;
  const paginationPageSize = 10;
  const paginationPageSizeSelector = [10, 100, 1000];

  const handleSelectedAll = (e) => {
    console.log(e.target.checked);
    const isChecked = !allSelectChecked;
    // setSelectAll(isChecked); // Update state
    if (gridRef.current) {
        const api = gridRef.current.api;

        if (isChecked) {
            setAllSelectChecked(true);

            // Select rows on the current pagination page
            api.forEachNodeAfterFilterAndSort((node) => {
                if (node.rowIndex >= api.paginationGetCurrentPage() * api.paginationGetPageSize() &&
                    node.rowIndex < (api.paginationGetCurrentPage() + 1) * api.paginationGetPageSize()) {
                    node.setSelected(true);
                }
            });
        } else {
            setAllSelectChecked(false);

            // Deselect rows on the current pagination page
            api.forEachNodeAfterFilterAndSort((node) => {
                if (node.rowIndex >= api.paginationGetCurrentPage() * api.paginationGetPageSize() &&
                    node.rowIndex < (api.paginationGetCurrentPage() + 1) * api.paginationGetPageSize()) {
                    node.setSelected(false);
                }
            });
        }

        // Access selected rows if needed
        const selectedRows = api.getSelectedRows();
        const currSelectedIds =  selectedRows.map((r)=>r.optionId);
        setSelectedIds(currSelectedIds);
        console.log("Currently selected rows:", selectedRows);
    }
};

const onRowSelected = () => {
    if (gridRef.current) {
      const api = gridRef.current.api;
      const currentlySelectedRows = api.getSelectedRows(); // Get all selected rows
    //   setSelectedRows(currentlySelectedRows); // Update state
      const selectedIds = currentlySelectedRows.map((m)=>m.optionId);
      console.log("Selected Rows:", selectedIds);
      setSelectedIds(selectedIds);
    }
  };


  const [colDefs, setColDefs] = useState([
    {
      // cellRenderer: put your components here
      // valueGetter: p => p.data.formName+ ' '+p.data.status, // if you want concatenated values
      field: "optionName",
      headerName: "Name",
      flex: 1,
      filter: true,
      floatingFilter: true,
      checkboxSelection: true,
      sortable: true,
    //   pinned: 'left'
    },
    {
      field: "optionDescription",
      headerName: "Description",
      flex: 1,
      filter: true,
      floatingFilter: true,
    },
    {
        field: 'Options',
        flex: 1,
        cellRenderer: (params) => (
            <TableActions
              handleView={() => handleView(params.data)}
              handleEdit={() => handleEdit(params.data)}
              openDeleteModal={() => openDeleteModal(params.data)}
              index={params.data}
              isOnlyReadAccess={isOnlyReadAccess}
            />
          ),
    }
  ]);

  const navigate = useNavigate();

  useEffect(() => {
    const getOptions = async (user) => {
      try {
        const response = await apiGatewayService.getOptionsData();
        const dataWithUpdatedKeyAndContent = response.data;
        setOptions(dataWithUpdatedKeyAndContent);
      } catch (error) {
        if (error.response && error.response.status === 403) {
          console.log("Forbidden error detected, redirecting to login...");
          navigate('/');
        } else {
          console.error('Error fetching options:', error);
        }
      }
      setIsLoading(false);
    };
    const user = localStorageService.getObject('user');
    setCognitoUser(user);
    setIsOnlyReadAccess(utilityFunctions.checkIfOnlyReadAccess(user));
    getOptions(user);
  }, []);

  const handleOpen = () => setOpen(true);
  const handleClose = async () => {
    setOpen(false);
    await getAllOptions();
    setAllOptionsFalse();
  };

  const getAllOptions = async () => {
    try {
      const response = await apiGatewayService.getOptionsData();
      const dataWithUpdatedKeyAndContent = response.data;
      setOptions(dataWithUpdatedKeyAndContent);
    } catch (error) {
      console.error('Error fetching options:', error);
    }
  };

  const setAllOptionsFalse = () => {
    setIsAddNew(false);
    setIsAddNewSimple(false);
    setIsImport(false);
    setIsEdit(false);
    setIsViewIndex(null);
    setImportModalOpen(false);
    setIsImportedOption(false);
    setPopupOpen(false);
    setIsEditSimple(false);
    setIsEditAdvanced(false);
    setIsAddApi(false);
    setIsEditApi(false);
    setIsURLModalOpen(false);
    setIsViewSimple(false);
    setIsViewAdvanced(false);
    setIsViewApi(false);
    setIsDeleteModalOpen(false);
    setOptionToDelete(null);
  };

  const handleAddNew = () => {
    setAllOptionsFalse();
    setPopupOpen(true);
  };

  const handleImport = () => {
    setAllOptionsFalse();
    setIsImport(true);
    setImportModalOpen(true);
  };

  const handleEdit = async (row) => {
    setAllOptionsFalse();
    setIsLoading(true);
    const selectedOption = row;
    console.log("selectedOption: ", selectedOption);

    if (selectedOption.type === 'simple') {
      await getOptionDataById(selectedOption.optionId, selectedOption.optionName, 'simple');
      setTimeout(() => {
        setIsEditSimple(true);
        // setIsViewIndex(index);
        setIsLoading(false);
      }, 500);
    } 
    else if (selectedOption.type === 'api') {
      await getOptionDataById(selectedOption.optionId,selectedOption.optionName, 'api');
      setTimeout(() => {
        setIsEditApi(true);
        setIsURLModalOpen(true);
        // setIsViewIndex(index);
        setIsLoading(false);
      }, 500);
    } 
    else {
      await getOptionDataById(selectedOption.optionId, selectedOption.optionName, 'advance',  selectedOption.optionDescription); 
      setTimeout(() => {
        setIsEditAdvanced(true);
        // setIsViewIndex(index);
        setIsLoading(false);
      }, 500);
    }
  };

  const handleView = async (row) => {
    setAllOptionsFalse();  // Reset all viewing/editing states
    setIsLoading(true);

    console.log("selected option index: ", row.optionId);

    const selectedOption = row;

    console.log("selectedOption ", selectedOption);

    if (selectedOption.type === 'simple') {
      await getOptionDataById(selectedOption.optionId, selectedOption.optionName, 'simple');
      setTimeout(() => {
        setIsViewSimple(true);  // Set view state for simple
        // setIsViewIndex(index);
        setIsLoading(false);
      }, 500);
    } else if (selectedOption.type === 'api') {
      await getOptionDataById(selectedOption.optionId, selectedOption.optionName, 'api');
      setTimeout(() => {
        setIsViewApi(true);  // Set view state for API
        // setIsViewIndex(index);
        setIsLoading(false);
      }, 500);
    } else {
      await getOptionDataById(selectedOption.optionId, selectedOption.optionName, 'advance', selectedOption.optionDescription);
      setTimeout(() => {
        setIsViewAdvanced(true);  // Set view state for advanced
        // setIsViewIndex(index);
        setIsLoading(false);
      }, 500);
    }
  };
  
    // Open delete modal and set the field to delete
    const openDeleteModal = (index) => {
      setOptionToDelete(filteredOptions[index]);
      setIsDeleteModalOpen(true);
    };

  const handleConfirmDelete = async (index) => {
    if (selectedIds.length>0) {
      try {
        const postData = {
          optionNames: selectedIds,
        };
        await apiGatewayService.deleteOptionsData(postData);
        setAllOptionsFalse();
        // const optionsNew = options.filter((_, index2) => index2 !== index);
        // setOptions(optionsNew);
        setDisplayAlert(true);
        setAlertMessage('Option deleted successfully!');
        setAlertType('success');
        setTimeout(() => setDisplayAlert(false), 10000);
        await getAllOptions();

      } catch (error) {
        console.error("Error:", error);
        // alert('Option cannot be deleted');
        setDisplayAlert(true);
        setAlertMessage('Error in deleting option!');
        setAlertType('error');
        setTimeout(() => setDisplayAlert(false), 10000);
      }
    } else {
        // setAllFalse();
        setDisplayAlert(true);
        setAlertMessage('Select an option to delete!');
        setAlertType('success');
        setTimeout(() => setDisplayAlert(false), 6000);
    }
    handleClose();
  };

  const getOptionDataById = async (optionId, optionName, type, optionDescription) => {
    console.log("optionName: ", optionDescription);
    try {
      const response = await apiGatewayService.getOptionsDataById(decodeURIComponent(optionId), type);
      setCurrentOption({
        title: optionName,
        optionId,
        values: response.data,
        optionDescription,
      });
    } catch (error) {
      console.log("Error in getting option by Id: ", error);
    }
  };

  const handleUpload = async (jsonContent) => {
    const isValid = validateJson(jsonContent);
    if (isValid) {
      setAllOptionsFalse();
      setImportedOptions({
        title: '',
        values: jsonContent
      });
      setImportModalOpen(false);
      setTimeout(setIsImportedOption(true), 300);
    } else {
      alert("Invalid JSON file. Please ensure the file follows the correct template.");
    }
  };

  const validateJson = (jsonContent) => {
    return utilityFunctions.validateUploadedOptionsJson(OptionsJSONTemplate, jsonContent);
  };

  const handlePopupSelect = (selection) => {
    setPopupOpen(false);
    if (selection === 'api') {
      setIsAddApi(true);
      setIsURLModalOpen(true);
    } else if (selection === 'simple') {
      setIsAddNewSimple(true);
    } else {
      setIsAddNew(true);
    }
    setOpen(true);
  };

  const handleCustomURLSubmit = (selectedApiIntegration) => {
    setApiIntegration(selectedApiIntegration);
    setIsURLModalOpen(false);
    setIsAddApi(false);
  };
  
  useEffect(() => {
    const applyFilters = () => {
      let filteredOptions = options.filter(option => {
        const optionNameMatches = !filterOptionName || option.optionId.toLowerCase().includes(filterOptionName.toLowerCase());
        const optionDescriptionMatches = !filterOptionDescription || option.optionDescription?.toLowerCase().includes(filterOptionDescription.toLowerCase());
      
        return optionNameMatches && optionDescriptionMatches;
      });
      if (sortKey){
        filteredOptions = utilityFunctions.sortByKey(filteredOptions, sortKey);
      }

      setFilteredOptions(filteredOptions);
    }

    applyFilters();

  }, [options, sortKey, filterOptionName, filterOptionDescription ]);

  const sortByColumn = (column) => {
    if (sortKey === tableColumnToKeyMapping[column]){
      setSortKey(null);
    } else{
      setSortKey(tableColumnToKeyMapping[column]);
    }
  };

  const handleRefresh = async () => {
    setIsLoading(true);
    await handleClose();
    setIsLoading(false);
  }
  

  return (
<Container disableGutters id = 'HELLO CHECK' sx={{pl:0, pr: 0, margin: 0, minWidth: '100%', height: '90%', display: 'flex', flexDirection: 'column'}}>      
    <Divider sx={{ borderBottomWidth: '2px', borderColor: 'var(--app-color)' }} />
    {(!isEdit && !isAddNew && !isAddNewSimple && !isEditAdvanced && !isEditSimple && !isAddApi  && !isViewSimple && !isViewAdvanced && !isViewApi) && 
     <Toolbar
        sx={{
            backgroundColor: 'white',
            borderRadius: "10px",
            borderEndEndRadius: '0px',
            borderEndStartRadius: '0px',
            padding: '0px',
            color: 'white',
            position: 'sticky',
            top: 0,
            zIndex: 1000,
            justifyContent: 'space-between',
        }} > 
            <>
            <Box sx={{ display: 'flex', alignItems: 'center', padding: 0, ml: '-24px' }}>
                {/* Select All Button */}
                <Button
                sx={{
                    color: '#2b4252',
                    p: '5px',
                    pt: 0,
                    pb: 0,
                    backgroundColor: 'transparent',
                    textTransform: 'none',
                    ':hover': {
                    backgroundColor: 'rgba(43, 66, 82, 0.1)',
                    },
                    '&.Mui-disabled': {
                    color: 'grey',
                    },
                    '.MuiButton-startIcon': {
                    marginRight: '2px',
                    },
                }}
                startIcon={
                    allSelectChecked ? (
                    <CheckBoxIcon />
                    ) : (
                    <CheckBoxOutlineBlankIcon />
                    )
                }
                onClick={handleSelectedAll}
                disabled={isOnlyReadAccess}
                >
                <Box sx={{ marginTop: '2px' }}>Select All</Box>
                </Button>

                {/* Delete Button */}
                <Button
                sx={{
                    ml: '5px',
                    color: '#CC3824',
                    p: '5px',
                    pt: 0,
                    pb: 0,
                    backgroundColor: 'transparent',
                    textTransform: 'none',
                    ':hover': {
                    backgroundColor: 'rgba(204, 56, 36, 0.1)',
                    },
                    '&.Mui-disabled': {
                    color: 'grey',
                    },
                    '.MuiButton-startIcon': {
                    marginRight: '2px',
                    },
                }}
                startIcon={<DeleteIcon />}
                onClick={() => openDeleteModal(-1)}
                disabled={isOnlyReadAccess}
                >
                <Box sx={{ marginTop: '2px' }}>Delete</Box>
                </Button>
            </Box>
            <Box>
                <Tooltip title="Refresh">
                <IconButton
                    sx={{
                    color: 'var(--app-color)',
                    p: '5px',
                    mr: '10px',
                    }}
                    aria-label="refresh"
                    onClick={handleRefresh}
                    disabled={isOnlyReadAccess}
                >
                    <RefreshIcon sx={{ fontSize: '34px', fontWeight: 'bold' }} />
                </IconButton>
                </Tooltip>

                <Button
                sx={{
                    marginRight: '-25px',
                    backgroundColor: 'var(--app-color)',
                    width: '130px',
                    ":hover": {
                    backgroundColor: 'var(--button-hover-color)',
                    },
                    '&.Mui-disabled': {
                    backgroundColor: '#e0e0e0',
                    color: 'grey',
                    },
                }}
                color="inherit"
                startIcon={<AddIcon />}
                onClick={handleAddNew}
                disabled={isOnlyReadAccess}
                >
                Add New
                </Button>
            </Box>
            </>
      </Toolbar> }
      {
      displayAlert &&
      <CustomAlert isOpen={displayAlert} message={alertMessage} type={alertType}></CustomAlert>
      }

      {
        isDeleteModalOpen &&
        <DeleteConfirmation
        isOpen={isDeleteModalOpen}
        handleClose={handleClose}
        submitDelete={handleConfirmDelete}
      />
      }

      {isLoading && <CircularLoader />}
      {(!isLoading && !isEdit && !isAddNew && !isAddNewSimple && !isEditAdvanced && !isEditSimple && !isAddApi && !isImportedOption && !isViewSimple && !isViewAdvanced && !isViewApi) &&
        <div
              className="ag-theme-quartz" // Or replace with your custom theme name
              style={{
                  overflow: 'auto',
                  height: '75vh',
                  minWidth: '1000px',
                  // '--ag-foreground-color': 'rgb(14, 68, 145)', // Grid text color
                  // '--ag-background-color': 'rgb(241, 247, 255)', // Grid background color
                  '--ag-header-background-color': 'rgb(228, 237, 250)', // Header row background color
                  '--ag-row-hover-color': 'rgb(216, 226, 255)', // Row hover color
              }}
          >
              <AgGridReact
                  ref={gridRef} // Attach the ref
                  onRowSelected={onRowSelected}
                  rowData={options}
                  columnDefs={colDefs}
                  rowSelection="multiple"
                  domLayout="normal" // Dynamically adjusts the height of the grid based on its content
                  // suppressHorizontalScroll={false} // Ensures horizontal scrolling is enabled
                  suppressColumnVirtualisation={false} // Ensure columns render correctly for scrolling
                  // suppressRowTransform={true} // Fixes issues with row scrolling
                  pagination={pagination}
                  paginationPageSize={paginationPageSize}
              // paginationPageSizeSelector={paginationPageSizeSelector}
              />
          </div>
      }

      {/* {(!isLoading && !isImportedOption && !isEdit && !isAddNew && !isAddNewSimple && !isEditAdvanced && !isEditSimple && !isAddApi) &&
        <List sx={{ flexGrow: 1, p: 0 }}> 
          {filteredOptions.map((option, index) => (
            <ListItem key={index} sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, pt: '6px', pb: '6px', pl: 0, pr: 0 }}>
              <Paper sx={{ flexGrow: 1, p: 1 }}>
                <ListItemText primary={option.optionId} />
              </Paper>
              <IconButton aria-label="view" onClick={() =>
                handleEdit(index)} disabled={isOnlyReadAccess}>
                <VisibilityIcon />
              </IconButton>
              <IconButton sx={{ color: '#2b4252' }} aria-label="edit" onClick={() => handleEdit(index)}>
                <EditIcon />
              </IconButton>
              <IconButton sx={{ color: '#CC3824' }} aria-label="delete" onClick={() => handleDelete(index)} disabled={isOnlyReadAccess}>
                <DeleteIcon />
              </IconButton>
            </ListItem>
          ))}
        </List>
      } */}

      {isAddNew && <FieldOptionsBuilder handleClose={handleClose} />}
      {isAddNewSimple && <SimpleFieldOptionsBuilder handleClose={handleClose} />}
      {isEditSimple && <SimpleFieldOptionsBuilder option={currentOption} handleClose={handleClose} isEdit = {true}  />}
      {isEditAdvanced && <FieldOptionsBuilder option={currentOption} handleClose={handleClose} isEdit = {true} />}
      {isImportedOption && <FieldOptionsBuilder option={importedOptions} handleClose={handleClose} />}

      {isViewSimple && (
        <SimpleFieldOptionsBuilder
          option={currentOption}
          handleClose={setAllOptionsFalse}
          isView={true}  // Set to true for view mode
        />
      )}

      {isViewAdvanced && (
        <FieldOptionsBuilder
          option={currentOption}
          handleClose={setAllOptionsFalse}
          isView={true}  // Set to true for view mode
        />
      )}

      {isViewApi && (
        <ApiIntegrationSelectorOption
          isOpen={true}
          handleClose={setAllOptionsFalse}
          apiIntegration={currentOption?.values?.apiIntegration || null}
          titleInput={currentOption?.title || null}
          isView={true}  // Set to true for view mode
        />
      )}
      <ImportOptionsModal
        open={importModalOpen}
        handleClose={() => setImportModalOpen(false)}
        handleUpload={handleUpload}
        jsonTemplate={OptionsJSONTemplate}
      />
      <SimpleAdvancedPopup
        open={popupOpen}
        onClose={() => setPopupOpen(false)}
        onSelect={handlePopupSelect}
      />
      {(isAddApi) && (
        <ApiIntegrationSelectorOption
          isOpen={isURLModalOpen}
          handleClose={handleClose}
          submitSelection={handleCustomURLSubmit}
          apiIntegration={apiIntegration || currentOption || null}
        />
      )}
      {(isEditApi) && (
        <ApiIntegrationSelectorOption
          isOpen={isURLModalOpen}
          handleClose={handleClose}
          submitSelection={handleCustomURLSubmit}
          apiIntegration={currentOption?.values?.apiIntegration || null}
          titleInput={currentOption?.title || null}
          optionId = {currentOption?.optionId || null}
          isEditInput = {true}
        />
      )}
    </Container>
  );
};

export default Options;