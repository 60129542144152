import React, { useState, useEffect } from 'react';
import classes from './OptionsDependentSelector.module.css';
import apiGatewayService from '../../../common/services/apiGatewayService';
import localStorageService from '../../../common/services/localStorage.service';
import utilityFunctions from '../../../common/services/utilityFunctions';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { Button, Drawer, List, ListItem, ListItemText, IconButton, AppBar, Toolbar, Typography, Box, CssBaseline, ListItemIcon, Divider, Avatar } from '@mui/material';
import validationService from '../../../common/validationService.service';
import { CircularProgress } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ReactSelect from 'react-select';

// const referenceFieldValueHelpKeys = ['label', 'value'];
const referenceFieldValueHelpKeys = [
  "VALID_VALUE_ID",
  "VALID_VALUE_SHORT_DESC",
  "VALID_VALUE_DESCRIPTION",
  "PARAMETER_ID",
  "PARAMETER",
  "FILTER1",
  "FILTER2",
  "FILTER3",
  "FILTER4",
  "COUNTER",
  "ENTITY",
  "VERSION",
  "ATTRIBUTE1",
  "ATTRIBUTE2",
  "VALID_VALUE_ADDITIONAL_TEXT"
]

const OptionsDependentSelector = (props) => {
  const [sections, setSections] = useState([]);
  const [availableFields, setAvailableFields] = useState([]);
  const [selectedField, setSelectedField] = useState('');
  const [fieldSelected, setFieldsSelected] = useState(false);
  const [dependentTitle, setDependentTitle] = useState('');
  const [valueHelpFilters, setValueHelpFilters] = useState([]);
  const [valueHelpFiltersSections, setValueHelpFiltersSections] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // const getFieldValues = (referenceField) => {
  //   console.log('Selected Field Name: ', referenceField);
  //   const currField = availableFields.filter((field)=>field.name === referenceField);
  //   const values = Object.keys(currField.field).map((c) => {
  //       if (c!=='api') {
  //         return currField.field[c]
  //       };
  //   });
  //   debugger
  //   console.log("selected field values: ", values);
  //   return values;
  // };

  useEffect(() => {
    console.log("Props for valuehelps filters: ", props.field);
    console.log("INSIE OPTIONS DEPENDENT SELECTOR!!!!!!")
    // const getOptions = async (user) => {
    //   try {
    //     console.log('Trying to get Fields data')
    //     const response = await apiGatewayService.getFieldsData();
    //     const dataFiltered = response.data.filter((d)=>(d.Content!==""));
    //     const dataWithUpdatedKeyAndContent = utilityFunctions.getKeyAndContentFields(dataFiltered);
    //     console.log("response data updated: ", dataWithUpdatedKeyAndContent); 
    //     setAvailableFields(dataWithUpdatedKeyAndContent);
    //   } catch (error) {
    //     console.error('Error fetching fields:', error);
    //   }
    // };
    // const user = localStorageService.getObject('user');
    // console.log("currentCognito User: ", user);
    // getOptions(user);
    const getAllSections = async () => {
      try {
        setIsLoading(true);
        // const response = await apiGatewayService.getSectionsData();
        // const dataFiltered = response.data.filter((d)=>(d.Content!==""));
        // const dataWithUpdatedKeyAndContent = utilityFunctions.getKeyAndContent(dataFiltered);
        const response = await apiGatewayService.getSectionsData();
        // console.log("get sections response: ", response);
        // const dataWithUpdatedKeyAndContent = response.data;
        // dataWithUpdatedKeyAndContent.sort((a, b) => {
        //   if (a.sectionName < b.sectionName) {
        //     return -1;
        //   }
        //   if (a.sectionName > b.sectionName) {
        //     return 1;
        //   }
        //   return 0;
        // });
        // console.log("response data updated: ", dataWithUpdatedKeyAndContent); 
        // setSections(dataWithUpdatedKeyAndContent);
        const updatedSections = utilityFunctions.updateSectionNamesWithDescription(response.data);
        const updatedSectionsSorted = utilityFunctions.sortByKey(updatedSections, 'sectionDisplayName');
        const formattedSections = updatedSectionsSorted.map((section) => ({
          ...section,
          value: section.sectionDisplayName,
          label: section.sectionDisplayName,
        }));
        setSections(formattedSections);
        if(props.field.api.valueHelpFilters.length>0){
          // await setValueHelpFiltersOnInput(props.field.api.valueHelpFilters, dataWithUpdatedKeyAndContent);
          await setValueHelpFiltersOnInput(props.field.api.valueHelpFilters, formattedSections);
        };
      } catch (error) {
        console.error('Error fetching fields:', error);
      }
      setIsLoading(false);
    };
    getAllSections();
  }, []);

  const setValueHelpFiltersOnInput = async (valueHelpFilters, sections) => {
    console.log("valueHelpFiltersTemp On Input: ", valueHelpFilters);

    const valueHelpFiltersTemp = [];
    const valueHelpFiltersSectionsTemp = [];

    for(const filter of valueHelpFilters) {
      console.log("valueHelpFiltersTemp filter: ", filter);
      const referenceSectionName = filter.referenceSectionName ? filter.referenceSectionName : "";
      const referenceSectionDescription = filter.referenceSectionDescription ? filter.referenceSectionDescription : "";
      const referenceField = filter.referenceField ? filter.referenceField : "";
      const valueHelpFilter = filter.valueHelpFilter ? filter.valueHelpFilter : "";
      const referenceFieldValueHelpKey = filter.referenceFieldValueHelpKey ? filter.referenceFieldValueHelpKey : "";
      const matchingValue = filter.matchingValue ? filter.matchingValue : "";
      const staticValue = filter.staticValue ? filter.staticValue : "";
      const tempSection =  sections.find(section => (section.sectionName === referenceSectionName && section.sectionDescription === referenceSectionDescription));
      const sectionId = tempSection?.sectionId; 
      const section = await getSectionById(sectionId);
      const fields = section.fields;
      // const referenceFields = fields.map((f)=>f.name);
      console.log("section: ", sectionId);
      console.log("section: ", section);
      console.log("section: ", fields);
      const field = fields.find(field =>  field.name === referenceField);
      let valueHelpsData = [];
      if (field.api) {
        valueHelpsData = await fetchValueHelpsData(field.api);
      };
      valueHelpFiltersTemp.push({
        "referenceSection": tempSection,
        "referenceSectionName": referenceSectionName,
        "referenceSectionDescription": referenceSectionDescription,
        "referenceField": referenceField,
        "valueHelpFilter": valueHelpFilter,
        "referenceFieldValueHelpKey": referenceFieldValueHelpKey,
        "matchingValue":  matchingValue,
        "staticValue"  : staticValue
      });
      valueHelpFiltersSectionsTemp.push({
          // referenceSectionName: sections.map((section)=>section.sectionName),
          referenceSectionName: sections,
          referenceField: fields,
          valueHelpFilter: ["FILTER1","FILTER2","FILTER3","FILTER4"],
          referenceFieldValueHelpKey: referenceFieldValueHelpKeys,
          matchingValue:  valueHelpsData.map((v)=>v['VALID_VALUE_DESCRIPTION']),
          staticValue: valueHelpsData.map((v)=>v['VALID_VALUE_DESCRIPTION'])
      });
    };

    console.log("valueHelpFiltersTemp : ", valueHelpFiltersTemp);

    setValueHelpFilters(Array.from(valueHelpFiltersTemp));
    setValueHelpFiltersSections(Array.from(valueHelpFiltersSectionsTemp))
  };

  const handleChange = (event) => {
    setSelectedField(event.target.value);
    setFieldsSelected(true);
  };

  const handleSelectSection = async (index, e) => {
    if (e!==null){
      const sectionDisplayName = e;
      console.log("sectionDisplayName: ", sectionDisplayName);
      const tempSection = sections.find(section => section.sectionDisplayName === sectionDisplayName);
      const currentSection =  await getSectionById(tempSection?.sectionId);
      const fields = currentSection.fields;
      console.log("fields: ", fields);
      valueHelpFilters[index].referenceSection = tempSection;
      valueHelpFiltersSections[index].referenceField = fields;
      console.log("valueHelpFilters: ", valueHelpFilters);
      console.log("valueHelpFiltersSections: ", valueHelpFiltersSections);
      setValueHelpFiltersSections(Array.from(valueHelpFiltersSections));
      handleSubmit();
    }
  };

  const handleSelectField = async (index, e) => {
    const referenceFieldName = e.target.value;
    console.log("referenceFieldName: ", referenceFieldName);
    valueHelpFilters[index].referenceField = referenceFieldName;
    // const referenceSectionName = valueHelpFilters[index].referenceSectionName;
    // const sectionsCurrent = await getSectionById(referenceSectionName);
    // const fields =  sectionsCurrent.find(section => section.sectionName === referenceSectionName).fields;
    // const fields = sectionsCurrent.fields;
    // const field = fields.find(field =>  field.name === referenceField);
    
    const field = valueHelpFiltersSections[index].referenceField.find((field)=>field.name === referenceFieldName)
    let valueHelpsData = [];

    if (field?.api) {
      valueHelpsData = await fetchValueHelpsData(field.api);
    };

    console.log("value helps data for field: ", valueHelpsData);

    valueHelpFiltersSections[index].matchingValue = valueHelpsData.map((v)=>v['VALID_VALUE_DESCRIPTION']);
    valueHelpFiltersSections[index].staticValue = valueHelpsData.map((v)=>v['VALID_VALUE_DESCRIPTION']);

    setValueHelpFiltersSections(Array.from(valueHelpFiltersSections));
    handleSubmit();

  };

  const handleSelectvalueHelpFilter = (index, e) => {    
    const valueHelpFilter = e.target.value;
    valueHelpFilters[index].valueHelpFilter = valueHelpFilter;
    setValueHelpFilters(Array.from(valueHelpFilters));
    handleSubmit();
  };

  const handleSelectreferenceFieldValueHelpKey = (index, e) => {
    const referenceFieldValueHelpKey = e.target.value;
    valueHelpFilters[index].referenceFieldValueHelpKey = referenceFieldValueHelpKey;
    setValueHelpFilters(Array.from(valueHelpFilters));
    handleSubmit();
  };

  const handleSelectMatchingValue = (index, e) => {
    const matchingValue = e.target.value;
    valueHelpFilters[index].matchingValue = matchingValue;
    setValueHelpFilters(Array.from(valueHelpFilters));
    handleSubmit();
  };

  const handleSelectStaticValue = (index, e) => {
    const staticValue = e.target.value;
    valueHelpFilters[index].staticValue = staticValue;
    setValueHelpFilters(Array.from(valueHelpFilters));
    handleSubmit();
  };

  const handleAddValue = () => {
    valueHelpFilters.push({
      "referenceSection": null,
      "referenceField": "",
      "valueHelpFilter": "",
      "referenceFieldValueHelpKey": "",
      "matchingValue":  "",
      "staticValue"  : ""
    });

    console.log("section: ", sections);

    valueHelpFiltersSections.push({
        // referenceSectionName: sections.map((section)=>section.sectionName),
        referenceSectionName: sections,
        referenceField: [],
        valueHelpFilter: ["FILTER1","FILTER2","FILTER3","FILTER4"],
        referenceFieldValueHelpKey: referenceFieldValueHelpKeys,
        matchingValue:  [],
        staticValue: []
    })
    console.log("valueHelpFiltersSections: ", valueHelpFiltersSections);

    setValueHelpFilters(Array.from(valueHelpFilters));
    setValueHelpFiltersSections(Array.from(valueHelpFiltersSections));
  };

  const fetchValueHelpsData = async (api) => {
    try {
      const response = await apiGatewayService.getValueHelpsData(decodeURIComponent(api.url.split('/')[1]));
      return response.data;
    }
    catch(error){
      console.log("Error in fetching value helps data");
      return []
    }
  };

  const handleRemoveFilter = (index, e) => {
    valueHelpFilters.splice(index,1);
    valueHelpFiltersSections.splice(index,1);
    setValueHelpFiltersSections(valueHelpFiltersSections);
    setValueHelpFilters(Array.from(valueHelpFilters));
    handleSubmit();
  };

  const getUpdatedValueHelpFilters = () => {
      return valueHelpFilters.map(item => {
        // Create a copy of the item to avoid mutating the original object
        item['referenceSectionName'] = item.referenceSection.sectionName;
        item['referenceSectionDescription'] = item.referenceSection.sectionDescription;
        let updatedItem = { ...item };
        
        // Check if matchingValue is empty and remove the key if true
        if (!updatedItem.matchingValue) {
          delete updatedItem.matchingValue;
        }
        
        // Check if staticValue is empty and remove the key if true
        if (!updatedItem.staticValue) {
          delete updatedItem.staticValue;
        }

        delete updatedItem.referenceSection;
        return updatedItem;
      });
  };


  const handleSubmit = () => {
    console.log("This is the filters Data Submitted: ");
    const valueHelpFiltersToSubmit = getUpdatedValueHelpFilters();
    console.log("valueHelpFiltersToSubmit: ", valueHelpFiltersToSubmit);
    if (validationService.hasEmptyKeys(valueHelpFiltersToSubmit)) {
      // alert('Please select all filter value!');
      console.log("Do not submit the value help filters as empty values!");
    } else {
      props.submitValueHelpFilters(valueHelpFiltersToSubmit);
      console.log("valueHelpFilters: ", valueHelpFilters);
   };
  }

  const handleRemoveFilters = () => {
    console.log();
  };

  const getOptionName = (api) => {
    if(api.url) {
      return api.url.split('/')[1].split('.')[0]
    }
    return ''

  };

  
  const getSectionById = async (sectionId) => {
    try {
      // setIsLoading(true);
      const response =  await apiGatewayService.getSectionsDataById(sectionId);
      console.log('Get section by Id response: ', response);
      // setCurrentSection(response.data);
      return response.data;
    } catch(error) {
      console.log('Error in getting sections: ', error);
    }
    // setIsLoading(false);
  };



  return (
    <div className={classes.MainContainer}>
      {/* <h5>Value Help Filters</h5> */}
      { isLoading ? 
              (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <CircularProgress sx={{ color: 'var(--app-color)' }} />
            </div>) : (<div>
          {   ( (valueHelpFilters?.map((valueHelpFilter, index) => (
            <div key={index} className={classes.FieldContainer}>
              <Button
                onClick={(e)=> handleRemoveFilter(index, e)}
                // variant="contained"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: 0,
                  height: 'max-with',
                  background: 'transparent',
                  color: 'var(--app-color)',
                  cursor: 'pointer',
                  // borderRadius: '10px',
                  ':hover': {
                    backgroundColor: 'transparent',
                    color: '#2b4252',
                  },
                  marginLeft: '-10px'
                  // height: '58px'
                }}
              >
                <RemoveCircleIcon sx={{ marginLeft: '-10px', padding: 0, fontSize: 30, marginTop: 1, marginBottom: '0px', color: 'red', padding: 0, ':hover': { color: '#FB7777' } }} />
                <Typography sx={{ fontSize: 10, textTransform: 'none' }} variant="body2"></Typography>
              </Button>
              <div className={classes.ColumnContainer}>
                <label>{'Section'}</label>
                {/* <select
                  className={classes.ValueContainer}
                  value={valueHelpFilter.referenceSectionName}
                  onChange={(e) => handleSelectSection(index, e)}
                >
                  <option value=""></option>
                  {valueHelpFiltersSections[index]?.referenceSectionName.map((option, i) => (
                    <option key={i} value={option}>
                      {option}
                    </option>
                  ))}
                </select> */}
                
            <ReactSelect
                  value={valueHelpFilter.referenceSection}
                  onChange={(selectedOption) => handleSelectSection(index, selectedOption?.value)}
                  // onChange={handleSelectSection}
                  options={valueHelpFiltersSections[index].referenceSectionName}
                  placeholder="Select Section"
                  isClearable
                  isSearchable
                  styles={{
                    control: (base) => ({
                      ...base,
                      height: 40,
                      backgroundColor: 'white',
                      marginTop: '0px',
                      marginRight: '5px'
                    }),
                    menu: (base) => ({
                      ...base,
                      zIndex: 100,
                    }),
                  }}
            />
              </div>
              <div className={classes.ColumnContainer}>
                <label>{'Field'}</label>
                <select
                  className={classes.ValueContainer}
                  value={valueHelpFilter.referenceField}
                  onChange={(e) => handleSelectField(index, e)}
                >
                  <option value=""></option>
                  {valueHelpFiltersSections[index]?.referenceField.map((option, i) => (
                    <option key={i} value={option.name}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className={classes.ColumnContainer}>
                <label>{'Filter'}</label>
                <select
                  className={classes.ValueContainer}
                  value={valueHelpFilter.valueHelpFilter}
                  onChange={(e) => handleSelectvalueHelpFilter(index, e)}
                >
                  <option value="" disabled></option>
                  {valueHelpFiltersSections[index]?.valueHelpFilter.map((option, i) => (
                    <option key={i} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              <div className={classes.ColumnContainer}>
                <label>{'Value'}</label>
                <select
                  className={classes.ValueContainer}
                  value={valueHelpFilter.referenceFieldValueHelpKey}
                  onChange={(e) => handleSelectreferenceFieldValueHelpKey(index, e)}
                >
                  <option value=""></option>
                  {valueHelpFiltersSections[index]?.referenceFieldValueHelpKey.map((option, i) => (
                    <option key={i} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              {/* <div className={classes.ColumnContainer}>
                <label>{'Matching'}</label>
                <select
                  className={classes.ValueContainer}
                  value={valueHelpFilter.matchingValue}
                  onChange={(e) => handleSelectMatchingValue(index, e)}
                >
                  <option value=""></option>
                  {valueHelpFiltersSections[index]?.matchingValue.map((option, i) => (
                    <option key={i} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div> */}
              {/* <div className={classes.ColumnContainer}>
                <label>{'Static'}</label>
                <select
                  className={classes.ValueContainer}
                  value={valueHelpFilter.staticValue}
                  onChange={(e) => handleSelectStaticValue(index, e)}
                >
                  <option value=""></option>
                  {valueHelpFiltersSections[index]?.staticValue.map((option, i) => (
                    <option key={i} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div> */}
              {/* <div className={classes.ColumnContainer}>
                <label>{'Static'}</label>
                <input
                  type="text"
                  style={{ height: '40px' }}
                  className={classes.ValueContainer}
                  value={valueHelpFilter.staticValue}
                  onChange={(e) => handleSelectStaticValue(index, e)}
                />
              </div> */}
              <div>
              </div>
              {/* <div className={classes.ColumnContainer}>
                <label>{'Source Field'}</label>
                <select
                  className={classes.ValueContainer}
                  value={field.sourceField}
                  onChange={(e) => handleChangeSourceField(index, e)}
                >
                  {((['VALID_VALUE_SHORT_DESC', 'VALID_VALUE_ID', 'VALID_VALUE_DESCRIPTION'])).map((option, i) => (
                    <option key={i} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              <div className={classes.ColumnContainer}>
                <label>{'Target'}</label>
                <select
                  className={classes.ValueContainer}
                  value={field.target}
                  onChange={(e) => handleChangeTarget(index, e)}
                >
                  {['Filter1', 'Filter2', 'Filter3', 'Filter4'].map((option, i) => (
                    <option key={i} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div> */}
            </div>
          ))))}
          <Button sx={{
                width: '100%',
            backgroundColor: 'var(--app-color)',
            // marginRight: '-22px',
            marginLeft: '5px',
            // marginTop: '5px',
            color: 'white',
            ":hover": {
              backgroundColor: 'var(--button-hover-color)',
              // color: '#2b4252',
            },
            '&.Mui-disabled': {
              backgroundColor: '#e0e0e0',
              color: 'grey',
            },
          }} color="inherit" startIcon={<AddIcon />} onClick={handleAddValue} >
            Add Filter
          </Button>
        {/* <button className={classes.Button} onClick={handleAddValue}>Add</button> */}
          {/* <button className={classes.Button} onClick={handleSubmit}>Submit</button>
          <button className={classes.Button} onClick={handleRemoveFilters}>Remove</button> */}
        </div>
       )}
    </div>
  );
};

export default OptionsDependentSelector;
