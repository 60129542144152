import React, { useState } from 'react';
import { Modal, Box, Button, Typography } from '@mui/material';

const ImportFormsModal = ({ open, handleClose, submitJsonFile }) => {
  const [fileContent, setFileContent] = useState(null);
  const [fileName, setFileName] = useState(null);

  // Function to handle file upload and ensure it's a JSON file
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    
    if (file && file.type === "application/json") {
      const reader = new FileReader();
      reader.onload = function(e) {
        try {
          const json = JSON.parse(e.target.result); // Parse the JSON file
          setFileContent(json);
          setFileName(file.name);
        } catch (error) {
          console.error("Error parsing JSON file", error);
          alert("Invalid JSON file.");
        }
      };
      reader.readAsText(file); // Read the file as text
    } else {
      alert("Please upload a valid JSON file.");
    }
  };

  const handleSubmit = () => {
    if (fileContent) {
      submitJsonFile(fileContent); // Pass the JSON content to the parent component
    //   handleClose();
    } else {
      alert("No file uploaded or file format is incorrect.");
    }
  };

  const handleCancel = () => {
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleCancel}
      aria-labelledby="import-forms-modal-title"
      aria-describedby="import-forms-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 300,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography id="import-forms-modal-title" variant="h6" component="h2">
          Import Form
        </Typography>

        {/* Upload Button */}
        <Button
          component="label"
          sx={{
            mt: 2,
            width: '100%',
            backgroundColor: 'var(--button-color)',
            color: 'white',
            fontWeight: 'normal',
            textTransform: 'none',
            ":hover": {
              backgroundColor: 'var(--button-hover-color)',
            },
          }}
        >
          Upload JSON
          <input
            type="file"
            accept=".json"
            onChange={handleFileUpload}
            hidden
          />
        </Button>

        {/* Conditionally show the submit button when a file is loaded */}
        {fileContent && (
          <>
            <Typography variant="body2" sx={{ mt: 2, mb: 1 }}>
              {fileName} loaded successfully.
            </Typography>
            <Button
              onClick={handleSubmit}
              sx={{
                mt: 2,
                width: '100%',
                backgroundColor: 'var(--button-color)',
                color: 'white',
                fontWeight: 'normal',
                textTransform: 'none',
                ":hover": {
                  backgroundColor: 'var(--button-hover-color)',
                },
              }}
            >
              Submit
            </Button>
          </>
        )}

        <Button
          variant="outlined"
          onClick={handleCancel}
          sx={{
            mt: 2,
            width: '100%',
            color: 'var(--button-color)',
            backgroundColor: 'transparent',
            borderColor: 'var(--button-color)',
            textTransform: 'none',
            ":hover": {
              borderColor: 'var(--button-color)',
            },
          }}
        >
          Cancel
        </Button>
      </Box>
    </Modal>
  );
};

export default ImportFormsModal;