import { React, useEffect, useState , memo, useCallback, useRef, useMemo} from "react";
import SectionFieldsBuilder from "../SectionFieldsBuilder/SectionFieldsBuilder";
import classes from  './SectionBuilder.module.css';
import apiGatewayService from "../../../common/services/apiGatewayService";
import validationService from "../../../common/validationService.service";
import localStorageService from "../../../common/services/localStorage.service";
import utilityFunctions from "../../../common/services/utilityFunctions";
import { TextField } from "@mui/material";
import CustomAlert from "../../CustomAlert/CustomAlert";

// New imports from section fields builder!!!!!
import FieldBuilder from "../FieldBuilder/FieldBuilder";
// import classes from "./SectionFieldsBuilder.module.css";
import FieldsSelector from "../FieldsSelector/FieldsSelector";
import SelectionModalComponent from "../SelectionModal/SelectionModal";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button, Tooltip, Typography } from "@mui/material";
import AddBoxIcon from '@mui/icons-material/AddBox';
import ReorderIcon from '@mui/icons-material/Reorder';
import BackupIcon from '@mui/icons-material/Backup';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import LowPriorityIcon from '@mui/icons-material/LowPriority'; 
import SectionDependentSelector from "../SectionDependentSelector/SectionDependentSelector";
import DeleteConfirmation from '../../DeleteConfirmation/DeleteConfirmation';

const initialFieldState = {
  name: '',
  label: '',
  type: 'text',
  dataType: 'string',
  length: 50,
  description: '',
  required: false,
  wrapping: false,
  visible: true,
  placeholdertext: '',
  controlType: '',
  barcode: false,
  options: '',
  dependent: '',
  config: '',
  sessionID: '',
  validate: {},
  // descriptionName: '',
};

const SectionBuilder = (props) => {

    const initialSectionState = 
    {
        title: "string",
        description: "", // Added description to the initial state
        fields: "array"
    };

    useEffect(() => {
      console.log('Props have changed SectionBuilder: ', props);
      setSection(props.section);
      setSectionTitle(props?.section?.title || "");
      setSectionDescription(props?.section?.sectionDescription || "");
      const currFieldsArray = props?.section?.fields ? props?.section?.fields : []; 
      setSectionFieldsArray(currFieldsArray);
      if(props.isForm){
        setIsForm(true);
      };
      if(props.isEdit){
        setIsEdit(true);
        setSectionId(props.sectionId);
      }
      if(props?.section?.dependant){
        setDependant(props.section.dependant);
      }
      if (props.isForm && props.checkForErroInSection) {
        setCheckForErroInField(true);
        checkForErrorsInSection(props.section);
        // checkForErrorsInSection(props.section);
        // if(checkForErroInField){
        //   setCheckForErroInField(false);
        //   setTimeout(()=>setCheckForErroInField(true), 100);
        // } else {
        //   setCheckForErroInField(true);
        // }
      }
      const user = localStorageService.getObject('user');
      setCogntioUser(user);
      setIsOnlyReadAccess(utilityFunctions.checkIfOnlyReadAccess(user));
      // console.log("currentCognito User: ", user);
    }, [props]);

    const [sectionTemplate, setSectionTemplate] = useState(initialSectionState);

    const [section, setSection] = useState({});

    const [sectionTitle, setSectionTitle] = useState("");

    const sectionTitleRef = useRef('');
    
    const [sectionDescription, setSectionDescription] = useState(""); // New state for section description

    const [sectionFieldsArray, setSectionFieldsArray] = useState([]);

    const [isForm, setIsForm] = useState(false);
    // const isForm = useRef(false);

    const [cogntioUser, setCogntioUser] = useState(null);

    const [isOnlyReadAcess, setIsOnlyReadAccess] = useState(false);

    const [isEdit, setIsEdit] = useState(false);

    const [sectionId, setSectionId] = useState(null);

    const [dependant, setDependant] = useState(null);

    const [checkForErroInField, setCheckForErroInField] = useState(false);

    const [sectionKeysWithError, setSectionKeysWithError] = useState([]);
    const [displayAlert, setDisplayAlert] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertMessage, setAlertMessage] = useState('');


    // states from section fields builder
    const [fieldsSelectorOpen, setFieldsSelectorOpen] = useState(false);
    const [dependentModalOpen, setdependentModalOpen] = useState(false);
    const [dependentEnabled, setdependentEnabled] = useState(false);
    const [changedValue, setChangedValue] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const convertToCapital = (value) => {
      return `${value[0].toUpperCase()}${value.slice(1)}`;
    };

    const handleTitleInputChange = (e) => {
      const titleValue = e.target.value;
      console.log(typeof titleValue);
      setSectionTitle(titleValue);
      handleUpdateSectionInForm({sectionTitle: titleValue});
    };

    const handleDescriptionInputChange = (e) => {
      const descriptionValue = e.target.value;
      console.log(descriptionValue);
      setSectionDescription(descriptionValue);
      handleUpdateSectionInForm({sectionDescription: descriptionValue});
    };

    const handleUpdateSectionInForm = (submitInput, onlyFieldsArray = false) => {
      console.log('submitInput: ', submitInput);
      console.log("sectionTitle: ", sectionTitle);
      console.log("sectionDescription: ", sectionDescription);
      const finalSection = {
        title: (submitInput?.sectionTitle !== undefined) ? submitInput?.sectionTitle : sectionTitle,
        sectionDescription: (submitInput?.sectionDescription !== undefined) ? submitInput?.sectionDescription : sectionDescription, // Include description in final section
        fields: (submitInput?.sectionFieldsArray  !== undefined) ? submitInput?.sectionFieldsArray : sectionFieldsArray,
      };
      console.log("section to update in form: ", finalSection);
      updateDependentInSection(finalSection);
      setSection(finalSection);
      if(isForm){
        props.updateSectionsArray(finalSection, props.indexSectionsArray, onlyFieldsArray);
      };
    };

    const handleSubmitSection = async () => {
      const finalSection = {
        title: sectionTitle,
        sectionDescription: sectionDescription, // Include description in final section
        fields: sectionFieldsArray,
      }
      setSection(finalSection);
      updateDependentInSection(finalSection);
      if(isForm){
        props.updateSectionsArray(finalSection, props.indexSectionsArray);
      };
      const sectionObject = {
        displayName: sectionTitle,
        section: finalSection
      };
      const sectionIsValid = checkForErrorsInSection(finalSection);
      // if(validationService.hasEmptyKeys(sectionObject)){
      //   alert('Section keys can not be empty!');
      //   setCheckForErroInField(true);
        // props.setAlertMessageAndPopup({
        //   message: 'Error in submitting section: mandatory values can not be empty!',
        //   type: 'error'
        // });
      if (!sectionIsValid) {
        setAlertMessageAndPopupSections({
          message: 'Error in submitting section: mandatory values can not be empty!',
          type: 'error'
        });
        if(checkForErroInField){
          setCheckForErroInField(false);
          setTimeout(()=>setCheckForErroInField(true), 100);
        } else {
          setCheckForErroInField(true);
        }
      } else{
          sectionObject['sectionDescription'] =  sectionDescription; // Include description in final section
          try {
            let response;
            console.log("isEdit: ", isEdit);
            console.log("sectionId: ", sectionId);
            if (isEdit){
              response = await apiGatewayService.putSectionsData(sectionId, sectionObject);
              if(!isForm){
                props.handleClose({
                  message: 'Section edited successfully!',
                  type: 'success'
                });
              } else {
                setAlertMessageAndPopupSections({
                  message: 'Section edited successfully!',
                  type: 'success'
                });
              }
            } else {
              response = await apiGatewayService.postSectionsData(sectionObject);
              if(!isForm){
                props.handleClose({
                  message: 'Section created successfully!',
                  type: 'success'
                });
              } else {
                setAlertMessageAndPopupSections({
                  message: 'Section created successfully!',
                  type: 'success'
                });
              }
            }

            console.log(response);

        }
        catch(error){
          console.log("Error in submitting form sections: ", error);
        }
      }
   };

   const updateDependent = (dependant) => {
      console.log("Dependant from the input: ", dependant);
      if (dependant!==null){
        setDependant(dependant)
      } else {
        setDependant(null)
      }
   };

  const updateDependentInSection = useCallback(
    (section) => {
      if (dependant !== null) {
        section['dependant'] = dependant;
      } else {
        delete section['dependant'];
      }
    },
    [dependant] // Add dependencies here, so the function only changes when `dependant` changes
  );

   const checkForErrorsInSection = (section) => {
    const sectionValidationResponse = validationService.validateSection(section);
    console.log(sectionValidationResponse);
    if (!sectionValidationResponse.isValid){
         setSectionKeysWithError(sectionValidationResponse.errorKeys);
         return false
    }
    setSectionKeysWithError([]);
    return true;
  };
   
  const setAlertMessageAndPopupSections  = (alertObject) => {
    setDisplayAlert(true);
    setAlertMessage(alertObject.message);
    setAlertType(alertObject.type);
    setTimeout(() => setDisplayAlert(false), 10000);
  }

  const keys = Object.keys(sectionTemplate);
  

  const handleRemoveSection = () => {
    setSection({});
    setSectionFieldsArray([]);
    console.log("isForm: ", isForm);
    if (isForm) {
      props.updateSectionsArray(undefined, props.indexSectionsArray);
    }
    setIsDeleteModalOpen(false);
  };

  const closeModal = () => {
    setFieldsSelectorOpen(false);
    setdependentModalOpen(false);
  };

  const updateFieldsArray = useCallback(
    (field, index) => {
      let sectionFieldsArrayCopy = sectionFieldsArray;
      if (field === undefined) {
        sectionFieldsArrayCopy = sectionFieldsArrayCopy.filter((_, ind) => ind !== index);
        setSectionFieldsArray(Array.from(sectionFieldsArrayCopy));
      } else {
        sectionFieldsArrayCopy[index] = field;
        setSectionFieldsArray(sectionFieldsArrayCopy);
      }
      if (isForm){
        handleUpdateSectionInForm({sectionFieldsArray: sectionFieldsArrayCopy}, true);
      }
    },
    [sectionFieldsArray]
  );


    const handleAddField = () => {
        sectionFieldsArray.push(initialFieldState);
        const newFieldsArray = Array.from(sectionFieldsArray);
        setSectionFieldsArray(newFieldsArray);
        console.log("fieldsArray: ", sectionFieldsArray);
    };

    const addOptionsIntoField = (field) => {
        if (field === undefined) {
            handleAddField();
        }
        else {
            sectionFieldsArray.push(field);
            const newFieldsArray = Array.from(sectionFieldsArray);
            setSectionFieldsArray(newFieldsArray);
        };
    };

    const handleMoveFieldUp = useCallback((index) => {
      console.log("index to move up: ", index);
      if (index > 0) {
        const newFieldsArray = [...sectionFieldsArray];
        [newFieldsArray[index - 1], newFieldsArray[index]] = [newFieldsArray[index], newFieldsArray[index - 1]];
        setSectionFieldsArray(newFieldsArray);
        handleUpdateSectionInForm({sectionFieldsArray:newFieldsArray}, true);
      }
    }, [sectionFieldsArray]);
    
    const handleMoveFieldDown = useCallback((index) => {
      console.log("index to move down: ", index);
      if (index < sectionFieldsArray.length - 1) {
        const newFieldsArray = [...sectionFieldsArray];
        [newFieldsArray[index + 1], newFieldsArray[index]] = [newFieldsArray[index], newFieldsArray[index + 1]];
        setSectionFieldsArray(newFieldsArray);
        handleUpdateSectionInForm({sectionFieldsArray:newFieldsArray}, true);
      }
    }, [sectionFieldsArray]);

    const handleSelectField = () => {
        setFieldsSelectorOpen(true);
    };
  
    const handleAdddependent = () => {
        // setdependentEnabled(true);
        setdependentModalOpen(true);
    };

    const submitdependent = (title, field, description) => {
        console.log('Submitted dependent for section is: ', {title, field, description} );
        setdependentEnabled(false);
        updateDependent({
            title,
            field,
            description,
        });
    };
    
    const removedependent = () => {
        setdependentEnabled(false);
        updateDependent(null);
    };

    // const handleMouseOut = () => {
    //   if(changedValue){
    //     if(isForm){
    //       const fieldToUpdateInSection = field;
    //       removeOptionalKeys(fieldToUpdateInSection)
    //       props.updateFieldsArray(fieldToUpdateInSection, props.indexFieldArray);
    //     }
    //     setChangedValue(false);
    //   }
    // };

    const memoizedFields = useMemo(() => {
      return (
        <div>
          {sectionFieldsArray.length > 0 && sectionFieldsArray.map((field, index) => (
            <div key={index} className={classes.fieldContainer}>
              <div className={classes.ButtonContainerDrag}>
                <Tooltip title="Move Up">
                  <Button
                    onClick={() => handleMoveFieldUp(index)}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      padding: 0,
                      background: 'transparent',
                      color: 'var(--app-color)',
                      cursor: 'pointer',
                      borderRadius: '10px',
                      ':hover': {
                        backgroundColor: 'transparent',
                        color: 'var(--button-hover-colo)',
                      },
                      height: '30px'
                    }}
                  >
                    <KeyboardArrowUpIcon sx={{ fontSize: 25, marginTop: 0, marginBottom: '0px', color: 'var(--app-color)' }} />
                  </Button>
                </Tooltip>
                <Tooltip title="Move Down">
                  <Button
                    onClick={() => handleMoveFieldDown(index)}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      padding: 0,
                      background: 'transparent',
                      color: 'var(--app-color)',
                      cursor: 'pointer',
                      borderRadius: '10px',
                      ':hover': {
                        backgroundColor: 'transparent',
                        color: 'var(--button-hover-colo)',
                      },
                      height: '30px'
                    }}
                  >
                    <KeyboardArrowDownIcon sx={{ fontSize: 25, marginTop: 0, marginBottom: '0px', color: 'var(--app-color)' }} />
                  </Button>
                </Tooltip>
              </div>
              <FieldBuilder
                key={index}
                indexFieldArray={index}
                field={field}
                updateFieldsArray={updateFieldsArray}
                isForm={true}
                // isView={props.isView || false}
                checkForErroInField={checkForErroInField}
              />
            </div>
          ))}
        </div>
      );
    }, [sectionFieldsArray, handleMoveFieldUp, handleMoveFieldDown, updateFieldsArray, checkForErroInField]);
    
    const openDeleteModal = (index) => {
      setIsDeleteModalOpen(true);
    };
    
  
  
    return (
       <div className = {classes.SectionContainerWithError}>
        {
          displayAlert &&
          <CustomAlert isOpen={displayAlert} message={alertMessage} type={alertType}></CustomAlert>
        }
        {
          isDeleteModalOpen &&
          <DeleteConfirmation
            isOpen={isDeleteModalOpen}
            handleClose={() => setIsDeleteModalOpen(false)}
            submitDelete={handleRemoveSection}
            message={'Are you sure you want to remove this section ?'}
            isRemove = {true}
          />
        }

        <div className={ isForm? classes.SectionFormContainerBorderTop : classes.SectionFormContainer}>
          {keys.map((key) => (
            <div className={classes.SectionContainer} key={key}>
              {key === 'title' ? (
                <div>
                <label className={classes.Label}>{convertToCapital(key)}</label>
                <TextField
                  type="text"
                  name="Section Title"
                  value={ sectionTitle || ""}
                  onChange={handleTitleInputChange}
                  variant="outlined"
                  size="medium"
                  sx={{
                    width: '100%',
                    "& .MuiInputBase-root": {
                      backgroundColor: 'white',
                      height: 40,
                      border: sectionKeysWithError.includes(key) ? '1px solid red' : 'inherit',
                    },
                    "& .MuiOutlinedInput-input": {
                      height: 40,
                      width: '100%',
                      border: sectionKeysWithError.includes(key) ? '1px solid red' : 'inherit',
                    },
                  }}
                />
                </div>
              ) : ( key === 'description') ? (
                <div>
                <label className={classes.Label}>{convertToCapital(key)}</label>
                <TextField
                  type="text"
                  name="Section Description"
                  value={sectionDescription}
                  onChange={handleDescriptionInputChange}
                  variant="outlined"
                  size="medium"
                  sx={{
                    width: '100%',
                    "& .MuiInputBase-root": {
                      backgroundColor: 'white',
                      height: 40,
                      border: (sectionKeysWithError.includes(key) || sectionKeysWithError.includes('sectionDescription')) ? '1px solid red' : 'inherit'
                    },
                    "& .MuiOutlinedInput-input": {
                      height: 40,
                      width: '100%',
                      border: sectionKeysWithError.includes(key) ? '1px solid red' : 'inherit'
                    },
                  }}
                />
                </div>
              ) : (
                // <div>
                //   <label className={classes.Label}>{convertToCapital(key)}</label>
                //   <SectionFieldsBuilder
                //     fieldsArray={sectionFieldsArray}
                //     updateSectionFieldsArray={updateSectionFieldsArray}
                //     removeFieldFromFieldsArray={removeFieldFromFieldsArray}
                //     handleSubmitSectionToBackend={handleSubmitSectionToBackend}
                //     isForm={true}
                //     isOnlyReadAcess={isOnlyReadAcess}
                //     handleRemoveSection={handleRemoveSection}
                //     isView={props.isView || false}
                //     // dependant={dependant || null}
                //     updateDependent={updateDependent}
                //     checkForErroInField = {checkForErroInField}
                //   />
                // </div>
                <div>
                  {/* <div>
                      {sectionFieldsArray.length > 0 && (sectionFieldsArray.map((field, index) => (
                          <div key={index} className={classes.fieldContainer}>
                                  <div className={classes.ButtonContainerDrag}>
                                  <Tooltip title="Move Up">
                                  <Button
                                      onClick={()=> handleMoveFieldUp(index)}
                                      // variant="contained"
                                      sx={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          alignItems: 'center',
                                          padding: 0,
                                          background: 'transparent',
                                          color: 'var(--app-color)',
                                          cursor: 'pointer',
                                          borderRadius: '10px',
                                          ':hover': {
                                              backgroundColor: 'transparent',
                                              color: 'var(--button-hover-colo)',
                                          },
                                          height: '30px'
                                      }}
                                  >
                                  <KeyboardArrowUpIcon sx={{ fontSize: 25, marginTop: 0, marginBottom: '0px', color: 'var(--app-color)' }}/>
                                  </Button>
                                  </Tooltip>
                                  <Tooltip title="Move Down">
                                  <Button
                                      onClick={()=>handleMoveFieldDown(index)}
                                      // variant="contained"
                                      sx={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          alignItems: 'center',
                                          padding: 0,
                                          background: 'transparent',
                                          color: 'var(--app-color)',
                                          cursor: 'pointer',
                                          borderRadius: '10px',
                                          ':hover': {
                                              backgroundColor: 'transparent',
                                              color: 'var(--button-hover-colo)',
                                          },
                                          height: '30px'
                                      }}
                                  ><KeyboardArrowDownIcon sx={{ fontSize: 25, marginTop: 0, marginBottom: '0px', color: 'var(--app-color)' }}/>
                                  </Button>
                                  </Tooltip>
                              </div>
                              <FieldBuilder
                                  key={index}
                                  indexFieldArray={index}
                                  field={field}
                                  updateFieldsArray={updateFieldsArray}
                                  isForm={true}
                                  isView={props.isView || false}
                                  checkForErroInField = {checkForErroInField}
                              />

                          </div>
                      )))}
                  </div> */}
                  <>{memoizedFields}</>
                  {/* {!props.isOnlyReadAcess && <div className={classes.ButtonContainer}>
                      <SelectionModalComponent
                          type={'Fields'}
                          Builder={FieldBuilder}
                          Selector={FieldsSelector}
                          addOptionsIntoField={addOptionsIntoField}
                      ></SelectionModalComponent>
                  </div>} */}
                  {!props.isView && (<div className={classes.FieldsButtonContainer}>
                      <Button
                          onClick={openDeleteModal}
                          sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              padding: 0,
                              background: 'transparent',
                              color: 'var(--app-color)',
                              cursor: 'pointer',
                              borderRadius: '10px',
                              ':hover': {
                                  backgroundColor: '#F0F4F4',
                                  color: '#2b4252',
                              },
                              height: '58px'
                          }}
                      >
                          <RemoveCircleIcon sx={{ fontSize: 30, marginTop: 1, marginBottom: '5px', color: 'red', padding: 0 }} />
                          <Typography sx={{ fontSize: 10, textTransform: 'none' }} variant="body2">Remove</Typography>
                      </Button>
                      <Button
                          onClick={handleSubmitSection}
                          sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              padding: 0,
                              background: 'transparent',
                              color: 'var(--icon-button-color-green)',
                              cursor: 'pointer',
                              borderRadius: '10px',
                              ':hover': {
                                  backgroundColor: '#F0F4F4',
                                  color: 'var(--icon-button-color-green)',
                              },
                              height: '58px'
                          }}
                      >
                          <BackupIcon sx={{ fontSize: 32, marginTop: 1, marginBottom: '5px', color: 'var(--icon-button-color-green)', padding: 0 }} />
                          <Typography sx={{ fontSize: 10, textTransform: 'none' }} variant="body2">Save Section</Typography>
                      </Button>
                      <Button
                          onClick={handleAddField}
                          sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              padding: 0,
                              background: 'transparent',
                              color: 'var(--icon-button-color-green)',
                              cursor: 'pointer',
                              borderRadius: '10px',
                              ':hover': {
                                  backgroundColor: '#F0F4F4',
                                  color: 'var(--icon-button-color-green)',
                              },
                              height: '58px'
                          }}
                      >
                          <AddBoxIcon sx={{ fontSize: 32, marginTop: 1, marginBottom: '5px', color: 'var(--icon-button-color-green)', padding: 0 }} />
                          <Typography sx={{ fontSize: 10, textTransform: 'none' }} variant="body2">Add Field</Typography>
                      </Button>
                      <Button
                          onClick={handleSelectField}
                          sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              padding: 0,
                              background: 'transparent',
                              color: 'var(--icon-button-color-green)',
                              cursor: 'pointer',
                              borderRadius: '10px',
                              ':hover': {
                                  backgroundColor: '#F0F4F4',
                                  color: 'var(--icon-button-color-green)',
                              },
                              height: '58px'
                          }}
                      >
                          <ReorderIcon sx={{ fontSize: 32, marginTop: 1, marginBottom: '5px', color: 'var(--icon-button-color-green)', padding: 0 }} />
                          <Typography sx={{ fontSize: 10, textTransform: 'none' }} variant="body2">Select Field</Typography>
                      </Button>
                      <Button
                          onClick={handleAdddependent}
                          sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          padding: 0,
                          background: 'transparent',
                          color: 'var(--icon-button-color-green)',
                          cursor: 'pointer',
                          borderRadius: '10px',
                          ':hover': {
                              backgroundColor: '#F0F4F4',
                              color: 'var(--icon-button-color-green)',
                          },
                          height: '58px'
                          }}
                      >
                          <LowPriorityIcon sx={{ fontSize: 30, marginTop: 1, marginBottom: '5px', color: 'var(--icon-button-color-green)', padding: 0 }} />
                          <Typography sx={{ fontSize: 10, textTransform: 'none' }} variant="body2">dependent</Typography>
                      </Button>
                  </div>)
                  }
                  <div className={classes.ButtonContainer}>
                  {/* <button className={classes.Button} onClick={handleAddField}>Add  Field</button>
                      <button className={classes.Button} onClick={handleSelectField}>Select Field</button> */}
                      {/* <button className={classes.Button} onClick={handleSubmitSection}>Save Section</button> */}
                  </div>
                  <FieldsSelector
                      isOpen={fieldsSelectorOpen}
                      handleClose={closeModal}
                      submitField={addOptionsIntoField}
                  />
                  {dependentModalOpen &&
                          <SectionDependentSelector
                          isOpen={dependentModalOpen}
                          handleClose={closeModal}
                          submitdependent={submitdependent}
                          removedependent={removedependent}
                          dependent = {dependant || null}
                          />
                  }
              </div>
              )}
            </div>
          ))}
        </div>
        </div>
      );
}

export default memo(SectionBuilder);