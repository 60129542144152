import React, { useEffect, useState } from 'react';
import classes from './SignUpOrSignIn.module.css';
import { useNavigate } from 'react-router-dom';
import localStorageService from '../../common/services/localStorage.service';
import { AppBar, Toolbar, Typography, Avatar } from '@mui/material';
import logo from '../../common/images/FreeForm-Logo-Without-Text.png';
import logoNew from '../../common/images/FreeForm-Logo_White.png';

const SignUpOrSignIn = ({auth}) => {
  const navigate = useNavigate();

  useEffect(()=>{
    const user = localStorageService.getObject('user');
    console.log("currentCognito User: ", user)
    if(user !== null) {
        console.log("Authentication Successfull. Navigating to side menu.");
        navigate('/sideMenu');
    }
  },[]);

  const handleSignIn = () => {
    navigate('/signIn');
  };

  const handleSignUp = () => {
    navigate('/signUpType');
  };
  
  return (
    <div>
        <AppBar position="fixed">
        <Toolbar   sx={{ backgroundColor: '#2b4252'}}>
          {/* <Avatar src={logo} alt="Logo" sx={{ marginRight: '10px',width: '40px', height: '40px' }} />
          <Typography variant="h5" noWrap>
            Freeform IO
          </Typography> */}
          {/* <Avatar src={logoNew} alt="Logo" sx={{ marginLeft: '-35px', marginRight: '0px', marginTop: '5px', width: '200px', height: '60px' }} />   */}
          <Avatar src={logoNew} alt="Logo" sx={{
              marginLeft: '-20px', marginRight: '0px', marginTop: '-5px', width: '165px', height: '65px',
              '& img': {
                width: '100%',
                height: '80%',
                objectFit: 'contain',
                mt: '7px',
                ml: '0px'
              }
        }} />
        </Toolbar>
      </AppBar>
      <div className={classes.Container}>
        <div className={classes.Border}>
        <div className={classes.LogoDiv}>
                <img className={classes.Logo} src={logo} alt="ao-logo"/>
         </div>
         <hr></hr>
        {/* <h1>Free Form Registration</h1> */}
        <h5 className={classes.Heading}>Please select Sign In or Sign Up</h5>
        <button className={classes.Button} onClick={handleSignIn}>Sign In</button>
        <button className={classes.Button} onClick={handleSignUp}>Sign Up</button>
        {/* <button className={classes.Button} onClick={handleGetUserGroups}>Get User Groups</button> */}
       </div>
      </div>
   </div>
  );
};

export default SignUpOrSignIn;
