import React, { useEffect, useState } from 'react';
import {TextField, Switch, Tooltip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Container, Typography, Toolbar, IconButton, Button , Divider} from '@mui/material';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import apiGatewayService from '../../common/services/apiGatewayService';
import localStorageService from '../../common/services/localStorage.service';
import utilityFunctions from '../../common/services/utilityFunctions';
import AddIcon from '@mui/icons-material/Add';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import DeleteIcon from '@mui/icons-material/Delete';
import SelectUserModal from './SelectUserModal/SelectUserModal';
import {
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Box,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LockIcon from '@mui/icons-material/Lock';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import TableActions from '../TableActions/TableActions';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddUser from '../AddUser/AddUser';
import DeleteConfirmation from '../DeleteConfirmation/DeleteConfirmation';
import CustomAlert from '../CustomAlert/CustomAlert';
import CircularLoader from '../Loader/Loader';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';


const EditUsers = (props) => {
  const [usersData, setUsersData] = useState(
    []
    // {
    //   builderUsers: [
    //     {
    //       username: '1111',
    //       email: 'abc@gmail.com'
    //     }
    //   ],
    //   readerUsers: [
    //     {
    //       username: '2222',
    //       email: 'xyz@gmail.com'
    //     }
    //   ]
    // }
  );
  const [cogntioUser, setCogntioUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isAdminAccess, setIsAdminAccess] = useState(false);
  const [isAddBuilder, setIsAddBuilder] = useState(false);
  const [isRemoveBuilder, setIsRemoveBuilder] = useState(false);
  const [isAddReader, setIsAddReader] = useState(false);
  const [isRemoveReader, setIsRemoveReader] = useState(false);
  const [selectedUserEmail, setSelectedUserEmail] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userEmailsForModal, setUserEmailsForModal] = useState(false);
  const [currUserEmails, setCurrentUserEmails] = useState(false);
  const [isAddUser, setIsAddUser] = useState(false);
  const [allSelectChecked, setAllSelectChecked] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [displayAlert, setDisplayAlert] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [currUser, setCurrUser] = useState(0);

  const navigate = useNavigate();


  useEffect(() => {

    console.log("inside use effect!");

    const getUsers = async (user) => {
      try {
        const response = await apiGatewayService.getAdminUsers();
        console.log("response from admin users: ", response);
        setUsersData(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
      setIsLoading(false);
    };
    const user = localStorageService.getObject('user');
    // console.log("currentCognito User: ", user);
    setCogntioUser(user);
    setIsAdminAccess(utilityFunctions.checkAdminAccess(user));
    getUsers(user);
  }, [props]);


  function convertToGroupArray(groupObject) {
    const result = [];

    for (const [group, users] of Object.entries(groupObject)) {
      users.forEach(user => {
        result.push({ group: group.slice(0, -1), user: user });
      });
    }

    return result;
  };

  const getUsers = async () => {
    try {
      const response = await apiGatewayService.getAdminUsers();
      console.log("response from admin users: ", response);
      setUsersData(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
    setIsLoading(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsAddUser(false);
  };

  const setAllFalse = () => {
    setIsAddBuilder(false);
    setIsRemoveBuilder(false);
    setIsAddBuilder(false);
    setIsRemoveBuilder(false);
    setIsDeleteModalOpen(false);
  };

  const handleAddBuilder = () => {
    setAllFalse();
    setIsAddBuilder(true);
    openModal();
    setCurrentUserEmails(usersData.readerUsers);
  };

  const handleRemoveBuilder = () => {
    setAllFalse();
    setIsRemoveBuilder(true);
    openModal();
    console.log(usersData.builderUsers);
    setCurrentUserEmails(usersData.builderUsers);
  };

  const handleAddReader = () => {
    setAllFalse();
    setIsAddReader(true);
    openModal();
    setCurrentUserEmails(usersData.builderUsers);
  };

  const handleRemoveReader = () => {
    setAllFalse();
    setIsRemoveReader(true);
    openModal();
    setCurrentUserEmails(usersData.readerUsers);
  };

  const handleSubmit = async () => {
    console.log("This is the curren user: ", selectedUserEmail);
    console.log(" Is Add New Builder: ", isAddBuilder);

    try {

      let response;
      if (isAddBuilder) {
        response = await apiGatewayService.updateGroup(selectedUserEmail, 'builder');
        console.log(response);
      }

      else if (isAddReader) {
        response = await apiGatewayService.updateGroup(selectedUserEmail, 'reader');
        console.log(response);
      }

      closeModal();
      await getUsers();

    } catch (error) {
      console.log("Could not update user group: ", error);
    }

  };

  const handleClose = () => {
    closeModal();
    setAllFalse();
  };


  const setReaderGroup = () => {

  };

  const setBuilderGroup = () => {

  };


  const handleChange = async (index, e) => {
    console.log(index);
    console.log(e);
    if (usersData[index].userGroup !== e.target.value) {
      usersData[index].userGroup = e.target.value;
      console.log(usersData);
      setUsersData(Array.from(usersData));
      try {

        let response;
        if (e.target.value === 'builderUser') {
          response = await apiGatewayService.updateGroup(usersData[index].userEmail, 'builder');
          console.log(response);
        }

        else if (e.target.value === 'readerUser') {
          response = await apiGatewayService.updateGroup(usersData[index].userEmail, 'reader');
          console.log(response);
        }

        closeModal();
        await getUsers();

      } catch (error) {
        console.log("Could not update user group: ", error);
      }

    }



  };

  const handleRefresh = async () => {
    setIsLoading(true);
    // await handleClose();
    // setIsLoading(false);
    await getUsers();
  };
  
  const handleSelectedAll = () => {
    console.log('All have been seelcted!');
    setAllSelectChecked(!allSelectChecked);
  };

  // Open delete modal and set the field to delete
const openDeleteModal = (user) => {
  setCurrUser(user);
  setIsDeleteModalOpen(true);
};

const handleAddUser = async () => {
  setIsAddUser(true);
};

const handleSubmitAddUser = async (user) => {
  console.log("Add New: ", user);
  try {
    const response = await apiGatewayService.addUser(user);
    console.log("Add user response: ", response);
    setAlertMessageAndPopup({message: 'User added successfully!', type: 'success'});
    await handleRefresh();
  } catch (error) {
    console.log("check error: ", error);
    console.log("check error message: ", error?.response?.data?.message);
    if (error?.response?.data?.message === 'User already exists!') {
      setAlertMessageAndPopup({message: error?.response?.data?.message, type: 'error'});
    } else {
      setAlertMessageAndPopup({message: 'Error in adding user!', type: 'error'});
    }
  }
};

const handleConfirmDelete = async () => {
  await handleDeleteUser(currUser);
  await getUsers();
  handleClose();
};

const assignAdmin = async (userIndex, user) => {
  console.log('Assign Admin:', user);  // Placeholder function
  try {
    const user = {
      userEmail: usersData[userIndex].email,
      groupType: 'admin'
    };
    console.log('user to update: ', user);
    const response = await apiGatewayService.updateUserGroup(user);
    console.log("Add user response: ", response);
    usersData[userIndex].userGroupName = 'freeform-admin';
    setUsersData(Array.from(usersData));
    setAlertMessageAndPopup({message: 'User group updated successfully!', type: 'success'});
  } catch (error) {
    console.log(error);
    setAlertMessageAndPopup({message: 'Error in updating user group!', type: 'error'});
  }
};

const assignBuilder = async (userIndex, user) => {
  console.log('Assign Builder:', user);  // Placeholder function
  try {
    const user = {
      userEmail: usersData[userIndex].email,
      groupType: 'builder'
    };
    console.log('user to update: ', user);
    const response = await apiGatewayService.updateUserGroup(user);
    console.log("Add user response: ", response);
    usersData[userIndex].userGroupName  = 'freeform-builder';
    setUsersData(Array.from(usersData));
    setAlertMessageAndPopup({message: 'User group updated successfully!', type: 'success'});
  } catch (error) {
    console.log(error);
    setAlertMessageAndPopup({message: 'Error in updating user group!', type: 'error'});
  }
};

const assignReader = async (userIndex, user) => {
  console.log('Assign Reader:', user);  // Placeholder function
  try {
    const user = {
      userEmail: usersData[userIndex].email,
      groupType: 'reader'
    };
    console.log('user to update: ', user);
    const response = await apiGatewayService.updateUserGroup(user);
    console.log("Add user response: ", response);
    usersData[userIndex].userGroupName  = 'freeform-reader';
    setUsersData(Array.from(usersData));
    setAlertMessageAndPopup({message: 'User group updated successfully!', type: 'success'});
  } catch (error) {
    console.log(error);
    setAlertMessageAndPopup({message: 'Error in updating user group!', type: 'error'});
  }
};

const handleToggleClick = (userIndex, role) => {
  // Update role selection for this specific row
  const updatedUsers = usersData.map((user, index) =>
    index === userIndex ? { ...user, userGroupName: role } : user
  );

  console.log('Updated User Roles:', updatedUsers);
};

const handleRoleChange = (selectedUser, newRole) => {
console.log(selectedUser)
  if (newRole !== null) {
    const userIndex = usersData.findIndex((user) => user.email === selectedUser.email);
    console.log('New Role Assigned:', newRole);
    if (newRole === 'freeform-admin') assignAdmin(userIndex, selectedUser);
    else if (newRole === 'freeform-builder') assignBuilder(userIndex, selectedUser);
    else if (newRole === 'freeform-reader') assignReader(userIndex, selectedUser);

    // Update the user's selected role
    // usersData[userIndex].userGroupName = newRole;
  }
};

const setAlertMessageAndPopup  = (alertObject) => {
  setDisplayAlert(true);
  setAlertMessage(alertObject.message);
  setAlertType(alertObject.type);
  setTimeout(() => setDisplayAlert(false), 10000);
};

const [searchEmail, setSearchEmail] = useState('');

const filteredUsers = usersData.filter((user) =>
  user.email.toLowerCase().includes(searchEmail.toLowerCase())
);

const handleDeleteUser = async(user) => {
  try {
    const userInfo = {
      userEmail: user.email,
      clientId: user.clientId
    };
    console.log('user to delete: ', userInfo);
    const response = await apiGatewayService.deleteUser(userInfo);
    console.log("Delete user response: ", response);
    setAlertMessageAndPopup({message: 'User has been deleted successfully!', type: 'success'});
  } catch (error) {
    console.log(error);
    setAlertMessageAndPopup({message: 'Error in deleting group!', type: 'error'});
  }
};

const columns = [
  {
    headerName: 'Email',
    field: 'email',
    flex: 4,
    filter: 'agTextColumnFilter', // Enables search filtering for the email column
    sortable: true,
    suppressMovable: true, // Prevents the column from being dragged to another position
    floatingFilter: true, // Adds a search box below the column header
  },
  {
    headerName: 'Admin',
    field: 'admin',
    cellRenderer: (params) => (
      <Switch
        checked={params.data?.userGroupName === 'freeform-admin'}
        onChange={() => handleRoleChange(params.data, 'freeform-admin')}
      />
    ),
    width: 150,
    sortable: true,
    suppressMovable: true, // Keeps the column fixed
  },
  {
    headerName: 'Builder',
    field: 'builder',
    cellRenderer: (params) => (
      <Switch
        checked={params.data?.userGroupName === 'freeform-builder'}
        onChange={() => handleRoleChange(params.data, 'freeform-builder')}
      />
    ),
    width: 150,
    sortable: true,
    suppressMovable: true, // Keeps the column fixed
  },
  {
    headerName: 'Reader',
    field: 'reader',
    cellRenderer: (params) => (
      <Switch
        checked={params.data?.userGroupName === 'freeform-reader'}
        onChange={() => handleRoleChange(params.data, 'freeform-reader')}
      />
    ),
    width: 150,
    sortable: true,
    suppressMovable: true, // Keeps the column fixed
  },
  {
    headerName: '', // No heading for the delete icon column
    field: 'delete',
    cellRenderer: (params) => (
      <IconButton
        onClick={() => openDeleteModal(params.data)} // Calls deleteUser function with params.data
        sx={{ color: 'red' }} // Style for the delete icon
        aria-label="delete user"
      >
        <DeleteIcon />
      </IconButton>
    ),
    width: 100,
    suppressMovable: true,
    sortable: false, // No sorting for the delete column
  },
];

  return (
    <Container disableGutters sx={{ minWidth: '100%', height: '75%'}}>
      <Divider sx={{ borderBottomWidth: '2px', backgroundColor: '#f1f2f5' }} />
      {
        isDeleteModalOpen &&
        <DeleteConfirmation
        isOpen={isDeleteModalOpen}
        handleClose={handleClose}
        submitDelete={handleConfirmDelete}
        message={"Are you sure you want to delete this user?"}
      />
      }
      <Toolbar
      disableGutters
        sx={{
          backgroundColor: 'white',
          padding: 0,
          position: 'sticky',
          top: 0,
          zIndex: 1000,
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'flex', flexGrow: 1}}></Box>
        <Box>
          <Tooltip title="Refresh">
            <IconButton onClick={handleRefresh} sx={{ color: 'var(--app-color)', p: '5px', mr: '10px' }}>
              <RefreshIcon sx={{ fontSize: '34px', fontWeight: 'bold' }} />
            </IconButton>
          </Tooltip>
          <Button
            sx={{
              backgroundColor: 'var(--app-color)',
              color: 'white',
              width: '130px',
              ':hover': { backgroundColor: 'var(--button-hover-color)' },
            }}
            startIcon={<AddIcon />}
            onClick={handleAddUser}
          >
            Add New
          </Button>
        </Box>
      </Toolbar>

      {displayAlert && <CustomAlert isOpen={displayAlert} message={alertMessage} type={alertType} />}
      {isAddUser && <AddUser isOpen={isAddUser} handleClose={handleClose} submitUser = {handleSubmitAddUser}/>}
      {isLoading ? (
        <CircularLoader />
      ) : (
        <div className="ag-theme-alpine" style={{ height: '100%', width: '100%' }}>
          <AgGridReact
            rowData={usersData}
            columnDefs={columns}
            defaultColDef={{ flex: 1, minWidth: 100 }}
            animateRows={true}
            pagination={true}
            paginationPageSize={10}
          />
        </div>
      )}
    </Container>
  );
};

export default EditUsers;
