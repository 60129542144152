import React from 'react';
import { Modal, Box, Button, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const LogoutConfirmation = ({ isOpen, handleClose, handleLogOut }) => {


  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="logout-confirmation-title"
      aria-describedby="logout-confirmation-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '8px',
          boxShadow: 24,
          width: '400px',
          outline: 'none',
        }}
      >
        <IconButton
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <Typography
          id="logout-confirmation-title"
          variant="h6"
          component="h2"
          sx={{ marginBottom: '16px' }}
        >
          Log Out Confirmation
        </Typography>
        <Typography id="logout-confirmation-description" sx={{ marginBottom: '24px' }}>
          Are you sure you want to log out?
        </Typography>

        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleLogOut}
            sx={{
              backgroundColor: 'var(--button-color)',
              color: 'white',
              fontWeight: 'normal',
              textTransform: 'none',
              ":hover": {
                backgroundColor: 'var(--button-hover-color)',
              },
            }}
          >
            Yes
          </Button>
          <Button
            onClick={handleClose}
            sx={{
              backgroundColor: 'var(--button-color-red)',
              color: 'white',
              fontWeight: 'normal',
              textTransform: 'none',
              ":hover": {
                backgroundColor: 'var(--button-hover-color-red)',
              },
            }}
          >
            No
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default LogoutConfirmation;
