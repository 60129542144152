import React, { useState } from "react";
import { Container, TextField, Button, Typography, Box, Divider, InputLabel } from "@mui/material";
import apiGatewayService from "../../common/services/apiGatewayService";
import CircularLoader from "../Loader/Loader";

const Feedback = () => {
  // Separate state for each input field
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [description, setDescription] = useState("");
  const [userName, setUserName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Handle form submission
  const handleSubmit = async () => {
    try {
      setIsLoading(true);

      const postData = {
        name,
        email,
        phone,
        description,
        userName, // Include new field
      };

      await apiGatewayService.saveFeedback(postData);

      // Clear inputs
      setName("");
      setEmail("");
      setPhone("");
      setDescription("");
      setUserName("");
    } catch (error) {
      console.error("Error submitting feedback:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container
      disableGutters
      id="feed-back"
      sx={{
        pl: 3,
        pr: 3,
        pt: 2,
        pb: 2,
        borderRadius: '20px',
        margin: 0,
        minWidth: "100%",
        height: "85%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: '#f1f2f5',
        overflow: 'auto',
      }}
    >
      {/* <Divider sx={{ borderBottomWidth: "2px", borderColor: "var(--app-color)" }} /> */}
      {isLoading && <CircularLoader />}
      {!isLoading &&  
            <Box 
            sx={{
                pl: 0,
                pr: 0,
                margin: 0,
                minWidth: "50%",
                height: "100%",
                justifyContent: 'center',
                alignItems: 'center'
            }}>
            <InputLabel sx={{ mt: 1 }}>
                 Name <span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <TextField
            //   placeholder="Name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
              sx={{
                mt: '5px',
                width: "100%",
                "& .MuiInputBase-root": {
                  height: 40,
                  backgroundColor: "white",
                },
                "& .MuiOutlinedInput-input": {
                  height: 40,
                  width: "100%",
                },
              }}
            />
            <InputLabel sx={{ mt: 1 }}>
               Email <span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <TextField
            //   placeholder="Email"
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              sx={{
                mt: '5px',
                width: "100%",
                "& .MuiInputBase-root": {
                  height: 40,
                  backgroundColor: "white",
                },
                "& .MuiOutlinedInput-input": {
                  height: 40,
                  width: "100%",
                },
              }}
            />
            <InputLabel sx={{ mt: 1 }}>
                Phone <span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <TextField
            //   placeholder="Phone"
              type="text"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              fullWidth
              sx={{
                mt: '5px',
                width: "100%",
                "& .MuiInputBase-root": {
                  height: 40,
                  backgroundColor: "white",
                },
                "& .MuiOutlinedInput-input": {
                  height: 40,
                  width: "100%",
                },
              }}
            />
            <InputLabel sx={{ mt: 1 }}>
                Description <span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <TextField
              multiline
              rows={1}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              fullWidth
              sx={{
                mt: '5px',
                width: "100%",
                "& .MuiInputBase-root": {
                  backgroundColor: "white",
                },
              }}
            />
    
            {/* Submit Button */}
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              sx={{
                mt: 3,
                width: '10% ',
                height: '50px',
                borderRadius: '10px',
                backgroundColor: 'var(--button-color)',
                color: 'white', // Set text color to white
                fontWeight: 'normal',
                textTransform: 'none', // Prevent uppercase text
                ":hover": {
                  backgroundColor: 'var(--button-hover-color)',
                },
              }}
            >
             Submit
            </Button>
          </Box>
      }

    </Container>
  );
};

export default Feedback;
