import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Button, TextField, Select, MenuItem, InputLabel } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

const FieldConfigBuilder = ({ isOpen, handleClose, fieldConfig, onSubmit, onRemove }) => {
  const [contentType, setContentType] = useState('');
  const [text, setText] = useState('');
  const [level, setLevel] = useState('');

  // Initialize the form with fieldConfig values if provided
  useEffect(() => {
    console.log("field config as input: ", fieldConfig);
    if (fieldConfig && typeof fieldConfig === 'object') {
      setContentType(fieldConfig.contentType || '');
      setText(fieldConfig.text || '');
      setLevel(fieldConfig.level || '');
    }
  }, [fieldConfig]);

  const handleContentTypeChange = (event) => {
    setContentType(event.target.value);
  };

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  const handleLevelChange = (event) => {
    setLevel(event.target.value);
  };

  const handleSubmit = () => {
    const formData = { contentType, text, level };
    if (level === '') {
        delete formData['level'];
    };
    onSubmit(formData);
    handleClose();
  };

  const handleRemove = () => {
    onRemove();
    handleClose();
  };

  const handleCancel = () => {
    handleClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        Field Configuration
        <IconButton
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
          }}
          onClick={handleCancel}
        >
          <CancelIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {/* Content Type Input */}
        <InputLabel sx={{ mt: 2 }}>Content Type</InputLabel>
        <TextField
          fullWidth
          variant="outlined"
          value={contentType}
          onChange={handleContentTypeChange}
          sx={{
            marginBottom: '10px',
            "& .MuiInputBase-root": {
              height: 40,
              backgroundColor: 'white',
            },
            "& .MuiOutlinedInput-input": {
              height: 40,
              width: '100%',
            },
          }}
        />

        {/* Text Input */}
        <InputLabel sx={{ mt: 2 }}>Text</InputLabel>
        <TextField
          fullWidth
          variant="outlined"
          value={text}
          onChange={handleTextChange}
          sx={{
            marginBottom: '10px',
            "& .MuiInputBase-root": {
              height: 40,
              backgroundColor: 'white',
            },
            "& .MuiOutlinedInput-input": {
              height: 40,
              width: '100%',
            },
          }}
        />

        {/* Level Selector */}
        <InputLabel sx={{ mt: 2 }}>Level</InputLabel>
        <Select
          fullWidth
          value={level}
          onChange={handleLevelChange}
          displayEmpty
          sx={{ height: '40px', backgroundColor: 'white', marginBottom: '10px' }}
        >
          {['', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'].map((levelOption) => (
            <MenuItem key={levelOption} value={levelOption}>
              {levelOption}
            </MenuItem>
          ))}
        </Select>
      </DialogContent>
      <DialogActions sx={{ ml: 2,mr: 2, justifyContent: 'space-between' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled = {!contentType || !text}
          sx={{
            backgroundColor: 'var(--button-color)',
            color: 'white',
            fontWeight: 'normal',
            textTransform: 'none',
            ":hover": {
              backgroundColor: 'var(--button-hover-color)',
            },
          }}
        >
          Submit
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleRemove}
          sx={{
            backgroundColor: 'var(--button-color-red)',
            color: 'white',
            fontWeight: 'normal',
            textTransform: 'none',
            ":hover": {
              backgroundColor: 'var(--button-hover-color-red)',
            },
          }}
        >
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FieldConfigBuilder;