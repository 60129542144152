// src/components/AddGroupModal/AddGroupModal.js
import React, { useState } from 'react';
import { Modal, Box, Button, TextField, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const AddGroupModal = ({ open, handleClose, handleSubmit }) => {
  const [groupName, setGroupName] = useState(''); // Local state within the modal

  const handleModalSubmit = () => {
    handleSubmit(groupName); // Pass the groupName to the parent component
    setGroupName(''); // Reset the input field after submission
    handleClose(); // Close the modal
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="add-group-modal-title"
      aria-describedby="add-group-modal-description"
    >
      <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
        {/* Close Icon positioned at the top right corner */}
            <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              color: 'grey.600',
              ':hover': {
                color: 'black',
              },
            }}
          >
            <CloseIcon />
    </IconButton>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 300,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            m: 3, // Margin around the Box for spacing
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography id="add-group-modal-title" variant="h6" component="h2">
            Create Group
          </Typography>
          <TextField
            variant="outlined"
            placeholder="Group Name"
            type="text"
            fullWidth
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            sx={{
              mt: 3, // Margin top for space between the title and input
              mb: 2, // Margin bottom for space between input and button
              "& .MuiInputBase-root": {
                height: 40,
                backgroundColor: 'white',
              },
              "& .MuiOutlinedInput-input": {
                height: 40,
                width: '100%',
              },
            }}
          />
          <Button 
            onClick={handleModalSubmit} 
            sx={{
              mb: 2, // Margin bottom for space between buttons
              width: '100%',
              backgroundColor: 'var(--button-color)',
              color: 'white', 
              fontWeight: 'normal',
              textTransform: 'none',
              ":hover": {
                backgroundColor: 'var(--button-hover-color)',
              },
            }}
          >
            Submit
          </Button>
          <Button 
            variant="outlined"
            onClick={handleClose} 
            sx={{
                mt: 0,
                width: '100%',
                color: 'var(--button-color)', // Set text color to white
                backgroundColor: 'transparent',
                borderColor: 'var(--button-color)',
                textTransform: 'none', // Prevent uppercase text
                ":hover": {
                  borderColor: 'var(--button-color)',
                },
              }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddGroupModal;