import React, { useState, useEffect } from 'react';
import {
  Modal,
  Box,
  Typography,
  IconButton,
  Button,
  MenuItem,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import StarIcon from '@mui/icons-material/Star';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import CircleIcon from '@mui/icons-material/Circle';

const Rating = ({ open, onClose, onSubmit, rating }) => {
  const [range, setRange] = useState(5); // Default range for ratings
  const [icon, setIcon] = useState('StarIcon'); // Default selected icon
  const [error, setError] = useState(''); // Error message state

  const iconOptions = [
    { label: '', value: 'StarIcon', component: <StarIcon /> },
    { label: '', value: 'FavoriteIcon', component: <FavoriteIcon /> },
    { label: '', value: 'ThumbUpIcon', component: <ThumbUpIcon /> },
    { label: '', value: 'CircleIcon', component: <CircleIcon /> },
  ];

  useEffect(() => {
    if (rating) {
      setRange(rating.range || 5);
      setIcon(rating.icon || 'StarIcon');
    }
  }, [rating]);

  const handleRangeChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (value > 10) {
      setError('Maximum range is 10');
    } else {
      setError('');
      setRange(value);
    }
  };

  const handleSubmit = () => {
    if (!error) {
      const ratingData = { range, icon };
      onSubmit(ratingData);
      onClose();
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 500,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        {/* Close Button */}
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
          }}
        >
          <CloseIcon />
        </IconButton>

        <Typography variant="h6" component="h2" gutterBottom>
          Rating Configuration
        </Typography>

        {/* Range Input */}
        <TextField
          label="Range"
          type="number"
          value={range}
          onChange={handleRangeChange}
          fullWidth
          sx={{
            mt: 2,
            '& .MuiInputBase-root': {
              height: 40,
              backgroundColor: 'white',
            },
            '& .MuiOutlinedInput-input': {
              height: 40,
              width: '100%',
            },
          }}
        />
        {error && (
          <Typography color="error" variant="body2" sx={{ mt: 1 }}>
            {error}
          </Typography>
        )}

        {/* Icon Dropdown */}
        <TextField
          select
          label="Select Icon"
          value={icon}
          onChange={(e) => setIcon(e.target.value)}
          fullWidth
          sx={{
            mt: 2,
            "& .MuiInputBase-root": {
              height: 40,
              backgroundColor: 'white',
            },
          }}
        >
          {iconOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.component} {option.label}
            </MenuItem>
          ))}
        </TextField>

        {/* Submit Button */}
        <Button
          variant="contained"
          onClick={handleSubmit}
          sx={{
            mt: 2,
            width: '100%',
            backgroundColor: 'var(--button-color)',
            color: 'white',
            fontWeight: 'normal',
            textTransform: 'none',
            ":hover": {
              backgroundColor: 'var(--button-hover-color)',
            },
          }}
        >
          Submit
        </Button>

        {/* Cancel Button */}
        <Button
          variant="outlined"
          onClick={onClose}
          sx={{
            mt: 2,
            width: '100%',
            color: 'var(--button-color)',
            backgroundColor: 'transparent',
            borderColor: 'var(--button-color)',
            textTransform: 'none',
            ":hover": {
              borderColor: 'var(--button-color)',
            },
          }}
        >
          Cancel
        </Button>
      </Box>
    </Modal>
  );
};

export default Rating;
