import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Form from './Form';
import Modal from 'react-modal';
import FormSelection from './modules/FormSelection/FormSelection';
import FieldBuilder from './modules/FormBuilder/FieldBuilder/FieldBuilder';
import SectionBuilder from './modules/FormBuilder/SectionBuilder/SectionBuilder';
import FormSectionsBuilder from './modules/FormBuilder/FormSectionsBuilder/FormSectionsBuilder';
import FieldOptionsBuilder from './modules/FormBuilder/FieldOptionsBuilder/FieldOptionsBuilder';
import AuthComponent from './modules/AuthComponent/AuthComponent';
import AuthComponentSignUp from './modules/AuthComponentSignUp/AuthComponentSignUp';
import FieldOptionsSelector from './modules/FormBuilder/FieldOptionsSelector/FieldOptionsSelector';
import ViewForms from './modules/FormViewer/ViewForms/ViewForms';
import authService from './common/services/auth.service';
import LandingPage from './modules/FormBuilder/LandingPage/LandingPage';
import SideMenu from './modules/SideMenu/SideMenu';
import NewOptionsBuilder from './modules/NewOptionsBuilder/NewOptionsBuilder';
import LandingPageMUI from './modules/LandingPage/LandingPage';
import SignUpType from './modules/SignUpType/SignUpType';
import SignUpOrSignIn from './modules/SignUpOrSignIn/SignUpOrSignIn';
// Stripe Components
import Subscription from './modules/Stripe/Subscription/Subscription';
import SubscriptionValidation from './modules/Stripe/SubscriptionValidation/SubscriptionValidation';
import SubscriptionManagement from './modules/Stripe/SubscriptionManagement/SubscriptionManagement';
import StripeContext from './StripeContext';


// Set the app element for React-Modal
Modal.setAppElement('#root');

function App() {
  const auth = authService;
  console.log(auth);
  return (
    <StripeContext>
    <Router>
      <Routes>
        <Route path="/:formId" element={<Form />} />
        {/* <Route path="/" element={<Form />} /> */}
        {/* <Route path="/" element={<FormSelection />} /> */}
        <Route path="/getForm/:formId" element={<Form />} />
        <Route path="/optionsBuilder" element={<FieldOptionsBuilder />} />
        <Route path="/fieldBuilder" element={<FieldBuilder />} />
        <Route path="/sectionBuilder" element={<SectionBuilder />} />
        <Route path="/formBuilder" element={<FormSectionsBuilder auth = {auth} />} />
        <Route path="/signIn" element={<AuthComponent  auth={auth}/>} />
        <Route path="/changePassword" element={<AuthComponent  auth={auth} changePassword = {true}/>} />
        <Route path="/signUp" element={<AuthComponentSignUp  auth={auth}/>} />
        <Route path="/signUpType" element={<SignUpType  auth={auth}/>} />
        <Route path="/optionSelector" element={<FieldOptionsSelector />} />
        <Route path="/viewForms" element={<ViewForms />} />
        <Route path="/landingPage" element={<LandingPage />} />
        <Route path="/sideMenu" element={<SideMenu />} />
        <Route path="/landingPageMUI" element={<LandingPageMUI />} />
        {/* <Route path="/" element={<LandingPageMUI />} /> */}
        <Route path="/" element={<SignUpOrSignIn />} />
        <Route path="/formDemo" element={<LandingPage />} />
        <Route path="/displayForm/:companyAlias/:formId" element={<Form  displayForm = {true}/>} />
        <Route path="/StripePayment" element={< Subscription/>} />
        <Route path="/subscriptionValidation" element={< SubscriptionValidation/>} />
        <Route path="/subscriptionManagement" element={< SubscriptionManagement/>} />
      </Routes>
    </Router>
  </StripeContext>
  );
}

export default App;
