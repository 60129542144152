export const OptionsJSONTemplate = [{
    "VALID_VALUE_ID": "",
    "VALID_VALUE_SHORT_DESC": "",
    "VALID_VALUE_DESCRIPTION": "",
    "PARAMETER_ID": "",
    "PARAMETER": "",
    "FILTER1": null,
    "FILTER2": null,
    "FILTER3": null,
    "FILTER4": null,
    "COUNTER": 2,
    "ENTITY": "",
    "VERSION": 1,
    "ATTRIBUTE1": null,
    "ATTRIBUTE2": null,
    "VALID_VALUE_ADDITIONAL_TEXT": null
}];

export const OptionsJSONTemplate2 = {
    "VALID_VALUE_ID": "",
    "VALID_VALUE_SHORT_DESC": "",
    "VALID_VALUE_DESCRIPTION": "",
    "PARAMETER_ID": "",
    "PARAMETER": "",
    "FILTER1": null,
    "FILTER2": null,
    "FILTER3": null,
    "FILTER4": null,
    "COUNTER": 2,
    "ENTITY": "",
    "VERSION": 1,
    "ATTRIBUTE1": null,
    "ATTRIBUTE2": null,
    "VALID_VALUE_ADDITIONAL_TEXT": null
};

export const FieldJSONTemplate = {
    "name": "ModeDetails",
    "label": "Declaration",
    "type": "dropdown",
    "dataType": "string",
    "visible": false,
    "required": true,
    "api": {
        "url": "",
        "label": "",
        "value": "",
        "displayPattern": ""
    },
    "dependent": [
        {
            "title": "",
            "field": ""
        }
    ]
};


export const SectionJSONTemplate = {
    title: "",
    FieldJSONTemplate
};


export const FormJSONTemplate = {

}


export const initialFieldState = {
    description: '',
    name: '',
    label: '',
    type: 'text',
    dataType: 'string',
    length: 50,
    required: false,
    wrapping: false,
    visible: true,
    placeholdertext: '',
    controlType: '',
    options: '',
    dependent: '',
    config: '',
    sessionID: '',
    validate: {},
    descriptionName: '',
  };
  