import React, { useEffect, useState } from 'react';
import { Modal as MUIModal, Box, Button, TextField, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const Modal = ({ isOpen, closeModal, saveValue, inputValue, setInputValue }) => {

  const [open, setOpen] = useState(false);

  useEffect(() => {
    console.log("Modal Props: ", isOpen);
    setOpen(isOpen);
  }, [isOpen]);

  const saveValueModal = () => {
    saveValue(inputValue);
    closeModal();
  };

  return (
    <MUIModal open={open} onClose={closeModal}>
      <Box 
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 5,
          borderRadius: 2,
        }}
      >
        <IconButton
          sx={{
            position: 'absolute',
            top: 1,
            right: 1,
          }}
          onClick={closeModal}
        >
          <CloseIcon />
        </IconButton>
        <form id="popupForm">
          <TextField
            // label="Enter a Value"
            placeholder='Enter Header'
            variant="outlined"
            fullWidth
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            sx={{
              marginBottom: '16px',
              "& .MuiInputBase-root": {
                height: 40,
                backgroundColor: 'white',
              },
              "& .MuiOutlinedInput-input": {
                height: 40,
                width: '100%',
              },
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={saveValueModal}
            sx={{
              mt: 0,
              width: '100%',
              backgroundColor: 'var(--button-color)',
              color: 'white', // Set text color to white
              fontWeight: 'normal',
              textTransform: 'none', // Prevent uppercase text
              ":hover": {
                backgroundColor: 'var(--button-hover-color)',
              },
            }}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            onClick={closeModal}
            sx={{
              mt: 2,
              width: '100%',
              color: 'var(--button-color)', // Set text color to white
              backgroundColor: 'transparent',
              borderColor: 'var(--button-color)',
              textTransform: 'none', // Prevent uppercase text
              ":hover": {
                borderColor: 'var(--button-color)',
              },
            }}
          >
            Cancel
          </Button>
        </form>
      </Box>
    </MUIModal>
  );
};

export default Modal;