import React, { useState, useEffect } from 'react';
import apiGatewayService from '../../../common/services/apiGatewayService';
import localStorageService from '../../../common/services/localStorage.service';
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  MenuItem,
  InputLabel,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import utilityFunctions from '../../../common/services/utilityFunctions';
import ReactSelect from 'react-select';

const FieldsDependentSelector = ({ isOpen, handleClose, submitdependent, removedependent, dependent }) => {
  const [availableFields, setAvailableFields] = useState([]);
  const [selectedField, setSelectedField] = useState(null);
  const [dependentTitle, setDependentTitle] = useState('');
  const [searchQuery, setSearchQuery] = useState(''); // New state for filtering
  const [selectedFieldDescription, setSelectedFieldDescription] = useState('');
  const [selectedFieldDisplayName, setSelectedFieldDisplayName] = useState('');
  
  const [unlocked, setUnlocked] = useState(false); // New state for lock/unlock, default is 'lock'
  const [conditions, setConditions] = useState([]); // State to manage the list of input values

  useEffect(() => {
    if (Array.isArray(dependent) && dependent.length > 0) {
      const displayName = dependent[0].description 
      ? `${dependent[0].field} - (${dependent[0].description})` 
      : `${dependent[0].field}`;    
      setSelectedField({fieldName: dependent[0].field, fieldDescription: dependent[0]?.description, fieldDisplayName: displayName, value:  displayName, label: displayName});
      setSelectedFieldDescription(dependent[0]?.description || '');
      setSelectedFieldDisplayName(`${dependent[0].field} - (${dependent[0].description})`);
      setUnlocked(dependent[0]?.unlocked || false);
      setConditions(dependent[0]?.conditions || []);
    }
  }, [dependent]);

  useEffect(() => {
    const getAllFields = async (user) => {
      try {
        const response = await apiGatewayService.getFieldsData();
        const data = utilityFunctions.updateFieldNamesWithDescription(response.data);
        const dataSorted = utilityFunctions.sortByKey(data, 'fieldDisplayName');
        const formattedFields = dataSorted.map((field) => ({
          ...field,
          value: field.fieldDisplayName,
          label: field.fieldDisplayName,
        }));
        setAvailableFields(formattedFields);
      } catch (error) {
        console.error('Error fetching fields:', error);
      }
    };
    const user = localStorageService.getObject('user');
    getAllFields(user);
  }, []);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleFieldSelect = (event) => {
    console.log("selectedField: ", event);
    if(event!==null){
      const selectedField = event;
      // const selectedField = availableFields.find((option) => option.fieldDisplayName === event.target.value);
      setSelectedField(selectedField);
      setSelectedFieldDisplayName(selectedField.fieldDisplayName);
      setSelectedFieldDescription(selectedField.fieldDescription);
    }
  };

  const handleSubmit = () => {
    console.log("type of: ", typeof unlocked);
    const dependent = generateDependentObjectForField();
    submitdependent(dependent);
    handleClose();
  };

  // Adding/Removing input fields in the bucket (multi-input functionality)
  const handleAddInput = () => {
    if (conditions.length<10){
      setConditions([...conditions, {value: ''}]);
    }
  };

  const handleInputChange = (index, value) => {
    const updatedValues = [...conditions];
    updatedValues[index].value = value;
    setConditions(updatedValues);
  };

  const handleRemoveInput = (index) => {
    const updatedValues = conditions.filter((_, i) => i !== index);
    setConditions(updatedValues);
  };

  const generateDependentObjectForField = () => {
    const currConditions = conditions.filter((c)=>c.value!=='');
    const dependent = {
      field: selectedField.fieldName,
      description: selectedField.fieldDescription,
      conditions: currConditions,
      unlocked: unlocked,
    };
    return dependent;
  };

  const handleUnlockChange = (status) => {
    const value = status === 'true' ? true : false;
    setUnlocked(value);
  };

  // const filteredFields = availableFields.filter((field) =>
  //   field.fieldName.toLowerCase().includes(searchQuery.toLowerCase())
  // );

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="fields-dependent-selector-title"
      aria-describedby="fields-dependent-selector-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '8px',
          boxShadow: 24,
          width: '500px',
          outline: 'none',
        }}
      >
        <IconButton
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>

        {/* <Typography id="fields-dependent-selector-title" variant="h6" component="h2" gutterBottom>
          Select dependent
        </Typography> */}
        <h3>Field Dependent</h3>

        <hr></hr>


        <Typography variant="body1" sx = {{mt: "20px", mb:1,}}>Field</Typography>

        <ReactSelect
                value={selectedField}
                onChange={handleFieldSelect}
                options={availableFields}
                placeholder="Select Field"
                isClearable
                isSearchable
                styles={{
                  control: (base) => ({
                    ...base,
                    height: 40,
                    backgroundColor: 'white',
                  }),
                  menu: (base) => ({
                    ...base,
                    zIndex: 100,
                  }),
                }}
          />

        {/* <TextField
          fullWidth
          variant="outlined"
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Search dependents"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{
            marginTop: '10px',
            marginBottom: '16px',
            "& .MuiInputBase-root": {
              height: 40,
              backgroundColor: 'white',
            },
            "& .MuiOutlinedInput-input": {
              height: 40,
              width: '100%',
            },
          }}
        />

        <TextField
          select
          fullWidth
          value={selectedFieldDisplayName}
          onChange={handleFieldSelect}
          displayEmpty
          variant="outlined"
          sx={{
            marginBottom: '16px',
            "& .MuiInputBase-root": {
              height: 40,
              backgroundColor: 'white',
            },
          }}
        >
          <MenuItem value="" disabled>
            <em>Select a dependent field</em>
          </MenuItem>
          {filteredFields.map((option, index) => (
            <MenuItem key={index} value={option.fieldDisplayName}>
              {option.fieldDisplayName}
            </MenuItem>
          ))}
        </TextField> */}


        <Typography variant="body1" sx = {{mt: "20px"}}>User Input</Typography>
        {/* Lock/Unlock toggle using Radio Buttons */}
        <FormControl component="fieldset" sx={{ mb: 1 }}>
          {/* <FormLabel component="legend">Lock/Unlock</FormLabel> */}
          <RadioGroup
            row
            aria-label="lock-unlock"
            name="lock-unlock-group"
            value={unlocked}
            onChange={(e) => handleUnlockChange(e.target.value)}
            color='var(--app-color)'
          >
            <FormControlLabel value={false} control={<Radio sx={{
              color: 'var(--app-color)',
              '&.Mui-checked': {
                color: 'var(--app-color)',
              },
            }} />} label="Lock" />
            <FormControlLabel value={true} control={<Radio sx={{
              color: 'var(--app-color)',
              '&.Mui-checked': {
                color: 'var(--app-color)',
              },
            }} />} label="Unlock" />
          </RadioGroup>
        </FormControl>

        {/* Multi-input bucket for adding and removing input values */}
        <Typography variant="body1" sx = {{mt: '5px'}}>Conditions</Typography>
        <List sx={{ m: 0, p: 0 }}>
        <Grid container spacing={0}>
          {conditions.map((inputValue, index) => (
            <Grid item xs={12} sm={6} key={index}> {/* Set to half-width (6 out of 12) for small screens */}
              <ListItem sx={{ p: 0, mt: 1 }}>
                <Grid container alignItems="center">
                  <Grid item xs={10}>
                    <TextField
                      value={inputValue?.value}
                      onChange={(e) => handleInputChange(index, e.target.value)}
                      fullWidth
                      placeholder={`Condition ${index + 1}`}
                      sx={{
                        "& .MuiInputBase-root": {
                          height: 40,
                          backgroundColor: 'white',
                        },
                        "& .MuiOutlinedInput-input": {
                          height: 40,
                          width: '100%',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton onClick={() => handleRemoveInput(index)}>
                      <DeleteIcon sx={{ color: 'red' }} />
                    </IconButton>
                  </Grid>
                </Grid>
              </ListItem>
            </Grid>
          ))}
        </Grid>
      </List>




        {/* Full-width Add Input button */}
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddInput}
          fullWidth
          sx={{
            mt:1,
            mb:2,
            backgroundColor: 'var(--button-color)',
            color: 'white',
            fontWeight: 'normal',
            textTransform: 'none',
            ":hover": {
              backgroundColor: 'var(--button-hover-color)',
            },
          }}
        >
          Add Condition
        </Button>

        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            disabled={!selectedField}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            sx={{
              backgroundColor: 'var(--button-color)',
              color: 'white',
              fontWeight: 'normal',
              textTransform: 'none',
              ":hover": {
                backgroundColor: 'var(--button-hover-color)',
              },
            }}
          >
            Update
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={removedependent}
            sx={{
              backgroundColor: 'var(--button-color-red)',
              color: 'white',
              fontWeight: 'normal',
              textTransform: 'none',
              ":hover": {
                backgroundColor: 'var(--button-hover-color-red)',
              },
            }}
          >
            Remove
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default FieldsDependentSelector;
